import React, { useState,useEffect } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  CardActions,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FetchManagerListAPI from "../../redux/actions/api/ReferenceApplications/FetchManagerList";
import { useDispatch, useSelector } from "react-redux";
import { APITransport } from "../../redux/actions";

function RequestAccessModal({ appDetails, setOpenModal,handleRequestAccessButtonClick }) {
  const dispatch = useDispatch();
  const [selectManager, setSelectManager] = useState("");
  const ceoEmail = process.env.REACT_APP_CEO_EMAIL?process.env.REACT_APP_CEO_EMAIL:"ceo-dibd@digitalindia.gov.in";
  const storedAppDetails = localStorage.getItem('appDetails');
  const appValue= JSON.parse(storedAppDetails)
  const managerList = useSelector(
    (state) => state.fetchManagerList?.data?.requests

  );
  const processApiData = () => {
    if (!Array.isArray(managerList)) {
      return [];
    }
    const transformedItems = managerList?.map(item => ({
      firstname: item.first_name , 
      lastname: item.last_name ,   
      email: item.official_email  
    }));

    const additionalItem = { firstname: "NA /", lastname: "Others", email: "NA" };

    if (!Array.isArray(transformedItems)) {
      return [additionalItem]; 
    }

    return [...transformedItems, additionalItem];
  };

  const handleChange = (event) => {
    setSelectManager(event.target.value);
  };

  const FetchingManagerList = () => {
    const payload={   "status": [ "Approved","Registered"] }
    const reqObj = new FetchManagerListAPI(payload);
    dispatch(APITransport(reqObj));
  };

  useEffect(() => {
    FetchingManagerList();
  }, []);

  const handleClick = () =>{
    handleRequestAccessButtonClick(selectManager==="NA"?ceoEmail:selectManager)
  }

  return (
    <>
      <IconButton
        onClick={() => setOpenModal(false)}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Card sx={{ maxWidth: 405, position: "relative",mt:1 }}>
        <CardMedia
          component="img"
          alt="app icon"
          height="140"
          image={appValue?.icon}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{
              fontSize: "24px",
              fontFamily: "Inter, sans-serif",
              fontWeight: "600",
            }}
          >
            {appValue?.name}
            <Typography
              gutterBottom
              variant="body2"
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            >
               {appValue?.description}
            </Typography>
          </Typography>

          <Typography variant="body2" sx={{ fontFamily: "Inter, sans-serif" }}>
          {appValue?.tagline}
          </Typography>
          <Grid sx={{ mt: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontFamily: "Inter, sans-serif" }}
            >
              Select Onboarding Manager
            </Typography>
            <FormControl sx={{ minWidth: 250, height: "auto" }} size="small">
              <InputLabel
                id="demo-select-small-label"
                sx={{ fontSize: "14px", mt: 1 }}
              >
                Select Manager
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectManager}
                label="Select Manager"
                onChange={handleChange}
                sx={{ fontSize: "12px", mt: 1 }}
              >
                {processApiData()?.map((item) => (
                  <MenuItem key={item.email} value={item.email}>
                    {item.firstname} {item.lastname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained" size="small" sx={{ borderRadius: "5px" }} onClick={handleClick}>
            Request Access
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

export default RequestAccessModal;
