/**
 * Login API
 */
import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class UserProfileSubmitAPI extends API {
  constructor(file, json_data, timeout = 2000) {
    super("POST", timeout, false);
    this.type = C.USER_PROFILE_SUBMIT;
    this.file = file;
    this.json_data = json_data;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.userProfileSubmit}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    const formData = new FormData();
    formData.append('govt_document', this.file);
    formData.append('json_data', JSON.stringify(this.json_data));
    console.log("formData --- ", formData);
    return formData
  }

  getHeaders() {
    this.headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        "authorization": localStorage.getItem("token")
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report
  }
}
