// referenceApplicationRequest


import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class getApplicationDetailsApi extends API {
    constructor(name,email, timeout = 2000) {
        super("POST", timeout, false);
        this.type = C.GET_APPLICATION_DETAILS;
        this.name = name;
        this.email = email;
      
        this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.getApplicationDetails}`;
    }

    processResponse(res) {
        super.processResponse(res);
        if (res) {
            this.report = res;
        }
    }

    apiEndPoint() {
        return this.endpoint;
    }

    getBody() {
        return {
            requested_by_email: this.email,
            application_name: this.name,
           
          };
    }

    getHeaders() {
        this.headers = {
            headers: {
                "Content-Type": "application/json",
                "authorization": localStorage.getItem("token")
            },
        };
        return this.headers;
    }

    getPayload() {
        return this.report
    }
}
