// RequestActionDialog

import React, {useEffect, useState} from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { styled } from "@mui/material/styles";
import RequestDetails from "./RequestDetails";
import {UserDetails} from "./UserDetails";
import { APITransport } from "../../redux/actions";
import FetchcustomerCompleteDetailsAPI from "../../redux/actions/api/Dashboard/FetchCustomerRequests/customerCompleteDetails";

const BlueTab = styled(Tab)(({ theme }) => ({
    color: "#656565",
    fontFamily:"Inter, sans-serif",
    fontWeight: 'normal',
    "&.Mui-selected": {
      color: "#2563EB",
      fontWeight: 'normal'
    },
    "&:hover": {
      color: "#656565",
      fontWeight: 'normal'
    }
  }));

  const CustomTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
      backgroundColor: '#2563EB', 
      height: '3px',
    },
  }));
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{color:"#656565"}}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

 const RequestAction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [rowData, setRowData] = React.useState();
  const requestData = location?.state
   const role = localStorage.getItem('role')
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const customerCompleteDetail = useSelector(
    (state) => state.fetchcustomerCompleteDetails?.data
);

  const customerCompleteDetails=()=>{
    const apiObj = new FetchcustomerCompleteDetailsAPI(requestData[3])
    dispatch(APITransport(apiObj))
  }
  useEffect(()=>{
    customerCompleteDetails()
    localStorage.setItem('tabId', role === 'ceo' ? 1 : 0)
},[])

  return (
    <Container maxWidth="lg">
      <Paper elevation={3}>
        <Button
          variant="text"
          color="inherit"
          onClick={() =>   navigate("/user-management",{ state: {replace: true} })
        }
          sx={{mt: "16px",mb:2}}
        >
          <ArrowBackOutlinedIcon /> &nbsp; Customers
        </Button>

        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{pl:3}}
        >
          <BlueTab label="Requests" {...a11yProps(0)} />
          <BlueTab label="User Details" {...a11yProps(1)} />
        </CustomTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <RequestDetails customerCompleteDetail={customerCompleteDetail}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UserDetails customerCompleteDetail={customerCompleteDetail} />
     </CustomTabPanel>
    </Box>
      </Paper>
    </Container>
  );
};
export default RequestAction;