import {
  Paper,
  Typography,
  Box,
  styled,
  FormControlLabel,
  Radio,
  Tooltip,
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch, useSelector } from "react-redux";
import { APITransport } from "../../../redux/actions";
import UserProfileSubmitAPI from "../../../redux/actions/api/UserManagement/UserProfileSubmit";
import { setSnackBar } from "../../../redux/actions/Common";
import { useNavigate } from "react-router-dom";
import GetUserDetailsAPI from "../../../redux/actions/api/UserManagement/GetUserDetails";

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: 558,
  height: "auto",
  margin: "auto",
  marginTop: theme.spacing(5),
  marginBottom: "40px",
  padding: "60px",
}));

const Container = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
});

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apistatus = useSelector(state => state.apistatus);
  const userDetails = useSelector(state => state.getUserDetails.data);
  const [selectedUserType, setSelectedUserType] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocumentOption, setSelectedDocumentOption] = useState("aadhar");
  const [orgName, setOrgName] = useState("");
  const [industry, setIndustry] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [governmentId, setGovernmentId] = useState(""); // State for government ID
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };



  const handleOrgNameChange = (event) => {
    setOrgName(event.target.value);
  };

  const handleIndustryChange = (event) => {
    setIndustry(event.target.value);
  };

  const handleEmployeeCountChange = (event) => {
    setEmployeeCount(event.target.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf" && file.size <= 5 * 1024 * 1024) {
      setSelectedFile(file);
    } else {
      console.error("Invalid file. Please upload a valid PDF file (max 5MB).");
    }
  };

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedUserType(value === "organisation");
  };

  const handleGovernmentIdChange = (event) => {
    setGovernmentId(event.target.value);
  };

  const UpdateProfileClick = () => {
    const postData = {
      representative_type: selectedUserType ? "organisation" : "individual",
      government_doc_type: selectedDocumentOption,
      government_id: governmentId,
      organisation_name: orgName,
      organisation_type: industry,
      employee_count: employeeCount,
    };

    // console.log("Posting data:", postData);
    // console.log("SelectedFile:", selectedFile);

    const reqObj = new UserProfileSubmitAPI(selectedFile, postData);
    dispatch(APITransport(reqObj));

    const fetchUpdatedBasicUserDetails = setTimeout(() => {
      const getBasicUserDetails = new GetUserDetailsAPI('basic');
      dispatch(APITransport(getBasicUserDetails));
      clearTimeout(fetchUpdatedBasicUserDetails);
    }, 1500);
  };

  useEffect(() => {
    console.log("apistatus --- ", apistatus)
    if (apistatus?.apiType === "USER_PROFILE_SUBMIT") {
      dispatch(setSnackBar({
        open: true,
        message: apistatus?.message || "Something went wrong",
        variant: apistatus.success ? "success" : "error"
      }));

    }
  }, [apistatus])

  useEffect(() => {
    if (userDetails?.basicDetails?.is_profile_updated) {
      navigate("/applications")
    }
  }, [userDetails])

  return (
    <StyledPaper>
      <Typography
        variant="h5"
        fontFamily={"Inter, sans-serif"}
        fontWeight={600}
        textAlign={"start"}
      >
       Are you an individual or representing
an organisation?
      </Typography>

      <Box display="flex" gap={2} mt={3}>
        <FormControlLabel
          control={
            <Radio
              onChange={handleOptionChange}
              value="Individual"
              name="radio-buttons"
              checked={!selectedUserType}
              inputProps={{ "aria-label": "Individual" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 28,
                },
              }}
            />
          }
          label="Individual"
        />

        <FormControlLabel
          control={
            <Radio
              checked={selectedUserType}
              onChange={handleOptionChange}
              value="organisation"
              name="radio-buttons"
              inputProps={{ "aria-label": "Organisation" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 28,
                },
              }}
            />
          }
          label="Organisation"
        />
      </Box>

      <div>
        {!selectedUserType ? (
          <div>
            <Typography
              variant="h5"
              fontFamily={"Inter, sans-serif"}
              fontWeight={600}
              textAlign={"start"}
              mt={3}
            >
              Select Document Type
            </Typography>
            <Box display="flex" gap={2} mt={2} mb={2}>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedDocumentOption === "aadhar"}
                    onChange={() => setSelectedDocumentOption("aadhar")}
                    value="aadhar"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "Individual" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label="Aadhar"
              />

              <FormControlLabel
                control={
                  <Radio
                    checked={selectedDocumentOption === "pan"}
                    onChange={() => setSelectedDocumentOption("pan")}
                    value="pan"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "Individual" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label="Pan Card"
              />
            </Box>
          </div>
        ) : (
          <Box display="flex" flexDirection="column" gap={3} mt={3} mb={3}>
            <TextField
              id="outlined-basic"
              label="Organization Name*"
              variant="outlined"
              fullWidth
              value={orgName}
              onChange={handleOrgNameChange}
            />
            <FormControl fullWidth className="mb-3">
              <InputLabel id="demo-simple-select-label">Industry</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={industry}
                label="Industry"
                onChange={handleIndustryChange}
              >
                <MenuItem value="">Select an Industry</MenuItem>
                <MenuItem value={"IT"}>Information Technology</MenuItem>
                <MenuItem value={"Finance"}>Finance</MenuItem>
                <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="outlined-basic"
              label="Employee Count*"
              variant="outlined"
              fullWidth
              value={employeeCount}
              onChange={handleEmployeeCountChange}
            />
          </Box>
        )}

        <div
          style={{
            backgroundColor: "#f4f4f4",
            padding: "20px",
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <div
            style={{ maxWidth: "600px", margin: "0 auto", padding: "20px" }}
          >
            <Typography
              variant="h6"
              sx={{ color: "black" }}
              fontFamily={"Inter, sans-serif"}
            >
              Upload document
            </Typography>
            <Typography
              variant="body2"
              sx={{ paddingTop: "15px", color: "#4D4D4D" }}
            >
              Drag to upload
            </Typography>
            <Typography
              variant="body2"
              sx={{ paddingTop: "5px", color: "#4D4D4D" }}
            >
              or
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<CloudUploadIcon />}
              component="label"
              sx={{ marginY: 2, fontWeight: "600", borderWidth: 2 }}
            >
              Upload
              <input
                type="file"
                style={{ display: "none" }}
                accept=".pdf"
                onChange={handleFileChange}
              />
            </Button>

            <div style={{ display: "flex", flexWrap: "wrap", justifyContent:"center" }}>
              {selectedFile && (
                <div>
                  <p>Selected file: {selectedFile.name}</p>
                  <embed
                    src={URL.createObjectURL(selectedFile)}
                    width="100"
                    height="100"
                    type="application/pdf"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Box mt={4}>
        <TextField
          id="outlined-basic"
          label={selectedDocumentOption === "aadhar" ? "Aadhar Number*" : 'Pan Number*'}
          variant="outlined"
          fullWidth
          value={governmentId}
          onChange={handleGovernmentIdChange}
        />
      </Box>

      <Box my={2}>
      <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="I agree to provide my information and enrol for onboarding with Bhashini"
          // style={{ marginTop: '20px' }}
        />
      </Box>

      <Box mt={4} display="flex" justifyContent="end">
        <Button
          variant="contained"
          sx={{ borderRadius: "4px" }}
          onClick={UpdateProfileClick}
          disabled={governmentId === '' ||  !checked || selectedFile === null}
        >
          Submit
        </Button>
      </Box>
    </StyledPaper>
  );
};

export default UpdateProfile;
