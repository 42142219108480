/**
 * Fetch Onboard User API
 */
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchManagerRequestAPI extends API {
  constructor(timeout = 2000) {
    super("POST", timeout, false);
    this.type = C.FETCH_MANAGER_REQUESTS;
    this.endpoint = `${super.apiEndPointAuto()}${
      ENDPOINTS.getManagerRequests
    }?page=${1}&page_size=${2000}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
      this.report = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() { return {
        "status": [] 
    };}

  getHeaders() {
    const authorization = localStorage.getItem("token");
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report;
  }
}
