// RequestActionDialog

import React, {useEffect, useState} from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  Avatar,
  Modal
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {useLocation, useNavigate} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {APITransport} from "../../redux/actions";
import updateManagerRegistrationAPI from "../../redux/actions/api/Dashboard/UpdateManagerRegistration/UpdateManagerRegistration";
import RevokeManagerAPI from "../../redux/actions/api/Dashboard/RevokeManager/RevokeManager";
import FetchManagerRequestAPI from "../../redux/actions/api/Dashboard/FetchManagerRequests/FetchManagerRequest";
import {
  setSnackBar,
  setUpdateManagerRegistration,
} from "../../redux/actions/Common";
import CustomModal from "../common/Modal";
import RejectModal from "./RejectModal";
import {getFormattedDate} from "../../utils/utils";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  p: 4,
};

export const CeoRequestAction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const requestData = location.state;
  const dispatch = useDispatch();

  const updateManagerStatus = useSelector(
    (state) => state.updateManagerRegistration?.onboardManagerResult ?? {}
  );
  const email = localStorage.getItem("email") ?? "";
  const [status, setStatus] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleButtonClick = (appStatusUpdate) => {
    const apiObj = new updateManagerRegistrationAPI(
      requestData._id,
      appStatusUpdate
    );
    dispatch(APITransport(apiObj));
  };

  useEffect(() => {
    localStorage.setItem('tabId', 0)
  }, [])

  const fetchManagerRequestsData = (() => {
    const apiObj = new FetchManagerRequestAPI();
    dispatch(APITransport(apiObj));
}, [dispatch]);

  const RevokeManager = async()=>{  
    try {
      const apiObj = new RevokeManagerAPI(requestData?.official_email);
      await dispatch(APITransport(apiObj));
      fetchManagerRequestsData();
    } catch (error) {
      console.error('Error during RevokeManager:', error);
    }
}

const handleClickOk = () =>{
  RevokeManager()
  setOpen(false)
  navigate("/user-management")
}
  const onActionButtonClick = (value) => {
    let appStatusUpdate;
    if (value) {
      setStatus(true);
      appStatusUpdate = {
        status: "Approved",
        updated_by: email,
        updater_name: "Abhilasha Singh",
      };

      handleButtonClick(appStatusUpdate);
    } else if (!value) {
      setOpenModal(true);
      setStatus(false);
    }
  };

  useEffect(() => {
    if (updateManagerStatus.status === "OK" && status) {
      dispatch(
        setSnackBar({
          open: true,
          message: "Request Approved Successfully",
          variant: "success",
        })
      );
      dispatch(setUpdateManagerRegistration({}));
      navigate(-1);
    } else if (updateManagerStatus.status === "OK" && !status) {
      dispatch(
        setSnackBar({
          open: true,
          message: "Request Rejected Successfully",
          variant: "success",
        })
      );
      dispatch(setUpdateManagerRegistration({}));
      navigate(-1);
    }
  }, [updateManagerStatus]);

  const renderActionStatus = (status) => {
    switch (status) {
      case "Approved":
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="12px 24px"
            borderRadius="40px"
            bgcolor={"#DEFFDB"}
            color={"#0C8501"}
            fontWeight={600}
            fontSize={"16px"}
            width="fit-content"
            marginLeft={"auto"}
          >
            <Typography>Approved</Typography>
          </Box>
        );

      case "Rejected":
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="12px 24px"
            borderRadius="40px"
            bgcolor={"#FFF1F0"}
            color={"#E02B1D"}
            fontWeight={600}
            fontSize={"16px"}
            width="fit-content"
            marginLeft={"auto"}
          >
            <Typography>Rejected</Typography>
          </Box>
        );
        case "Registered":
          return (
            <Box
            display="flex"
            justifyContent="flex-end"
            padding="12px 24px"
            borderRadius="40px"
            bgcolor={"#DEFFDB"}
            color={"#0C8501"}
            fontWeight={600}
            fontSize={"16px"}
            width="fit-content"
            marginLeft={"auto"}
            >
              <Typography>Registered</Typography>
            </Box>
          );
      default:
        return (         
          <Box display="flex" justifyContent="flex-end">
          { (requestData?.reg_type ==="Direct" && requestData?.status === "Pending") && <>
            <Button
              variant="outlined"
              color="error"
              sx={{mr: 1, borderRadius: 1}}
              onClick={() => onActionButtonClick(false)}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{borderRadius: 1}}
              onClick={() => onActionButtonClick(true)}
            >
              Approve
            </Button>
            </>}
          </Box>
        );
    }
  };
  return (
    <Container maxWidth="lg">
      <Paper elevation={3}>
        <Button
          variant="text"
          color="inherit"
          onClick={() => navigate(-1, {replace: true})}  
          sx={{mt: "16px"}}
        >
          <ArrowBackOutlinedIcon /> &nbsp; Managers
        </Button>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          sx={{p: 3}}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Avatar
                        style={{
                          height: "57px",
                          width: "57px",
                          backgroundColor: "#A8A8A8",
                          color: "#595656",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontWeight={700} fontSize={"24px"}>
                        {`${requestData?.first_name} ${requestData?.last_name}`}
                      </Typography>
                      <Typography
                        fontSize={"16px"}
                        gutterBottom
                        color="#3A3A3A"
                      >
                        {requestData?.official_email}
                      </Typography>
                    </Grid>
                 {(requestData?.reg_type ==="Invited" && requestData?.status === "Registered")||(requestData?.reg_type ==="Direct" && requestData?.status === "Approved") ? <Grid item>
                      <Button
                       color="primary"
                       variant="contained"
                       onClick={handleOpen}
                       >
                       Revoke Manager
                      </Button>
                    </Grid>:<></>}
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  { renderActionStatus(requestData?.status)}
                </Grid>
              </Grid>
            </Grid>    
          </Grid>
        </Box>
        <Box sx={{p: 3}}>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Registration Type
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {requestData?.reg_type}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Personal email ID
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {requestData?.personal_email}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {requestData?.phone_number}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Date of Birth
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {requestData?.dob}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    {requestData.reg_type === "Invited"
                      ? "Invited"
                      : "Requested"}{" "}
                    Date & Time
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {getFormattedDate(requestData?.requested_on)}
                  </Typography>
                </Grid>
                {requestData?.status !== "Pending" && (
                  <>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        {requestData?.status === "Approved"
                          ? "Approved Date & Time"
                          : "Rejected Date & Time"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="16px" color="#212121">
                        {requestData?.ceo_approval?.updated_on
                          ? getFormattedDate(
                              requestData?.ceo_approval?.updated_on
                            )
                          : "-- --"}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            {requestData?.status === "Rejected" && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={2.4}>
                    <Typography mb={3} variant="subtitle2">
                      <span>Reason for Rejection</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9.6}>
                    <Typography mb={2} variant="subtitle2">
                      <strong>{requestData?.ceo_approval.remarks_title}</strong>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={2.4}>
                    <Typography
                      fontFamily={"Inter, sans-serif"}
                      mb={3}
                      variant="subtitle2"
                    >
                      <span>Description</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9.6}>
                    <Typography
                      fontFamily={"Inter, sans-serif"}
                      mb={2}
                      variant="subtitle2"
                    >
                      <strong>
                        {requestData?.ceo_approval.remarks_description}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        {openModal && (
          <CustomModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            width={"50%"}
          >
            <RejectModal
              handleClose={() => setOpenModal(false)}
              handleSave={(data) => handleButtonClick(data)}
            />
          </CustomModal>
        )}

       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
                    <Typography id="modal-modal-title" fontWeight={600} fontSize={"16px"}>
                    Are you sure you want to revoke access of "{requestData?.official_email}"?
                    </Typography>
                   
                    <Grid container spacing={2} justifyContent="flex-end"sx={{mt:2}}>
                        <Grid item>
                            <Button variant="outlined" style={{ borderRadius: '4px', width: '100px', height: '38px' }} onClick={handleClose}>Cancel</Button>
                        </Grid>
                       <Grid item>
                                <Button  variant="contained" color="primary" style={{ borderRadius: '4px', width: '100px', height: '38px' }} onClick={handleClickOk} >
                                   Ok
                                </Button>
                            </Grid>
                    </Grid>
                </Box>
      </Modal>  
     </Paper>
    </Container>
  );
};
