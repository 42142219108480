import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import "./FormComponent.css";
import WarningIcon from "../../../../assets/warning.svg";
const EmailVerification = ({
  handleEmailSubmit,
  formData,
  handleOTPChange,
  handleForm2ButtonClick,
  errors,
  handleChange,
  handleSubmit,
  formatTime,
  time,
  stepChangePrevHandler
}) => {
  return (
    <div className="FormComponent__section">
      <form onSubmit={handleSubmit}>
        <div className="formContainer">
          <div
            className="FormComponent__Heading"
            style={{
              marginBottom: "0px",
              fontFamily: "Inter, sans-serif",
              fontWeight: "600",
              marginTop: "3rem",
            }}
          >
            Email Verification{" "}
          </div>

          <Typography variant="body2">
            We've sent an verification to <strong>{formData.email_address}</strong>  to verify
            your email address and activate your account.
          </Typography>
          {/* <div> */}
          <div className="textFieldValidation">
            <div style={{ position: "relative" }}>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "400",
                  marginBottom: "5px",
                }}
              >
                Verification Code
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="otpValue"
                value={formData.otpValue || ""}
                onChange={handleChange}
                error={
                  errors.otpValue &&
                  formData.otpValue === "" &&
                  Boolean(errors.otpValue)
                }
                style={{ width: "100%" }}
              />
              <Typography variant="body2" style={{margin:"10px 0px", fontSize:"15px", color:"#848484"}}>Resend Code <span style={{fontWeight:"bold", color:"#232323"}}>{formatTime(time)}</span></Typography>
              {errors.otpValue && formData.otpValue === "" && (
                <img
                  src={WarningIcon}
                  style={{
                    position: "absolute",
                    right: 20,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
              )}
            </div>
            {errors.otpValue && formData.otpValue === "" && (
              <span className="error">{errors.otpValue}</span>
            )}
          </div>
          <Box display="flex" justifyContent="flex-end">
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Inter, sans-serif",
                fontWeight: 400,
                marginBottom: "5px",
                display: "flex",
                alignItems: "center",
                color: "#8F8F8F",
              }}
            >
              Didn’t receive any code?
              <Button
                component="span"
                sx={{
                  fontSize: "17px",
                  cursor: "pointer",
                  marginLeft: "0.3em",
                  color: "#2947A3",
                  fontFamily: "Inter, sans-serif",
                }}
                disabled={time > 0}
                onClick={handleEmailSubmit}               
              >
                Resend
              </Button>
            </Typography>
          </Box>
          <div className="FormComponent__divBtnBox">
          <button
              className="FormComponent__divBtn"
              style={{  marginRight:"2rem" }}
              
              onClick={stepChangePrevHandler}
            >
              Previous
            </button>
            <button
              className="FormComponent__divBtn"
              style={{ width: "40%" }}
              type="submit"
              onClick={handleForm2ButtonClick}
            >
              Verify My Account
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmailVerification;
