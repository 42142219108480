import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DashboardStyles } from "../../styles/dashboardStyles";

// Register the components you need
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const DoughnutChart = ({ ApplicationRequestStatus }) => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const classes = DashboardStyles();

  const CeoDashboardRequests = useSelector(
    (state) => state?.fetchCeoDashboardRequests?.data
  );

  const handleClickbutton = (label) => {
    navigate("/requests", { state: { clickedLabel: label } });
  };

  const pendingRequest = Object.values(CeoDashboardRequests).reduce(
    (total, item) => {
      const pendingRequests = item?.pending_requests;
      return (
        total + (typeof pendingRequests === "number" ? pendingRequests : 0)
      );
    },
    0
  );
  const approvedRequest = Object.values(CeoDashboardRequests).reduce(
    (total, item) => {
      const approvedRequests = item?.approved_requests;
      return (
        total + (typeof approvedRequests === "number" ? approvedRequests : 0)
      );
    },
    0
  );
  const rejectedRequest = Object.values(CeoDashboardRequests).reduce(
    (total, item) => {
      const rejectedRequests = item?.rejected_requests;
      return (
        total + (typeof rejectedRequests === "number" ? rejectedRequests : 0)
      );
    },
    0
  );

  const piePendingRequest =
    CeoDashboardRequests?.total_requests > 0
      ? Math.round(
          (pendingRequest / CeoDashboardRequests?.total_requests) * 100
        )
      : 0;
  const pieapprovedRequest =
    CeoDashboardRequests?.total_requests > 0
      ? Math.round(
          (approvedRequest / CeoDashboardRequests?.total_requests) * 100
        )
      : 0;
  const pierejectedRequest =
    CeoDashboardRequests?.total_requests > 0
      ? Math.round(
          (rejectedRequest / CeoDashboardRequests?.total_requests) * 100
        )
      : 0;

  const managerchartDataRequest = {
    labels: ["Approved", "Pending", "Rejected"],
    datasets: [
      {
        data: [pieapprovedRequest, piePendingRequest, pierejectedRequest],
        backgroundColor: ["#05B966", "#F0B727", "#E94949"],
        hoverOffset: 4,
      },
    ],
  };

  const manageroptionsRequest = {
    plugins: {
      legend: {
        display: false, // Hide the default legend
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable tooltips
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.raw;
            const total = context.chart._data.datasets[0].data.reduce(
              (acc, val) => acc + val,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "white",
        bodyColor: "white",
        borderColor: "white",
        borderWidth: 1,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length) {
        const element = elements[0];
        const label = managerchartDataRequest.labels[element.index];
        navigate("/requests", { state: { clickedLabel: label } });
      }
    },
  };

  const chartDataRequest = {
    labels: [
      "Approved",
      "Pending for CEO Action",
      "Rejected",
      "Unassigned",
      "Pending for Manager Action",
    ],
    datasets: [
      {
        data: [
          ApplicationRequestStatus?.total_approved_requests,
          ApplicationRequestStatus?.total_pending_ceo_action,
          ApplicationRequestStatus?.total_rejected_requests,
          ApplicationRequestStatus?.total_unassigned_requests,
          ApplicationRequestStatus?.total_pending_manager_action,
        ],
        backgroundColor: [
          "#05B966",
          "#F0B727",
          "#E94949",
          "#3976C8",
          "#DA8427",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const optionsRequest = {
    plugins: {
      legend: {
        display: false, // Hide the default legend
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable tooltips
        callbacks: {
          label: function (context) {
            const label = context.label || "";
            const value = context.raw;
            const total = context.chart._data.datasets[0].data.reduce(
              (acc, val) => acc + val,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "white",
        bodyColor: "white",
        borderColor: "white",
        borderWidth: 1,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event, elements) => {
      if (elements.length) {
        const element = elements[0];
        const label = chartDataRequest.labels[element.index];
        navigate("/requests", { state: { clickedLabel: label } });
      }
    },
  };

  const styles = [
    {
      labelStyle: { color: "black", fontWeight: 400 },
      valueStyle: { color: "black", fontWeight: 400 },
    },
    {
      labelStyle: { color: "black", fontWeight: 400 },
      valueStyle: { color: "black", fontWeight: 400 },
    },
    {
      labelStyle: { color: "black", fontWeight: 400 },
      valueStyle: { color: "black", fontWeight: 400 },
    },
    {
      labelStyle: { color: "black", fontWeight: 400 },
      valueStyle: { color: "black", fontWeight: 400 },
    },
    {
      labelStyle: { color: "black", fontWeight: 400 },
      valueStyle: { color: "black", fontWeight: 400 },
    },
  ];

  return (
    <>
      {role === "ceo" ? (
        <Grid container alignItems="flex-start" sx={{ p: 2 }}>
          <Grid item xs={4}>
            <Doughnut data={chartDataRequest} options={optionsRequest} />
          </Grid>

          <Grid item xs={8} container direction="column" spacing={1}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={8}
                md={3}
                container
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Grid
                  item
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor:
                      chartDataRequest.datasets[0].backgroundColor[0],
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                /> <Box
                sx={{
                  backgroundColor: "#ffffff",
                  transition:
                    "background-color 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#f2f4f4",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={() => handleClickbutton("Approved")}
              >
                <Grid item>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[0].labelStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Approved
                  </Typography>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[0].valueStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {Math.round(
                      (chartDataRequest.datasets[0].data[0] /
                        ApplicationRequestStatus?.total_requests) *
                        100
                    )}
                    %
                  </Typography>
                </Grid>
                </Box>
              </Grid>
            
              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                container
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Grid
                  item
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor:
                      chartDataRequest.datasets[0].backgroundColor[1],
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                /> <Box
                sx={{
                  backgroundColor: "#ffffff",
                  transition:
                    "background-color 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#f2f4f4",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={() => handleClickbutton("Pending for CEO Action")}
              >
                <Grid item>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[1].labelStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Pending for CEO Action
                  </Typography>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[1].valueStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {Math.round(
                      (chartDataRequest.datasets[0].data[1] /
                        ApplicationRequestStatus?.total_requests) *
                        100
                    )}
                    %
                  </Typography>
                </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={4}
                container
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Grid
                  item
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor:
                      chartDataRequest.datasets[0].backgroundColor[2],
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                /> <Box
                sx={{
                  backgroundColor: "#ffffff",
                  transition:
                    "background-color 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#f2f4f4",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={() => handleClickbutton("Rejected")}
              >
                <Grid item>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[2].labelStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Rejected
                  </Typography>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[2].valueStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {Math.round(
                      (chartDataRequest.datasets[0].data[2] /
                        ApplicationRequestStatus?.total_requests) *
                        100
                    )}
                    %
                  </Typography>
                </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={3}
                container
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <Grid
                  item
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor:
                      chartDataRequest.datasets[0].backgroundColor[3],
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />
                 <Box
                sx={{
                  backgroundColor: "#ffffff",
                  transition:
                    "background-color 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#f2f4f4",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={() => handleClickbutton("Unassigned")}
              >
                <Grid item>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[3].labelStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Unassigned
                  </Typography>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[3].valueStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {Math.round(
                      (chartDataRequest.datasets[0].data[3] /
                        ApplicationRequestStatus?.total_requests) *
                        100
                    )}
                    %
                  </Typography>
                </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={6}
                container
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <Grid
                  item
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor:
                      chartDataRequest.datasets[0].backgroundColor[4],
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />
                <Box
                sx={{
                  backgroundColor: "#ffffff",
                  transition:
                    "background-color 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  padding: "10px",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#f2f4f4",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={() => handleClickbutton("Pending for Manager Action")}
              >
                <Grid item>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[4].labelStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    Pending for Manager Action
                  </Typography>
                  <Typography
                   variant="subtitle2"
                    style={{
                      ...styles[4].valueStyle,
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    {Math.round(
                      (chartDataRequest.datasets[0].data[4] /
                        ApplicationRequestStatus?.total_requests) *
                        100
                    )}
                    %
                  </Typography>
                </Grid></Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="flex-start" sx={{ p: 2 }}>
          <Grid item xs={4}>
            <Doughnut
              data={managerchartDataRequest}
              options={manageroptionsRequest}
            />
          </Grid>

          <Grid item xs={8} container direction="column" spacing={1}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={8}
                md={4}
                container
                alignItems="center"
                sx={{ mt: 5 }}
              >
                <Grid
                  item
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor:
                      managerchartDataRequest.datasets[0].backgroundColor[0],
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    transition:
                      "background-color 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    padding: "10px",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "#f2f4f4",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  onClick={() => handleClickbutton("Approved")}
                >
                  <Grid item>
                    <Typography
                     variant="subtitle2"
                      style={{
                        ...styles[0].labelStyle,
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Approved
                    </Typography>
                    <Typography
                     variant="subtitle2"
                      style={{
                        ...styles[0].valueStyle,
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      {Math.round(
                        (managerchartDataRequest.datasets[0].data[0] /
                          ApplicationRequestStatus?.total_requests) *
                          100
                      )}
                      %
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={4}
                container
                alignItems="center"
                sx={{ mt: 5 }}
              >
                <Grid
                  item
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor:
                      managerchartDataRequest.datasets[0].backgroundColor[1],
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    transition:
                      "background-color 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    padding: "10px",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "#f2f4f4",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  onClick={() => handleClickbutton("Pending")}
                >
                  <Grid item>
                    <Typography
                     variant="subtitle2"
                      style={{
                        ...styles[1].labelStyle,
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Pending
                    </Typography>
                    <Typography
                     variant="subtitle2"
                      style={{
                        ...styles[1].valueStyle,
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      {Math.round(
                        (managerchartDataRequest.datasets[0].data[1] /
                          ApplicationRequestStatus?.total_requests) *
                          100
                      )}
                      %
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={4}
                container
                alignItems="center"
                sx={{ mt: 5 }}
              >
                <Grid
                  item
                  style={{
                    width: 12,
                    height: 12,
                    backgroundColor:
                      managerchartDataRequest.datasets[0].backgroundColor[2],
                    borderRadius: "50%",
                    marginRight: 8,
                  }}
                />{" "}
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    transition:
                      "background-color 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    padding: "10px",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "#f2f4f4",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  onClick={() => handleClickbutton("Rejected")}
                >
                  <Grid item>
                    <Typography
                     variant="subtitle2"
                      style={{
                        ...styles[2].labelStyle,
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      Rejected
                    </Typography>
                    <Typography
                     variant="subtitle2"
                      style={{
                        ...styles[2].valueStyle,
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                      }}
                    >
                      {Math.round(
                        (managerchartDataRequest.datasets[0].data[2] /
                          ApplicationRequestStatus?.total_requests) *
                          100
                      )}
                      %
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DoughnutChart;
