
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchOnboardingRequestsAPI extends API {
  constructor(role,request_to, timeout = 2000) {
    super(role === "ceo"?"GET":"POST", timeout, false); 
    this.type = C.FETCH_CEO_DASHBOARD;
    this.endpoint = `${super.apiEndPointAuto()}${role === "ceo" ? ENDPOINTS.FetchCeodashbord : ENDPOINTS.FetchCeodashbordManager}${role === "manager" ? `?request_to=${request_to}` : ''}`;}
  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.ceo = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    
    return {};
  }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.ceo;
  }
}
