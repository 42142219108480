/**
 * Fetch Customer Requests API
 */
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchCustomerRequestAPI extends API {
    constructor(role, timeout = 2000) {
        super("POST", timeout, false);
        this.type = C.FETCH_CUSTOMER_REQUESTS;
        this.role = role
        this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.listUsers
            }?page=1&page_size=2000`;
    }

    processResponse(res) {
        super.processResponse(res);
        if (res) {
            this.report = res;
        }
    }

    apiEndPoint() {
        return this.endpoint;
    }

    getBody() {
        const urlencoded = new URLSearchParams();
        urlencoded.append("role", this.role);

        return urlencoded
     }

    getHeaders() {
        const authorization = localStorage.getItem("token");
        this.headers = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: authorization,
            },
        };
        return this.headers;
    }

    getPayload() {
        return this.report;
    }
}
