import apiStatus from "../reducers/apistatus/apistatus";
import fetchOnboardUsers from "./Dashboard/FetchOnboardUsers/FetchOnboardUsers";
import fetchOnboardManager from "./Dashboard/FetchOnboardManager/FetchOnboardManager";
import updateOnboardManager from './Dashboard/UpdateOnboardingManager/UpdateOnboardingManager'
import commonReducer from "./Common";
import referenceApplicationStatus from "./ReferenceApplications/fetchReferenceApplicationStatus/fetchReferenceApplicationStatus";
import getUserDetails from "./UserManagement/GetUserDetails/GetUserDetails";
import fetchOnboardingRequests from "./Dashboard/FetchOnboardingRequests/FetchOnboardingRequests";
import fetchSahyogiRequests from "./Dashboard/FetchSahyogiRequests/FetchSahyogiRequests";
import fetchOrganisationNames from "./UserManagement/OrganisationTypes/fetchOrganisationNames";
import fetchCeoDashboardRequests from "./Dashboard/FetchCeodashboard/FetchCeoDashBoard";
import fetchCustomeroverview from "./Dashboard/FetchCeodashboard/FetchCustomeroverview";
import fetchPluginUlcaKeys from './ReferenceApplications/FetchPluginUlcaKeys/FetchPluginUlcaKeys'
import fetchPluginUlcaKeysData from './ReferenceApplications/FetchPluginUlcaKeysData/FetchPluginUlcaKeysData'
import forgotPassword from './UserManagement/ForgotPassword/ForgotPassword'

import fetchAppRequestDetails from "./Dashboard/FetchAppRequestDetails";
import fetchManagerRequests from "./Dashboard/FetchManagerRequests";
import updateManagerRegistration from "./Dashboard/UpdateManagerRegistration";
import FetchCeoSahyogiRequest from "./Dashboard/FetchCeoSahyogiRequest/FetchCeoSahyogiRequest";
import SahyogiSingleUserDetail from "./Dashboard/FetchCeoSahyogiRequest/SahyogiSingleUserDetail";
import FetchEmployeeRegistration from "./Dashboard/FetchCeodashboard/FetchEmployeeRegistration";
import FetchSahyogiRequestOverview from "./Dashboard/FetchCeodashboard/FetchSahyogiRequestOverview"
import fetchReferenceApplicationDetails from './ReferenceApplications/FetchReferenceApplicationDetails/FetchReferenceApplicationDetails'
import FetchEmployeeInviteManager from "./Dashboard/FetchCeodashboard/FetchEmployeeInvitedManagers";
import UpdateInviteManager from "./Dashboard/UpdateInviteManager/UpdateInviteManager"

import fetchAllApplication from './Dashboard/FetchAllApplication';
import fetchAppNumbers from './ReferenceApplications/FetchAppNumbers/FetchAppNumbers'
import fetchLoggedInUserDetails from './ReferenceApplications/FetchLoggedInUserDetails/FetchLoggedInUserDetails';
import viewPDF from "./Dashboard/FetchSahyogiRequests/viewPDF";
import VerifyManagerRegistration from "./UserManagement/VerifyManagerRegistration/VerifyManagerRegistration";
import fetchTranslationPluginRequests from "./Dashboard/PluginDashboard/FetchTranslationPluginRequests";
import fetchTranslationPluginRequestDetails from "./Dashboard/PluginDashboard/FetchTranslationPluginRequestDetails";
import RevokeManager from "./Dashboard/RevokeManager/RevokeManager";
import fetchCustomerRequests from './Dashboard/FetchCustomerRequests';
import fetchcustomerCompleteDetails from "./Dashboard/FetchCustomerRequests/customerCompleteDetails";
import fetchManagerList from "./ReferenceApplications/FetchManagerList/FetchManagerList";
import OnboardingFilter from "./Filter/OnboardingFilter";
import pluginRequestFilter from "./Filter/PluginRequest";
import SahyogiRequestFilter from "./Filter/SahyogiRequestFilter";
import ManagerUserManagementFilter from "./Filter/ManagerUserManagementFilter";
import CustomerUserManagementFilter from "./Filter/CustomerUserManagementFilter";
import FetchManagerOverview from "./Dashboard/FetchCeodashboard/FetchManagersOverView";
import FetchApplicationRequestStatus from "./Dashboard/FetchCeodashboard/FetchApplicationRequestStatus";
import FetchCheckListData from "./Dashboard/FetchChecklistData/FetchChecklistData";
import fetchRegisteredExpertList from "./Dashboard/FetchRegisteredExpert/FetchRegisteredExpertList";
import fetchRegisteredExpertdetail from "./Dashboard/FetchRegisteredExpert/FetchRegisteredExpertdetails";
import fetchlanguagesAPI from "./Dashboard/FetchRegisteredExpert/Fetchlanguages";
import RegisteredExpertFilter from "./Filter/RegisteredExpertFilter";

const rootReducer = {
    apiStatus,
    fetchOnboardUsers,
    fetchOnboardManager,
    updateOnboardManager,
    commonReducer,
    referenceApplicationStatus,
    getUserDetails,
    fetchOnboardingRequests,
    fetchSahyogiRequests,
    fetchAppRequestDetails,
    fetchManagerRequests,
    updateManagerRegistration,
    fetchOrganisationNames,
    fetchCeoDashboardRequests,
    fetchCustomeroverview,
    FetchCeoSahyogiRequest,
    SahyogiSingleUserDetail,
    FetchEmployeeRegistration,
    FetchSahyogiRequestOverview,
    fetchReferenceApplicationDetails,
    fetchCeoDashboardRequests,
    FetchEmployeeInviteManager,
    UpdateInviteManager,
    fetchAllApplication,
    fetchAppNumbers,
    fetchLoggedInUserDetails,
    viewPDF,
    VerifyManagerRegistration,
    fetchTranslationPluginRequests,
    fetchTranslationPluginRequestDetails,
    RevokeManager,
    fetchPluginUlcaKeys,
    fetchPluginUlcaKeysData,
    forgotPassword,
    fetchCustomerRequests,
    fetchcustomerCompleteDetails,
    fetchManagerList,
    OnboardingFilter,
    pluginRequestFilter,
    SahyogiRequestFilter,
    ManagerUserManagementFilter,
    CustomerUserManagementFilter,
    FetchManagerOverview,
    FetchApplicationRequestStatus,
    FetchCheckListData,
    fetchRegisteredExpertList,
    fetchRegisteredExpertdetail,
    fetchlanguagesAPI,
    RegisteredExpertFilter,
}

export default rootReducer;