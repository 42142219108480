import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  Avatar,
  Divider,
  TextField,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../common/Modal";
import { useDispatch, useSelector } from "react-redux";
import RejectModal from "../CeoRequestAction/RejectModal";
import Modal from "./Modal"
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { APITransport } from "../../redux/actions";
import UpdateTranslnPluginStatusAPI from "../../redux/actions/api/Dashboard/PluginDashboard/UpdateTranslnPluginStatus";
import {setSnackBar} from "../../redux/actions/Common";
import FetchTranslationPluginRequestsAPI from "../../redux/actions/api/Dashboard/PluginDashboard/FetchTranslationPluginRequests";
 import {getFormattedDate} from "../../utils/utils";

export const PluginRequestDetails = ({requestData,filterdata,FetchTranslationPluginRequestDetails,fetchTranslationPluginRequestDetails,requestRowData}) => {
  const navigate = useNavigate();

  const [approveStatus, setApproveStatus] = useState(false);
  const [rejectStatus, setRejectStatus] = useState(false);
  const [rejectuser, setRejectuser] = useState(false);
  const dispatch = useDispatch();
  const apiSatus = useSelector((state) => state.apiStatus);
  const [status, setStatus] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");
  const [modalInfo, setModalInfo] = useState({title: "", description: ""});

  const profileDetails = fetchTranslationPluginRequestDetails?.profile_details?.requests[0];
  
  const FetchTranslationPluginRequest = () => {
    const apiObj = new FetchTranslationPluginRequestsAPI();
    dispatch(APITransport(apiObj));
  };
  
  const handleActionFn = (value) => {
    const {title, description} = modalInfo;
    const payloadData =
      role === "manager"
        ? {
            manager_approval: {
              status: value,
              updated_by: email,
              updated_on: new Date(),
              updater_name: username,
              remarks_title: title,
              remarks_description: description,
            },
          }
        : role === "ceo"
        ? {
            ceo_approval: {
              status: value,
              updated_by: email,
              updated_on: new Date(),
              updater_name: username,
              remarks_title: title,
              remarks_description: description,
            },
          }
        : {};
    const apiObj = new UpdateTranslnPluginStatusAPI(
      requestData?._id,
      payloadData
    );
    dispatch(APITransport(apiObj));
    FetchTranslationPluginRequest(email) 
    FetchTranslationPluginRequestDetails()
  };
  const onActionButtonClick = (value) => {
    if (value !== "Rejected") {
      setStatus(() => {
        handleActionFn(value);
        return value;
      });
      return;
    }
    setRejectStatus(true);
    setOpenModal(true);
  };

  


        const alternateButtonClick = () => {
          setRejectuser(true)

          const {title, description} = modalInfo;
          if (!!title && !!description) {
            setStatus(() => {
              handleActionFn("Rejected");
              return "Rejected";
            });
      
            setOpenModal(false);
            setRejectuser(false)

          }
        };

  useEffect(() => {
    if (
      apiSatus.apiType === "UPDATE_TRANSLATION_PLUGIN_STATUS" &&
      apiSatus.success
    ) {
      navigate(-1, {replace: true});
      dispatch(
        setSnackBar({
          open: true,
          message:
            status === "Approved"
              ? "Request Approved Successfully"
              : "Request Rejected Successfully",
          variant: "success",
        })
      );
    }
  }, [apiSatus, status]);

  const handleModalValueChange = (prop, value) => {
    setModalInfo((prev) => ({...prev, [prop]: value}));
  };
  const renderActionStatus = (status,requestRowData) => {
    if(role==="ceo"){
      const isRejected = requestRowData && (requestRowData[6] === "Rejected" || requestRowData[5] === "Rejected");
      const  Requesrstatus = ((requestRowData[5]==="Approved"&& requestRowData[6]==="Pending"))

      if ((requestRowData[6] === "Approved"||requestRowData[5] === "Approved")&&status==="Approved") {
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="7px 10px"
            borderRadius="40px"
            bgcolor="#DEFFDB"
            color="#0C8501"
            fontWeight={400}
            fontSize="14px"
            width="fit-content"
            marginLeft="auto"
          >
            <CheckCircleIcon
              style={{ width: "20px", height: "20px", color: "#165E3D", marginTop: "6px", margin: "5px" }}
            />
            <Typography>Approved</Typography>
          </Box>
        );
      } else if (isRejected) {
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="7px 10px"
            borderRadius="40px"
            bgcolor="#FFF1F0"
            color="#E02B1D"
            fontWeight={400}
            fontSize="14px"
            width="fit-content"
            marginLeft="auto"
          >
            <CancelIcon
              style={{ width: "20px", height: "20px", color: "#B83131", marginTop: "6px", margin: "5px" }}
            />
            <Typography>Rejected</Typography>
          </Box>
        );
      } else {
        return Requesrstatus ? (
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="success"
              sx={{ borderRadius: 1, mr: 1 }}
              onClick={() => onActionButtonClick("Approved")}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ borderRadius: 1 }}
              onClick={() => onActionButtonClick("Rejected")}
            >
              Reject
            </Button>
          </Box>
        ) : null;
      }
    }else if(role==="manager")
    switch (status) {
      case "Approved":
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="7px 10px"
            borderRadius="40px"
            bgcolor={"#DEFFDB"}
            color={"#0C8501"}
            fontWeight={400}
            fontSize={"14px"}
            width="fit-content"
            marginLeft={"auto"}
          >
              <CheckCircleIcon
                    style={{ width: "20px", height: "20px", color: "#165E3D" ,marginTop:"6px",margin:"5px" }}
                  />
            <Typography>Approved</Typography>
          </Box>
        );

      case "Rejected" :
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="7px 10px"
            borderRadius="40px"
            bgcolor={"#FFF1F0"}
            color={"#E02B1D"}
            fontWeight={400}
            fontSize={"14px"}
            width="fit-content"
            marginLeft={"auto"}
          >
             <CancelIcon
               style={{ width: "20px", height: "20px", color: "#B83131" ,marginTop:"6px",margin:"5px" }}
                  />
            <Typography>Rejected</Typography>
          </Box>
        );
        default:
            return (
              
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="success"
                  sx={{borderRadius: 1,mr: 1}}
                  onClick={() => onActionButtonClick("Approved")}
                >
                  Approve
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ borderRadius: 1}}
                  onClick={() => onActionButtonClick("Rejected")}
                >
                  Reject
                </Button>
              </Box>
            );
    }
  };

  const maskKey = (key) => {
    if (!key || key.length <= 8) return key; // Return the key as is if it's too short or undefined

    const firstFour = key.slice(0, 5);
    const lastFour = key.slice(-5);
    const maskedSection = '*'.repeat(key.length - 8); // Mask the middle section

    return `${firstFour}${maskedSection}${lastFour}`;
  };
  
  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Grid container>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ marginLeft: "auto" }}
          >
            {renderActionStatus(filterdata?.status,requestRowData)}
          </Box>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              fontSize="18px"
              fontWeight={600}
              sx={{ mb: "24px" }}
            >
              API Key Details
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Email ID
                </Typography>
              </Grid>
             
               
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px">
                <strong> {profileDetails?.email_id}</strong> 
                </Typography>
              </Grid>
  
              
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Typography fontSize="14px" color="#6A6A6A">
                  API Key
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px">
                <strong> {maskKey(requestData?.api_key)}</strong> 
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  App Name
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong> {requestData?.app_name}</strong>  
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              fontSize="18px"
              fontWeight={600}
              sx={{ mb: "24px" }}
            >
              Hostname/Website Details
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Hostname
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{requestData?.host_url}</strong> 
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Manager’s Name
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong> {requestData?.manager_approval?.updater_name }</strong>  
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Request Date & Time
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>  {getFormattedDate(requestData?.requested_on)}</strong> 
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
            <><Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                {requestData?.manager_approval?.status ==="Rejected"? "Manager Rejected Date":"Manager Approval Date"}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                 <strong>{requestData?.manager_approval?.updated_on && getFormattedDate(requestData?.manager_approval?.updated_on)} </strong> 
                </Typography>
              </Grid></>
              {/* {requestData?.manager_approval?.status ==="Rejected" &&<><Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                Rejected Date & Time
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                 <strong>{requestData?.manager_approval?.updated_on && getFormattedDate(requestData?.manager_approval?.updated_on)} </strong> 
                </Typography>
              </Grid></>} */}
             <><Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                {requestData?.ceo_approval?.status ==="Rejected" ? "Rejected Date & Time":"Approved Date & Time"}
                </Typography>
              </Grid>
           <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                { requestData?.ceo_approval?.updated_on ? (
                  <Typography fontSize="16px" >
                    <strong>  {requestData?.ceo_approval?.updated_on && getFormattedDate(requestData?.ceo_approval?.updated_on)} </strong> 
                  </Typography>
                ) : (
                  <Typography fontSize="16px" color="#212121">
                    ---------
                  </Typography>
                )}
              </Grid> </>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        {(requestRowData[6] === "Rejected" || requestRowData[5] === "Rejected") && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={2.4}>
                <Typography mb={3} variant="subtitle2">
                  <span>Reason for Rejection</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={9.6}>
                <Typography mb={2} variant="subtitle2">
                  <strong>
                  {requestData?.ceo_approval?.remarks_title ? requestData?.ceo_approval?.remarks_title : requestData?.manager_approval?.remarks_title}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={2.4}>
                <Typography
                  fontFamily={"Inter, sans-serif"}
                  mb={3}
                  variant="subtitle2"
                >
                  <span>Description</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={9.6}>
                <Typography
                  fontFamily={"Inter, sans-serif"}
                  mb={2}
                  variant="subtitle2"
                >
                  <strong>
                  {requestData?.ceo_approval?.remarks_description? requestData?.ceo_approval?.remarks_description:requestData?.manager_approval?.remarks_description
}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
          {openModal && ( <Modal
        
          title={"Are you sure you want to reject this request?"}
          children={"You are rejecting the “App” request, and this action cannot be undone."}

          isOpen={openModal}
        handleClose={() => setOpenModal(false)}

          alternateButtonClick={alternateButtonClick}
        ></Modal>)}
         {rejectuser && (
          <Modal
          cancelButton
          isAlternativeBtnDisabled={
            !(!!modalInfo.title && !!modalInfo.description)
          }
          alternateButton="Send"
          title={"Please provide the reason for rejecting the request"}
          isOpen={rejectuser}
          handleClose={() => setRejectuser(false)}    
                alternateButtonClick={alternateButtonClick}
        >
          <Grid container spacing={3} margin="16px 0px" width="inherit">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{paddingLeft: "0px", paddingTop: "0px"}}
            >
              <TextField
                fullWidth
                label="Title"
                value={modalInfo.title}
                onChange={(e) =>
                  handleModalValueChange("title", e.target.value)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{paddingLeft: "0px"}}
            >
              <TextField
                fullWidth
                label="Description"
                rows={4}
                multiline
                value={modalInfo.description}
                onChange={(e) =>
                  handleModalValueChange("description", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Modal>
        )}
      </Box>
    </Container>
  );
};
