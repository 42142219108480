import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  Divider,
  Avatar,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { APITransport } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import pfdIcon from "../../assets/pdficon.svg";
import FetchRegisteredExpertdetailsAPI from "../../redux/actions/api/Dashboard/FetchRegisteredExpert/FetchRegisteredExpertdetails";
import { getFormattedDate } from "../../utils/utils";
import viewPdfAPI from "../../redux/actions/api/Dashboard/SahyogiRequests/viewPDF";

export const RegisteredExpertAction = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestData = location?.state?.reqData;
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");
  const requestRowData = location.state?.rowValue;

  const RegisteredExpertdetail = useSelector(
    (state) => state?.fetchRegisteredExpertdetail?.data
  );

  const handleClickBiodata = () => {
    const apiObj = new viewPdfAPI(RegisteredExpertdetail?.bio_data);
    dispatch(APITransport(apiObj));
    apiObj.downloadDocument();
  };

  useEffect(() => {
    const reqObj = new FetchRegisteredExpertdetailsAPI(requestRowData[1]);
    dispatch(APITransport(reqObj));
  }, []);

  const cleanLinks = (links) => {
    return links?.map((link) => {
      const cleanedLink = link?.replace(/^\["|"\]$/g, "").replace(/\\\"/g, '"');
      return cleanedLink;
    });
  };
  const cleanedLinks = cleanLinks(RegisteredExpertdetail?.relevant_links);
  return (
    <>
      <Container maxWidth="lg">
        <Paper elevation={3}>
          <Button
            variant="text"
            color="inherit"
            onClick={() => navigate(-1, { replace: true })}
            sx={{ mt: "16px", ml: "5px" }}
          >
            <ArrowBackOutlinedIcon /> &nbsp; Registered Language Expert
          </Button>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            sx={{ p: 3 }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container>
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Avatar
                          style={{
                            height: "57px",
                            width: "57px",
                            backgroundColor: "#A8A8A8",
                            color: "#595656",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <Typography fontWeight={700} fontSize={"24px"}>
                          {requestRowData[5]}
                        </Typography>
                        <Typography
                          fontSize={"16px"}
                          gutterBottom
                          color="#3A3A3A"
                        >
                          {requestRowData[1]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <Button
                      variant="contained"
                      sx={{ borderRadius: "5px" }}
                      onClick={handleClickBiodata}
                    >
                      View Biodata
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 3 }}>
            <Grid container spacing={5}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      User Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {RegisteredExpertdetail?.user_type}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      Personal email ID
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {RegisteredExpertdetail?.personal_email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      State
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {RegisteredExpertdetail?.state}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      Phone No
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {RegisteredExpertdetail.phone_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      Registered Date & time
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {getFormattedDate(RegisteredExpertdetail.registered_on)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider style={{ margin: "0px 16px" }} />
          <Box sx={{ p: 3 }}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              fontSize="18px"
              fontWeight={600}
              sx={{ mb: "24px" }}
            >
              Professional Details
            </Typography>

            {requestRowData[3] === "Individual" ? (
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Work Experience
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="16px" color="#212121">
                        {RegisteredExpertdetail.work_experience} years
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Portfolio
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="16px" color="#0A65CC">
                        {RegisteredExpertdetail.portfolio_link}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={5}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Organisation Type
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="16px" color="#212121">
                        {RegisteredExpertdetail?.org_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Designation
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="16px" color="#212121">
                        {RegisteredExpertdetail?.designation}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Portfolio
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="16px" color="#0A65CC">
                        {RegisteredExpertdetail.portfolio_link}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Organisation Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="16px" color="#212121">
                        {RegisteredExpertdetail?.org_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Work Experience
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="16px" color="#212121">
                        {RegisteredExpertdetail.work_experience} years
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
          <Divider style={{ margin: "0px 16px" }} />
          <Box sx={{ p: 3 }}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              fontSize="18px"
              fontWeight={600}
              sx={{ mb: "24px" }}
            >
              Language Expertise
            </Typography>
            <Grid container spacing={5}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      Domain Specialization
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {RegisteredExpertdetail.domain_expertise}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      Primary Langauge
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {RegisteredExpertdetail?.primary_language_expertise
                        ? `${RegisteredExpertdetail.primary_language_expertise} | ${RegisteredExpertdetail.primary_proficiency_level}`
                        : ""}
                    </Typography>
                    <Typography fontSize="16px" color="#212121" sx={{mt:1}}>
                      {RegisteredExpertdetail?.secondary_language_expertise
                        ? `${RegisteredExpertdetail.secondary_language_expertise} | ${RegisteredExpertdetail.secondary_proficiency_level}`
                        : ""}
                    </Typography>
                    <Typography fontSize="16px" color="#212121" sx={{mt:1}}>
                      {RegisteredExpertdetail?.ternary_language_expertise
                        ? `${RegisteredExpertdetail.ternary_language_expertise} | ${RegisteredExpertdetail.ternary_proficiency_level}`
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider style={{ margin: "0px 16px" }} />
          <Box sx={{ p: 3 }}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              fontSize="18px"
              fontWeight={600}
              sx={{ mb: "24px" }}
            >
              Additional Info
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Biodata/Resume
                </Typography>
              </Grid>
              <Grid itemxs={9} sm={9} md={9} lg={9} xl={9}>
                <Typography fontSize="14px" fontWeight={400}>
                  <strong
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "12px",
                    }}
                  >
                    <img src={pfdIcon} alt="file icon" />
                    {RegisteredExpertdetail?.bio_data}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Add Relevant links
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                <Typography fontSize="14px" fontWeight={400} color="#0A65CC">
                  {cleanedLinks?.join(" , ")}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Brief Info
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                <Typography fontSize="14px" fontWeight={400}>
                  {RegisteredExpertdetail?.brief_description}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
};
