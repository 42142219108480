import { Box, Button, IconButton, TextField, Typography,useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

const OrganisationDetails = ({handleCloseDialog}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [respData, setRespData] = useState();
    const mobileDevice = useMediaQuery('(max-width:600px)');
    const Base_url =
  process.env.REACT_APP_APIGW_BASE_URL ||
  "https://dashboard-staging-be.bhashini.co.in";

    const FetchOrganisationdetails = () => {
      const endpoint = `${Base_url}/advance-user-details`;
      fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization:localStorage.getItem("token"),
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Response:", data);
          setRespData(respData);
          // Handle success response
          // setResponseData(data[0])
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle error
        });
    };

    useEffect(() => {
      FetchOrganisationdetails()
    },[])
  return (
    <>
    <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
        <Typography fontFamily={"Inter, sans-serif"} fontWeight={600} mt={2} variant='h4'>Organisation Details</Typography>
        <IconButton
                    aria-label="edit"
                    style={{ position: 'absolute', top: '30px', right: '70px', zIndex: 9999, background:"#DBEDFF"}}
                    // onClick={handleCloseDialog}
                >
                    <EditIcon sx={{color:"#0671E0"}} />
                </IconButton>
     <IconButton
                    aria-label="close"
                    style={{ position: 'absolute', top: '30px', right: '20px', zIndex: 9999 }}
                    onClick={handleCloseDialog}
                >
                    <CloseIcon />
                </IconButton>
    </Box>
    <Box display="flex" flexDirection="column" gap={3} mt={4} mb={2}>
         <TextField
              fullWidth
              label="Organisation Name"
              variant="outlined"
              name="contact"
              value={respData?.organisation ? respData?.organisation : ""}
            //   onChange={handleChange}
            //   error={errors.contact && formData.contact === '' && Boolean(errors.contact)}
            />
             <TextField
              fullWidth
              label="Sector"
              variant="outlined"
              name="contact"
            //   value={formData.contact}
            //   onChange={handleChange}
            //   error={errors.contact && formData.contact === '' && Boolean(errors.contact)}
            />
             <TextField
              fullWidth
              label="Reference Application"
              variant="outlined"
              name="contact"
              
            //   value={formData.contact}
            //   onChange={handleChange}
            //   error={errors.contact && formData.contact === '' && Boolean(errors.contact)}
            />
             <TextField
              fullWidth
              label="Employee Count"
              variant="outlined"
              name="contact"
            //   value={formData.contact}
            //   onChange={handleChange}
            //   error={errors.contact && formData.contact === '' && Boolean(errors.contact)}
            />

            <Box mt={3} display="flex" flexDirection={mobileDevice ? 'column' : 'row'} gap={mobileDevice ? '10px' : ''} justifyContent="space-between" alignItems="center" sx={{backgroundColor:"#F5F7FA",borderRadius:"4px", padding:"10px"}}>
              <Typography fontFamily={"Inter, sans-serif"} fontWeight={600} sx={{marginLeft:"10px"}} variant='h6'>Adhaar</Typography>
              <Box display="flex" flexDirection='row' justifyContent="space-between">
              <Box><Button variant="outlined" color="error" sx={{borderRadius:"2px", marginRight:"15px", border:"none"}} >
  Delete
</Button></Box>
                <Box><Button variant="outlined" sx={{borderRadius:"2px",backgroundColor:"white", color:"#0671E0", border:"none"}}>
  Update
</Button></Box>
               
              </Box>
            </Box>
          
             
    </Box>
    </>
  )
}

export default OrganisationDetails