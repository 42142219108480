import axios from 'axios';
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";
import { Snackbar } from '@mui/material';
export default class SahyogiDownloadFileAPI extends API {
  constructor(value, timeout = 2000) {
    super("GET", timeout, false); 
    this.type = C.SAHYOGI_DOWNLOAD_FILE; // Set the type of the API call
    this.value = value; // Set the value (filename)
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.SahyogiDownloadFile}?filename=${value}`; // Construct the endpoint URL with the filename
  }

  async downloadDocument() {
    try {
      const response = await axios.get(this.endpoint, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/octet-stream', 
          'Authorization': localStorage.getItem('token') 
        }
      });
      const blob = new Blob([response.data], { type: 'application/octet-stream' }); 
      const url = window.URL.createObjectURL(blob); 
      const link = document.createElement('a'); 
      link.href = url; 
      link.setAttribute('download', this.value); 
      document.body.appendChild(link); 
      link.click(); 
      document.body.removeChild(link); 
    } catch (error) {
      console.error('Error fetching data:', error); 
    }
  }

  processResponse(res) {
    super.processResponse(res);
    console.log(res, "res"); // Log the response to the console
    if (res) {
      this.report = res; // Store the response if it's valid
    }
  }

  apiEndPoint() {
    return this.endpoint; // Return the constructed endpoint URL
  }

  getBody() {
    return {}; // No body needed for GET request
  }

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json", // Set the content type to JSON
        "Authorization": localStorage.getItem('token') // Include the authorization token from local storage
      },
    };
  }

  getPayload() {
    return this.report; // Return the stored response
  }
}
