/**
 * Update Onboard User API
 */
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class updateManagerRegistrationAPI extends API {
  constructor(request_id, payload, timeout = 2000) {
    super("PUT", timeout, false);
    this.type = C.UPDATE_MANAGER_REGISTRATION;
    this.request_id = request_id;
    this.payload = payload;
    // this.status = status;
    this.endpoint = `${super.apiEndPointAuto()}${
      ENDPOINTS.updateManagerRegistration
    }/${this.request_id}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
      this.report = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {
      ceo_approval: {...this.payload},
    };
  }

  getHeaders() {
    const authorization = localStorage.getItem("token");
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report;
  }
}
