// LogoutAPI.js
import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class LogoutAPI extends API {
  constructor(timeout = 2000) {
    super("GET", timeout, false); 
    this.type = C.LOGOUT;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.logout}`;
  }

  processResponse(res) {
    super.processResponse(res);
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    
    return {};
  }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return null;
  }
}
