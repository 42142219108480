/**
 * Login API
 */
import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class EditPluginDataApi extends API {
  constructor(request_id,email,name,app_name,user_id,api_key,host_url, timeout = 2000) {
    super("PUT", timeout, false);
    this.type = C.EDIT_PLUGIN_DATA;
    this.request_id = request_id;
    this.email = email;
    this.name = name;
    this.app_name = app_name;
    this.user_id = user_id;
    this.api_key = api_key;
    this.host_url = host_url;
   
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.editPluginData}?request_id=${request_id}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {
      request_id: this.request_id,
      requested_by: this.email,
      requestor_name: this.name,
      app_name: this.app_name,
      user_id: this.user_id,
      api_key: this.api_key,
      host_url: this.host_url,
    };
  }

  getHeaders() {
    this.headers = {
        headers: {
            "Content-Type": "application/json",
            "authorization": localStorage.getItem("token")
        },
    };
    return this.headers;
}

  getPayload() {
    return this.report
  }
}
