// RequestActionDialog

import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  Divider,
  Avatar,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Chip
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateOnboardingRequestStatusAPI from "../../redux/actions/api/Dashboard/UpdateOnboardingRequestStatus/UpdateOnboardingRequestStatus";
import { APITransport } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AdhaarCardImage from "../../assets/aadhar.png";
import PanCardImage from "../../assets/pancard.png";
import FetchAppRequestDetails from "../../redux/actions/api/Dashboard/FetchAppRequestDetails";
import Modal from "../../common/Modal";
import { setSnackBar } from "../../redux/actions/Common";
import { getFormattedDate } from "../../utils/utils";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import apiEndpoint from "../../config/apiEndpoint";
import API from "../../redux/actions/api/api";
import CustomModal from "../common/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FetchManagerListAPI from "../../redux/actions/api/ReferenceApplications/FetchManagerList";
import ReferenceApplicationRequestAPI from "../../redux/actions/api/ReferenceApplications/referenceApplicationRequest";
import success from "../../assets/success.gif"
import GetChecklistDataApi from "../../redux/actions/api/Dashboard/GetChecklistData/GetChecklistData";
import ArrowRight from '../../assets/arrowBack1.svg'
export const RequestActionDialog = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestData = location?.state?.reqData;
  const requestRowData = location.state?.rowValue;
  const apiSatus = useSelector((state) => state.apiStatus);
  const checklistData = useSelector((state) => state?.FetchCheckListData?.data);  
  const appRequestDetails = useSelector(
    (state) => state.fetchAppRequestDetails?.data ?? {}
  );
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");
  const [openModal, setOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: "", description: "" });
  const [status, setStatus] = useState(null);
  const [okmodalInfo, setOkmodalInfo] = useState(false);
  const [selectedManager, setSelectedManager] = useState("");
  const [openConfoirmModal, setOpenConfoirmModal] = useState(false);
  const [selectedManagerName, setselectedManagerName] = useState({});
  const [remark, setRemark] = useState("");
  const ceoEmail = process.env.REACT_APP_CEO_EMAIL ? process.env.REACT_APP_CEO_EMAIL : "ceo-dibd@digitalindia.gov.in";
  const apiStatus = useSelector((state) => state.apiStatus);
  const showAssignManager = requestData?.request_to === ceoEmail;

  // dialog states 
  const [open, setOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [context, setContext] = useState("");

// console.log(remark,"remarkremarkremark")

  const managerList = useSelector(
    (state) => state.fetchManagerList?.data?.requests

  );
  const FetchingManagerList = () => {
    const payload = { "status": ["Approved", "Registered"] }
    const reqObj = new FetchManagerListAPI(payload);
    dispatch(APITransport(reqObj));
  };

  useEffect(() => {
    FetchingManagerList();
  }, []);

  const handleRequestAccessButtonClick = () => {
    const payloadData = {
      request_to: selectedManager,
      comments_from_ceo: remark
    }
    const reqObj = new UpdateOnboardingRequestStatusAPI(requestData._id, payloadData);
    dispatch(APITransport(reqObj));
  }

  const handleChangeRemark = (event) => {
    setRemark(event.target.value);
  };

  const handleActionFn = (value) => {
    const { title, description } = modalInfo;
    const selectedLabels = Object.entries(checkedItems)
    .filter(([_, value]) => value !== null)
    .map(([_, value]) => value);
    const payloadData =
      role === "manager"
        ? {
            manager_approval: {
              status: value,
              updated_by: email,
              updated_on: new Date(),
              updater_name: username,
              // remarks_title: title,
              // remarks_description: description,
              checklist: selectedLabels,
              remarks_to_ceo: context
            },
          }
        : role === "ceo"
          ? {
            ceo_approval: {
              status: value,
              updated_by: email,
              updated_on: new Date(),
              updater_name: username,
              remarks_title: title,
              remarks_description: description,
            },
          }
          : {};
    const apiObj = new UpdateOnboardingRequestStatusAPI(
      requestData?._id,
      payloadData
    );
    dispatch(APITransport(apiObj));
  };
  const onActionButtonClick = (value) => {
    /// console.log("requestData --- ", requestData);
    // const varificationBy = localStorage.getItem("role");
    // let appStatusUpdate;
    // if (value && varificationBy === "manager") {
    //     appStatusUpdate = "Onboard Manager Approved"
    // } else if (value && varificationBy === "CEO") {
    //     appStatusUpdate = "CEO Approved"
    // } else if (!value) {
    //     appStatusUpdate = "Rejected"
    // }

    // const payloadData = requestData?.reference_apps?.filter(el => el?.status === varificationBy === "manager" ? "Pending" : "Onboard Manager Approved").map(((el) => {

    //     return {
    //         "requestID": requestData.request_id,
    //         "appName": el.name,
    //         "status": appStatusUpdate
    //     }
    // }))

    // console.log("payloadData --- ", payloadData);
    if (value !== "Rejected") {
      setStatus(() => {
        handleActionFn(value);
        return value;
      });
      return;
    }

    setIsOpen(true);
  };

  const handleClose = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    if (
      apiSatus.apiType === "UPDATE_ONBOARDING_REQUEST_STATUS" &&
      apiSatus.success
    ) {
      // console.log("woo hooo!")
      apiStatus?.success && selectedManager && setOpenConfoirmModal(true)
      apiStatus?.success && setOpenModal(false);

      navigate(-1, { replace: true });
      dispatch(
        setSnackBar({
          open: true,
          message: selectedManager !== "" ? "Request Assigned Successfully" :
            status === "Approved"
              ? "Request Approved Successfully"
              : "Request Rejected Successfully",
          variant: "success",
        })
      );
      if (apiStatus?.success) {
        const timer = setTimeout(() => {
          setOpenConfoirmModal(false);
          navigate(-1, { replace: true });
        }, 3000);

        return () => clearTimeout(timer);
      }
    }
  }, [apiSatus, status, selectedManager]);

  const fetchAppRequestDetails = () => {
    const { requested_by_email, application_name } = requestData;
    const apiObj = new FetchAppRequestDetails(
      requested_by_email,
      application_name
    );
    dispatch(APITransport(apiObj));
  };

  useEffect(() => {
    fetchAppRequestDetails();
  }, [requestData]);

  const handleClickAssignManager = () => {
    setOpenModal(true);
  };

  const handleConfoirmModalClose = () => {
    setOpenConfoirmModal(false)
    setSelectedManager("")
    setRemark("")
    navigate(-1, { replace: true });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenConfoirmModal(false)
    }, 3000);

    return () => clearTimeout(timer);
  }, [openConfoirmModal, selectedManager]);

  const processApiData = () => {
    if (!Array.isArray(managerList)) {
      return [];
    }
    const transformedItems = managerList?.map(item => ({
      firstname: item.first_name,
      lastname: item.last_name,
      email: item.official_email
    }));

    return [...transformedItems];
  };
  const renderActionStatus = (status, requestRowData) => {
    if (role === "ceo") {
      const isRejected =
        requestRowData &&
        (requestRowData[7] === "Rejected" || requestRowData[8] === "Rejected");
      const Requesrstatus =
        requestRowData[7] === "Approved" && requestRowData[8] === "Pending";
        console.log(requestRowData,"requestRowDatarequestRowData")
      if (
        (requestRowData[7] === "Approved" ||
          requestRowData[8] === "Approved") &&
        status === "Approved"
      ) {
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="7px 10px"
            borderRadius="40px"
            bgcolor="#DEFFDB"
            color="#0C8501"
            fontWeight={400}
            fontSize="14px"
            width="fit-content"
            marginLeft="auto"
          >
            <Typography>Approved</Typography>
          </Box>
        );
      } else if (isRejected) {
        return (
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="7px 10px"
            borderRadius="40px"
            bgcolor="#FFF1F0"
            color="#E02B1D"
            fontWeight={400}
            fontSize="14px"
            width="fit-content"
            marginLeft="auto"
          >
            <Typography>Rejected</Typography>
          </Box>
        );
      } else {
        return Requesrstatus ? (
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="success"
              sx={{ borderRadius: 1, mr: 1 }}
              onClick={() => onActionButtonClick("Approved")}
            >
              Approve
            </Button>
            <Button
              variant="outlined"
              color="error"
              sx={{ borderRadius: 1 }}
              onClick={() => onActionButtonClick("Rejected")}
            >
              Reject
            </Button>
          </Box>
        ) : showAssignManager ? <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="error"
            sx={{ borderRadius: 1 }}
            onClick={() => onActionButtonClick("Rejected")}
          >
            Reject
          </Button>
        </Box> : null;
      }
    } else if (role === "manager") {
      switch (status) {
        case "Approved":
          return (
            <Box
              display="flex"
              justifyContent="flex-end"
              padding="12px 24px"
              borderRadius="40px"
              bgcolor={"#DEFFDB"}
              color={"#0C8501"}
              fontWeight={600}
              fontSize={"16px"}
              width="fit-content"
              marginLeft={"auto"}
            >
              <Typography>Approved</Typography>
            </Box>
          );

        case "Rejected":
          return (
            <Box
              display="flex"
              justifyContent="flex-end"
              padding="12px 24px"
              borderRadius="40px"
              bgcolor={"#FFF1F0"}
              color={"#E02B1D"}
              fontWeight={600}
              fontSize={"16px"}
              width="fit-content"
              marginLeft={"auto"}
            >
              <Typography>Rejected</Typography>
            </Box>
          );
        default:
          return (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="error"
                sx={{ mr: 1, borderRadius: 1 }}
                onClick={() => onActionButtonClick("Rejected")}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ borderRadius: 1 }}
                // onClick={() => onActionButtonClick("Approved")}
                onClick={handleToggle}
              >
                Approve
              </Button>
            </Box>
          );
      }
    }
  };

  const handleModalValueChange = (prop, value) => {
    setModalInfo((prev) => ({ ...prev, [prop]: value }));
  };

  const alternateButtonClick = () => {
    const { title, description } = modalInfo;
    if (!!title && !!description) {
      setStatus(() => {
        handleActionFn("Rejected");
        return "Rejected";
      });

      setIsOpen(false);
    }
  };

  const downloadVerificationFile = async (fileName) => {
    const apiObj = new API();
    try {
      const response = await fetch(
        `${apiObj.apiEndPointAuto()}${apiEndpoint.getProofDownloadUrl
        }?filename=${fileName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error("Failed to download file:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };



  const handleSelectChange = (event) => {
    const email = event.target.value;
    setSelectedManager(email);
    const selectedManager = processApiData()?.find(manager => manager.email === email);
    if (selectedManager) {
      setselectedManagerName({ firstname: selectedManager.firstname, lastname: selectedManager.lastname });
    }
  };
  const handleCancel = () => {
    setSelectedManager("");
    setRemark("")
    setOpenModal(false);
  };

  const handleOk = () => {
    handleRequestAccessButtonClick()
    dispatch(
      setSnackBar({
        open: selectedManager === "",
        message: selectedManager === "" ? "Please select the manager." : "",
        variant: "error",
      })
    );
  };

  const handleCheckboxChange = (id,label) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: prev[id] ? null : label, 
    }));
  };

  const handleApprove = () => {
   
    onActionButtonClick("Approved")
    setOpen(false);
    setCheckedItems({})
    setContext("")
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const isApproveDisabled = 
  Object.values(checkedItems).filter(value => value !== null).length === 0 || 
  context.trim() === "";

  const managerCheckedItems =
    appRequestDetails?.app_details?.[0]?.manager_approval?.checklist || [];

// useEffect(() => {
  //   if (role === 'manager') {
  //     const apiObj = new GetChecklistDataApi();
  //     dispatch(APITransport(apiObj));
  //   }
  // }, [role, dispatch]);

  useEffect(() => {
      const apiObj = new GetChecklistDataApi();
      dispatch(APITransport(apiObj));
  }, []);

  return (
    <>
    <Container maxWidth="lg">
      <Paper elevation={3}>
        <Button
          variant="text"
          color="inherit"
          onClick={() => navigate(-1, { replace: true })}
          sx={{ mt: "16px", ml: "5px" }}
        >
          <ArrowBackOutlinedIcon /> &nbsp; Onboarding Requests
        </Button>
        {role === "manager" && appRequestDetails?.app_details?.[0]?.is_assigned_by_ceo &&
          <Box sx={{ p: 3 }} display="flex" justifyContent="space-between" alignItems="center">
            {appRequestDetails?.app_details?.[0]?.comments_from_ceo && <Typography variant="body2" style={{ maxWidth: '542px' }}>{appRequestDetails?.app_details?.[0]?.comments_from_ceo} </Typography>}
            <Chip style={{ color: "#2947A3", fontWeight: '600', height:"40px" }} label="Assigned by CEO" />
          </Box>}
          {role === 'ceo' && appRequestDetails?.app_details?.[0]?.manager_approval.status === 'Approved' &&
          <Box sx={{ p: 3 }} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
            <Typography variant="body2" style={{ maxWidth: '542px' }}>{appRequestDetails?.app_details?.[0]?.manager_approval?.updater_name} has reviewed and approved this application request.</Typography>
             <Typography variant="body2">
             <Button onClick={handleToggle} style={{padding:"0px"}}>View Approval details &nbsp; <img src={ArrowRight}/></Button>
             </Typography>
            </Box>
            <Chip style={{ color: "#2947A3", fontWeight: '600', height:"43px" }} label="Approved by Manager" />
          </Box>}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          sx={{ p: 3 }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

              {(showAssignManager && requestData?.status !== "Rejected") && <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ fontSize: "14px" }}>
                    Requests marked as 'NA/Other' require your attention. Please
                    assign a manager to process these application access
                    requests.
                  </Typography>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "5px" }}
                    onClick={handleClickAssignManager}
                  >
                    Assign Manager
                  </Button>
                </Grid>
              </Grid>}
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Avatar
                        style={{
                          height: "57px",
                          width: "57px",
                          backgroundColor: "#A8A8A8",
                          color: "#595656",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontWeight={700} fontSize={"24px"}>
                        {`${requestData?.requestor_name}`}
                      </Typography>
                      <Typography
                        fontSize={"16px"}
                        gutterBottom
                        color="#3A3A3A"
                      >
                        {appRequestDetails?.profile_details?.email_id}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  {renderActionStatus(
                    requestData?.roleBasedStatus,
                    requestRowData
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Account Type
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.org_info?.acnt_type}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Personal email ID
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.personal_email_id}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Designation
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.designation}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Phone No
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.phone}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Date of Birth
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.dob}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ margin: "0px 16px" }} />
        <Box sx={{ p: 3 }}>
          <Typography
            fontFamily={"Inter, sans-serif"}
            fontSize="18px"
            fontWeight={600}
            sx={{ mb: "24px" }}
          >
            Request Details
          </Typography>
          {role !== "ceo" &&
            appRequestDetails?.app_details?.map((detail) => {
              const {
                application_name,
                requested_on,
                manager_approval: { updated_by, updated_on },
              } = detail;

              return (
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography fontSize="14px" color="#6A6A6A">
                          Application Requested
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography fontSize="16px" color="#212121">
                          {application_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography fontSize="14px" color="#6A6A6A">
                          Request Date & Time
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Typography fontSize="16px" color="#212121">
                          {getFormattedDate(requested_on)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {requestData?.roleBasedStatus !== "Pending" && (
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography fontSize="14px" color="#6A6A6A">
                            {requestData?.roleBasedStatus === "Rejected"
                              ? "Rejected Date & Time"
                              : "Approved Date & Time"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography fontSize="16px" color="#212121">
                            {getFormattedDate(updated_on)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* {requestData?.roleBasedStatus === "Approved" && (
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography fontSize="14px" color="#6A6A6A">
                            CEO Status
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Typography fontSize="16px" color="#212121">
                            {requestData.ceo_approval.status}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )} */}
                  {requestData?.roleBasedStatus !== "Pending" &&
                    role !== "ceo" && (
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography fontSize="14px" color="#6A6A6A">
                              {requestData?.roleBasedStatus === "Rejected"
                                ? "Rejected By"
                                : "Approved By"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography fontSize="16px" color="#212121">
                              {updated_by}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              );
            })}
          {role === "ceo" && (
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      Application Requested
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {requestData?.application_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      Manager Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {requestData?.manager_approval?.updater_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      {requestData?.status === "Pending"
                        ? "Request Date & Time"
                        : requestData.status === "Rejected"
                          ? "Rejected Date & Time"
                          : "Approved Date & Time"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {requestData?.status === "Pending"
                        ? getFormattedDate(requestData.requested_on)
                        : requestData?.ceo_approval?.updated_on
                          ? getFormattedDate(
                            requestData?.ceo_approval?.updated_on
                          )
                          : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="14px" color="#6A6A6A">
                      {requestData?.manager_approval?.status === "Rejected"
                        ? "Manager Rejected Date & Time"
                        : "Manager Approval Date & Time"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontSize="16px" color="#212121">
                      {requestData?.manager_approval?.updated_on &&
                        getFormattedDate(
                          requestData?.manager_approval?.updated_on
                        )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
        <Divider style={{ margin: "0px 16px" }} />
        <Box sx={{ p: 3 }}>
          <Typography
            fontFamily={"Inter, sans-serif"}
            fontSize="18px"
            fontWeight={600}
            sx={{ mb: "24px" }}
          >
            Organization Details
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Organisation Type
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.org_type}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Department Name
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.org_info?.dpt_name}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Industry
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.org_info?.industry}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Organization Name
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.org_info?.org_name}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    Organisation Website
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.org_info?.org_site}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    GST Number
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="16px" color="#212121">
                    {appRequestDetails?.profile_details?.org_info?.gstn}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Divider style={{ margin: "0px 16px" }} />
        <Box sx={{ p: 3 }}>
          <Typography
            fontFamily={"Inter, sans-serif"}
            fontSize="18px"
            fontWeight={600}
            sx={{ mb: "24px" }}
          >
            Document
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography fontSize="14px" color="#6A6A6A">
                    {appRequestDetails?.profile_details?.verification?.type ===
                      "pan"
                      ? "Pan Card"
                      : "Aadhaar Card"}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{
                        width: "182.77px",
                        height: "151.79px",
                      }}
                    >
                      <img
                        src={
                          appRequestDetails.profile_details?.verification
                            ?.type === "pan"
                            ? PanCardImage
                            : AdhaarCardImage
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        alt="doc"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        display="flex"
                        gap="5px"
                        alignItems="center"
                        rowGap={"11px"}
                      >
                        <Typography fontSize="14px" fontWeight={400}>
                          {appRequestDetails?.profile_details?.verification?.id}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            downloadVerificationFile(
                              appRequestDetails?.profile_details?.verification
                                ?.proof
                            )
                          }
                        >
                          <DownloadForOfflineOutlinedIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {requestData?.roleBasedStatus === "Rejected" && role !== "ceo" && (
          <>
            <Divider style={{ margin: "0px 16px" }} />
            <Box sx={{ p: 3 }}>
              {appRequestDetails?.app_details?.map((detail) => {
                const {
                  manager_approval: { remarks_description, remarks_title },
                } = detail;
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Reason for Rejection
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" fontWeight={400}>
                        {remarks_title}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Description:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" fontWeight={400}>
                        {remarks_description}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </>
        )}
        {role === "ceo" && requestData?.status === "Rejected" && (
          <>
            <Divider style={{ margin: "0px 16px" }} />
            <Box sx={{ p: 3 }}>
              {appRequestDetails?.app_details?.map((detail) => {
                const {
                  ceo_approval: { remarks_description, remarks_title },
                } = detail;
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Reason for Rejection
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" fontWeight={400}>
                        {detail?.ceo_approval?.remarks_title
                          ? detail?.ceo_approval?.remarks_title
                          : detail?.manager_approval?.remarks_title}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" color="#6A6A6A">
                        Description:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontSize="14px" fontWeight={400}>
                        {detail?.ceo_approval?.remarks_description
                          ? detail?.ceo_approval?.remarks_description
                          : detail?.manager_approval?.remarks_description}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </>
        )}
      </Paper>
      {isOpen && (
        <Modal
          cancelButton
          isAlternativeBtnDisabled={
            !(!!modalInfo.title && !!modalInfo.description)
          }
          alternateButton="Send"
          title={"Please provide the reason for rejecting the request"}
          isOpen={isOpen}
          handleClose={handleClose}
          alternateButtonClick={alternateButtonClick}
        >
          <Grid container spacing={3} margin="16px 0px" width="inherit">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingLeft: "0px", paddingTop: "0px" }}
            >
              <TextField
                fullWidth
                label="Title"
                value={modalInfo.title}
                onChange={(e) =>
                  handleModalValueChange("title", e.target.value)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ paddingLeft: "0px" }}
            >
              <TextField
                fullWidth
                label="Description"
                rows={4}
                multiline
                value={modalInfo.description}
                onChange={(e) =>
                  handleModalValueChange("description", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </Modal>
      )}
      {openModal && (
        <CustomModal
          open={openModal}
          handleClose={handleCancel}
          paddingdata="18px 24px 18px 24px"

        >
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography sx={{ fontSize: "18px", fontFamily: "Inter" }}>
                  Assign Manager
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleCancel}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 3 }}>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Inter, sans-serif" }}
              >
                Select Manager
              </Typography>
              <FormControl
                sx={{ minWidth: 400, height: "auto" }}
                size="small"
              >
                <InputLabel
                  id="demo-select-small-label"
                  sx={{ fontSize: "14px", mt: 1 }}
                >
                  Select Manager
                </InputLabel>
                <Select
                  labelId="select-manager-label"
                  id="select-manager"
                  value={selectedManager}
                  label="Select Manager"
                  onChange={handleSelectChange}
                  sx={{ fontSize: "12px", mt: 1 }}
                >
                  {processApiData()?.map((item) => (
                    <MenuItem key={item.email} value={item.email}>
                      {item.firstname} {item.lastname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid sx={{ mt: 3 }}>
              <Typography
                variant="body2"
                sx={{ fontFamily: "Inter, sans-serif" }}
              >
                Remark
              </Typography>
              <TextField
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                placeholder="Write yours remarks"
                value={remark}
                onChange={handleChangeRemark}

                sx={{
                  fontSize: "12px",
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    padding: '10px',
                    marginTop: "5px"
                  },
                  '& .MuiInputLabel-root': {
                    top: '-8px',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '16px',
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '16px',
                    color: '#0C0F0F',
                  },
                }}
              />
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 3 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleCancel}
                  sx={{ borderRadius: "5px" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOk(true)}
                  sx={{ borderRadius: "5px" }}
                >
                  Assign Request
                </Button>
              </Grid>
            </Grid>{" "}
          </>
        </CustomModal>
      )}

      {openConfoirmModal && (
        <CustomModal
          open={openConfoirmModal}
          handleClose={handleConfoirmModalClose}
          paddingdata="18px 24px 18px 24px"
        >
          <Grid container alignItems="center">
            <Grid item>
              <IconButton sx={{ position: "absolute", top: 0, right: 0 }} onClick={handleConfoirmModalClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography align="center">
                <img
                  src={success}
                  alt="Logo"
                  style={{
                    height: "130px"
                  }}
                />
              </Typography>
              <Typography
                sx={{ textAlign: "center", fontSize: "18px" }}
              >
                {`You’ve successfully assigned the request to ${selectedManagerName?.firstname} ${selectedManagerName?.lastname}`}
              </Typography>
            </Grid>
          </Grid>
        </CustomModal>
      )}
    </Container>
    <Dialog open={open} onClose={handleToggle} fullWidth maxWidth="sm"  PaperProps={{
          style: { borderRadius: 8, padding:"10px" },  // Set borderRadius here
        }}>
        <DialogTitle >
          <Typography variant="h2" fontWeight={600} fontFamily = "Inter, sans-serif" sx={{ fontSize: "20px", marginTop:"10px" }}>
          Approval Checklist
          </Typography>
         </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box style={{marginBottom:"10px", fontSize:"16px"}}>Please confirm that the following tasks have been completed before approving the request:</Box>
              {( role === "ceo"?checklistData.some(item => managerCheckedItems.includes(item))
                ? checklistData 
                : managerCheckedItems :checklistData
              ).map((item, index) => (
                <Box key={item}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          role === "ceo"
                            ? managerCheckedItems.includes(item) // Show manager's checked state for CEO
                            : !!checkedItems[index]
                        }
                        onChange={
                          role === "manager"
                            ? () => handleCheckboxChange(index, item)
                            : undefined
                        }
                        disabled = {role === "ceo"}
                      />
                    }
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '17px' } }}
                    label={item}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={12}>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Inter, sans-serif",
                fontWeight: "600",
                marginBottom: "5px",
              }}
            >
             Request context to CEO<sup style={{color:"red"}}>*</sup>
            </Typography>
              <TextField
                placeholder="Write here...."
                multiline
                fullWidth
                rows={3}
                value={role === 'ceo' ? appRequestDetails?.app_details?.[0]?.manager_approval?.remarks_to_ceo : context}
                onChange={(e) => setContext(e.target.value)}
                disabled = {role === "ceo"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"5px 20px 30px 25px"}}>
          <Button onClick={handleToggle} variant="outlined" color="primary" sx={{borderRadius:"5px"}}>Cancel</Button>
          <Button onClick={handleApprove} variant="contained" disabled={role === 'ceo' ? null :isApproveDisabled} sx={{borderRadius:"5px", backgroundColor:"#009262", color:"#ffffff"}}>Approve</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
