import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    maxWidth: 345,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
    padding:"20px"
  },
  mediaCard:{
    display:"flex",
    justifyContent:"center",
    paddingTop:"25px",
  },
  media: {
    height: 60,
  },
  cardNumber: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    backgroundColor: '#F0F4F8',
    color: 'black',
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    width:"29px",
    fontSize:"14px"
  },
  content: {
    textAlign: 'center',
  },
}));

const CardComponent = ({ image, title, description, cardNumber }) => {
  const classes = useStyles();

  

  return (
    <Card className={classes.card}>
      <Box className={classes.cardNumber}>{cardNumber}</Box>
      <Box  className={classes.mediaCard}>

      <img
        className={classes.media}
        src={image}
        
      />
      </Box>
      <CardContent className={classes.content}>
        <Typography variant="h5"  component="h2" sx={{ fontFamily: "Inter, sans-serif", fontWeight: "600" }}>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ fontFamily: "Inter, sans-serif", fontWeight: "400", marginTop:"10px" }} component="p">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
