import C from "../../../constants";

const initialState = {
    data: [],
    searchTerm: '',  
}

const updateStatusBasedOnRole = (payload) => {
    const role = localStorage.getItem('role');
    return payload.map((element) => {
        return { ...element, roleBasedStatus: role === 'manager' ? element.manager_approval.status : element.ceo_approval.status };
    });
};

const filterDataBySearchTerm = (data, searchTerm) => {
    if (!searchTerm) return data;
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return data.filter(item =>
        item.requested_by_email?.toLowerCase().includes(lowerCaseSearchTerm) 
       );
};

const fetchOnboardingRequests = (state = initialState, action) => {
    switch (action.type) {
        case C.FETCH_ONBOARDING_REQUESTS:
            return {
                ...state,
                data: updateStatusBasedOnRole(action.payload?.applications ?? [])
            };
        case C.SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload
            };
        default:
            return state;
    }
};

export const getFilteredData = (state) => {
    const { data, searchTerm } = state.fetchOnboardingRequests;
    return filterDataBySearchTerm(data, searchTerm);
};

export default fetchOnboardingRequests;