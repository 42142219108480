// import { AppBar, IconButton, Toolbar, Menu, MenuItem } from '@mui/material'
import {
  AppBar,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  Typography,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Logo from "../../assets/bhashiniLogo.png";
import Logo from "../../assets/Bhashini_new_en.png";
import AccountCircle from "@mui/icons-material/AccountCircle";

// import { logout } from '../../services/logout';
import { APITransport, LogoutAPI } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { setSnackBar,setSearchTerm } from "../../redux/actions/Common";
import { styled } from "@mui/system";
import UserDetails from "../UserManagement/ProfileDetails/UserDetails";
import OrganisationDetails from "../UserManagement/ProfileDetails/OrganisationDetails";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import GetUserDetailsAPI from "../../redux/actions/api/UserManagement/GetUserDetails";
import { getHeaderTitle } from "../../constants/constants";
import { renderTitle, roleBasedHeader } from "../../utils/utils";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from 'react-router-dom';
const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "lightgray",
  zIndex: 999,
});

const Header = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.getUserDetails.data);
  const apiStatus = useSelector((state) => state.apiStatus);
  const [avatarCircleChar, setAvatarCircleChar] = useState("");

  const searchTerm = useSelector(state => state?.fetchOnboardingRequests?.searchTerm);

  const placeholderText = location.pathname === '/requests'
  ? "Search with customers email ID"
  : "Search...";

  useEffect(() => {
    dispatch(setSearchTerm(''));
  }, [location.pathname, dispatch]);
  
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const logoutAPIObj = new LogoutAPI();
    dispatch(APITransport(logoutAPIObj));
    localStorage.clear();
    window.location.reload();
  };

  const handleMyProfile = () => {
    setAnchorEl(null);
    // setOpenDialog(true);
    if(role === 'User'){
    navigate("/profile");
    }else{
      navigate('/my-profile')
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const getBasicUserDetails = new GetUserDetailsAPI();
    dispatch(APITransport(getBasicUserDetails));
  }, []);

  useEffect(() => {
    userDetails &&
      setAvatarCircleChar(
        userDetails?.first_name?.substring(0, 2).toUpperCase()
      );
  }, [userDetails]);

  const setHeaderTitle=(path)=>{
    if (/^\/sahyogi-requests(\/[a-f0-9\-]+)?$/.test(path)) {
      return 'Sahyogi Requests';
    }
    switch(path){
      case '/sahyogi-requests':
        return 'Sahyogi Requests';
      case '/user-management':
      case '/ceo-request-action':
        return 'Managers Requests';
      default:
          return 'Parikshan Applications';
    }
  
  }

  const role = localStorage.getItem("role");
  const username = localStorage.getItem("username")
  const shouldDisplayGrid = (role === 'ceo' || role === "manager") && (location.pathname === '/ceo-dashboard' || location.pathname === '/manager-dashboard');
  const pluginHeader=(role === 'ceo' || role === "manager") && (location.pathname === '/plugin-request' ||  location.pathname === '/plugin-request-action')

  const handleSearchChange = (event) => {
    if (location.pathname === '/requests') {
      dispatch(setSearchTerm(event.target.value));
    }
  };

  return (
    <>
      <AppBar
        style={{
          backgroundColor: "white",
          boxShadow: "0px 2px 4px #ABBED1 !important",
        }}
        elevation={10}
      >
        <Toolbar style={{ backgroundColor: "white" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100vw",
              marginLeft: "15rem",
              alignItems: "center",
              height:"84px"
            }}
          >
            <div style={{width:"50%"}}>
              {/* <Typography variant='h3' fontFamily={"Inter, sans-serif"} color={'black'} fontWeight={600}>Welcome, {userDetails?.basicDetails?.name} </Typography> */}
              <Typography fontSize={"18px"}
                    color={"#424242"}
                    fontWeight={500}
                    fontFamily={"Inter, sans-serif"}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginLeft:"25px",}}>
                {renderTitle()}
              </Typography>
              {!!roleBasedHeader?.[location.pathname] && (
                <Typography
                  fontSize={"14px"}
                  color={"#424242"}
                  fontWeight={400}
                >
                  {setHeaderTitle(location.pathname)}
                </Typography>
              )}

              {shouldDisplayGrid && (
                <div style={{padding:"30px"}}>
                  <Typography
                    fontSize={"18px"}
                    color={"#424242"}
                    fontWeight={500}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {" "}
                    Welcome, {username}
                  </Typography>
                  <Typography
                    fontSize={"14px"}
                    color={"#424242"}
                    fontWeight={400}
                  >
                    {role === "ceo" ? "Chief executive officer" : role}
                  </Typography>
                </div>
               )}

             {pluginHeader &&   <Typography
                    fontSize={"18px"}
                    color={"#424242"}
                    fontWeight={500}
                    fontFamily={"Inter, sans-serif"}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      marginLeft:"25px",
                    }}
                  >
                   Translation Plugin Request
                  </Typography>}
            </div>
            {(shouldDisplayGrid ||location.pathname === '/requests' || location.pathname === '/user-management')&& (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "20px",
                  padding: "1px",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <TextField
                      variant="outlined"
                      placeholder={placeholderText}
                      size="small"
                      value={searchTerm} 
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" size="small">
                            <IconButton edge="start">
                              <SearchIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        marginRight: "8px",
                        width: "340px", 
                        '& .MuiInputBase-input': {
                          fontSize: '17px',
                        },
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '16px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: '35px',
                          padding: '0',
                        },
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                      }}
                    />
                <div style={{ display: "flex", alignItems: "center" ,marginLeft:"10px"}}>
                  <NotificationsIcon sx={{ color: "gray" }} fontSize="medium"
                  />
                </div>
              </div>
            )}

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              {/* <span style={{ fontSize: '1.2rem' }}>{userCode}</span> */}
              <Avatar sx={{ fontFamily: "Inter, sans-serif" }}>
                {avatarCircleChar}
              </Avatar>{" "}
              <ArrowDropDownOutlinedIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
              sx={{
                "& .MuiPaper-root": {
                  // Targeting the Paper component inside Menu for rounded corners
                  borderRadius: "0.25rem",
                  border: "2px solid white",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)!important",
                },
              }}
            >
              <MenuItem onClick={handleMyProfile}>My Profile</MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {/* {openDialog && <Overlay />} */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        sx={{ paddimg: "20px" }}
      >
        <DialogContent
          style={{ display: "flex", alignItems: "start", padding: "0px" }}
        >
          <div
            style={{
              marginLeft: "20px",
              marginRight: "40px",
              marginBottom: "40px",
              flexGrow: 1,
            }}
          >
            <UserDetails handleCloseDialog={handleCloseDialog} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;
