import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GetUserDetailsAPI from "../../../redux/actions/api/UserManagement/GetUserDetails";
import { useDispatch, useSelector } from 'react-redux';
import { APITransport } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';
import UserProfileSubmitAPI from '../../../redux/actions/api/UserManagement/UserProfileSubmit';
import { setSnackBar } from "../../../redux/actions/Common";
import fetchLoggedinUserDetailsApi from '../../../redux/actions/api/ReferenceApplications/fetchLoggedinUserDetails';
const steps = ['Personal Details', 'Organization Details'];

const UpdatePersonalDetails = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [userFormSuccess, setUserFormSucess] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(false);
  const apiStatus = useSelector((state) => state.apiStatus);
  const emails = localStorage.getItem('email')
  const [errors, setErrors] = useState({});
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    designation: '',
    dateOfBirth: '',
    phoneNumber: '',
    officialEmail: emails,
    personalEmail: '',
    gender: 'male',
    verificationDocument: 'aadhar',
    file:selectedFile,
    adharNumber: '',
    accountType: '',
    organizationType: '',
    organizationName: '',
    departmentName: '',
    organizationWebsite: '',
    industry: '',
    gstNumber: '',
  });
  const userDetails = useSelector(state => state.getUserDetails.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = localStorage.getItem('role')

  const userDetailsData = useSelector((state) => state.fetchLoggedInUserDetails.data);
  

  const FetchingAplicationNumbers = useCallback(() => {
    const reqObj = new fetchLoggedinUserDetailsApi(); 
    dispatch(APITransport(reqObj));
  },[dispatch]);

  useEffect(() => {
    FetchingAplicationNumbers();
  }, [FetchingAplicationNumbers]);

  function convertDateFormat(dateString) {
    if(!dateString) {
      return ''
    }
    const [day, month, year] = dateString?.split('/');
    return `${year}-${month}-${day}`;
  }

  useEffect(()=>{
setFormData({
  ...formData,
  firstName: userDetailsData?.first_name,
  lastName: userDetailsData?.last_name,
  dateOfBirth: convertDateFormat(userDetailsData?.dob),
  phoneNumber: userDetailsData?.phone,
  personalEmail: userDetailsData?.personal_email_id,
  organizationType:userDetailsData?.org_type
})
  },[userDetailsData])

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value)
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf" && file.size <= 5 * 1024 * 1024) {
      setSelectedFile(file);
    } else {
      console.error("Invalid file. Please upload a valid PDF file (max 5MB).");
    }
  };

  const validateForm = () => {
    const newErrors = {};
  
    // if (!formData.firstName) {
    //   newErrors.firstName = 'Required';
    // }
  
    // if (!formData.lastName) {
    //   newErrors.lastName = 'Required';
    // }
  
    // if (!formData.designation.trim() || formData.designation === '') {
    //   newErrors.designation = 'Required';
    // }
  
    // if (!formData.dateOfBirth) {
    //   newErrors.dateOfBirth = 'Required';
    // }
  
    // if (!formData.phoneNumber || formData.phoneNumber.length !== 10) {
    //   newErrors.phoneNumber = 'Phone Number is required and must be 10 digits';
    // }
  
    // if (!formData.personalEmail) {
    //   newErrors.personalEmail = 'Personal Email is required';
    // } else if (!/\S+@\S+\.\S+/.test(formData.personalEmail)) {
    //   newErrors.personalEmail = 'Personal Email is not valid';
    // }
  
    if (!selectedFile) {
      newErrors.file = 'A PDF file is required';
    } else if (selectedFile.type !== 'application/pdf' || selectedFile.size > 5 * 1024 * 1024) {
      newErrors.file = 'File must be a PDF and less than 5MB';
    }

    if (!formData.adharNumber.trim() || formData.adharNumber === '') {
      newErrors.adharNumber = 'Required';
    }


    // if (!formData.accountType) {
    //   newErrors.accountType = 'Required';
    // }

    if (!formData.organizationName.trim() || formData.organizationName === '') {
      newErrors.organizationName = 'Required';
    }

    // if (!formData.departmentName) {
    //   newErrors.departmentName = 'Required';
    // }

    // if (!formData.organizationWebsite) {
    //   newErrors.organizationWebsite = 'Required';
    // }

    // if (!formData.industry) {
    //   newErrors.industry = 'Required';
    // }

    // if (!formData.gstNumber) {
    //   newErrors.gstNumber = 'Required';
    // }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };
  

  const handleSubmit = (event) => {
  
    event.preventDefault();
    const officialEmail = formData?.officialEmail?.trim().toLowerCase();
    const personalEmail = formData?.personalEmail?.trim().toLowerCase();
  

    const areEmailsSame = officialEmail && personalEmail && (officialEmail === personalEmail);

    if (areEmailsSame) {
      dispatch(setSnackBar({
        open: true,
        message: 'Official Email Id and Personal Email Id cannot be the same',
        variant: "error"
      }));
      return; 
    }

    if (!validateForm()) {   
      dispatch(setSnackBar({
        open: true,
        message:  'Please complete the details before submitting',
        variant: "error"
      }));
      return;
    }
    // Implement form submission logic here
    
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log(formData);
    const postData = {
      first_name: formData?.firstName,
      middle_name: '',
      last_name: formData?.lastName,
      gender: formData?.gender,
      personal_email: formData?.personalEmail,
      designation:formData?.designation,
      dob: formData?.dateOfBirth,
      phone_number: formData?.phoneNumber,
      role: 'customer',
      organization_type: formData?.organizationType,
      government_id: formData?.adharNumber,
      government_doc_type: formData?.verificationDocument,
      gst_number: formData?.gstNumber,
      organisation_name: formData?.organizationName,
      department_name: formData?.departmentName,
      organisation_website: formData?.organizationWebsite,
      industry: formData?.industry,
      account_type: formData?.accountType,
    };

    // console.log("Posting data:", postData);
    // console.log("SelectedFile:", selectedFile);

    const reqObj = new UserProfileSubmitAPI(selectedFile, postData);
    dispatch(APITransport(reqObj));
    // const fetchUpdatedBasicUserDetails = setTimeout(() => {
    //     const getBasicUserDetails = new GetUserDetailsAPI('basic');
    //     dispatch(APITransport(getBasicUserDetails));
    //     clearTimeout(fetchUpdatedBasicUserDetails);
    //   }, 1500);
  };

  // useEffect(() => {
  //   if (role === 'User') {
  //     navigate("/applications")
  //   }
  // }, [role])

  useEffect(() => {
    console.log("apistatus --- ", apiStatus)
    if (apiStatus.apiType === "USER_PROFILE_SUBMIT" ) {
      // apiStatus?.success && setActiveStep(2);
      apiStatus?.errors === true ? setActiveStep(1) : setActiveStep(2);
      apiStatus?.success === true && setUserFormSucess(true)
      // apiStatus?.success && navigate('/applications');
     
    }
  }, [apiStatus]);

  useEffect(() => {
    const { personalEmail, officialEmail } = formData;
    if (personalEmail && officialEmail &&  (personalEmail.toLowerCase().startsWith(officialEmail.toLowerCase()) ||
    officialEmail.toLowerCase().startsWith(personalEmail.toLowerCase()))) {
     setEmailError("Official Email Id and Personal Email Id cannot be the same");
    } else {
      setEmailError("");
    }
  }, [formData]);

  // useEffect(() => {
  //   if (userDetails?.basicDetails?.is_profile_updated) {
  //     navigate("/applications")
  //   }
  // }, [userDetails])

  return (
    <Box sx={{ width: '100%', padding: '20px' }}>
        <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
        <Box>
        <Typography variant="h4" align="center" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>
        Complete your profile details!
      </Typography>
      <Typography variant="body1" align="center" gutterBottom style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>
        Please review and complete your profile details
      </Typography>
      </Box>
      <Stepper activeStep={activeStep} alternativeLabel style={{width:"50%"}}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
    {
      //   userFormSuccess ?  
      //    <Box style={{ textAlign: 'center',display: "flex",flexDirection: "column", justifyContent: "space-between",
      //       alignItems: "center", height:"80vh" }}>
      //   {/* Completion Step */}
      //   <Box style={{marginTop:"15rem"}}>
      //   <Typography variant="h3"  style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}} gutterBottom>
      //     Congratulations, your profile is 100% complete!
      //   </Typography>
      //   <Typography variant="body1"  style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}} gutterBottom>
      //     You're ready to go! View your Bhimashri Portal dashboard and request applications.
      //   </Typography>
      //   </Box>
      //   <Grid container spacing={2} justifyContent="end" sx={{ marginBottom: "2rem", marginRight:"10rem" }}>
      //     <Grid item>
      //       <Button
      //         variant="outlined"
      //         color="primary"
      //         onClick={() => navigate('/user-dashboard')}
      //         style={{borderRadius:"4px"}}
      //       >
      //         View Dashboard
      //       </Button>
      //     </Grid>
      //     <Grid item>
      //       <Button
      //         variant="contained"
      //         color="primary"
      //         onClick={() => navigate('/applications')}
      //         style={{borderRadius:"4px"}}
      //       >
      //         Request Applications
      //       </Button>
      //     </Grid>
      //   </Grid>
      // </Box> :
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>

        {userFormSuccess && 
         <Box style={{ textAlign: 'center',display: "flex",flexDirection: "column", justifyContent: "space-between",
            alignItems: "center", height:"80vh" }}>
        {/* Completion Step */}
        <Box style={{marginTop:"15rem"}}>
        <Typography variant="h3"  style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}} gutterBottom>
          Congratulations, your profile is 100% complete!
        </Typography>
        <Typography variant="body1"  style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}} gutterBottom>
          You're ready to go! View your Onboarding Portal dashboard and request applications.
        </Typography>
        </Box>
        <Grid container spacing={2} justifyContent="end" sx={{ marginBottom: "2rem", marginRight:"10rem" }}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate('/user-dashboard')}
              style={{borderRadius:"4px"}}
            >
              View Dashboard
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/applications')}
              style={{borderRadius:"4px"}}
            >
              Request Applications
            </Button>
          </Grid>
        </Grid>
      </Box>}
        {userFormSuccess === false && activeStep === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>First Name</Typography>
              <TextField
                fullWidth
                placeholder="First Name"
                variant="outlined"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                // error={!!errors.firstName}
                // helperText={errors.firstName}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Last Name</Typography>
              <TextField
                fullWidth
                placeholder="Last Name"
                variant="outlined"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={!!errors.lastName}
                helperText={errors.lastName}
                FormHelperTextProps={{
                  style: {
                    color: 'red', 
                    fontSize: '0.875rem', 
                    marginLeft:"0px"
                  },
                }}
                required
              />
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Designation</Typography>
              <TextField
                fullWidth
                placeholder="Designation"
                variant="outlined"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                // error={errors.designation && formData.designation === '' && Boolean(errors.designation)}
                // helperText={errors.designation}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
               {/* {errors.designation && formData.designation === '' && <span className="error">{errors.designation}</span>} */}
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Date of Birth</Typography>
              <TextField
                fullWidth
                // label="Date of Birth"
                variant="outlined"
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                defaultValue={formData.dateOfBirth}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                // error={!!errors.dateOfBirth}
                // helperText={errors.dateOfBirth}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Phone Number</Typography>
              <TextField
                fullWidth
                placeholder="Phone Number"
                variant="outlined"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                inputProps={{ maxLength: 10, pattern: '[0-9]*' }}
                // error={!!errors.phoneNumber}
                // helperText={errors.phoneNumber}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Official Email ID</Typography>
              <TextField
                fullWidth
                placeholder="Official Email ID"
                variant="outlined"
                type="email"
                name="officialEmail"
                value={formData.officialEmail}
                onChange={handleChange}
                disabled
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Personal Email ID</Typography>
              <TextField
                fullWidth
                placeholder="Personal Email ID"
                variant="outlined"
                type="email"
                name="personalEmail"
                value={formData.personalEmail}
                // error={!!errors.personalEmail}
                // helperText={errors.personalEmail}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                onChange={handleChange}
                required
              />
              {emailError && <span className="error">{emailError}</span>}
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  label="Gender"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Gender</Typography>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
                <InputLabel>Verification Document</InputLabel>
                <Select
                  name="verificationDocument"
                  value={formData.verificationDocument}
                  onChange={handleChange}
                  label="Verification Document"
                >
                  <MenuItem value="adhar">Adhar</MenuItem>
                  <MenuItem value="pan">Pan</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" fullWidth>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Verification Document</Typography>
                <RadioGroup
                  row
                  aria-label="verificationDocument"
                  name="verificationDocument"
                  value={formData.verificationDocument}
                  onChange={handleChange}
                >
                  <FormControlLabel value="aadhar" control={<Radio />} label="Aadhar" />
                  <FormControlLabel value="pan" control={<Radio />} label="PAN" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: '2px dashed #ccc',
                  padding: '20px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  mt: 2,
                }}
              >
                <CloudUploadIcon fontSize="large" sx={{ color: '#1976d2' }} />
                <Typography variant="body2" color="textSecondary">
                  Upload your {formData.verificationDocument.toUpperCase()}<span style={{color:"red"}}>*</span>
                </Typography>
                {/* <Typography variant="body2" color="textSecondary">
                  Drag your file(s) to start uploading
                </Typography> */}
                <Typography variant="body2" color="textSecondary">
                  OR
                </Typography>
                <Button variant="contained" component="label" sx={{ mt: 1 }}>
                  Browse files
                  <input type="file" hidden accept=".pdf"
                onChange={handleFileChange} />
                </Button>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent:"center" }}>
              {selectedFile && (
                <div>
                  <p>Selected file: {selectedFile.name}</p>
                  <embed
                    src={URL.createObjectURL(selectedFile)}
                    width="150"
                    height="100"
                    type="application/pdf"
                  />
                </div>
              )}
              {!selectedFile && (
                <Typography variant="body2" color="error" style={{marginTop:"10px"}}>
                  {errors.file}
                </Typography>
              )}
            </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>{formData.verificationDocument.toUpperCase()} Number </Typography>
              <TextField
                fullWidth
                // placeholder="Adhar Number"
                variant="outlined"
                name="adharNumber"
                value={formData.adharNumber}
                onChange={handleChange}
                inputProps={{ maxLength: 12, pattern: '[0-9]*' }}
                required
                placeholder="xxxx xxxx xxxx"
                error={errors.adharNumber && formData.adharNumber === '' && Boolean(errors.adharNumber)}
                // error={!!errors.adharNumber}
                // helperText={errors.adharNumber}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
              />
                {errors.adharNumber && formData.adharNumber === '' && <span className="error">{errors.adharNumber}</span>}
            </Grid>
          </Grid>
        )}

        {userFormSuccess === false && activeStep === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Account Type</Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="accountType"
                value={formData.accountType}
                onChange={handleChange}
                // error={!!errors.accountType}
                // helperText={errors.accountType}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Organization Type</Typography>
              <FormControl fullWidth>
                {/* <InputLabel>Organization Type</InputLabel> */}
                <Select
                  name="organizationType"
                  value={formData.organizationType}
                  onChange={handleChange}
                //   label="Organization Type"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Central Government">Central Government</MenuItem>
                  <MenuItem value="State Government">State Government</MenuItem>
                  <MenuItem value="Public Sector Units (PSUs)">Public Sector Units (PSUs)</MenuItem>
                  <MenuItem value="Banking & Financial Institutes (BFSI)">
                    Banking & Financial Institutes (BFSI)
                  </MenuItem>
                  <MenuItem value="Private Sector Corporates">Private Sector Corporates</MenuItem>
                  <MenuItem value="Academics & Research Institutions">
                    Academics & Research Institutions
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Organization Name <span style={{color:"red"}}>*</span></Typography>
              <TextField
                fullWidth
                placeholder="Organization Name"
                variant="outlined"
                name="organizationName"
                value={formData.organizationName}
                onChange={handleChange}
                error={errors.organizationName && formData.organizationName === '' && Boolean(errors.organizationName)}
                // error={!!errors.organizationName}
                // helperText={errors.organizationName}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
                {errors.organizationName && formData.organizationName === '' && <span className="error">{errors.organizationName}</span>}
            </Grid>
            <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Department Name (if applicable)</Typography>
              <TextField
                fullWidth
                placeholder="Department Name (if applicable)"
                variant="outlined"
                name="departmentName"
                value={formData.departmentName}
                onChange={handleChange}
                error={!!errors.departmentName}
                helperText={errors.departmentName}
                FormHelperTextProps={{
                  style: {
                    color: 'red', 
                    fontSize: '0.875rem', 
                    marginLeft:"0px"
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Organization Website</Typography>
              <TextField
                fullWidth
                placeholder="Organization Website"
                variant="outlined"
                name="organizationWebsite"
                value={formData.organizationWebsite}
                onChange={handleChange}
                // error={!!errors.organizationWebsite}
                // helperText={errors.organizationWebsite}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Industry</Typography>
              <TextField
                fullWidth
                placeholder="Industry"
                variant="outlined"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                // error={!!errors.industry}
                // helperText={errors.industry}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>GST Number</Typography>
              <TextField
                fullWidth
                placeholder="GST Number"
                variant="outlined"
                name="gstNumber"
                inputProps={{ maxLength: 15, pattern: '[0-9]*' }}
                value={formData.gstNumber}
                onChange={handleChange}
                // error={!!errors.gstNumber}
                // helperText={errors.gstNumber}
                // FormHelperTextProps={{
                //   style: {
                //     color: 'red', 
                //     fontSize: '0.875rem', 
                //     marginLeft:"0px"
                //   },
                // }}
                required
              />
            </Grid>
          </Grid>
        )}

       {userFormSuccess === false && <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          {activeStep !== 0 && (
            <Button onClick={handleBack} sx={{ mr: 2 }}>
              Back
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
          >
            {activeStep === steps.length - 1 ? 'Save & Submit' : 'Save & Next'}
          </Button>
        </Box>}

      </Box>
}
    </Box>
  );
};

export default UpdatePersonalDetails;
