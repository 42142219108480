/**
 * Update Onboard User API
 */
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class UpdateOnboardUserAPI extends API {
  constructor(userList, timeout = 2000) {
    super("POST", timeout, false);
    this.type = C.UPDATE_ONBOARD_USER;
    // this.requestId = requestId;
    // this.status = status;
    this.userList = userList
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.updateOnboardUser}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {
      userList: this.userList
    }
  }

  getHeaders() {
    const authorization = localStorage.getItem("token");
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authorization
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report
  }
}
