import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  Link,
} from "@mui/material";
import tableTheme from "../../theme/tableTheme";
import MUIDataTable from "mui-datatables";
import { Button, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DashboardStyles } from "../../styles/dashboardStyles";
import { useDispatch, useSelector } from "react-redux";
import { APITransport } from "../../redux/actions";
import { setSnackBar } from "../../redux/actions/Common";
import FetchOnboardingRequestsAPI from "../../redux/actions/api/Dashboard/FetchOnboardingRequests/FetchOnboardingRequests";
import { useNavigate } from "react-router-dom";
import SahyogiRequestsAPI from "../../redux/actions/api/Dashboard/SahyogiRequests/SahyogiRequests";
import FetchCeoSahyogiRequestsAPI from "../../redux/actions/api/Dashboard/SahyogiRequests/FetchCeoSahyogiRequest";
import FeatchSahyogiSingleUserDetailAPI from "../../redux/actions/api/Dashboard/SahyogiRequests/FeatchSahyogiSingleUserDetail";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import{setRegisteredExpertFilter,setOnboardingFilter,setpluginRequestFilter,setSahyogiRequestFilter,setManagerUserManagementFilter,setCustomerUserManagementFilter} from "../../redux/actions/Common";

const statusData = [
  {
    value: "",
    label: "User Type",
  },
 
  {
    value: "Central Government",
    label: "Central Government"
  },
  {
    value: "State Government",
    label: "State Government"
  },
  {
    value: "Private Sector",
    label: "Private Sector"
  },
  {
    value: "Public Sector",
    label: "Public Sector"
  }
];

const SahyogiDashboard = () => {

//   const statusData = [
//     { value: localStorage.getItem('role') === "CEO" ? "manager-approved" : "pending", label: "Sahyogi Request Received" },
//     { value: "approved", label: "Request Approved" },
//     { value: "rejected", label: "Request Rejected" }
//   ]

  const navigate = useNavigate();
  const classes = DashboardStyles();
  const SahyogiRequestFilter = useSelector((state) => state?.SahyogiRequestFilter?.filterData);

//   const [status, setStatus] = useState(statusData[0])
  const [searchText, setSearchText] = useState(''); // State to store search text
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null); // add this state
  const [status, setStatus] = useState(SahyogiRequestFilter?.userType?SahyogiRequestFilter?.userType:"");

  const tableRef = useRef(null);
  useEffect(() => {
    dispatch(setSahyogiRequestFilter({ userType:status,
     }))

  }, [dispatch,status]);

  useEffect(() => {
    dispatch(setOnboardingFilter({}));
    dispatch(setpluginRequestFilter({}));
    dispatch(setManagerUserManagementFilter({}));
    dispatch(setCustomerUserManagementFilter({}));
    dispatch(setRegisteredExpertFilter({}));

  }, []);
  const sahyogiUserList = useSelector(
    (state) => state.fetchSahyogiRequests.data.formList
  );
  const CeosahyogiUserList = useSelector(
    (state) => state.FetchCeoSahyogiRequest?.data?.applications

  );
  const SahyogiSingleUserDetail = useSelector(
    (state) => state.SahyogiSingleUserDetail?.data?.formList
  );
  const filteredData = CeosahyogiUserList?.filter(item => item?.organisation_type === status || status === '');
  const fetchSahyogiRequestsData = () => {
    const apiObj = new SahyogiRequestsAPI();
    dispatch(APITransport(apiObj));
  };
  const CeofetchSahyogiRequestsData = ()=>{
    const apiObj = new FetchCeoSahyogiRequestsAPI();
    dispatch(APITransport(apiObj));
  }
  const SahyogiSingleUserDetailAPI = (UserId) =>{
    const apiObj = new FeatchSahyogiSingleUserDetailAPI(UserId);
    dispatch(APITransport(apiObj));
  }

  useEffect(() => {
    fetchSahyogiRequestsData();
    CeofetchSahyogiRequestsData()
  }, [])

  const onViewRequestClick = (meta) => {
    SahyogiSingleUserDetailAPI(meta.rowData[0])
    navigate(`/sahyogi-requests/${meta.rowData[0]}`, { state: SahyogiSingleUserDetail } );
  }
  const handleSearch = (event) => {
    setSearchText(event.target.value); // Update search text state
  };

//   const handleStatusFilterChange = (e) => {
//     const selectedStatus = statusData.find(status => status.value === e.target.value);
//      console.log(selectedStatus);
//     setStatus(selectedStatus);
//   }
// const onViewRequestClick = (rowData) => {
//   // const reqData = managerList?.filter(
//   //   (el) => el._id === rowData.rowData[0]
//   // )[0];
//   navigate("/ceo-request-action");
// };
const handleDateChange = (date) => {
  setSelectedDate(date);
};
const handleStatusFilterChange = (e) => {
  setStatus(e.target.value);
};
  const renderToolBar = () => {
    const enableBulkButtons = tableRef.current?.state.selectedRows.data.length > 0;

    return (
      // <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
      //   <Typography variant="h3" fontFamily={"Inter, sans-serif"} fontWeight={600}  >Sahyogi Request Received</Typography>
      //   <Grid display={"flex"} alignItems={"center"}>
      //     <TextField
      //       placeholder="Search"
      //       className={classes.searchBox}
      //       InputProps={{
      //         endAdornment: (
      //           <InputAdornment position="end">
      //             <SearchIcon />
      //           </InputAdornment>
      //         ),
      //       }}
      //       onChange={handleSearch}
      //     />
          
          
      //   </Grid>

      // </Grid>
      <Grid
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"100%"}
    >
      <Typography
            variant="h3"
            fontFamily={"Inter, sans-serif"}
            fontWeight={600}
            sx={{ fontSize: "18px", textAlign: "left" }}
          >
       Sahyogi Request Received
      </Typography>
      <Grid display={"flex"} alignItems={"center"} gap={2} >
      <Select
                  sx={{width:"130px", textAlign:"left"}}

             displayEmpty
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={classes.searchBox}
            value={status}
            onChange={handleStatusFilterChange}
          >
            {statusData.map((el, i) => (
              <MenuItem key={i} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{width: "200px", ml: 2}}
            label="Date"
            value={selectedDate}
            onChange={handleDateChange}
            className={classes.searchBox}
          />
        </LocalizationProvider> */}
      </Grid>
    </Grid>
      // <>
      //   <Button variant="contained" className={classes.bulkApproveBtn} disabled={!enableBulkButtons} onClick={() => onBulkButtonClick("approved")}>
      //     Bulk Approve
      //   </Button>

      // <Button variant="contained" className={classes.bulkRejectBtn} disabled={!enableBulkButtons} onClick={() => onBulkButtonClick("rejected")}>
      //   Bulk Reject
      // </Button>

      //   <TextField
      //     placeholder="Search"
      //     className={classes.searchBox}
      //     InputProps={{
      //       endAdornment: (
      //         <InputAdornment position="end">
      //           <SearchIcon />
      //         </InputAdornment>
      //       ),
      //     }}
      //     onChange={handleSearch}
      //   />
      // </>
    );
  };

  const options = {
    textLabels: {
      body: { noMatch: "No records" },
      toolbar: {
        search: "Search",
        viewColumns: "View Column",
      },
      pagination: { rowsPerPage: "Rows per page" },
      options: { sortDirection: "desc" },
    },
    selectToolbarPlacement: "none",
    displaySelectToolbar: false,
    fixedHeader: false,
    // filterType: "checkbox",
    download: false,
    print: false,
    rowsPerPageOptions: [10, 25, 50, 100],
    filter: false,
    viewColumns: false,
    search: false,
    jumpToPage: false,
    pagination: true,
    customToolbar: renderToolBar,
    // search: true, // Enable search
    searchText: searchText, // Set search text
    onSearchChange: (searchText) => setSearchText(searchText),
    selectableRows: "none", // Enable multiple row selection
  };

  const datavalue = [{
    request_id:"1",
    organisation_type:"ert",
    organisation_name:"namebhh",
    representative_name:"asdf",
    sector:"a"
  },{
    request_id:"1",
    organisation_type:"ert",
    organisation_name:"namebhh",
    representative_name:"asdf",
    sector:"a"
  }]

  const columns = [
    {
      name: "user_id",
      label: "Request Id",
      options: {
        display: false,
      },
    },
    {
      name: "organisation_type",
      label: "User Type",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "organisation_name",
      label: "Organisation Name",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "representative_name",
      label: "Representative Name",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "sector",
      label: "Sector",
    },
    
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        align: "center",
        customBodyRender: (value, meta) => {
          return (
            <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Link
              fontWeight={600}
              fontSize={"14px"}
              color={"#2947A3"}
              onClick={() => onViewRequestClick(meta)}
            >
              View More
            </Link>
          </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Grid container direction="row">
        <Grid item md={12} xs={12} style={{ width: "100%" }}>
          <ThemeProvider theme={tableTheme}>
            <MUIDataTable
              ref={tableRef}
              data={filteredData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default SahyogiDashboard;
