// UpdateOnboardingRequestStatus

import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class UpdateOnboardingRequestStatusAPI extends API {
  constructor(id, request_data, timeout = 2000) {
    super("PUT", timeout, false);
    this.type = C.UPDATE_ONBOARDING_REQUEST_STATUS
    this.requestData = request_data;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.updateOnboardingRequest}/${id}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {...this.requestData}
  }

  getHeaders() {
    const authorization = localStorage.getItem("token");
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authorization
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report
  }
}
