import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import ManageAccountsOutlined from '@mui/icons-material/ManageAccountsOutlined';
import HubIcon from '@mui/icons-material/Hub';
import GridViewIcon from '@mui/icons-material/GridView';
import LinguisticLight from "../assets/Linguistic.svg";
import LinguisticDark from "../assets/Linguisticdark.svg";
import SahyogiLight from "../assets/SahyogiLight.svg";
import Sahyogidark from "../assets/SahyogiDark.svg";
import PluginLight from "../assets/PluginLight.svg"
import PluginDark from "../assets/PluginDark.svg"
import OnboardingLight from "../assets/OnboardingLight.svg"
import OnboardingDark from "../assets/OnboardingDark.svg"
import ApplicationsLight from "../assets/ApplicationsLight.svg"
import ApplicationsDark from "../assets/ApplicationsDark.svg"

console.log(localStorage.getItem('activePath') === "/linguistic-expert" ? "123" : "abc","iiiiiiiiiiiiiiii")

export const sidebarMenuData = [
    {name: 'Dashboard', icon: (props) => <GridViewIcon {...props} />, path: "/manager-dashboard", allowedRoles : ["manager"]},
    {name: 'Dashboard', icon: (props) => <GridViewIcon {...props} />, path: "/user-dashboard", allowedRoles : ["User"]},
    {name: 'Dashboard', icon: (props) => <GridViewIcon {...props} />, path: "/ceo-dashboard", allowedRoles : ["ceo",]},
    // {name: 'Parikshan Applications', icon: (props) => <AppsOutlinedIcon {...props} />, path: "/applications", allowedRoles : ["User"]},
    {
      name: 'Parikshan Applications',
      icon: (props) => (
        <img 
      src={localStorage.getItem('activePath') && localStorage.getItem('findpath') === "/applications" ? ApplicationsDark : ApplicationsLight} 
      alt="applications" 
      {...props} 
      style={{ 
        width: '24px', 
        height: '24px',
      
      }} 
    />
      ),
      path: "/applications",
      allowedRoles: ["User"]
  },
    {
      name: 'Onboarding Requests',
      icon: (props) => (
        <img 
      src={localStorage.getItem('activePath') && localStorage.getItem('findpath') === "/requests" ? OnboardingDark : OnboardingLight} 
      alt="Onboarding" 
      {...props} 
      style={{ 
        width: '24px', 
        height: '24px',
      }} 
    />
      ),
      path: "/requests",
      allowedRoles: ["ceo", "manager"]
  },
    { name: 'User Management', icon: (props) => <ManageAccountsOutlined {...props} />, path: "/user-management", allowedRoles: ["ceo", "manager"]},
    {
      name: 'Plugin Request',
      icon: (props) => (
        <img 
      src={localStorage.getItem('activePath') && localStorage.getItem('findpath') === "/plugin-request" ? PluginDark : PluginLight} 
      alt="Sahyogi" 
      {...props} 
      style={{ 
        width: '24px', 
        height: '24px',
        marginTop:"5px"
      }} 
    />
      ),
      path: "/plugin-request",
      allowedRoles: ["ceo", "manager"]
  }, 
   
    {
      name: 'Sahyogi Requests',
      icon: (props) => (
        <img 
      src={localStorage.getItem('activePath') && localStorage.getItem('findpath') === "/sahyogi-requests" ? Sahyogidark : SahyogiLight} 
      alt="Sahyogi" 
      {...props} 
      style={{ 
        width: '24px', 
        height: '24px',
        marginTop:"5px"
      }} 
    />
      ),
      path: "/sahyogi-requests",
      allowedRoles: ["ceo", "manager"]
  }, 
    {
        name: 'Linguistic Expert',
        icon: (props) => (
          <img 
        src={localStorage.getItem('activePath') && localStorage.getItem('findpath') === "/linguistic-expert" ? LinguisticDark : LinguisticLight} 
        alt="Translate" 
        {...props} 
        style={{ 
          width: '24px', 
          height: '24px',
        }} 
      />
        ),
        path: "/linguistic-expert",
        allowedRoles: ["ceo", "manager"]
    }
    
]