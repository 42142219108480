// LogoutAPI.js
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FeatchSahyogiSingleUserDetailAPI extends API {
  constructor(user_id, timeout = 2000) {
    console.log(user_id,"user_iduser_id")
    super("GET", timeout, false); 
    this.type = C.FETCH_SAHYOGI_SINGLE_USER;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.SingleUserDetail}?user_id=${user_id}`;
  }

  processResponse(res) {
    super.processResponse(res);
    console.log(res,"res");
    if (res) {
        this.report = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    
    return {};
  }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.report;
  }
}
