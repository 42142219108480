const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export const convertBase64ToPDF = (base64Content, fileName) => {
  let binaryString = window.atob(base64Content);
  let len = binaryString.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob object from the bytes
  let blob = new Blob([bytes], {type: "application/pdf"});
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName; // Specify the file name
  link.click();
};

export const convertDateFormat = (dateString) => {
  // dateString should be in dd/mm/yyyy format
  const parts = dateString.split("/");
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

export const roleBasedHeader = {
  manager : {
    // "/manager-dashboard": "Dashboard",
    "/requests": "Onboarding Requests",
    "/sahyogi-requests": "Sahyogi Requests",
    "/user-management": "User Management",
  },
  ceo : {
    "/requests": "Onboarding Requests",
    // "/sahyogi-requests": "Sahyogi Requests",
    "/user-management": "User Management",
  },
};

const getHeaderTitle = (role, path) => {
  return roleBasedHeader?.[role]?.[path] ?? "";
};

export const renderTitle = () => {
    const role = localStorage.getItem('role');
    const path = window.location.pathname
    return getHeaderTitle(role, path)
}  

export function convertTimestamp(timestamp) {
    // Create a new Date object from the timestamp
    const dateObj = new Date(timestamp);

    // Extract the date parts (year, month, day)
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(dateObj.getDate()).padStart(2, '0');

    // Extract the time parts (hours, minutes, seconds)
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    const seconds = String(dateObj.getSeconds()).padStart(2, '0');

    // Format the date and time strings
    const date = `${year}-${month}-${day}`;
    const time = `${hours}:${minutes}:${seconds}`;

    return { date, time };
}

export const getFormattedDate = (date) => {
  return (
    dayjs(date)
      .utc("z")
      .local()
      .tz("Asia/Kolkata", true)
      .format("DD-MM-YYYY hh:mm A") + " IST"
  );
};


export const getFormattedDateandTime = (timestamp) => {
    const dateTimeInKolkata = dayjs(timestamp)
      .utc('z') 
      .local()
      .tz("Asia/Kolkata", true); 
  
    const dateWithTimezone = dateTimeInKolkata.format("DD-MM-YYYY") ;
  
    const timeOnly = dateTimeInKolkata.format("hh:mm A");
  
    return {
      dateWithTimezone,
      timeOnly
    };
  };