import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
  } from "react";
  import {
    Box,
    Grid,
    Link,
    MenuItem,
    Select,
    ThemeProvider,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    IconButton,
  } from "@mui/material";
  import MUIDataTable from "mui-datatables";
  import { useDispatch, useSelector } from "react-redux";
  import { useNavigate } from "react-router-dom";
  import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
  import CheckCircleIcon from "@mui/icons-material/CheckCircle";
  import tableTheme from "../../theme/tableTheme";
  import { APITransport } from "../../redux/actions";
  import { DashboardStyles } from "../../styles/dashboardStyles";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { DatePicker } from "@mui/x-date-pickers/DatePicker";
  import FetchManagerRequestAPI from "../../redux/actions/api/Dashboard/FetchManagerRequests/FetchManagerRequest";
  import UpdateInviteManagerAPI from "../../redux/actions/api/Dashboard/UpdateInviteManager/UpdateInviteManager";
  import RevokeManagerAPI from "../../redux/actions/api/Dashboard/RevokeManager/RevokeManager";
  import AddIcon from "@mui/icons-material/Add";
  import CloseIcon from "@mui/icons-material/Close";
  import CustomizedSnackbars from "../../components/common/SnackBar";
  import { setSnackBar } from "../../redux/actions/Common";
  import ClearIcon from '@mui/icons-material/Clear';
  import{setRegisteredExpertFilter,setOnboardingFilter,setpluginRequestFilter,setSahyogiRequestFilter,setManagerUserManagementFilter,setCustomerUserManagementFilter} from "../../redux/actions/Common";
  const ManagerRequests = ({RegistrationTypelabel}) => {
    const statusData = [
      {
        value: "",
        label: "All Requests",
      },
      {
        value: "Pending",
        label: "Pending",
      },
      { value: "Approved", label: "Approved" },
      { value: "Rejected", label: "Rejected" },
      { value: "Registered", label: "Registered" },
    ];
  
    const registrationType = [
      {
        value: "",
        label: "Select Registration Type",
      },
      {
        value: "Invited",
        label: "Invited",
      },
      {
        value: "Direct",
        label: "Direct",
      },
    ];
  
    const navigate = useNavigate();
    const classes = DashboardStyles();
    const [searchText, setSearchText] = useState(""); // State to store search text
    const dispatch = useDispatch();
    const ManagerUserManagementFilter = useSelector((state) => state?.ManagerUserManagementFilter?.filterData);

    const [status, setStatus] = useState(ManagerUserManagementFilter?.AllRequests?ManagerUserManagementFilter?.AllRequests:"");
    const [registration, setRegistration] = useState(RegistrationTypelabel?RegistrationTypelabel:ManagerUserManagementFilter?.registrationType?ManagerUserManagementFilter?.registrationType:"");
    const [selectedDate, setSelectedDate] = useState(ManagerUserManagementFilter?.selectedDate?ManagerUserManagementFilter?.selectedDate:null); // add this state
    const [managerListData, setManagerListData] = useState([]);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [inviteRevokeStatus, setinviteRevokeStatus] = useState("");
    const [revokeEmail, setRevokeEmail] = useState("");
  
    const tableRef = useRef(null);
    const managerList = useSelector(
      (state) => state.fetchManagerRequests?.data ?? []
    );
    const InviteManager = useSelector((state) => state.UpdateInviteManager?.data);
    const snackbar = useSelector((state) => state.commonReducer.snackbar);
    useEffect(() => {
      dispatch(setManagerUserManagementFilter({registrationType:registration,AllRequests:status,selectedDate:selectedDate}))
  
    }, [dispatch,registration,status,selectedDate]);
  
    useEffect(() => {
      dispatch(setOnboardingFilter({}));
      dispatch(setpluginRequestFilter({}));
      dispatch(setSahyogiRequestFilter({}));
      dispatch(setCustomerUserManagementFilter({}));
      dispatch(setRegisteredExpertFilter({}));

    }, []);
    const UpdateInviteManager = () => {
      const apiObj = new UpdateInviteManagerAPI(firstName, lastName, email);
      dispatch(APITransport(apiObj));
      fetchManagerRequestsData()
    };
  
    const fetchManagerRequestsData = useCallback(() => {
        const apiObj = new FetchManagerRequestAPI();
        dispatch(APITransport(apiObj));
    }, [dispatch]);
  
    useEffect(() => {
      fetchManagerRequestsData();
    }, [fetchManagerRequestsData]);
  
    useEffect(() => {
      let data = [];
      managerList.length &&
        managerList?.forEach((item, index) => {
          data.push({
            ...item,
            sr_no: index + 1,
            manager_name: `${item.first_name} ${item.last_name}`,
            registration_type: item.reg_type,
            status: item.status,
            date_of_joining: item.requested_on,
            action: "",
          });
        });
  
      setManagerListData(data);
    }, [managerList]);
  
    const onViewRequestClick = (rowData) => {
      const reqData = managerList?.filter(
        (el) => el._id === rowData.rowData[0]
      )[0];
      navigate("/ceo-request-action", { state: reqData });
    };
  
    const handleStatusFilterChange = (e) => {
      setStatus(e.target.value);
    };
  
    const handleRegistrationFilterChange = (e) => {
      setRegistration(e.target.value);
    };
  
  
  
    const handleClickOpen = (value) => {
      setOpen(true);
      setinviteRevokeStatus(value)
    };
  
    const handleClose = () => {
      setOpen(false);
      setEmail("")
    setFirstName("")
    setLastName("")
    setRevokeEmail("")
    };
  
    const handleSendInvitation = () => {
        UpdateInviteManager();
        handleClose();
        setEmail("")
      setFirstName("")
      setLastName("")
    };
  
    const renderSnackBar = React.useCallback(() => {
      return (
        <CustomizedSnackbars
          open={snackbar.open}
          handleClose={() =>
            dispatch(setSnackBar({ open: false, message: "", variant: "" }))
          }
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          variant={snackbar.variant}
          message={[snackbar.message]}
        />
      );
  
      //eslint-disable-next-line
    }, [snackbar]);
  
    const handleDateChange = (newDate) => {
      setSelectedDate(newDate);
    };
  
    const handleClearDate = (event) => {
      event.stopPropagation();
      setSelectedDate(null);
    };
  
    const renderToolBar = () => {
      return (
        <Grid container direction="column" spacing={2}>
          <Grid
            item
            container
            xs={12} sm={6} md={9} lg={12}
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
            paddingLeft="0px"
          >
            <Grid item xs={12} sm={6} md={12} lg={3} display="flex" alignItems="center" gap={2} sx={{ mr: 2 }} style={{paddingLeft:"0px"}}>
              <Select
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={classes.searchBox}
                value={registration}
                sx={{ ml: 2 }}
                onChange={handleRegistrationFilterChange}
                style={{ minWidth: "200px", textAlign:"left" }}
              >
                {registrationType.map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
              <Select
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={classes.searchBox}
                value={status}
                sx={{ ml: 2 }}
                style={{ minWidth: "200px", textAlign:"left" }}
                onChange={handleStatusFilterChange}
              >
                {statusData.map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ display: 'flex', alignItems: 'center',marginBottom:"5px" }}>
          <DatePicker
            sx={{ width: '200px', ml: 2 ,paddingTop:"10px",'& .MuiInputBase-root': {fontSize: '18px', marginTop: "7px",},'& .MuiInputLabel-root': {
              fontSize: '18px', 
            },'& .MuiInputBase-input::placeholder': {
              fontSize: '18px',
            }, }}
            label="Date"
            className={classes.searchBox}
            value={selectedDate}
            onChange={handleDateChange}
            format="DD/MM/YYYY"
          />
          {selectedDate && (
            <IconButton onClick={handleClearDate} sx={{ ml: 1 }}>
              <ClearIcon />
            </IconButton>
          )}
        </div>
      </LocalizationProvider>
            </Grid>
            {/* <Grid item xs /> */}
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={()=>handleClickOpen("Invite")}
              >
                Invite Manager
              </Button>
            </Grid>
             {/*
             <Grid item>
               <Button
                 color="primary"
                 variant="contained"
                 onClick={()=>handleClickOpen("Revoke")}
               >
                 Revoke Manager
               </Button>
             </Grid>
             */}
          </Grid>
          <Grid item>
            {/* <Dialog open={open} onClose={handleClose}>
            <DialogTitle
              sx={{
                fontFamily: "Inter, sans-serif",
                fontWeight: 600,
                position: 'relative'
              }}
            >
              Invite Manager
            </DialogTitle>
            <IconButton
              color="disabled"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 0,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h3"
              fontFamily={"Inter, sans-serif"}
              fontWeight={600}
              sx={{ fontSize: "18px", textAlign: "left" }} // Ensure text alignment is left
            >
              Managers Request
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            spacing={2}
            justifyContent="flex-start"
          >
            <Grid item display="flex" alignItems="center" gap={2} sx={{ mr: 2 }}>
              <Select
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={classes.searchBox}
                value={registration}
                sx={{ ml: 2 }}
                onChange={handleRegistrationFilterChange}
                style={{ minWidth: "200px" }}
              >
                {registrationType.map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
              <Select
                displayEmpty
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className={classes.searchBox}
                value={status}
                sx={{ ml: 2 }}
                style={{ minWidth: "200px" }}
                onChange={handleStatusFilterChange}
              >
                {statusData.map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "200px", ml: 2 }}
                  label="Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className={classes.searchBox}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Invite Manager
              </Button>
            </DialogActions>
          </Dialog> */}
            <Dialog
              open={open}
              onClose={handleClose}
              sx={{
                borderRadius: "25px",
                padding: "20px",
                "& .MuiPaper-root": {
                  borderRadius: "25px",
                  padding: "20px",
                },
              }}
            >
              <DialogTitle
                sx={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  position: "relative",
                }}
              >
               Invite Manager
              </DialogTitle>
              <IconButton
                color="disabled"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            
              <Typography
                sx={{ ml: "25px", fontSize: "14px", fontWeight: 400, mt: 1 }}
              >
                First Name
              </Typography>
              <TextField
                margin="dense"
                placeholder="Enter text here"
                type="firstname"
                fullWidth
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{
                  margin: "0px",
                  padding: "0px 25px 20px 20px",
                  "& .MuiInputBase-root": {
                    height: "45px",
                    padding: "0 8px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    padding: "0",
                    fontSize: "16px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "15px",
                  },
                }}
              />
  
              <Typography sx={{ ml: "25px", fontSize: "14px", fontWeight: 400 }}>
                Last Name
              </Typography>
              <TextField
                margin="dense"
                placeholder="Enter text here"
                type="lastname"
                fullWidth
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{
                  margin: "0px",
                  padding: "0px 25px 20px 20px",
                  "& .MuiInputBase-root": {
                    height: "45px",
                    padding: "0 8px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    padding: "0",
                    fontSize: "16px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "15px",
                  },
                }}
              />
  
              <Typography sx={{ ml: "25px", fontSize: "14px", fontWeight: 400 }}>
                Email ID
              </Typography>
              <TextField
                margin="dense"
                placeholder="Enter text here"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  margin: "0px",
                  padding: "0px 25px 32px 20px",
                  "& .MuiInputBase-root": {
                    height: "45px",
                    padding: "0 8px",
                  },
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    padding: "0",
                    fontSize: "16px",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    fontSize: "15px",
                  },
                }}
              />  
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  overflow: "hidden",
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{ backgroundColor: "#E7F0FA", ml: 2 }}
                >
                  Close
                </Button>
                <Button
                  onClick={handleSendInvitation}
                  color="primary"
                  variant="contained"
                >
                  Send Invitation
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      );
    };
  
    const options = {
      textLabels: {
        body: { noMatch: "No records" },
        toolbar: {
          search: "Search",
          viewColumns: "View Column",
        },
        pagination: { rowsPerPage: "Rows per page" },
        options: { sortDirection: "desc" },
      },
      selectToolbarPlacement: "none",
      displaySelectToolbar: false,
      fixedHeader: false,
      download: false,
      print: false,
      rowsPerPageOptions: [10, 25, 50, 100],
      filter: false,
      viewColumns: false,
      search: false,
      jumpToPage: false,
      pagination: true,
      customToolbar: renderToolBar,
      searchText: searchText,
      onSearchChange: (searchText) => setSearchText(searchText),
      selectableRows: "none",
    };
  
    const columns = [
      {
        name: "_id",
        label: "Request Id",
        options: {
          display: false,
        },
      },
      {
        name: "sr_no",
        label: "Sr. No.",
        options: {
          setCellHeaderProps: () => ({
            style: {
              width: "auto",
              whiteSpace: 'nowrap', 
              overflow: 'hidden',  
              textOverflow: 'ellipsis' 
            },
          }),
        },
      },
      {
        name: "manager_name",
        label: "Manager Name",
        options: {
          setCellHeaderProps: () => ({
            style: {
              width: "auto",
              whiteSpace: 'nowrap', 
              overflow: 'hidden',  
              textOverflow: 'ellipsis' 
            },
          }),
        },
      },
      {
        name: "registration_type",
        label: "Registration Type",
        options: {
          setCellHeaderProps: () => ({
            style: {
              width: "auto",
              whiteSpace: 'nowrap', 
              overflow: 'hidden',  
              textOverflow: 'ellipsis' 
            },
          }),
        },
      },
      {
        name: "status",
        label: "Request Status",
        options: {
          setCellHeaderProps: () => ({
            style: {
              width: "auto",
              whiteSpace: 'nowrap', 
              overflow: 'hidden',  
              textOverflow: 'ellipsis' 
            },
          }),
          customBodyRender: (value) => {
            return (
              <>
                {value === "Approved" ? (
                  <Box
                    display="flex"
                    gap="6px"
                    width="fit-content"
                    bgcolor={"#EDFFEA"}
                    borderRadius="100px"
                    padding={"4px 6px"}
                  >
                    <CheckCircleIcon
                      style={{ width: "16px", height: "16px", color: "#165E3D" }}
                    />
                    <Typography
                      color={"#165E3D"}
                      fontSize="12px"
                      fontWeight={500}
                    >
                      Approved
                    </Typography>
                  </Box>
                ) : value === "Pending" ? (
                  <Box
                    display="flex"
                    gap="6px"
                    width="fit-content"
                    bgcolor={"#FFEAEA"}
                    borderRadius="100px"
                    padding={"4px 6px"}
                  >
                    <RemoveCircleIcon
                      style={{ width: "16px", height: "16px", color: "#B83131" }}
                    />
                    <Typography
                      color={"#B83131"}
                      fontSize="12px"
                      fontWeight={500}
                    >
                      Pending
                    </Typography>
                  </Box>
                ) : value === "Registered" ? (
                  <Box
                    display="flex"
                    gap="6px"
                    width="fit-content"
                    bgcolor={"#EDFFEA"}
                    borderRadius="100px"
                    padding={"4px 6px"}
                  >
                    <CheckCircleIcon
                      style={{ width: "16px", height: "16px", color: "#165E3D" }}
                    />
                    <Typography
                      color={"#165E3D"}
                      fontSize="12px"
                      fontWeight={500}
                    >
                      Registered
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    gap="6px"
                    width="fit-content"
                    bgcolor={"#FFEAEA"}
                    borderRadius="100px"
                    padding={"4px 6px"}
                  >
                    <RemoveCircleIcon
                      style={{ width: "16px", height: "16px", color: "#B83131" }}
                    />
                    <Typography
                      color={"#B83131"}
                      fontSize="12px"
                      fontWeight={500}
                    >
                      Rejected
                    </Typography>
                  </Box>
                )}
              </>
            );
          },
        },
      },
      {
        name: "date_of_joining",
        label: "Date of Request",
        options: {
          setCellHeaderProps: () => ({
            style: {
              width: "auto",
              whiteSpace: 'nowrap', 
              overflow: 'hidden',  
              textOverflow: 'ellipsis' 
            },
          }),
          customBodyRender: (value) => {
            const date = new Date(value);
            const formattedDate = date.toLocaleDateString("en-GB");
            return <>{formattedDate}</>;
          },
        },
      },
      {
        name: "action",
        label: "Action",
        options: {
          filter: false,
          sort: false,
          align: "center",
          customBodyRender: (value, meta) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Link
                  fontWeight={600}
                  fontSize={"14px"}
                  color={"#2947A3"}
                  onClick={() => onViewRequestClick(meta)}
                >
                  View More
                </Link>
              </div>
            );
          },
        },
      },
    ];
  
    const filteredManagerList = useMemo(() => {
      return managerListData.filter((el) => {
        const matchesStatus = status ? el.status === status : true;
        const matchesRegistration = registration
          ? el.registration_type === registration
          : true;
        const matchesDate = selectedDate
          ? new Date(el.date_of_joining).toDateString() ===
            new Date(selectedDate).toDateString()
          : true;
        return matchesStatus && matchesRegistration && matchesDate;
      });
    }, [managerListData, registration, selectedDate, status]);
  
    const rowData = useMemo(() => {
      return filteredManagerList.map((el, i) => ({
        ...el,
        sr_no: i + 1,
        manager_name: `${el.first_name} ${el.last_name}`,
        registration_type:
          el.registration_type === "Invited" ? "Invited" : "Direct",
        status: el.status,
        date_of_joining: el.date_of_joining,
        action: "",
      }));
    }, [filteredManagerList]);
  
    return (
      <div>
        {renderSnackBar()}
        <Grid container direction="row" className="userManagementRow">
          <Grid item md={12} xs={12} style={{ width: "100%" }}>
            <ThemeProvider theme={tableTheme}>
              <MUIDataTable
                ref={tableRef}
                data={rowData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </div>
    );
  };
  
  export default ManagerRequests;
  