

import {Modal, Box} from "@mui/material";



const CustomModal = ({open, handleClose, width = 450, children, paddingdata, ...props}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid white",
    boxShadow: 24,
    borderRadius: "20px",
    p:paddingdata?paddingdata:4
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{...style, width: width}}>{children}</Box>
    </Modal>
  );
};

export default CustomModal;
