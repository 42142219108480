// RequestActionDialog

import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  Divider,
  Avatar,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateOnboardingRequestStatusAPI from "../../redux/actions/api/Dashboard/UpdateOnboardingRequestStatus/UpdateOnboardingRequestStatus";
import { APITransport } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import pfdIcon from "../../assets/pdficon.svg";
import SahyogiDownloadFileAPI from "../../redux/actions/api/Dashboard/SahyogiDownloadFile/SahyogiDownloadFile";
import { setSnackBar } from "../../redux/actions/Common";
import {getFormattedDate} from "../../utils/utils";
import viewPdfAPI from "../../redux/actions/api/Dashboard/SahyogiRequests/viewPDF";

const SahyogiUserView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestData = location.state;
  const apiSatus = useSelector((state) => state.apiStatus);

  const SahyogiSingleUserDetail = useSelector(
    (state) => state.SahyogiSingleUserDetail?.data?.formList
  );

  const viewpdf = useSelector(
    (state) => state.viewPDF?.data
  );

  const onActionButtonClick = (value) => {
    const apiObj = new SahyogiDownloadFileAPI(value);
    dispatch(APITransport(apiObj));
    apiObj.downloadDocument();
  };

  const onClickOfviewPDF = (value)=>{
    const apiObj = new viewPdfAPI(value);
    dispatch(APITransport(apiObj));
    apiObj.downloadDocument();
  }

  // useEffect(() => {
  //     if (apiSatus.apiType === "SAHYOGI_DOWNLOAD_FILE" && apiSatus.success) {
  //         console.log("woo hooo!")
  //         dispatch(setSnackBar({
  //             open: true,
  //             message: "Downloaded Successfully",
  //             variant: "success"
  //           }));
  //     }
  // }, [apiSatus])

  return (
    <Container maxWidth="lg">
        {SahyogiSingleUserDetail?.map((user, index) => (  

      <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box>
            {/* Button at the top */}
            <Button
              variant="text"
              color="inherit"
              onClick={() => navigate(-1, { replace: true })}
              sx={{ mb: 2 }} // Adds margin-bottom for spacing
            >
              <ArrowBackOutlinedIcon /> &nbsp; Sahyogi Request
            </Button>
            {/* Grid container below the button */}
            <Grid container alignItems="center" justifyContent="space-between">
              {/* Left side: Avatar and text */}
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar
                      style={{
                        height: "57px",
                        width: "57px",
                        backgroundColor: "#A8A8A8",
                        color: "#595656",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography fontWeight={700} fontSize={"24px"}>
                      {user?.representative_name}
                    </Typography>
                    <Typography fontSize={"16px"} color="#3A3A3A">
                      {user?.organisation_mail}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> 
          </Box>
          <Box>
          <Button
              variant="outlined"
              sx={{ mt: 9,mr:2, borderRadius: 1, backgroundColor: "#F5F7FA" ,color:"#2947A3"}}
              onClick={() => onClickOfviewPDF(user?.filename)}
            >
               View File
            </Button>
            {/* <Button variant="outlined"  sx={{ mr: 1, borderRadius: 1, border:" 2px solid #2947A3" }} >View PDF</Button> */}
            <Button
              variant="contained"
              sx={{ mt: 9, borderRadius: 1, backgroundColor: "#2947A3" }}
              onClick={() => onActionButtonClick(user?.filename)}
            >
              Download File
            </Button>
          </Box>
        </Box>
        <Divider />
        <Typography
          fontFamily={"Inter, sans-serif"}
          variant="h3"
          fontWeight={"600"}
          gutterBottom
          sx={{ mt: 2, mb: 4 }}
        >
          {user?.name}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                User Type
              </span>
              <strong> {user?.organisation_type}</strong>
            </Typography>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                Organisation Name
              </span>
              <strong> {user?.organisation_name}</strong>
            </Typography>
            {/* <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                Organisation type
              </span>
              <strong style={{ display: "inline-block", width: "40%" }}>
                {" "}
                {user?.organisation_type}{" "}
              </strong>
            </Typography> */}
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                Organisation Website
              </span>
              <strong> {user?.organisation_website}</strong>
            </Typography>

            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                State
              </span>
              <strong> {user?.state} </strong>
            </Typography>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                Personal email ID
              </span>
              <strong> {user?.personal_mail}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                GST Number
              </span>
              <strong> {user?.gst_number}</strong>
            </Typography>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                Phone No
              </span>
              <strong>{user?.contact_number}</strong>
            </Typography>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                Submission Date
              </span>
              <strong>{user?.date}</strong>
            </Typography>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span style={{ display: "inline-block", width: "40%" }}>
                Submission Time
              </span>
              <strong>  {getFormattedDate(user?.user?.time).split(" ")[1] + " " + getFormattedDate(user?.user?.time).split(" ")[2] } IST</strong>
            </Typography>
          </Grid>
        </Grid>

        <Divider />
        <Typography
          fontFamily={"Inter, sans-serif"}
          variant="h3"
          fontWeight={"600"}
          gutterBottom
          sx={{ mt: 2, mb: 4 }}
        >
          Use Case Details
        </Typography>
        {/* <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2"><span style={{display:"inline-block", width:"20%"}}>Sector</span><strong>{requestData?.sector}</strong></Typography>
                <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2"><span style={{display:"inline-block", width:"20%"}}>Use Case Title</span><strong>{requestData?.usecase_title}</strong></Typography>
                <Typography fontFamily={"Inter, sans-serif"} mb={3} variant="subtitle2"><span style={{display:"inline-block", width:"20%"}}>State</span><strong>{requestData?.state}</strong></Typography> */}
        <Typography
          fontFamily={"Inter, sans-serif"}
          display="flex"
          mb={3}
          variant="subtitle2"
        >
          <span style={{ display: "inline-block", width: "20%" }}>
            Use Case Document
          </span>
          <strong style={{ display: "flex", alignItems: "center" }}>
            <img src={pfdIcon} alt="file icon" />
            {user?.filename}
          </strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2.4}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={3}
              variant="subtitle2"
            >
              <span>Description</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={9.6}>
            <Typography
              fontFamily={"Inter, sans-serif"}
              mb={2}
              variant="subtitle2"
            >
              <strong>{user?.details}</strong>
            </Typography>
          </Grid>
        </Grid>
      </Paper> ))}
    </Container>
  );
};

export default SahyogiUserView;
