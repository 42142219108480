// RequestActionDialog

import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  Divider,
  Avatar,
  IconButton,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { APITransport } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import AdhaarCardImage from "../../assets/aadhaarcard.png";
import PanCardImage from "../../assets/pancard.png";
import FetchAppRequestDetails from "../../redux/actions/api/Dashboard/FetchAppRequestDetails";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import apiEndpoint from "../../config/apiEndpoint";
import API from "../../redux/actions/api/api";

export const UserDetails  = ({fetchTranslationPluginRequestDetails}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestData = location.state;
  const apiSatus = useSelector((state) => state.apiStatus);
  const appRequestDetails = useSelector(
    (state) => state.fetchAppRequestDetails?.data ?? {}
  );
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("username");
  const [isOpen, setIsOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: "", description: "" });
  const [status, setStatus] = useState(null);
 const RequestDetail = fetchTranslationPluginRequestDetails?.profile_details?.requests[0];


  const downloadVerificationFile = async (fileName) => {
    const apiObj = new API();
    try {
      const response = await fetch(
        `${apiObj.apiEndPointAuto()}${
          apiEndpoint.getProofDownloadUrl
        }?filename=${fileName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error("Failed to download file:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar
                      style={{
                        height: "57px",
                        width: "57px",
                        backgroundColor: "#A8A8A8",
                        color: "#595656",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontWeight={700} fontSize={"24px"}>
                     {RequestDetail?.first_name} {RequestDetail?.last_name}
                    </Typography>
                    <Typography fontSize={"16px"} gutterBottom color="#3A3A3A">
                     {RequestDetail?.email_id} 
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Account Type
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.org_info?.acnt_type}</strong>  
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Personal email ID
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.personal_email_id}</strong>  
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Designation
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.designation}</strong>  
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Phone No
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.phone}</strong>  
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Date of Birth
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px">
                <strong>{RequestDetail?.dob}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider style={{ margin: "0px 16px" }} />
      <Box sx={{ p: 3 }}>
        <Typography
          fontFamily={"Inter, sans-serif"}
          fontSize="18px"
          fontWeight={600}
          sx={{ mb: "24px" }}
        >
          Organization Details
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Organisation Type
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.org_type}</strong>   
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Department Name
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.org_info?.dpt_name}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Industry
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.org_info?.industry}</strong>   
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Organization Name
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.org_info?.org_name}</strong> 
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Organisation Website
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.org_info?.org_site}</strong>  
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  GST Number
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="16px" >
                <strong>{RequestDetail?.org_info?.gstn}</strong> 
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider style={{ margin: "0px 16px" }} />
      <Box sx={{ p: 3 }}>
        <Typography
          fontFamily={"Inter, sans-serif"}
          fontSize="18px"
          fontWeight={600}
          sx={{ mb: "24px" }}
        >
          Document
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Typography fontSize="14px" color="#6A6A6A">
                  Aadhaar Card
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      width: "182.77px",
                      height: "151.79px",
                    }}
                  >
                    <img
                      src={AdhaarCardImage}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      alt="doc"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        display="flex"
                        gap="5px"
                        alignItems="center"
                        rowGap={"11px"}
                      >
                        <Typography fontSize="14px" fontWeight={400}>
                          {RequestDetail?.verification?.id}
                        </Typography>
                        <IconButton onClick={()=>downloadVerificationFile(RequestDetail?.verification?.proof)}>
                          <DownloadForOfflineOutlinedIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
