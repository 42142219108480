
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class RevokeManagerAPI extends API {
  constructor(email, timeout = 2000) {
    super("POST", timeout, false); 
    this.type = C.REVOKE_MANAGER;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.RevokeManager}?email_id=${email}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.customer = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    
    return {
       
    };
  }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.customer;
  }
}
