import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomerRequests from './CustomerRequests';
import ManagerRequests from './ManagerRequests';
import { useLocation } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const location = useLocation();
  const requestData = location?.state?.clickedLabel
  const request = location?.state?.userManagement
  const backToCustomer = location?.state?.replace
  const RegistrationTypelabel = location?.state?.label
  const [value, setValue] = useState(0);
  const role = localStorage.getItem("role");
  
  useEffect(() => {
    setValue(() => {
      let tabId = localStorage.getItem('tabId');
      return tabId !== null && role === "ceo" ? Number(tabId) : 0;
    });
  }, []);

  useEffect(() => {
    if(request && role === "ceo"){
      setValue(1)
    }
  }, [request,role]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {role === "ceo" && (
            <Tab label="Managers" {...a11yProps(0)} style={{fontFamily:"Inter, sans-serif", fontWeight:"600"}}/>
          )}
          <Tab label="Customers" {...a11yProps(1)} style={{fontFamily:"Inter, sans-serif", fontWeight:"600"}} />
        </Tabs>
      </Box>

      {role === "ceo" && <CustomTabPanel value={value} index={0}>
        <ManagerRequests RegistrationTypelabel={RegistrationTypelabel} />
      </CustomTabPanel>}
      <CustomTabPanel value={value} index={role === "ceo" ? 1 : 0}>
        <CustomerRequests requestData={requestData} />
      </CustomTabPanel>
    </Box>
  );
}
