import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import tableTheme from "../../theme/tableTheme";
import MUIDataTable from "mui-datatables";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardStyles } from "../../styles/dashboardStyles";
import { useDispatch, useSelector } from "react-redux";
import { APITransport } from "../../redux/actions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FetchRegisteredExpertListAPI from "../../redux/actions/api/Dashboard/FetchRegisteredExpert/FetchRegisteredExpertList";
import FetchlanguagesAPI from "../../redux/actions/api/Dashboard/FetchRegisteredExpert/Fetchlanguages";
import ClearIcon from "@mui/icons-material/Clear";
import{setRegisteredExpertFilter,setOnboardingFilter,setpluginRequestFilter,setSahyogiRequestFilter,setManagerUserManagementFilter,setCustomerUserManagementFilter} from "../../redux/actions/Common";

const RegisteredExpert = () => {
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");

  const statusData = [
    {
      value: "",
      label: "User Type",
    },

    {
      value: "Individual",
      label: "Individual",
    },
    {
      value: "Organisation",
      label: "Organisation",
    },
  ];
  
  const RegisteredExpertList = useSelector(
    (state) => state?.fetchRegisteredExpertList?.data?.requests
  );
  const languages = useSelector((state) => state?.fetchlanguagesAPI?.data);
  const OnboardingFilter = useSelector((state) => state?.RegisteredExpertFilter?.filterData);

  console.log("RegisteredExpertList", languages);
  const location = useLocation();
  const navigate = useNavigate();
  const classes = DashboardStyles();

  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(OnboardingFilter?.date?OnboardingFilter?.date:null);
  const [languageType, setLanguageType] = useState(OnboardingFilter?.language?OnboardingFilter?.language:"");
  const [status, setStatus] = useState(OnboardingFilter?.userType?OnboardingFilter?.userType:"");
  const tableRef = useRef(null);

  useEffect(() => {
    const reqObj = new FetchRegisteredExpertListAPI(1, 2000);
    dispatch(APITransport(reqObj));

    const languagesObj = new FetchlanguagesAPI(1, 2000);
    dispatch(APITransport(languagesObj));
  }, []);

  useEffect(() => { 
    dispatch(setRegisteredExpertFilter({ userType: status,
      language: languageType,
      date:selectedDate }))

  }, [dispatch,languageType,selectedDate,status]);

  useEffect(() => {
    dispatch(setOnboardingFilter({}));
    dispatch(setpluginRequestFilter({}));
    dispatch(setSahyogiRequestFilter({}));
    dispatch(setManagerUserManagementFilter({}));
    dispatch(setCustomerUserManagementFilter({}));
  }, []);

  const onViewRequestClick = (rowData) => {
    console.log(rowData, "rowDatarowData");
    navigate("/linguistic-expert-action", {
      state: { rowValue: rowData?.rowData },
    });
  };

  const handleStatusFilterChange = (e) => {
    setStatus(e.target.value);
  };

  const handleApplicationFilterChange = (e) => {
    setLanguageType(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClearDate = (event) => {
    event.stopPropagation();
    setSelectedDate(null);
  };

  const languageOptions = [
    {
      value: "",
      label: "Select Language",
    },
    ...languages.map((lang) => ({
      value: lang,
      label: lang,
    })),
  ];

  const renderToolBar = () => {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h3"
            fontFamily={"Inter, sans-serif"}
            fontWeight={600}
            sx={{ fontSize: "18px", textAlign: "left" }}
          >
            Registered Language Experts
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          spacing={2}
          justifyContent="flex-start"
        >
          <Grid
            item
            display="flex"
            alignItems="center"
            gap={0}
            sx={{ mr: 9, mb: 3 }}
          >
            <Select
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={classes.searchBox}
              value={status}
              style={{ minWidth: "200px", textAlign: "left" }}
              onChange={handleStatusFilterChange}
            >
              {statusData.map((el, i) => (
                <MenuItem key={i} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
            <Select
              displayEmpty
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={classes.searchBox}
              value={languageType}
              sx={{ ml: 2 }}
              style={{ minWidth: "200px", textAlign: "left" }}
              onChange={handleApplicationFilterChange}
            >
              {languageOptions?.map((el, i) => (
                <MenuItem key={i} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "7px",
                }}
              >
                <DatePicker
                  sx={{
                    width: "220px",
                    ml: 2,
                    paddingTop: "10px",
                    "& .MuiInputBase-root": {
                      fontSize: "18px",
                      marginTop: "6px",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "18px",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      fontSize: "18px",
                    },
                  }}
                  label="Date"
                  className={classes.searchBox}
                  value={selectedDate}
                  onChange={handleDateChange}
                  format="DD/MM/YYYY"
                />
                {selectedDate && (
                  <IconButton onClick={handleClearDate} sx={{ ml: 1 }}>
                    <ClearIcon />
                  </IconButton>
                )}
              </div>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const options = {
    textLabels: {
      body: { noMatch: "No records" },
      toolbar: {
        search: "Search",
        viewColumns: "View Column",
      },
      pagination: { rowsPerPage: "Rows per page" },
      options: { sortDirection: "desc" },
    },
    selectToolbarPlacement: "none",
    displaySelectToolbar: false,
    fixedHeader: false,
    // filterType: "checkbox",
    download: false,
    print: false,
    rowsPerPageOptions: [10, 25, 50, 100],
    filter: false,
    viewColumns: false,
    search: false,
    jumpToPage: false,
    pagination: true,
    customToolbar: renderToolBar,
    // search: true, // Enable search
    searchText: searchText, // Set search text
    onSearchChange: (searchText) => setSearchText(searchText),
    selectableRows: "none", // Enable multiple row selection
  };

  const columns = [
    {
      name: "_id",
      label: "Request Id",
      options: {
        display: false,
      },
    },
    {
      name: "official_email",
      label: "Request Id",
      options: {
        display: false,
      },
    },
    {
      name: "number",
      label: "Sr. No.",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "userType",
      label: "User Type",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "OrganisationName",
      label: "Organisation Name",
      align: "center",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "UserName",
      label: "User Name",
      align: "center",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },

    {
      name: "LanguageExpertise",
      label: "Language Expertise",
      options: {
        align: "center",
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
     {
      name: "domainExpertise",
      label: "Domain",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "WorkExperience",
      label: "Work Experience ( years )",
      options: {
        setCellProps: () => ({ style: { textAlign: "center" } }),
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }),
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        align: "center",
        customBodyRender: (value, meta) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                fontWeight={600}
                fontSize={"14px"}
                color={"#2947A3"}
                onClick={() => onViewRequestClick(meta)}
              >
                View More
              </Link>
            </div>
          );
        },
      },
    },
  ];


  const filteredData = RegisteredExpertList?.filter(item => {
    const matchesUserType = item?.user_type === status || status === "";
    const matchesDate = selectedDate
    ? new Date(item.registered_on).toDateString() ===
      new Date(selectedDate).toDateString()
    : true;
    const matcheslanguage = item?.primary_language_expertise=== languageType || languageType === "";

    return matchesUserType && matchesDate && matcheslanguage ;
  });
  
 
  const rowData = useMemo(() => {
    return filteredData?.map((el, i) => ({
      ...el,
      number: i + 1,
      userType: el.user_type,
      OrganisationName: el?.org_name,
      UserName: `${el.first_name} ${el.last_name}`,
      LanguageExpertise: `${el.primary_language_expertise} | ${el.primary_proficiency_level}`,
      domainExpertise:el.domain_expertise,
      WorkExperience: el?.work_experience,
      action: "",
    }));
  }, [filteredData]);
  return (
    <div>
      <Grid container direction="row" sx={{ mt: "20px" }}>
        <Grid item md={12} xs={12} style={{ width: "100%" }}>
          <ThemeProvider theme={tableTheme}>
            <MUIDataTable
              ref={tableRef}
              data={rowData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegisteredExpert;
