import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import tableTheme from "../../../theme/tableTheme";
import MUIDataTable from "mui-datatables";
import {useLocation, useNavigate} from "react-router-dom";
import {TextField, InputAdornment} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {DashboardStyles} from "../../../styles/dashboardStyles";
import {useDispatch, useSelector} from "react-redux";
import {APITransport} from "../../../redux/actions";
import FetchOnboardingRequestsAPI from "../../../redux/actions/api/Dashboard/FetchOnboardingRequests/FetchOnboardingRequests";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import fetchReferenceApplicationStatusAPI from "../../../redux/actions/api/ReferenceApplications/fetchReferenceApplicationStatus";
import fetchAllApplicationAPI from "../../../redux/actions/api/ReferenceApplications/fetchAppApplication";
import ClearIcon from '@mui/icons-material/Clear';
import{setRegisteredExpertFilter,setOnboardingFilter,setpluginRequestFilter,setSahyogiRequestFilter,setManagerUserManagementFilter,setCustomerUserManagementFilter} from "../../../redux/actions/Common";
import FetchManagerListAPI from "../../../redux/actions/api/ReferenceApplications/FetchManagerList";
import{getFilteredData} from "../../../redux/reducers/Dashboard/FetchOnboardingRequests/FetchOnboardingRequests";
import fetchOrganisationNamesAPI from '../../../redux/actions/api/UserManagement/fetchOrganisationNames';

const OnboardingRequests = () => {
  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");

  const statusData = [
    {
      value: "",
      label: "All Requests",
    },
    {value: "Approved", label: "Approved"},
    {value: "Rejected", label: "Rejected"},
  ];
  const OnboardingFilter = useSelector((state) => state?.OnboardingFilter?.filterData);
  
  const location = useLocation();
  const navigate = useNavigate();
  const classes = DashboardStyles();
  const requestData = location?.state?.label;
  const requestDataStatus = location?.state?.clickedLabel;
  const requestManagerData = location?.state;

  const [searchText, setSearchText] = useState("");
  const [selectManager, setSelectManager] = useState(requestManagerData?.status?requestManagerData?.status:OnboardingFilter?.managerlist?OnboardingFilter?.managerlist:"");
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(OnboardingFilter?.datafilter?OnboardingFilter?.datafilter:null); // add this state
  const [applicationType, setApplicationType] = useState(requestData ? requestData:OnboardingFilter?.applicationType?OnboardingFilter?.applicationType:"");
  const [status, setStatus] = useState(requestDataStatus ? requestDataStatus :OnboardingFilter?.allRequest?OnboardingFilter?.allRequest :"");
  const tableRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedorgDetails,setSelectedOrgDetails]= useState(OnboardingFilter?.org_type?OnboardingFilter?.org_type:"")

  // const onboardUserList = useSelector(
  //   (state) => state.fetchOnboardingRequests?.data ?? []
  // );

  const onboardUserList = useSelector(getFilteredData);

  const [applicationlist, setApplicationList] = useState([]);

  const ceoEmail = process.env.REACT_APP_CEO_EMAIL?process.env.REACT_APP_CEO_EMAIL:"ceo-dibd@digitalindia.gov.in";
  const managerList = useSelector(
    (state) => state.fetchManagerList?.data?.requests
  );

  const orgDetails = useSelector(state => state.fetchOrganisationNames.data);

  useEffect(() => {
    dispatch(setOnboardingFilter({ applicationType: applicationType,
      allRequest: status,
      datafilter: selectedDate,managerlist:selectManager,org_type:selectedorgDetails}))

  }, [dispatch,applicationType,status,selectedDate,selectManager,selectedorgDetails]);

    useEffect(() => {
      if(requestDataStatus==="Pending"&& role==="ceo"){
        setStatus("Pending for Manager Action")
      }
        }, [requestDataStatus,role]);

  useEffect(() => {
    dispatch(setpluginRequestFilter({}));
    dispatch(setSahyogiRequestFilter({}));
    dispatch(setManagerUserManagementFilter({}));
    dispatch(setCustomerUserManagementFilter({}));
    dispatch(setRegisteredExpertFilter({}));

  }, []);

  const fetchOnboardingRequestsData = () => {
    if(role==="manager"){
    var payload = {
      "request_to": [
        email
      ]
    }}
    const apiObj =
      new FetchOnboardingRequestsAPI(1, 2000,payload)
    dispatch(APITransport(apiObj));
  };

  const referenceApplicationStatus = useSelector(
    (state) => state.fetchAllApplication?.data ?? []
  );

  const getAllApplicationData = useCallback(() => {
    const reqObj = new fetchAllApplicationAPI();
    dispatch(APITransport(reqObj));
  }, [dispatch]);

  useEffect(() => {
    fetchOnboardingRequestsData();
    getAllApplicationData();
  }, [status, getAllApplicationData]);

  useEffect(() => {
    let data = [{label: "All Application", value: ""}];
    referenceApplicationStatus.forEach((el) => {
      data.push({label: el.name, value: el.name});
    });
    setApplicationList(data);
  }, [referenceApplicationStatus]);

  const onViewRequestClick = (rowData) => {
    const reqData = onboardUserList?.filter(
      (el) => el._id === rowData.rowData[0]
    )[0];
    // console.log("reqData --- ", reqData);
    navigate("/request-action", { state: { reqData, rowValue: rowData?.rowData }});
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value); // Update search text state
  };

  const handleStatusFilterChange = (e) => {
    setStatus(e.target.value);
  };

  const handleApplicationFilterChange = (e) => {
    setApplicationType(e.target.value);
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClearDate = (event) => {
    event.stopPropagation();
    setSelectedDate(null);
  };

  const statusDatafiltred = [
    ...statusData,
    ...(role === "ceo" ? [{ value: "Unassigned", label: "Unassigned" }] : []),
    ...(role === "ceo" ? [{ value: "Pending for CEO Action", label: "Pending for CEO Action" }] : [{
      value: "Pending",
      label: "Pending",
    },]),
    ...(role === "ceo" ? [{ value: "Pending for Manager Action", label: "Pending for Manager Action" }] : []),
    ...(role === "manager" ? [{ value: "Approved by CEO", label: "Approved by CEO" }] : []),
    ...(role === "manager" ? [{ value: "Pending by CEO", label: "Pending by CEO" }] : []),
    ...(role === "manager" ? [{ value: "Rejected by CEO", label: "Rejected by CEO" }] : []),

  ]; 

  const FetchingManagerList = () => {
    const payload={   "status": [ "Approved","Registered"] }
    const reqObj = new FetchManagerListAPI(payload);
    dispatch(APITransport(reqObj));
  };

  const getOrganisationTypes = () => {
    const reqObj = new fetchOrganisationNamesAPI();
    dispatch(APITransport(reqObj));
}


  useEffect(() => {
    FetchingManagerList();
    getOrganisationTypes()
  }, []);

  const processApiData = () => {
    if (!Array.isArray(managerList)) {
      return [];
    }
    const transformedItems = managerList?.map(item => ({
      firstname: item.first_name , 
      lastname: item.last_name ,   
      email: item.official_email  
    }));

    const additionalItem = { firstname: "All", lastname: "", email: "All" };

    if (!Array.isArray(transformedItems)) {
      return [additionalItem]; 
    }

    return [...transformedItems, additionalItem];
  };

  const handleChange = (event) => {
    setSelectManager(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getorgDetails = () => {
    const requests = orgDetails || []; 

    // Format the data to include both 'value' and 'label'
    const formattedKeys = requests.map(item => ({
        value: item.name,  
        label: item.description || item.name 
    }));

    return [{ value: "", label: "Organisation Type" }, ...formattedKeys];
};

  const renderPlaceholder = () => {
    return !open && selectManager === "" ? (
      <MenuItem value="" disabled>
       Select Manager
      </MenuItem>
    ) : null;
  };

  const renderToolBar = () => {
    const enableBulkButtons =
    tableRef.current?.state.selectedRows.data.length > 0;
    
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            variant="h3"
            fontFamily={"Inter, sans-serif"}
            fontWeight={600}
            sx={{ fontSize: "18px", textAlign: "left" }}
          >
          Parikshan Application Request
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          spacing={2}
          justifyContent="flex-start"
        >
          <Grid item display="flex" alignItems="center" gap={0} sx={{ mr: 9, mb: 3 }}>
          <Select
            displayEmpty
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={classes.searchBox}
            value={status}
            style={{minWidth: "200px", textAlign:"left"}}
            onChange={handleStatusFilterChange}
          >
            {statusDatafiltred.map((el, i) => (
              <MenuItem key={i} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
          <Select
            displayEmpty
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={classes.searchBox}
            value={applicationType}
            sx={{ml: 2}}
            style={{minWidth: "200px", textAlign:"left"}}
            onChange={handleApplicationFilterChange}
          >
            {applicationlist.map((el, i) => (
              <MenuItem key={i} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ display: 'flex', alignItems: 'center',marginBottom:"7px" }}>
        <DatePicker
          sx={{ width: '220px', ml: 2 ,paddingTop:"10px",'& .MuiInputBase-root': {fontSize: '18px', marginTop: "6px",},'& .MuiInputLabel-root': {
            fontSize: '18px', 
          },'& .MuiInputBase-input::placeholder': {
            fontSize: '18px',
          }, }}
          label="Date"
          className={classes.searchBox}
          value={selectedDate}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
        />
        {selectedDate && (
          <IconButton onClick={handleClearDate} sx={{ ml: 1 }}>
            <ClearIcon />
          </IconButton>
        )}
      </div>
    </LocalizationProvider>

       {role==="ceo" && <Select
            displayEmpty
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className={classes.searchBox}
            value={selectManager}
            sx={{ml: 2}}
            style={{minWidth: "200px", textAlign:"left"}}
            onChange={handleChange}
            onOpen={handleOpen}
        onClose={handleClose}
          >
            {renderPlaceholder()}
        {processApiData()?.map((item) => (
          <MenuItem key={item.email} value={item.email}>
            {item.firstname} {item.lastname}
          </MenuItem>
        ))}
          </Select>}
          <Select
                  displayEmpty
                  labelId="apiKey-select-label"
                  id="apiKey-select"
                  className={classes.searchBox}
                  value={selectedorgDetails}
                  onChange={(e) => setSelectedOrgDetails(e.target.value)}
                  sx={{ml: 2}}
                  style={{ minWidth: "200px", textAlign:"left" }}
                >
                  {getorgDetails()?.map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
          </Grid>
        </Grid>
      </Grid>
      // <>
      //   <Button variant="contained" className={classes.bulkApproveBtn} disabled={!enableBulkButtons} onClick={() => onBulkButtonClick("approved")}>
      //     Bulk Approve
      //   </Button>

      // <Button variant="contained" className={classes.bulkRejectBtn} disabled={!enableBulkButtons} onClick={() => onBulkButtonClick("rejected")}>
      //   Bulk Reject
      // </Button>

      //   <TextField
      //     placeholder="Search"
      //     className={classes.searchBox}
      //     InputProps={{
      //       endAdornment: (
      //         <InputAdornment position="end">
      //           <SearchIcon />
      //         </InputAdornment>
      //       ),
      //     }}
      //     onChange={handleSearch}
      //   />
      // </>
    );
  };

  const options = {
    textLabels: {
      body: {noMatch: "No records"},
      toolbar: {
        search: "Search",
        viewColumns: "View Column",
      },
      pagination: {rowsPerPage: "Rows per page"},
      options: {sortDirection: "desc"},
    },
    selectToolbarPlacement: "none",
    displaySelectToolbar: false,
    fixedHeader: false,
    // filterType: "checkbox",
    download: false,
    print: false,
    rowsPerPageOptions: [10, 25, 50, 100],
    filter: false,
    viewColumns: false,
    search: false,
    jumpToPage: false,
    pagination: true,
    customToolbar: renderToolBar,
    // search: true, // Enable search
    searchText: searchText, // Set search text
    onSearchChange: (searchText) => setSearchText(searchText),
    selectableRows: "none", // Enable multiple row selection
  };

  const columns = [
    {
      name: "_id",
      label: "Request Id",
      options: {
        display: false,
      },
    },
    {
      name: "requested_by_email",
      label: "Request by Email",
      options: {
        display: false,
      },
    },
    {
      name: "org_type",
      label: "Organisation Type",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "requestor_name",
      label: "Name",
    },
    {
      name: "application_name",
      label: "Application Requested",
      align: "center",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "requested_on",
      label: "Date of Request",
      options: {
        align: "center",
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value, meta) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB");
          return <>{formattedDate}</>;
        },
      },
    },
    {
      name: "overAllTAT",
      label: "Total Processing Time (days)",
      options: {
      setCellProps: () => ({ style: { textAlign: "center" }}),
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    
    {
      name: "roleBasedStatus",
      label: "Manager Approval",
      options: {
        align: "center",
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        display:  role === "ceo"? true:false,
        customBodyRender: (value) => {
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    },
    {
      name: "overallStates",
      label: "Your action",
      options: {
        align: "center",
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value, meta) => {
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    },
    {
      name: "CeoroleBasedStatus",
      label: "Status",
      options: {
        align: "center",
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        display:  role === "manager"? true:false,
        customBodyRender: (value) => {
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        align: "center",
        customBodyRender: (value, meta) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                fontWeight={600}
                fontSize={"14px"}
                color={"#2947A3"}
                onClick={() => onViewRequestClick(meta)}
              >
                View More
              </Link>
            </div>
          );
        },
      },
    },
  ];
  const filteredOnBoardUserList = useMemo(() => {
    return onboardUserList.filter((el) => {
      let matchesStatus;
          if (status) {
            if (status === 'Unassigned') {
              if (el.request_to === ceoEmail && el.status !== 'Rejected') {
                matchesStatus = true;
              } else {
                matchesStatus = false;
              }
            } else if (status === 'Pending for CEO Action' && role === 'ceo') {
              if (el.status === 'Pending' && el.manager_approval.status === 'Approved') {
                matchesStatus = true;
              } else {
                matchesStatus = false;
              }
            } else if (status === 'Pending for Manager Action' && role === 'ceo') {
              matchesStatus = el.status === 'Pending' && el.manager_approval.status === 'Pending'&& el.request_to !== ceoEmail;
            } else if (role === 'ceo') {
              if (el.status === status) {
                matchesStatus = true;
              } else {
                matchesStatus = false;
              }
            } else if (status === 'Approved by CEO'){
               matchesStatus = (el?.manager_approval.status==='Approved'&& el?.ceo_approval?.status==='Approved')
            }
            else if (status === 'Pending by CEO'){
              matchesStatus = (el?.manager_approval.status==='Approved' && el?.ceo_approval?.status==='Pending')
           }
           else if (status === 'Rejected by CEO'){
            matchesStatus = (el?.manager_approval.status==='Approved' && el?.ceo_approval?.status==='Rejected')
         }
            else {
              if (el.manager_approval.status === status) {
                matchesStatus = true;
              } else {
                matchesStatus = false;
              }
            }
          } else {
            matchesStatus = true;
          }
    
      const matchesApplication = applicationType
        ? el.application_name === applicationType
        : true;
      const matchesDate = selectedDate
        ? new Date(el.requested_on).toDateString() ===
          new Date(selectedDate).toDateString()
        : true;
        const matchManagern = selectManager === "All"|| selectManager === ""
         ? true
       : el.request_to === selectManager;

       const matchOrgType = selectedorgDetails? el.org_type === selectedorgDetails
       : true;

      return matchesStatus && matchesDate && matchesApplication && matchManagern &&matchOrgType;
    
    });
  }, [onboardUserList, status, applicationType, selectedDate,role,selectManager,ceoEmail,selectedorgDetails]);

  const rowData = useMemo(() => {
    return filteredOnBoardUserList.map((el, i) => ({
      ...el,
      org_type: el.org_type,
      requestor_name: el.requestor_name,
      application_name: el.application_name,
      requested_on: el.requested_on,
      action: "",
      overAllTAT:el?.overall_approval_tat,
      roleBasedStatus: role === "ceo" && el.manager_approval.status,
      CeoroleBasedStatus:  role === "manager" && el.status,
      overallStates: role === "ceo"? el.status:el.manager_approval.status,
    }));
    
  }, [filteredOnBoardUserList,role]);
  return (
    <div>
      <Grid container direction="row" sx={{mt:"20px"}}>
        <Grid item md={12} xs={12} style={{width: "100%"}}>
          <ThemeProvider theme={tableTheme}>
            <MUIDataTable
              ref={tableRef}
              data={rowData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default OnboardingRequests;
