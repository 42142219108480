import C from "../../../constants";

const initialState = {
    userManager: [],
}



const fetchOnboardManager = (state = initialState, action) => {
    switch (action.type) {
        case C.FETCH_ONBOARD_MANAGER:
           
            return {
                ...state,
                userManager : action.payload
            }
        default:
            return {
                ...state
            }
    }
};

export default fetchOnboardManager;