import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, TextField, Typography, useMediaQuery } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoginStyles } from "../../../styles/LoginStyles";
import { useDispatch, useSelector } from 'react-redux';
import { LoginAPI } from "../../../redux/actions";
import { setSnackBar } from "../../../redux/actions/Common";
import authenticateUser from "../../../utils/authenticateUser";
import { roles } from "../../../constants/constants";
import '../Register/Register.css'
import CardComponentData from "../Register/CardComponent/CardComponentData";
import CardComponent from "../Register/CardComponent/CardComponent";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../../assets/bhashininewlogo3.png";
const Login = () => {
  const classes = LoginStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector(state => state.getUserDetails.data);

  const [credentials, setCredentails] = useState({
    email: "",
    password: "",
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');


  const settings = {
    dots: true,
    infinite: true,
    // speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    // cssEase: "linear",
    arrows:false,
    
       responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };

  const handleFieldChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setCredentails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const roleBasedNavigation = (role) => {
    console.log("role --- ", role);
    switch (role) {
      case roles.USER:
        return navigate('/user-dashboard')
      case roles.MANAGER:
        return navigate('/manager-dashboard')
      case roles.CEO:
        return navigate('/ceo-dashboard')
      default:
        return navigate('/requests')
    }
  }

  const handleSignIn = async () => {
    const { email, password } = credentials;
    const loginAPIObj = new LoginAPI(email, password);
    const res = await fetch(loginAPIObj.apiEndPoint(), {
      method: 'POST',
      headers: loginAPIObj.getHeaders().headers,
      body: JSON.stringify(loginAPIObj.getBody())
    });
    const resp_data = await res.json();
    if (res.ok) {
      localStorage.setItem('token', resp_data.token);
      localStorage.setItem('role', resp_data.role === 'customer'?'User':resp_data.role);
      localStorage.setItem('email', resp_data.email);
      localStorage.setItem('username', resp_data.username);
      localStorage.setItem('org_type', resp_data.org_type);
      dispatch(setSnackBar({
        open: true,
        message: 'Login successful',
        variant: "success"
      }));
      if(resp_data.userinfo.is_fresh){
        navigate(`/user/reset-password/${email}`, { replace: true });  
      } else if(!resp_data.userinfo.is_profile_updated  && resp_data.role !== 'ceo' && resp_data.role !== 'manager') {
        navigate("/update-profile", { replace: true });
        // navigate("/applications", { replace: true });
      } else {
        roleBasedNavigation(resp_data.role === 'customer'?'User':resp_data.role)
        // roleBasedNavigation(resp_data.role)
      }
    } else {
      dispatch(setSnackBar({
        open: true,
        message: resp_data?.message || "Something went wrong",
        variant: "error"
      }));
    }
  };

  // Prevent user to go back to login screen
  useEffect(()=>{
    if(authenticateUser('User') && userDetails?.basicDetails){
      const userBasicData = userDetails?.basicDetails;
      if(userBasicData?.is_fresh){
        navigate(`/user/reset-password/${credentials.email}`, { replace: true });  
      } else if(!userBasicData?.is_profile_updated && localStorage.getItem('role') !=='manager' ) {
        navigate("/update-profile", { replace: true });
        // navigate("/applications", { replace: true });
      } else {
        navigate("/user-dashboard", { replace: true });
      }
    }
  }, [])
console.log("checking")
  return (
    <>
     
        <Box className="OnboardingForm_register">
      {/* <Container> */}
        <Grid container style={{ width: isMobile ? '' : '100%', height:"100%" }}>
          <Grid item xs={12} md={6}>
            <Box className="OnboardingForm__imgSection" style={{height:"100%"}}>
              <Box className="OnboardingForm__imgSectionLogo">
                <img src={Logo} alt="logo" />
              </Box>
              <Box className="OnboardingForm__imgSectionTextContainer">
                <Box>

                <Typography variant="h5" className="OnboardingForm__imgSectionHeading" fontFamily={"Inter, sans-serif"} fontWeight={600}>
                Welcome to Bhashini Onboarding Portal
                </Typography>
                <Typography variant="body1" className="OnboardingForm__imgSectionText">
                Your portal to access Parikshan Applications
                </Typography>
                </Box>
                {/* <div style={{display:"flex", justifyContent:"center"}}>
        <div style={{width:"400px"}}>

      <div className="slider-container">
      <Slider {...settings}>
       {CardComponentData.map((item, index) => (
        <div key={index}>
          <CardComponent
            image={item.image}
            title={item.title}
            description={item.description}
            cardNumber={index + 1}
            IconComponent={item.icon}
          />
        </div>
      ))}
      </Slider>
    </div>
        </div>

      </div> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} >
            <Grid container className={classes.loginNewCard}>
              <Box sx={{width:"80%", display:"flex", flexDirection:"column", gap:"5px"}}>
        <Grid item xs={12}>
          <Typography className={classes.heading}>Welcome Back,</Typography>
          <Typography className={classes.subHeading}>Login to access your account</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Enter Email Id"
            className={classes.emailField}
            name="email"
            onChange={handleFieldChange}
            value={credentials["email"]}
            InputLabelProps={{shrink: true}}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Enter Password"
            name="password"
            type={passwordVisibility ? "text" : "password"}
            onChange={handleFieldChange}
            value={credentials["password"]}
            InputLabelProps={{shrink: true}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Box display="flex" justifyContent="space-between" sx={{width:"100%"}} mt={2} mb={2}>
        <FormControlLabel  control={<Checkbox />} label="Remember Password" />
          <Button variant="text" className={classes.forgotPasswordBtn} onClick={()=>navigate("/user/forgot-password")}>
            Forgot Password
          </Button>
        </Box>

        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.loginButton}
            onClick={handleSignIn}
          >
            Login
          </Button>
        </Grid>
        </Box>
          <Typography className={classes.registerLink}>Don’t have an account? <Link to='/user/register' style={{color:"#2947A3"}}>Register</Link></Typography>
      </Grid>
          </Grid>
        </Grid>
      {/* </Container> */}
    </Box>
    </>
  );
};

export default Login;
