import React from "react"
import { Box, Button, Grid, Modal as MuiModal, Typography } from '@mui/material';

const Modal = ({ children, title, alternateButton, isOpen, handleClose, alternateButtonClick, isAlternativeBtnDisabled }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'fit-content',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: "32px",
        borderRadius: '8px'
    };

    return (
        <div>
            <MuiModal
                open={isOpen}
                onClose={handleClose}
                disableAutoFocus
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" fontWeight={600} fontSize={"16px"}>
                        {title}
                    </Typography>
                    <Typography id="modal-modal-title" fontWeight={200} fontSize={"16px"} sx={{mt:1}}>
                        {children}
                    </Typography>
                    <Grid container spacing={2} justifyContent="flex-end" sx={{mt:2}}>
                        <Grid item>
                            <Button variant="outlined" style={{ borderRadius: '4px', width: '117px', height: '38px' }} onClick={handleClose}>Cancel</Button>
                        </Grid>
                        {/* {
                            alternateButton &&  */}
                            <Grid item>
                                <Button  variant="contained" color="primary" style={{ borderRadius: '4px', width: '117px', height: '38px' }} onClick={alternateButtonClick}>
                                    {/* {alternateButton} */}
                                    Yes, Reject
                                </Button>
                            </Grid>
                        {/* } */}
                    </Grid>
                </Box>
            </MuiModal>
        </div>
    );
}

export default Modal