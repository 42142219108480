import C from "../../../constants";

const reducer = (state, action) => {
  switch (action.type) {
    case C.FETCH_ALL_APPLICATION:
      const {payload} = action;
      return {
        ...state,
        data: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
