// referenceApplicationRequest


import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class DeletePluginUlcaDataApi extends API {
    constructor(request_by,api_key,host_url, timeout = 2000) {
        super("PUT", timeout, false);
        this.type = C.DELETE_PLUGIN_DATA;
        this.request_by = request_by;
        this.api_key = api_key;
        this.host_url = host_url;
        this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.deletePluginData}`;
    }

    processResponse(res) {
        super.processResponse(res);
        if (res) {
            this.report = res;
        }
    }

    apiEndPoint() {
        return this.endpoint;
    }

    getBody() {
        return {
            requested_by: this.request_by,
            api_key: this.api_key,
            host_url: this.host_url,
          };
    }

    getHeaders() {
        this.headers = {
            headers: {
                "Content-Type": "application/json",
                "authorization": localStorage.getItem("token")
            },
        };
        return this.headers;
    }

    getPayload() {
        return this.report
    }
}
