import C from "../../../constants";

const initialState = {
    userList: [],
    count: 0
}

const restructureUsersList = (userList) => {

    return userList.map((user)=>({
        ...user,
        organization_name: user?.organization?.organization_name,
        document_name: user?.govt_document
    }))
};

const fetchOnboardUsers = (state = initialState, action) => {
    switch (action.type) {
        case C.FETCH_ONBOARD_USERS:
            const { userList, count } = action.payload;
            const updatedList = restructureUsersList(userList);
            return {
                ...state,
                userList: updatedList,
                count
            }
        default:
            return {
                ...state
            }
    }
};

export default fetchOnboardUsers;