export default function authenticateUser(allowedRoles) {
    // Check if user exists in local storage
    // const user = localStorage.getItem('user');

    // Check if token is present in local storage
    const token = localStorage.getItem('token');

    // Check if user role matches one of the allowed roles
    const userRole = localStorage.getItem('role');
    // const isRoleAllowed = allowedRoles.includes(userRole);

    // If user, token, and user role exist in local storage and user role is allowed
    if (token) {
      return true;
    } else {
      return false;
    }
  }
  