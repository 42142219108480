// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family: "Inter", sans-serif;
  /* border: 1px solid red; */
  box-sizing: border-box;
}

html, body, #root{
  background: #F5F7FA;
  height: 100vh;
  width: 100%;
}

.userInput__form .react-datepicker-wrapper{
  width: 100%;
}

.userInput__form .react-datepicker-wrapper .react-datepicker__input-container input{
  width: 100%;
  padding: 17px;
  border: 1px solid lightgray;
  font-size: 18px;
  color: gray;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.userManagementRow .tss-b0igv7-MUIDataTableToolbar-root.tss-b0igv7-MUIDataTableToolbar-root {
  height: auto;
}


@media screen and (max-width:600px) {
  .userInput__form .react-datepicker-wrapper .react-datepicker__input-container input{
    padding: 20px;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,gCAAgC;EAChC,2BAA2B;EAC3B,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,0BAA0B;EAC1B,uBAAuB;EACvB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;;AAGA;EACE;IACE,aAAa;IACb,WAAW;EACb;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n*{\n  font-family: \"Inter\", sans-serif;\n  /* border: 1px solid red; */\n  box-sizing: border-box;\n}\n\nhtml, body, #root{\n  background: #F5F7FA;\n  height: 100vh;\n  width: 100%;\n}\n\n.userInput__form .react-datepicker-wrapper{\n  width: 100%;\n}\n\n.userInput__form .react-datepicker-wrapper .react-datepicker__input-container input{\n  width: 100%;\n  padding: 17px;\n  border: 1px solid lightgray;\n  font-size: 18px;\n  color: gray;\n  border-radius: 4px;\n  -webkit-border-radius: 4px;\n  -moz-border-radius: 4px;\n  -ms-border-radius: 4px;\n  -o-border-radius: 4px;\n}\n\n.userManagementRow .tss-b0igv7-MUIDataTableToolbar-root.tss-b0igv7-MUIDataTableToolbar-root {\n  height: auto;\n}\n\n\n@media screen and (max-width:600px) {\n  .userInput__form .react-datepicker-wrapper .react-datepicker__input-container input{\n    padding: 20px;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
