/**
 * Fetch Onboard User API
 */
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchOnboardUsersAPI extends API {
  constructor(status, timeout = 2000) {
    super("GET", timeout, false);
    this.type = C.FETCH_ONBOARD_USERS;
    this.status = status;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.fetchOnboardUsers}?status=${this.status}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody(){
    return{
        status: this.status
    }
  }

  getHeaders() {
    const authorization = localStorage.getItem("token");
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authorization
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report
  }
}
