import C from '../../../constants'

const reducer = (state, action) => {
    switch (action.type) {
        case C.GET_APP_REQUEST_DETAILS:
            const { payload } = action
            return {
                ...state,
                data: payload
            }
        default:
            return {
                ...state
            }
    }
}

export default reducer;