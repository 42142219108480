import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Typography, Button, Grid, Box } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

const NotFoundPage = () => {
    const navigate = useNavigate()
    useEffect(()=>{
        navigate('/user/login')
    }, [])
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <Grid item xs={12}>
        <Box textAlign="center">
          <ErrorOutline style={{ fontSize: 100, color: 'red' }} />
          <Typography variant="h4" component="h1" gutterBottom>
            404 - Page Not Found
          </Typography>
          <Typography variant="body1" gutterBottom>
            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
          </Typography>
          <Button component={Link} to="/user/login" variant="contained" color="primary">
            Go to Home
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
