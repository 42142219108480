import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Input,
  } from "@mui/material";
  import DeleteIcon from '@mui/icons-material/Delete';
  import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import FetchPluginUlcaKeysDataApi from '../../redux/actions/api/ReferenceApplications/FetchPluginUlcaKeysData';
import { APITransport } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedDate, getFormattedDateandTime } from '../../utils/utils';
import AddPluginUlcaDataApi from '../../redux/actions/api/ReferenceApplications/AddPluginData';
import EditPluginDataApi from '../../redux/actions/api/ReferenceApplications/EditPluginData';
import DeletePluginUlcaDataApi from '../../redux/actions/api/ReferenceApplications/DeletePluginData';
import { setSnackBar } from "../../redux/actions/Common";
const ViewWTMSDetails = ({UlcaApiKeys,myapplicationDetail}) => {
    
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRejectionDialog, setOpenRejectionDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedApiKey, setSelectedApiKey] = useState(' ');
  const [selectedApiKeyData, setSelectedApiKeyData] = useState(null);
  
  const [hostUrl,setHostUrl] = useState('')
  const username = localStorage.getItem('username')
  const email = localStorage.getItem('email')

  const dispatch = useDispatch()
  const apiStatus = useSelector((state) => state.apiStatus);
  const UlcaApiKeysData = useSelector((state) => state.fetchPluginUlcaKeysData?.data);
  console.log(UlcaApiKeysData,"ula");
  const [capturedUlcaKeys, setCapturedUlcaKeys] = useState(UlcaApiKeysData)
  // const [data, setData] = useState(UlcaApiKeysData);
    const handleOpenAddDialog = () => setOpenAddDialog(true);
    const handleCloseAddDialog = () => setOpenAddDialog(false);
  
    const handleOpenEditDialog = (item) => {
      setSelectedItem(item);
      setOpenEditDialog(true);
    };
    const handleCloseEditDialog = () => setOpenEditDialog(false);
  
    const handleOpenDeleteDialog = (item) => {
      setSelectedItem(item);    
      setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);
  
    const handleAdd = () => {
      const reqObj = new AddPluginUlcaDataApi(email,username,selectedApiKeyData?.appName,selectedApiKeyData?.userID,selectedApiKey,hostUrl);
      dispatch(APITransport(reqObj));
      handleCloseAddDialog();
    };
  
    const handleEdit = () => {
      const reqObj = new EditPluginDataApi(selectedItem?._id,email,username,selectedApiKeyData?.appName,selectedApiKeyData?.userID,selectedApiKey,hostUrl);
      dispatch(APITransport(reqObj));
      handleCloseEditDialog();
    };
  
    const handleDelete = () => {
      const reqObj = new DeletePluginUlcaDataApi(email,selectedApiKey,selectedItem?.host_url);
      dispatch(APITransport(reqObj));
      handleCloseDeleteDialog();
    };

    const handleOpenRejectionDialog = (item) => {
      setSelectedItem(item);
      setOpenRejectionDialog(true);
    };
  
    const handleCloseRejectionDialog = () => {
      setOpenRejectionDialog(false);
    };

    const handleApiKeyChange = (event) => {
      setSelectedApiKey(event.target.value);
      const selectedObject = UlcaApiKeys?.data?.find(
        (item) => item.ulcaApiKey === event.target.value
      );
      setSelectedApiKeyData(selectedObject);
      
      const reqObj = new FetchPluginUlcaKeysDataApi(event.target.value);
      dispatch(APITransport(reqObj));
    };

    useEffect(() => {
      // if (apiStatus.apiType === "ADD_PLUGIN_DATA" ) {
      //   apiStatus?.success && FetchPluginUlcaKeysDataApi(selectedApiKeyData?.ulcaApiKey);
       
      // }
      
      const fetchData = async () => {
        await new Promise(resolve => setTimeout(resolve, 2000));
        if (apiStatus.apiType === "ADD_PLUGIN_DATA" && apiStatus.success) {
          const fetchPluginDataInstance = new FetchPluginUlcaKeysDataApi(selectedApiKeyData?.ulcaApiKey);
          await fetchPluginDataInstance.perform();
           const response = fetchPluginDataInstance.getPayload();
           setCapturedUlcaKeys(response)
        }
    
        if (apiStatus.apiType === "DELETE_PLUGIN_DATA" && apiStatus.success) {
          const fetchPluginDataInstance = new FetchPluginUlcaKeysDataApi(selectedApiKeyData?.ulcaApiKey);
          await fetchPluginDataInstance.perform();
          const response = fetchPluginDataInstance.getPayload();
          setCapturedUlcaKeys(response)
          dispatch(setSnackBar({
            open: true,
            message:  'Request deleted successfully',
            variant: "success"
          }));
        }

        if (apiStatus.apiType === "EDIT_PLUGIN_DATA" && apiStatus.success) {
          const fetchPluginDataInstance = new FetchPluginUlcaKeysDataApi(selectedApiKeyData?.ulcaApiKey);
          await fetchPluginDataInstance.perform();
          const response = fetchPluginDataInstance.getPayload();
          setCapturedUlcaKeys(response)
        }
      };
      fetchData();
      
      // if (apiStatus.apiType === "DELETE_PLUGIN_DATA" ) {
      //   apiStatus?.success && FetchPluginUlcaKeysDataApi(selectedApiKeyData?.ulcaApiKey);
      // }
    }, [apiStatus]);

    useEffect(() => {
      setCapturedUlcaKeys(UlcaApiKeysData)
    },[UlcaApiKeysData])

  return (
    <>
       <Box py={4} px={2}>
          <Grid container spacing={2} sx={{marginBottom:"30px", display:"flex", alignItems:"center"}}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h5" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}} gutterBottom>
                App Integration Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
            <Typography variant="h5" style={{fontFamily:"Inter, sans-serif", fontWeight:"400",marginBottom:"5px"}} gutterBottom>
                Select Api key
              </Typography>
              <FormControl fullWidth variant="outlined">
        <Select
          labelId="role-select-label"
          id="role-select"
          value={selectedApiKey}
          onChange={handleApiKeyChange}
          disabled={UlcaApiKeys?.data?.length === 0 &&  myapplicationDetail.status === 'Approved'}
        //   label="Organisation Type"
        >
          <MenuItem value=' '>
     {(UlcaApiKeys?.data?.length !== 0 &&  myapplicationDetail.status === 'Approved') ? "Select API Key" : "---------"}
    </MenuItem>
    {UlcaApiKeys?.data?.map((keyObj, index) => (
      <MenuItem key={index} value={keyObj.ulcaApiKey}>
        {keyObj.ulcaApiKey}
      </MenuItem>
    ))}
          
        </Select>
      </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12}>
              {UlcaApiKeys?.data?.length !== 0  && selectedApiKey !== ' ' ? (
                <>
                <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"80%", marginBottom:"35px"}}>
                    <Box>
                    <Typography variant="body1" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>
                   {selectedApiKeyData?.userID}
                  </Typography>
                  <Typography variant="body1" style={{fontFamily:"Inter, sans-serif", fontWeight:"400",marginBottom:"5px"}}>User ID</Typography>
                    </Box>
                    <Box>
                    <Typography variant="body1" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>
                    {selectedApiKeyData?.appName}
                  </Typography>
                  <Typography variant="body1" style={{fontFamily:"Inter, sans-serif", fontWeight:"400",marginBottom:"5px"}}>App Name</Typography>
                    </Box>
                </Box>
                 
                <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%", marginBottom:"15px"}}>
                    <Box>
                    <Typography variant="body1" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>
                    List of Host Name/URL
                  </Typography>
                
                    </Box>
                    <Box>
                    <Button variant="contained" color="primary" style={{borderRadius:"4px"}} onClick={handleOpenAddDialog}>
         + Add  HostName
          </Button>
                 
                    </Box>
                </Box>
                  <TableContainer component={Paper} style={{ marginTop: 16 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Host Name/URL</TableCell>
                          <TableCell>Approved By</TableCell>
                          <TableCell>Approval Status</TableCell>
                          <TableCell>Date of Request</TableCell>
                          <TableCell>Date of Approval</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {capturedUlcaKeys?.requests?.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.host_url}</TableCell>
                <TableCell>{item.requestor_name}</TableCell>
                <TableCell style={{ color: item.statusColor }}>
                  {item.status}
                </TableCell>
                <TableCell>{getFormattedDate(item.requested_on)}</TableCell>
                <TableCell>{item?.ceo_approval?.updated_on === null || item?.ceo_approval?.status === "Rejected" ? "--" :getFormattedDate(item?.ceo_approval?.updated_on )}</TableCell>
                <TableCell>
                  {
                    item.status === "Rejected" ? <>
                    <Button  onClick={() => handleOpenRejectionDialog(item)}>
        View More
      </Button>
                    </>
                  :
                  <>
                  <IconButton onClick={() => handleOpenEditDialog(item)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOpenDeleteDialog(item)}>
                    <DeleteIcon />
                  </IconButton>
                  </>
                  }
                </TableCell>
              </TableRow>
            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                // When no API Key is available
               selectedApiKey === ' ' && UlcaApiKeys?.data?.length === 0 &&  myapplicationDetail.status === 'Approved' && <>
                  <Typography variant="body1"  style={{fontFamily:"Inter, sans-serif", fontWeight:"400",marginBottom:"15px"}}>
                    Currently, no API keys are available for your ID. To request
                    the addition of the translation plugin to your website, you
                    need an API key.
                  </Typography>
                  <Typography variant="body1" style={{fontFamily:"Inter, sans-serif", fontWeight:"400",marginBottom:"15px"}}>
                    Please generate one by registering on the{" "}
                    <a
                      href="https://bhashini.gov.in/ulca/user/login"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bhashini Udyat platform
                    </a>
                    .
                  </Typography>
                  {/* <Typography variant="body2" style={{ marginTop: 16 }}>
                    <a
                      href="#"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link: User Manual Download
                    </a>
                  </Typography> */}
                </>
              )}
            </Grid>
          </Grid>

           {/* Add Dialog */}
      {/* <Dialog open={openAddDialog} onClose={handleCloseAddDialog} >
        <DialogTitle><Typography fontFamily={"Inter, sans-serif"} variant="h3" fontWeight={"600"}>Add Hostname/URL</Typography></DialogTitle>
        <DialogContent>
        <Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"400"} >API Key</Typography>
          <TextField margin="dense" placeholder="API Key"     defaultValue={selectedApiKey} disabled fullWidth style={{marginBottom:"20px"}}/>
          <Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"400"}>Website Domain URL</Typography>
          <TextField margin="dense" placeholder="Website Domain URL" fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}   maxWidth="sm" fullWidth>
        <DialogTitle><Typography fontFamily={"Inter, sans-serif"} variant="h3" fontWeight={"600"} mb={2} mt={2}>Add Hostname/URL</Typography></DialogTitle>
        <DialogContent>
          <Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"400"} mb={2}>API Key</Typography>
          <TextField
                        fullWidth
                        placeholder="API Key"
                        style={{marginBottom:"30px"}}
                        name="email"
                        // onChange={handleEmailChange}
                        value={selectedApiKey}
                        disabled
                    // InputLabelProps={{ shrink: true }}
                    />

          <Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"400"} mb={2}>Website Domain URL</Typography>
          <TextField
                        fullWidth
                        placeholder="Website Domain URL"
                      
                        name="email"
                        onChange={(e) => setHostUrl(e.target.value)}
                        // value={email}
                    // InputLabelProps={{ shrink: true }}
                    />

         
        </DialogContent>
        <DialogActions style={{marginBottom:"20px", marginRight:"20px"}}>
          <Button variant="outlined" color="primary" onClick={handleCloseAddDialog} style={{borderRadius:"4px", width:"120px"}}>
            Cancel
          </Button>
          {/* <Button onClick={handleAdd} color="primary">
            Add
          </Button> */}
          <Button variant="contained" color="primary" onClick={handleAdd} style={{borderRadius:"4px", width:"120px"}}>
          Add
          </Button>
        </DialogActions>
      </Dialog>

     {/* Edit Dialog */}
     <Dialog open={openEditDialog} onClose={handleCloseEditDialog} maxWidth="sm" fullWidth>
        <DialogTitle><Typography fontFamily={"Inter, sans-serif"} variant="h3" fontWeight={"600"} mb={2} mt={2}>Edit Hostname/URL</Typography></DialogTitle>
        <DialogContent>
        <Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"400"} mb={1}>Api Key</Typography>
          <TextField
            margin="dense"
            placeholder="Api Key"
            defaultValue={selectedApiKey}
            style={{marginBottom:"30px"}}
            disabled
            fullWidth
          />
           <Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"400"} mb={1}>Website Domain</Typography>
          <TextField
            margin="dense"
            placeholder="Website Domain"
            defaultValue={selectedItem?.host_url}
            onChange={(e) => setHostUrl(e.target.value)}
            style={{marginBottom:"30px"}}
            fullWidth
          />
          {/* <TextField
            margin="dense"
            label="Approval Status"
            defaultValue={selectedItem?.approvalStatus}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Date of Request"
            defaultValue={selectedItem?.requestDate}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Date of Approval"
            defaultValue={selectedItem?.approvalDate}
            fullWidth
          /> */}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEdit} color="primary">
            Save
          </Button>
        </DialogActions> */}
        <DialogActions style={{marginBottom:"20px", marginRight:"20px"}}>
          <Button variant="outlined" color="primary" onClick={handleCloseEditDialog} style={{borderRadius:"4px", width:"120px"}}>
            Cancel
          </Button>
          {/* <Button onClick={handleAdd} color="primary">
            Add
          </Button> */}
          <Button variant="contained" color="primary" onClick={handleEdit} style={{borderRadius:"4px", width:"120px"}}>
          Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle><Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"600"} mt={2}>Are you sure you want to delete this hostname?</Typography></DialogTitle>
        <DialogContent>
          {/* Are you sure you want to delete {selectedItem?.name}? */}
          <Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"400"}>  You are deleting the <b>{selectedItem?.host_url}</b> hostname, and this action cannot be undone.</Typography>
        
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions> */}
        <DialogActions style={{marginBottom:"20px", marginRight:"20px"}}>
          <Button variant="outlined" color="primary" onClick={handleCloseDeleteDialog} style={{borderRadius:"4px", width:"120px"}}>
            Cancel
          </Button>
          {/* <Button onClick={handleAdd} color="primary">
            Add
          </Button> */}
          <Button variant="contained" color="primary" onClick={handleDelete} style={{borderRadius:"4px", width:"120px"}}>
          Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Rejection dialog */}
      <Dialog
      open={openRejectionDialog}
      onClose={handleCloseRejectionDialog}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Typography fontFamily={"Inter, sans-serif"} variant="h5" fontWeight={"600"}  mt={1}>Reason for Rejection</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseRejectionDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Reason for Rejection
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">{selectedItem?.ceo_approval?.remarks_title || '----'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Description:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1" mb={2}>{selectedItem?.ceo_approval?.remarks_description || '----'} </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
        </Box>
    </>
  )
}

export default ViewWTMSDetails