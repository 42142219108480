import { TextField, Grid, Button, Typography, IconButton, Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoginStyles } from "../../../styles/LoginStyles";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import ResetPasswordAPI from "../../../redux/actions/api/UserManagement/ResetPassword";
import { APITransport } from "../../../redux/actions";
import { setApiStatus } from "../../../redux/actions/Common";
import Logo from "../../../assets/bhashininewlogo3.png";
import '../Register/Register.css'
import ForgotResetPasswordAPI from "../../../redux/actions/api/UserManagement/ForgotResetPassword";
const ResetPassword = ({ open, handleClose }) => {
    const classes = LoginStyles();
    const { email } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const location = useLocation()
    const apiStatus = useSelector((state) => state.apiStatus)
    const tokenValue = useSelector((state) => state?.forgotPassword?.data)
    const loginToken = localStorage.getItem('token')
    const [password, setPassword] = useState({
        password: "",
    });
    const [rePassword, setRePassword] = useState({
        rePassword: ""
    });
    console.log(rePassword,"rePasswordrePassword",password)
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [repasswordVisibility, setRepasswordVisibility] = useState(false);
    const [criteria, setCriteria] = useState({
        length: false,
        lowerCase: false,
        upperCase: false,
        specialChar: false,
        numeric: false,
    });

    const validatePassword = (value,param) => {
        const length = param === "password" && value.length >= 8 && value.length <= 20
        const lowerCase = /[a-z]/.test(value);
        const upperCase =  /[A-Z]/.test(value);
        const specialChar =  /[!@#$%^&*(),.?":{}|<>]/.test(value);
        const numeric =   /[0-9]/.test(value);

        setCriteria({
            length,
            lowerCase,
            upperCase,
            specialChar,
            numeric
        });
    };

    const { check } = location.state || {};
    useEffect(() => {
        return () => {
            setPassword({
                password: "",
            });
        }
    }, []);

    useEffect(() => {
        if (apiStatus && apiStatus.apiType === "FORGOT_RESET_PASSWORD" && apiStatus.success) {
            navigate("/user/login");
            localStorage.setItem('token', tokenValue?.token);
            dispatch(setApiStatus({
                progress: true,
                errors: false,
                message: "",
                unauthorized: false,
                apiType: "",
                data: [],
                success: true,
            }))
        }
    }, [apiStatus])

    const handlePasswordChange = (value, param) => {
        setPassword((prev) => ({ ...prev, [param]: value }))
        validatePassword(value,param);
    };
    const handleRePasswordChange = (value, param) => {
        setRePassword((prev) => ({ ...prev, [param]: value }))
    };
    

    const submitPasswordChange = (e) => {
        e.preventDefault();
        // const resetPasswordAPIObj = new ResetPasswordAPI(email, password.password, password.rePassword);
        const forgotResetPasswordAPIObj = new ForgotResetPasswordAPI(email, password.password, rePassword.rePassword, tokenValue?.token || loginToken);
        // dispatch(APITransport(check === true ? forgotResetPasswordAPIObj : resetPasswordAPIObj));
        dispatch(APITransport(forgotResetPasswordAPIObj));
    };

    return (
        <>
            <Box className="OnboardingForm_register">
                <Grid container style={{ width: isMobile ? '' : '100%', height: "100%" }}>
                    <Grid item xs={12} md={6}>
                        <Box className="OnboardingForm__imgSection">
                            <Box className="OnboardingForm__imgSectionLogo">
                                <img src={Logo} alt="logo" />
                            </Box>
                            <Box className="OnboardingForm__imgSectionTextContainer">

                                <Box>

                                    <Typography variant="h5" className="OnboardingForm__imgSectionHeading" fontFamily={"Inter, sans-serif"} fontWeight={600}>
                                        Welcome to Bhashini Onboarding Portal
                                    </Typography>
                                    <Typography variant="body1" className="OnboardingForm__imgSectionText">
                                        Your portal to access Parikshan Applications
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <form onSubmit={submitPasswordChange} style={{ height: "102.2%" }}>
                            <Grid container spacing={2} className={classes.forgotPasswordCard}>
                                <Box style={{ display: "flex", height: "75%", flexDirection: "column", gap: "35px" }}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.heading}>Reset Password</Typography>
                                        <Typography className={classes.subHeading}>Please reset your password to access your account.</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Enter New Password"
                                            name="password"
                                            type={passwordVisibility ? "text" : "password"}
                                            onChange={(e) => handlePasswordChange(e.target.value, "password")}
                                            value={password["password"]}
                                            style={{ marginTop: "-15px" }}
                                            // InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setPasswordVisibility(!passwordVisibility)}
                                                        >
                                                            {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <Typography fontSize="14px" fontWeight={700} >Password must contain</Typography>
                                        <ul style={{ listStyleType: 'none', padding: 0, marginTop: '8px' }}>
                                            <li style={{ color: '#545454', padding: '4px 0px' }}>
                                                <span style={{ color: criteria.length ? '#0165FC' : '',backgroundColor:criteria.length? '#d3e4f8':"",  borderRadius: '50%', borderRadius: '50%',
                                                  display: 'inline-flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                 height: '17px',  
                                                width: '17px',    fontSize: '14px'  }}> 
                                             {criteria.length ? '✔' : <span style={{
                                                    height: '17px',
                                                    width: '17px',
                                                    backgroundColor: '#E9E9E9B2',
                                                    opacity: '70%',
                                                    display: 'inline-block',
                                                    borderRadius: '50%'
                                                }} />}</span> 8-20 characters
                                            </li>
                                            <li style={{ color: '#545454', padding: '4px 0px' }}>
                                                <span   style={{
                                                color: criteria.lowerCase && criteria.upperCase && criteria.numeric ? '#0165FC' : '',
                                                backgroundColor: criteria.lowerCase && criteria.upperCase && criteria.numeric ? '#d3e4f8' : '',
                                                borderRadius: '50%',
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '17px',  
                                                width: '17px',   
                                                fontSize: '14px' 
                                                }}>{criteria.lowerCase && criteria.upperCase && criteria.numeric ? '✔' : <span style={{
                                                    height: '17px',
                                                    width: '17px',
                                                    backgroundColor: '#E9E9E9B2',
                                                    opacity: '70%',
                                                    display: 'inline-block',
                                                    borderRadius: '50%'
                                                }} />}</span> 1 lower case, 1 upper case character and 1 number
                                            </li>
                                            <li style={{ color: '#545454', padding: '4px 0px' }}>
                                                <span style={{ color: criteria.specialChar ? '#0165FC' : '' ,backgroundColor:criteria.specialChar? '#d3e4f8':"",  borderRadius: '50%', borderRadius: '50%',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '17px',  
                                                    width: '17px',   
                                                    fontSize: '14px' }}>{criteria.specialChar ? '✔' : <span style={{
                                                    height: '17px',
                                                    width: '17px',
                                                    backgroundColor: '#E9E9E9B2',
                                                    opacity: '70%',
                                                    display: 'inline-block',
                                                    borderRadius: '50%'
                                                }} />}</span> 1 special character
                                            </li>
                                        </ul>


                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Confirm Password"
                                            name="rePassword"
                                            type={repasswordVisibility ? "text" : "password"}
                                            onChange={(e) => handleRePasswordChange(e.target.value,"rePassword" )}
                                            value={rePassword["rePassword"]}
                                            style={{ marginTop: "-15px" }}
                                            // InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setRepasswordVisibility(!repasswordVisibility)}
                                                        >
                                                            {repasswordVisibility ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            className={classes.loginButton}
                                            // onClick={submitPasswordChange}
                                            type="submit"
                                            style={{ marginTop: "-15px" }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
};

export default ResetPassword;