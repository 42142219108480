import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class UpdateProfileDetails extends API {
    constructor(formData, timeout = 2000) {
        super("PATCH", timeout, false);
        this.type = C.USER_UPDATE_PROFILE;
        this.formData = formData;
        this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.userProfileUpdate}`;
    }

    processResponse(res) {
        super.processResponse(res);
        if (res) {
            this.report = res;
        }
    }

    apiEndPoint() {
        return this.endpoint;
    }

    getBody() {
        const form = new FormData()
        form.append('json_data', JSON.stringify(this.formData))
        return form

    }

    getHeaders() {
        this.headers = {
            headers: {
                "Content-Type": 'multipart/form-data',
                "Authorization": localStorage.getItem('token')
            },
        };
        return this.headers;
    }

    getPayload() {
        return this.report
    }
}
