import constants from "../../../constants";

let initialState = {
  onboardManagerResult: {},
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.UPDATE_MANAGER_REGISTRATION:
      return {
        ...state,
        onboardManagerResult: action.payload,
      };

    case constants.UPDATE_MANAGER_REGISTRATION_EMPTY:
      return {
        ...state,
        onboardManagerResult: {},
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
