import Login from "./Login/Login";
import ResetPassword from "./ResetPassword/ResetPassword";
import { LoginStyles } from "../../styles/LoginStyles";
import CustomizedSnackbars from "../common/SnackBar";
import { useDispatch, useSelector } from "react-redux";
import bhashiniNewLogo from "../../assets/Bhashini_new_logo.png";
import { useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { setSnackBar } from "../../redux/actions/Common";
import ForgetPassword from "./ForgotPassword/ForgotPassword";
import Register from "./Register/Register";
import VerifyOtp from "./ForgotPassword/VerifyOtp";
import ViewApplicationDetails from "../UserDashboard/ViewApplicationDetails";
import ManagerDashboard from "../ManagerDashboard/ManagerDashboard";

const UserManagement = () => {
    const classes = LoginStyles();
    const snackbar = useSelector((state) => state.commonReducer.snackbar);
    const dispatch = useDispatch();
    const location = useLocation();
    const param = useParams ();

    const renderSnackBar = useCallback(() => {
        return (
            <CustomizedSnackbars
                open={snackbar.open}
                handleClose={() =>
                    dispatch(setSnackBar({ open: false, message: "", variant: "" }))
                }
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                variant={snackbar.variant}
                message={[snackbar.message]}
            />
        );

        //eslint-disable-next-line
    }, [snackbar]);

    const renderComponent = () => {
        console.log(param);
        switch (param && param.page) {
            case "login":
                return <Login />;
            case "register":
                return <Register />;
            case "reset-password":
                return <ResetPassword />;
            case "forgot-password":
                return <ForgetPassword />;
            case "verify-otp":
                return <VerifyOtp />;
            // case "view":
            //     return <ViewApplicationDetails />;
            // case "manager-dashboard":
            //     return <ManagerDashboard />;
            default:
                return <Login />;
        }
    };

    return <div className={classes.root}>
        {renderSnackBar()}
       {/* {location.pathname === '/user/forgot-password' || /^\/user\/reset-password\/[^/]+$/.test(location.pathname)  ?  <img
            src={bhashiniNewLogo}
            alt="Bhashini Logo"
            className={classes.bhashiniLogoImg}
        /> : ''} */}
        {renderComponent()}
    </div>
};

export default UserManagement;