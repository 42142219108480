
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchRegisteredExpertdetailsAPI extends API {
  constructor(email,timeout = 2000) {
    super("POST", timeout, false); 
    this.type = C.FETCH_REGISTERED_EXPERT_DETAILS;
    this.email = email;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.registeredExpertdetails}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.customer = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {
        linguistics_email: this.email
    };
  }

  getHeaders() {
    const authorization = localStorage.getItem("token");
  
    this.headers = {
        headers: {
            "Content-Type": "application/json",
            Authorization: authorization,
        },
    };
    return this.headers;
}

  getPayload() {
    return this.customer;
  }
}
