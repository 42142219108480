// GetUserDetails

import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class GetChecklistDataApi extends API {
  constructor(timeout = 2000) {
    super("GET", timeout, false);
    this.type = C.GET_CHECKLIST_DETAILS
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.getCheckListDetails}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
      this.report = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {

    return {};
  }

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.report
  }
}
