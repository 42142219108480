import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import UserManagement from "./components/UserManagement";
import Layout from "./Layout";
import RoleBasedDashboard from "./components/RoleBasedDashboard";
import UpdateProfile from "./components/UserManagement/UpdateProfile/UpdateProfile";
import NotFoundPage from "./components/NotFound/NotFoundPage";
import PrivateLayout from "./Layout/PrivateLayout";
import ReferenceApplications from "./components/ReferenceApplications/ReferenceApplications";
import { RequestActionDialog } from "./components/RequestActionDialog/RequestActionDialog";
import SahyogiDashboard from "./components/SahyogiDashboard/SahyogiDashboard";
import SahyogiUserView from "./components/SahyogiDashboard/SahyogiUserView";
import UpdatePersonalDetails from "./components/UserManagement/UpdateProfile/UpdatePersonalDetails";
import UserProfile from "./components/UserProfile/UserProfile";
import UserDashboard from "./components/UserDashboard/UserDashboard";
import ManagerDashboard from "./components/ManagerDashboard/ManagerDashboard";
import CeoDashboard from "./components/CeoDashboard/CeoDashboard";
import CeoUserManagement from "./components/CeoUserManagement/CeoUserManagement";
import { CeoRequestAction } from "./components/CeoRequestAction/CeoRequestAction";
import ViewApplicationDetails from "./components/UserDashboard/ViewApplicationDetails";
import MyProfile from "./components/UserProfile/MyProfile";
import CeoPlugin from "./components/PluginDashboard/CeoPlugin";
import {PluginRequestAction} from "./components/PluginDashboard/pluginRequestAction";
import RequestAction from "./components/CeoUserManagement/CustomerRequestAction";
import RegisteredExpert from "./components/LinguisticExpert/LinguisticExpert";
import {RegisteredExpertAction} from "./components/LinguisticExpert/LinguisticExpertAction";

const App = () => {

  function authenticateUser(allowedRoles) {
    // Check if user exists in local storage
    const user = localStorage.getItem('user');

    // Check if token is present in local storage
    const token = localStorage.getItem('token');

    // Check if user role matches one of the allowed roles
    const userRole = localStorage.getItem('role');
    const isRoleAllowed = allowedRoles.includes(userRole);

    // If user, token, and user role exist in local storage and user role is allowed
    if (token && isRoleAllowed) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Router basename="/">
      <Routes>
        {/* <Route path="/" element={<UserManagement />} /> */}
        <Route path="/user/:page" element={<UserManagement />} />
        <Route path="/user/:page/:email" element={<UserManagement />} />
        <Route path="*" element={<NotFoundPage />} />
        {/* 
          ---------------------------------------
          ------------- PRIVATE ROUTES ----------
          ---------------------------------------
        */}
        {/* update-profile */}
        {/* <Route path="/update-profile" element={<PrivateLayout path="/update-profile" component={<UpdateProfile />} />} /> */}
        <Route path="/update-profile" element={<PrivateLayout path="/update-profile" component={<UpdatePersonalDetails />} />} />
        {/* <Route path="/my-profile" element={<PrivateLayout path="/my-profile" component={<UserProfile />} />} /> */}
        <Route path="/my-profile" element={<PrivateLayout path="/my-profile" component={<MyProfile />} />} />
        <Route path="/profile" element={<PrivateLayout path="/profile" component={<UserProfile />} />} />


        <Route path="/view" element={<PrivateLayout path="/view" component={<ViewApplicationDetails />} />} />

        {/* dashboard */}
        <Route path="/dashboard" element={<PrivateLayout path="/dashboard" component={<RoleBasedDashboard />} />} />
        <Route path="/user-dashboard" element={<PrivateLayout path="/user-dashboard" component={<UserDashboard />} />} />

        {/* requests */}
        <Route path="/requests" element={<PrivateLayout path="/requests" component={<RoleBasedDashboard />} />} />

         {/* Sahyogi Requests */}
         <Route path="/sahyogi-requests" element={<PrivateLayout path="/sahyogi-requests" component={<SahyogiDashboard />} />} />

         
         <Route path="/sahyogi-requests/:id" element={<PrivateLayout path="/sahyogi-requests/:id" component={<SahyogiUserView />} />} />

        {/* request-action */}
        <Route path="/request-action" element={<PrivateLayout path="/request-action" component={<RequestActionDialog />} />} />

        {/* applications */}
        <Route path="/applications" element={<PrivateLayout path="/applications" component={<ReferenceApplications />} />} />
        <Route path="/manager-dashboard" element={<PrivateLayout path="/manager-dashboard" component={<ManagerDashboard />} />} />
        <Route path="/ceo-dashboard" element={<PrivateLayout path="/ceo-dashboard" component={<CeoDashboard />} />} />
        <Route path="/user-management/:tabId" element={<PrivateLayout path="/user-management/:tabId" component={<CeoUserManagement />} />} />
        <Route path="/user-management" element={<PrivateLayout path="/user-management" component={<CeoUserManagement />} />} />
        <Route path="/ceo-request-action" element={<PrivateLayout path="/ceo-request-action" component={<CeoRequestAction />} />} />
        <Route path="/plugin-request" element={<PrivateLayout path="/plugin-request" component={<CeoPlugin />} />} />
        <Route path="/plugin-request-action" element={<PrivateLayout path="/plugin-request-action" component={<PluginRequestAction />} />} />
        <Route path="/customer-request-action" element={<PrivateLayout path="/ customer-request-action" component={<RequestAction />} />} />
        <Route path="/linguistic-expert" element={<PrivateLayout path="/linguistic-expert" component={<RegisteredExpert />} />} />
        <Route path="/linguistic-expert-action" element={<PrivateLayout path="/linguistic-expert-action" component={<RegisteredExpertAction/>} />} />


        {/* Add more private routes here */}
        {/* 
          ---------------------------------------
          ------------- PRIVATE ROUTES ----------
          ---------------------------------------
        */}
      </Routes>
    </Router>
  );
};

export default App;
