// App.js

import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  IconButton,
  Avatar,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CeoDashboard from "../CeoDashboard/CeoDashboard";
const ManagerDashboard = () => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(0);
  const [profile, setProfile] = useState({
    firstName: "Abhilasha",
    lastName: "Singh",
    gender: "Female",
    dateOfJoining: '12-03-2020',
    phoneNumber: "976547876",
    dateOfBirth: "17-09-1990",
    designation: "Senior Consultant",
    primaryEmail: "abhilashasingh1234@gmail.com",
    alternateEmail: "ajay.singh10@gmail.com",
    aadhar: "5485 5000 8600",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleChangeProfile = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
    <CeoDashboard/>
    </>
    // <Box sx={{ backgroundColor:"#ffffff", width:"100%", height:"100%" }}>
      
     
    //     <Card sx={{ mt: 2 }}>
    //       <CardContent>
    //       <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
    //         <Box sx={{ textAlign: "start", mb: 2, display:"flex", gap:"20px", marginBottom:"30px", alignItems:"center" }}>
    //           <Avatar
    //             sx={{ width: 80, height: 80, mx: "auto", mb: 1 }}
    //             alt="Profile Picture"
    //             src=""
    //           />
    //           <Box>
    //           <Typography fontFamily={"Inter, sans-serif"} variant="h3" fontWeight={"600"}>Abhilasha Singh</Typography>
    //           <Typography fontFamily={"Inter, sans-serif"}  fontWeight={"600"} variant="body2" color="textSecondary">
    //             {profile.primaryEmail}
    //           </Typography>
    //           <Typography fontFamily={"Inter, sans-serif"}  fontWeight={"600"} variant="body2" color="textSecondary">
    //            On-Boarding Manager
    //           </Typography>
    //           </Box>
    //         </Box>

    //         <Box sx={{ textAlign: "center", mb: 2}}>

    //         <Button
    //               variant="contained"
    //               color="primary"
    //               onClick={handleEditToggle}
    //               startIcon={<EditIcon />}
    //               style={{marginRight:"15px",borderRadius:"4px" }}
    //             >
    //               Edit
    //             </Button>
              
               
             
                
           
    //         </Box>
    //         </Box>

    //         <Grid container spacing={2}>
    //           <Grid item xs={12} sm={12}>
    //           <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Phone Number</Typography>
    //             <TextField
    //               fullWidth
    //               placeholder="Phone Number"
    //               name="firstName"
    //               value={profile.firstName}
    //               onChange={handleChangeProfile}
    //               disabled={!editMode}
    //               variant="outlined"
    //             />
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //           <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Date of Birth</Typography>
    //             <TextField
    //               fullWidth
    //               placeholder="Date of Birth"
    //               name="dateOfBirth"
    //               value={profile.dateOfBirth}
    //               onChange={handleChangeProfile}
    //               disabled={!editMode}
    //               variant="outlined"
    //             />
    //           </Grid>
    //           <Grid item xs={12} sm={6}>
    //           <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Date of Joining</Typography>
    //             <TextField
    //               fullWidth
    //               placeholder="Date of Joining"
    //               name="dateOfJoining"
    //               value={profile.dateOfJoining}
    //               onChange={handleChangeProfile}
    //               disabled={!editMode}
    //               variant="outlined"
    //             />
    //           </Grid>
    //           <Grid item xs={12} sm={12}>
    //           <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Personal Email ID</Typography>
    //             <TextField
    //               fullWidth
    //               placeholder="Personal Email ID"
    //               name="alternateEmail"
    //               value={profile.primaryEmail}
    //               onChange={handleChangeProfile}
    //               disabled={!editMode}
    //               variant="outlined"
    //             />
    //           </Grid>
             
             

           
    //         </Grid>
    //       </CardContent>
    //     </Card>
    

      
    // </Box>
  );
};

export default ManagerDashboard;
