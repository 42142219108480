// App.js

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  IconButton,
  Avatar,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { APITransport } from "../../redux/actions";
import fetchLoggedinUserDetailsApi from "../../redux/actions/api/ReferenceApplications/fetchLoggedinUserDetails";
import { useDispatch, useSelector } from "react-redux";
import UserProfileSubmitAPI from "../../redux/actions/api/UserManagement/UserProfileSubmit";
import EditUserProfileSubmitApi from "../../redux/actions/api/UserManagement/EditUserProfileSubmit";
import fetchOrganisationNamesAPI from "../../redux/actions/api/UserManagement/fetchOrganisationNames";
import { setSnackBar } from "../../redux/actions/Common";

const UserProfile = () => {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(0);
  const dispatch = useDispatch()
  const userDetails = useSelector((state) => state.fetchLoggedInUserDetails.data);
  const orgDetails = useSelector(state => state.fetchOrganisationNames.data);
  console.log(userDetails?.first_name,"use");
  const [disableEdit, setDisableEdit] = useState(false)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const FetchingAplicationNumbers = () => {
    const reqObj = new fetchLoggedinUserDetailsApi();
    dispatch(APITransport(reqObj));
  };

  const getOrganisationTypes = () => {
    const reqObj = new fetchOrganisationNamesAPI();
    dispatch(APITransport(reqObj));
}

  useEffect(() => {
    FetchingAplicationNumbers()
    getOrganisationTypes()
  }, []);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf" && file.size <= 5 * 1024 * 1024) {
      setSelectedFile(file);
    } else {
      console.error("Invalid file. Please upload a valid PDF file (max 5MB).");
    }
  };
  const [emailError, setEmailError] = useState("");
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    phoneNumber: '',
    dateOfBirth: '',
    designation: '',
    primaryEmail: '',
    alternateEmail: '',
    aadhar: '',
    doc_type: '',
  });

  const [organization, setOrganization] = useState({
    organizationType: "",
    organizationName: "",
    department: "",
    organizationWebsite: "",
    industry: "",
    gstNumber: "",
  });

  const handleOrganisationType = (event) => {
    setOrganization((prevFormData) => ({
      ...prevFormData, // Preserve other state properties if needed
      organizationType: event.target.value,
    }));
  };

  useEffect(() => {
    if (userDetails) {
      setProfile({
        firstName: userDetails.first_name || '',
        lastName: userDetails.last_name || '',
        gender: userDetails.gender || '',
        phoneNumber: userDetails.phone || '',
        dateOfBirth: userDetails.dob || '',
        designation: userDetails.designation || '',
        primaryEmail: userDetails.email_id || '',
        alternateEmail: userDetails.personal_email_id || '',
        aadhar:userDetails?.verification?.proof ||'',
        doc_type: userDetails?.verification?.type || '', 
      });
    }
  }, [userDetails]); 

  useEffect(() => {
    if (userDetails) {
      setOrganization({
        organizationType: userDetails.org_type || '',
        organizationName: userDetails?.org_info?.org_name || '',
        department: userDetails?.org_info?.dpt_name || '',
        organizationWebsite: userDetails.org_info?.org_site || '',
        industry: userDetails?.org_info?.industry || '',
        gstNumber: userDetails?.org_info?.gstn || '',
      });
    }
  }, [userDetails]);

 



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
    setDisableEdit(true)
  };

  const handleChangeProfile = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleOrganizationInputChange = (e) => {
    const { name, value } = e.target;
    setOrganization((prevOrganization) => ({
      ...prevOrganization,
      [name]: value,
    }));
  };

  const handleSave = () => {
    setEditMode(false);
    const postData = {
      first_name: profile?.firstName,
      middle_name: '',
      last_name: profile?.lastName,
      gender: profile?.gender,
      personal_email: profile?.alternateEmail,
      designation:profile?.designation,
      dob: profile?.dateOfBirth,
      phone_number: profile?.phoneNumber,
      role: 'customer',
      organization_type: organization?.organizationType,
      government_id: profile?.aadhar,
      government_doc_type: '',
      gst_number: organization?.gstNumber,
      organisation_name: organization?.organizationName,
      department_name: organization?.department,
      organisation_website: organization?.organizationWebsite,
      industry: organization?.industry,
      account_type: '',
    };

    const officialEmail = userDetails.email_id?.trim().toLowerCase();
    const personalEmail = profile?.alternateEmail?.trim().toLowerCase();
  

    const areEmailsSame = officialEmail && personalEmail && ((officialEmail === personalEmail) || (personalEmail.toLowerCase().startsWith(officialEmail.toLowerCase()) ||
    officialEmail.toLowerCase().startsWith(personalEmail.toLowerCase())));

    if (areEmailsSame) {
      setProfile({
        ...profile,
        alternateEmail: userDetails.personal_email_id,
      });
      dispatch(setSnackBar({
        open: true,
        message: 'Official Email Id and Personal Email Id cannot be the same',
        variant: "error"
      }));
      return; 
    }

    if(!emailRegex.test(profile.alternateEmail)){
      setProfile({
        ...profile,
        alternateEmail: userDetails.personal_email_id,
      });
      dispatch(setSnackBar({
        open: true,
        message: ' email format is not correct',
        variant: "error"
      }));
      return; 
    }

    // console.log("Posting data:", postData);
    // console.log("SelectedFile:", selectedFile);

    const reqObj = new EditUserProfileSubmitApi(selectedFile, postData);
    dispatch(APITransport(reqObj));
    // Capture current data here
    console.log("Saved data:", profile, organization);

    // Optionally, perform additional actions such as API calls here
  };

  
  useEffect(() => {
    const officialEmail = userDetails.email_id;
    const personalEmail = profile?.alternateEmail;
    if (personalEmail && officialEmail &&  (personalEmail.toLowerCase().startsWith(officialEmail.toLowerCase()) ||
    officialEmail.toLowerCase().startsWith(personalEmail.toLowerCase()))) {
     setEmailError("Official Email Id and Personal Email Id cannot be the same");
    } else {
      setEmailError("");
    }
  }, [profile]);

  return (
    <Box sx={{ backgroundColor:"white"}}>
      <Tabs value={value} onChange={handleChange} start style={{marginLeft:"3rem", borderBottom:"1px solid #EFEFEF",fontFamily:"Inter, sans-serif", fontWeight:"500"}}>
        <Tab label="Personal Details" style={{fontFamily:"Inter, sans-serif", fontWeight:"500"}}/>
        <Tab label="Organization Details" style={{fontFamily:"Inter, sans-serif", fontWeight:"500"}}/>
      </Tabs>

      {value === 0 && (
        <Card sx={{ margin:"10px 30px" }}>
          <CardContent>
            <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <Box sx={{ textAlign: "start", mb: 2, display:"flex", gap:"20px", marginBottom:"30px", alignItems:"center" }}>
              <Avatar
                sx={{ width: 80, height: 80, mx: "auto", mb: 1 }}
                alt="Profile Picture"
                src=""
              />
              <Box>
              <Typography>{profile?.firstName}&nbsp;{profile?.lastName}</Typography>
              <Typography variant="body2" color="textSecondary">
                {profile.primaryEmail}
              </Typography>
              </Box>
            </Box>

            <Box sx={{ textAlign: "center", mb: 2}}>

              {!editMode ? 

            <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEditToggle}
                  startIcon={<EditIcon />}
                  style={{marginRight:"15px",borderRadius:"4px" }}
                >
                  Edit
                </Button>
              :
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  startIcon={<SaveIcon />}
                  style={{borderRadius:"4px"}}
                >
                  Save
                </Button>
             
              }
           
            </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>First Name</Typography>
                <TextField
                  fullWidth
                  placeholder="First Name"
                  name="firstName"
                  value={profile.firstName}
                  onChange={handleChangeProfile}
                  disabled={!editMode}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Last Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Last Name"
                  name="lastName"
                  value={profile.lastName}
                  onChange={handleChangeProfile}
                  disabled={!editMode}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Gender</Typography>
                <TextField
                  fullWidth
                  placeholder="Gender"
                  name="gender"
                  value={profile.gender}
                  onChange={handleChangeProfile}
                  disabled={!editMode}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Phone Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={profile.phoneNumber}
                  onChange={handleChangeProfile}
                  disabled={!editMode}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Date of Birth</Typography>
                <TextField
                  fullWidth
                  placeholder="Date of Birth"
                  name="dateOfBirth"
                  type="date"
                  value={profile.dateOfBirth}
                  onChange={handleChangeProfile}
                  disabled={!editMode}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Designation</Typography>
                <TextField
                  fullWidth
                  placeholder="Designation"
                  name="designation"
                  value={profile.designation}
                  onChange={handleChangeProfile}
                  disabled={!editMode}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Official Email ID</Typography>
                <TextField
                  fullWidth
                  placeholder="Official Email ID"
                  name="primaryEmail"
                  value={profile.primaryEmail}
                  onChange={handleChangeProfile}
                  disabled={!editMode || disableEdit}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Personal Email ID</Typography>
                <TextField
                  fullWidth
                  placeholder="Personal Email ID"
                  name="alternateEmail"
                  value={profile.alternateEmail}
                  onChange={handleChangeProfile}
                  disabled={!editMode}
                  variant="outlined"
                />
                    {emailError && <span className="error">{emailError}</span>}
              </Grid>

              <Grid item xs={12}>
               {editMode &&
                <Box
                  sx={{
                    border: "1px dashed grey",
                    p: 2,
                    textAlign: "center",
                    mb: 2,
                    mt:3,
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    Upload your {profile.doc_type.toUpperCase()}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                  >
                    Browse files
                    <input
                      type="file"
                      hidden
                      disabled={!editMode}
                      accept=".pdf"
                      onChange={handleFileChange}
                    />
                  </Button>
                  <div style={{ display: "flex", flexWrap: "wrap", justifyContent:"center" }}>
              {selectedFile && (
                <div>
                  <p>Selected file: {selectedFile.name}</p>
                  <embed
                    src={URL.createObjectURL(selectedFile)}
                    width="150"
                    height="100"
                    type="application/pdf"
                  />
                </div>
              )}
            </div>
                </Box>}
                <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>{profile.doc_type.toUpperCase()}</Typography>
                <TextField
                  fullWidth
                  placeholder={profile.doc_type.toUpperCase()}
                  name="aadhar"
                  value={profile.aadhar}
                  onChange={handleChangeProfile}
                  disabled={!editMode}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">🔒</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {value === 1 && (
         <Card sx={{ margin:"10px 30px" }}>
         <CardContent>
           <Box style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
           <Box sx={{ textAlign: "start", mb: 2, display:"flex", gap:"20px", marginBottom:"30px", alignItems:"center" }}>
             <Avatar
               sx={{ width: 80, height: 80, mx: "auto", mb: 1 }}
               alt="Profile Picture"
               src=""
             />
             <Box>
             <Typography>{profile?.firstName}&nbsp;{profile?.lastName}</Typography>
             <Typography variant="body2" color="textSecondary">
               {profile.primaryEmail}
             </Typography>
             </Box>
           </Box>

           <Box sx={{ textAlign: "center", mb: 2}}>

           {!editMode ? 
           <Button
                 variant="contained"
                 color="primary"
                 onClick={handleEditToggle}
                 startIcon={<EditIcon />}
                 style={{marginRight:"15px",borderRadius:"4px" }}
               >
                 Edit
               </Button>
             :
               <Button
                 variant="contained"
                 color="primary"
                 onClick={handleSave}
                 startIcon={<SaveIcon />}
                 style={{borderRadius:"4px"}}
               >
                 Save
               </Button>
            }
               
          
           </Box>
           </Box>
           <Grid container spacing={2}>
             <Grid item xs={12} sm={12}>
             <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Organisation type</Typography>
               {/* <TextField
                 fullWidth
                 placeholder="Organisation type"
                 name="organizationType"
                 value={organization.organizationType}
                 onChange={handleOrganizationInputChange}
                 disabled={!editMode}
                 variant="outlined"
               /> */}
                <FormControl fullWidth variant="outlined">
        <Select
          labelId="role-select-label"
          id="role-select"
          name="organizationType"
          value={organization.organizationType}
          onChange={handleOrganisationType}
          label="Organisation Type"
          disabled={!editMode}
        >
          {orgDetails.map(data => 
          <MenuItem value={data?.name}>{data?.description}</MenuItem>

          )}

        </Select>
      </FormControl>
             </Grid>
             <Grid item xs={12} sm={6}>
             <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Organisation Name</Typography>
               <TextField
                 fullWidth
                 placeholder="Organisation Name"
                 name="organizationName"
                 value={organization.organizationName}
                 onChange={handleOrganizationInputChange}
                 disabled={!editMode}
                 variant="outlined"
               />
             </Grid>
             <Grid item xs={12} sm={6}>
             <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Department (if applicable)</Typography>
               <TextField
                 fullWidth
                 placeholder="Department (if applicable)"
                 name="department"
                 value={organization.department}
                 onChange={handleOrganizationInputChange}
                 disabled={!editMode}
                 variant="outlined"
               />
             </Grid>
             <Grid item xs={12} sm={12}>
             <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Organisation Website</Typography>
               <TextField
                 fullWidth
                 placeholder="Organisation Website"
                 name="organizationWebsite"
                 value={organization.organizationWebsite}
                 onChange={handleOrganizationInputChange}
                 disabled={!editMode}
                 variant="outlined"
               />
             </Grid>
             <Grid item xs={12} sm={6}>
             <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>Industry</Typography>
               <TextField
                 fullWidth
                 placeholder="Industry"
                 name="industry"
                 value={organization.industry}
                 onChange={handleOrganizationInputChange}
                 disabled={!editMode}
                 variant="outlined"
               />
             </Grid>
             <Grid item xs={12} sm={6} style={{paddingBottom:"2.2rem"}}>
             <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"500",marginBottom:"5px"}}>GST Number</Typography>
               <TextField
                 fullWidth
                 placeholder="GST Number"
                 name="gstNumber"
                 value={organization.gstNumber}
                 onChange={handleOrganizationInputChange}
                 disabled={!editMode}
                 variant="outlined"
               />
             </Grid>
            
           </Grid>
         </CardContent>
       </Card>
      )}
    </Box>
  );
};

export default UserProfile;
