import C from "../constants";

const initialState = {
    snackbar: {
        open: false,
        message: "",
        variant: "success",
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case C.SNACKBAR: {
            let result = { ...state };
            result.snackbar = { ...action.payload };
            return result;
        }

        default:
            return {
                ...state,
            };
    }
};

export default reducer;