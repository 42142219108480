import C from "../../../constants";

const initialState = {
    data: [],
}



const fetchCeoDashboardRequests = (state = initialState, action) => {
    switch (action.type) {
        case C.FETCH_CEO_DASHBOARD:
           
            return {
                ...state,
                data : action.payload
            }
        default:
            return {
                ...state
            }
    }
};

export default fetchCeoDashboardRequests;