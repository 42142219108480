import C from "../../constants";

const initialState = {
    filterData: {
        ApiKeys: "",
        hostName: "",
        allRequest: "",
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case C.PLUGINREQUEST_FILTER: {
            let result = { ...state };
            result.filterData
             = { ...action.payload };
            return result;
        }

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
