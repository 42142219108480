import C from "../constants";

export const setSnackBar = (data) => {
    return {
      type: C.SNACKBAR,
      payload: data,
    }
}

export const setApiStatus = (data) => {
  return {
    type: C.APISTATUS,
    payload: data
  }
}

export const setUpdateManagerRegistration = (data) => {
  return {
    type: C.UPDATE_MANAGER_REGISTRATION_EMPTY,
    payload: {}
  }
}

export const setOnboardingFilter = (data) => {
  return {
    type: C.ONBOARDING_FILTER,
    payload: data
  }
}

export const setpluginRequestFilter = (data) => {
  return {
    type: C.PLUGINREQUEST_FILTER,
    payload: data
  }
}
export const setSahyogiRequestFilter = (data) => {
  return {
    type: C.SAHYOGIREQUEST_FILTER,
    payload: data
  }
}
export const setManagerUserManagementFilter = (data) => {
  return {
    type: C.MANAGER_FILTER,
    payload: data
  }
}
export const setCustomerUserManagementFilter = (data) => {
  return {
    type: C.CUSTOMER_FILTER,
    payload: data
  }
}
export const setSearchTerm = (text) => ({
  type: C.SET_SEARCH_TERM,
  payload: text,
});

export const setRegisteredExpertFilter = (data) => {
  return {
    type: C.REGISTERED_EXPERT_FILTER,
    payload: data
  }
}
