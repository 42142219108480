import React, { useCallback } from "react";
import { LayoutStyles } from "./styles/LayoutStyles";
import Header from "./components/common/Header";
import { useSelector, useDispatch } from "react-redux";
import { setSnackBar } from "./redux/actions/Common";
import CustomizedSnackbars from "./components/common/SnackBar";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const classes = LayoutStyles();
  const snackbar = useSelector((state) => state.commonReducer.snackbar);
  
  const renderSnackBar = useCallback(() => {
    return (
      <CustomizedSnackbars
        open={snackbar.open}
        handleClose={() =>
          dispatch(setSnackBar({ open: false, message: "", variant: "" }))
        }
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        variant={snackbar.variant}
        message={[snackbar.message]}
      />
    );

    //eslint-disable-next-line
  }, [snackbar]);

  return <div className={classes.root}>
    <Header />
    {renderSnackBar()}
    <div style={{ marginTop: '7vh' }}>
      {children}
    </div>
  </div>;
};

export default Layout;
