import {Box, Button, TextField, Typography} from "@mui/material";
import {DashboardStyles} from "../../styles/dashboardStyles";
import {useState} from "react";

const RejectModal = (props) => {
  const email = localStorage.getItem("email") ?? "";
  const username = localStorage.getItem("username")??"";

  const classes = DashboardStyles();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleCloseButton = () => {
    setTitle("");
    setDescription("");
    props.handleClose();
  };

  const handleSubmit = () => {
    if (title && description) {
      const appStatusUpdate = {
        status: "Rejected",
        updated_by: email,
        updater_name: username,
        remarks_title: title,
        remarks_description: description,
      };
      props.handleClose();
      props.handleSave(appStatusUpdate);
    }
  };

  return (
    <Box>
      <Typography
        color="#212121"
        fontFamily={"Inter, sans-serif"}
        style={{fontWeight: "bold"}}
      >
        Please provide the reason for rejecting the request
      </Typography>
      <TextField
        fullWidth
        placeholder="Title"
        name="title"
        variant="outlined"
        label="Title"
        sx={{mt: 3, mb: 3}}
        onChange={(e) => setTitle(e.target.value)}
      />

      <TextField
        fullWidth
        placeholder="Description"
        name="description"
        variant="outlined"
        label="Description"
        multiline
        rows={4}
        onChange={(e) => setDescription(e.target.value)}
      />

      <Box
        sx={{display: "flex", justifyContent: "flex-end", mt: 2, gap: "1rem"}}
      >
        <Button
          variant="outlined"
          color="primary"
          className={classes.cancelBtn}
          onClick={handleCloseButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{borderRadius: "0.25rem", marginRight: "0!important"}}
          className={classes.bulkApproveBtn}
          disabled={!title || !description}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default RejectModal;
