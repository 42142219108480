import { Box, Button, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './FormComponent.css'
const UserRole = ({handleSubmit,selectedRole,handleRoleSelect,handleForm0ButtonClick}) => {
  return (
    <div className="FormComponent__section" style={{marginTop:"3rem"}} >
      <form onSubmit={handleSubmit}>
        <div className="formContainer">
          <div
            className="FormComponent__Heading"
            style={{
              marginBottom: "0px",
              fontFamily: "Inter, sans-serif",
              fontWeight: "600",
              marginTop: "3rem",
            }}
          >
           Tell us who you are..
          </div>

          <Typography variant="body2">
          Please select your role to begin the registration process. 
          </Typography>
          <Box display="flex" flexDirection="column" gap={2} mt={3}>
        <Card
          variant="outlined"
          sx={{
            border: selectedRole === "customer" ? "2px solid #1976d2" : "1px solid #ccc",
            backgroundColor: selectedRole === "customer" ? '#ECF4FF' : '',
            borderRadius: 1,
          }}
        >
          <CardActionArea onClick={() => handleRoleSelect("customer")}>
            <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="body1">Customer</Typography>
              <ArrowForwardIosIcon />
            </CardContent>
          </CardActionArea>
        </Card>

        <Card
          variant="outlined"
          sx={{
            border: selectedRole === "employee" ? "2px solid #1976d2" : "1px solid #ccc",
            backgroundColor: selectedRole === "employee" ? '#ECF4FF' : '',
            borderRadius: 1,
          }}
        >
          <CardActionArea onClick={() => handleRoleSelect("employee")}>
            <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography variant="body1">Employee</Typography>
              <ArrowForwardIosIcon />
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      {/* <Box mt={4} display="flex" justifyContent="flex-end" width="60%"> */}
      <div className='FormComponent__divBtnBox' style={{marginTop:"2rem"}}>
            <button className="FormComponent__divBtn" type="submit"  onClick={handleForm0ButtonClick}>
            Next
            </button>
        </div>
      {/* </Box> */}
        </div>
      
        </form>
        </div>
  )
}

export default UserRole