const constants = {
    APISTATUS: "APISTATUS",
    GET_USER_ACCESS_TOKEN: 'GET_USER_ACCESS_TOKEN',
    REMOVE_USER_ACCESS_TOKEN: 'REMOVE_USER_ACCESS_TOKEN',
    FETCH_ONBOARD_USERS: "FETCH_ONBOARD_USERS",
    UPDATE_ONBOARD_USER: 'UPDATE_ONBOARD_USER',
    FETCH_ONBOARD_MANAGER: "FETCH_ONBOARD_MANAGER",
    ASSIGN_ONBOARD_MANAGER: "ASSIGN_ONBOARD_MANAGER", 
    SNACKBAR: "SNACKBAR",
    RESET_PASSWORD: "RESET_PASSWORD",
    FORGOT_RESET_PASSWORD: "FORGOT_RESET_PASSWORD",
    FORGET_PASSWORD: "FORGOT_PASSWORD",
    USER_PROFILE_SUBMIT: "USER_PROFILE_SUBMIT",
    EDIT_USER_PROFILE_SUBMIT: "EDIT_USER_PROFILE_SUBMIT",
    FETCH_ORGANISATION_NAME: "FETCH_ORGANISATION_NAME",
    FETCH_REFERENCE_APPLICATION_STATUS: "FETCH_REFERENCE_APPLICATION_STATUS",
    FETCH_REFERENCE_APPLICATION: "FETCH_REFERENCE_APPLICATION",
    FETCH_PLUGIN_ULCA_KEYS : "FETCH_PLUGIN_ULCA_KEYS",
    FETCH_PLUGIN_ULCA_KEYS_DATA : "FETCH_PLUGIN_ULCA_KEYS_DATA",
    FETCH_LOGGEDIN_USER_DETAILS: "FETCH_LOGGEDIN_USER_DETAILS",
    FETCH_APP_NUMBERS: "FETCH_APP_NUMBERS",
    RESUBMIT_APPLICATION: "RESUBMIT_APPLICATION",
    REQUEST_FOR_REFERENCE_APPLICATION: "REQUEST_FOR_REFERENCE_APPLICATION",
    ADD_PLUGIN_DATA: "ADD_PLUGIN_DATA",
    EDIT_PLUGIN_DATA: "EDIT_PLUGIN_DATA",
    DELETE_PLUGIN_DATA: "DELETE_PLUGIN_DATA",
    REVOKE_REFERENCE_APPLICATION: "REVOKE_REFERENCE_APPLICATION",
    GET_BASIC_USER_DETAILS: "GET_BASIC_USER_DETAILS",
    GET_APPLICATION_DETAILS: "GET_APPLICATION_DETAILS",
    GET_ADVANCE_USER_DETAILS: "GET_ADVANCE_USER_DETAILS",
    FETCH_ONBOARDING_REQUESTS: "FETCH_ONBOARDING_REQUESTS",
    FETCH_SAHYOGI_REQUESTS: "FETCH_SAHYOGI_REQUESTS",
    SAHYOGI_DOWNLOAD_FILE: "SAHYOGI_DOWNLOAD_FILE",
    UPDATE_ONBOARDING_REQUEST_STATUS: "UPDATE_ONBOARDING_REQUEST_STATUS",
    LOGOUT: "LOGOUT",
    FETCH_CEO_DASHBOARD :"FETCH_CEO_DASHBOARD",
    FETCH_CUSTOMER_OVERVIEW:"FETCH_CUSTOMER_OVERVIEW",
    GET_APP_REQUEST_DETAILS: 'GET_APP_REQUEST_DETAILS',
    FETCH_MANAGER_REQUESTS: 'FETCH_MANAGER_REQUESTS',
    FETCH_CUSTOMER_REQUESTS: 'FETCH_CUSTOMER_REQUESTS',
    UPDATE_MANAGER_REGISTRATION: 'UPDATE_MANAGER_REGISTRATION',
    UPDATE_MANAGER_REGISTRATION_EMPTY: 'UPDATE_MANAGER_REGISTRATION_EMPTY',
    FETCH_CEO_SAHYOGIREQUEST:"FETCH_CEO_SAHYOGIREQUEST",
    FETCH_SAHYOGI_SINGLE_USER:"FETCH_SAHYOGI_SINGLE_USER",
    FETCH_EMPLOYEE_REGISTRATION:"FETCH_EMPLOYEE_REGISTRATION",
    FETCH_SAHYOGI_OVERVIEW:"FETCH_SAHYOGI_OVERVIEW",
    FETCH_EMPLOYEE_INVITEMANAGER:"FETCH_EMPLOYEE_INVITEMANAGER",
    UPDATE_INVITE_MANAGER:"UPDATE_INVITE_MANAGER",
    GET_USER_DETAILS: "GET_USER_DETAILS",
    GET_CHECKLIST_DETAILS: "GET_CHECKLIST_DETAILS",
    USER_UPDATE_PROFILE: "USER_UPDATE_PROFILE",
    FETCH_ALL_APPLICATION: "FETCH_ALL_APPLICATION",
    VIEW_PDF:"VIEW_PDF",
    VERIFY_MANAGER_REGISTRATION:"VERIFY_MANAGER_REGISTRATION",
    FETCH_TRANSLATION_PLUGIN_REQUESTS:"FETCH_TRANSLATION_PLUGIN_REQUESTS",
    FETCH_TRANSLATION_PLUGIN_REQUESTS_DETAILS:"FETCH_TRANSLATION_PLUGIN_REQUESTS_DETAILS",
    UPDATE_TRANSLATION_PLUGIN_STATUS:"UPDATE_TRANSLATION_PLUGIN_STATUS",
    REVOKE_MANAGER:"REVOKE_MANAGER",
    CUSTOMER_COMPLETE_DETAILS:"CUSTOMER_COMPLETE_DETAILS",
    FETCH_MANAGER_LIST:"FETCH_MANAGER_LIST",
    ONBOARDING_FILTER:"ONBOARDING_FILTER",
    PLUGINREQUEST_FILTER:"PLUGINREQUEST_FILTER",
    SAHYOGIREQUEST_FILTER:"SAHYOGIREQUEST_FILTER",
    MANAGER_FILTER:"MANAGER_FILTER",
    CUSTOMER_FILTER:"CUSTOMER_FILTER",
    FETCH_MANAGERS_OVERVIEW:"FETCH_MANAGERS_OVERVIEW",
    FETCH_APPLICATION_REQUEST:"FETCH_APPLICATION_REQUEST",
    SET_SEARCH_TERM:"SET_SEARCH_TERM",
    FETCH_REGISTERED_EXPERT_LIST:"FETCH_REGISTERED_EXPERT_LIST",
    FETCH_REGISTERED_EXPERT_DETAILS:"FETCH_REGISTERED_EXPERT_DETAILS",
    FETCH_LIST_LANGUAGES:"FETCH_LIST_LANGUAGES",
    REGISTERED_EXPERT_FILTER:"REGISTERED_EXPERT_FILTER"
}
export default constants;