import { makeStyles } from "@mui/styles";

export const DashboardStyles = makeStyles({
  bulkApproveBtn: {
    background: "#0671E0",
    fontSize: "16px",
    fontWeight: 500,
    padding: "14px 32px 14px 32px",
    lineHeight: "20px",
    marginRight: "16px",
  },
  bulkRejectBtn: {
    background: "#E02B1D",
    fontSize: "16px",
    fontWeight: 500,
    padding: "14px 32px 14px 32px",
    lineHeight: "20px",
    marginRight: "16px",
  },
  cancelBtn: {
    fontSize: "16px",
    fontWeight: 500,
    padding: "14px 32px 14px 32px",
    lineHeight: "20px",
    marginRight: "16px",
    borderColor: "#0671E0",
    color: "#0671E0",
    borderRadius: "0.25rem",
  },

  searchBox: {
    marginRight: "auto",
    padding: 0,
    "& .MuiOutlinedInput-input": { padding: "8px 16px" },
  },
  dateBox: {
    margin: "auto",
    padding: 0,
    "& .MuiOutlinedInput-input": { padding: "8px 16px" },
  },

  rejectBtn: {
    color: "#E02B1D",
    fontSize: "14px",
    fontWeight: 500,
    padding: "8px 24px 8px 0px",
    lineHeight: "16px",
    marginRight: "8px",
  },

  approveBtn: {
    color: "#009262",
    fontSize: "14px",
    fontWeight: 500,
    padding: "8px 24px 8px 24px",
    lineHeight: "16px",
    borderColor: "#009262",
  },

  tabHeader: {
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "inter",
    lineHeight: "20px",
    color: "#ABBED1",
  },
  employeeRegistrationheader: {
    backgroundColor: "#ffffff",
    height: "230px",
    padding: "5px 5px 10px 5px",
    borderRadius: "8px",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  employeeRegistrationTypography: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 600,
  },
  invitedHeader: {
    padding: "5px 5px 10px 5px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  invitedTypography: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
  },
  invitedTypographyValue: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    color: "#FFB200",
  },
  directTypographyValue: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    color: "#02A0FC",
  },
  applicationRequestBox: {
    backgroundColor: "#ffffff",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  applicationRequestHeaderBox: {
    // width: "300px",
    backgroundColor: "#ffffff",
    marginTop: "8px",
    borderRadius:"5px",
    marginLeft: "8px",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  applicationRequestTypography: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    fontSize: "14px",
    padding: "10px",
  },
  applicationRequestlabelBox: {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    fontSize: "14px",
    marginBottom: "10px",
    padding: "10px 5px 10px 5px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  applicationRequestlabelmanagerBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    fontSize: "12px",
    padding: "2px 10px 0px 5px",
    marginRight: "20px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  sahyogiRequestHeader: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    padding: "8px",
  },
  customerOverviewHeader: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 600,
    fontSize: "14px",
    padding: "8px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    marginTop: "8px",
    marginLeft: "8px",
    width: "200px",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  totalCustomersHeader: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    padding: "8px",
    marginTop: "8px",
    marginRight: "8px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  customerRequestsTypography: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    padding: "8px",
  },
  customerRequestsSubtitleHeader:{
    marginTop: "16px",
                  padding: "8px",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  transition:
                    "background-color 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f2f4f4 ",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                  },
  },
  customerRequestsSubtitleName:{
    fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
  },
  customerRequestsSubtitleValue:{
    fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
  },
  applicationRequestTotalRequestslabel:{
    backgroundColor: "#ffffff",
    marginTop: "8px",
    transition:
      "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    marginLeft: "auto",
    marginRight: "8px",
    padding: "10px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#f2f4f4",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
  },
  managerBox:{
    display: "flex",
    alignItems: "center",
    marginRight: "24px",
    backgroundColor: "#ffffff",
    // transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    padding: "10px",
    // borderRadius: "5px",
    // "&:hover": {
    //   backgroundColor: "#f2f4f4",
    //   boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    // },
  },
  managerTypography:{
    color: "#000000",
    fontFamily: "Inter, sans-serif",
    fontSize: "13px",
    fontWeight: 400,
  }
});
