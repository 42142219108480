import C from "../../constants";

const initialState = {
    filterData: {
        userType: "",
        language: "",
        date: "",
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case C.REGISTERED_EXPERT_FILTER: {
            let result = { ...state };
            result.filterData
             = { ...action.payload };
            return result;
        }

        default:
            return {
                ...state,
            };
    }
};

export default reducer;
