import { Avatar, Box, Button, IconButton, TextField, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { convertDateFormat } from '../../../utils/utils';

// Register locale to ensure the date-fns library uses the correct format
registerLocale("en-GB", enGB);

// Calculate the date 18 years ago from today
const today = new Date();
const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

const UserDetails = ({ handleCloseDialog }) => {
  const mobileDevice = useMediaQuery('(max-width:600px)');
  const [birthDate, setbirthDate] = useState(dayjs());
  const userDetails = useSelector(state => state.getUserDetails.data);

  useEffect(() => {
    userDetails?.basicDetails && setbirthDate(dayjs(convertDateFormat(userDetails?.basicDetails?.dob)))
  }, [userDetails])
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: "30px" }}>
        <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
          <Avatar fontFamily={"Inter, sans-serif"}>{userDetails?.basicDetails?.name?.slice(0, 2)?.toUpperCase()}</Avatar>
          <Typography fontFamily={"Inter, sans-serif"} fontWeight={600} ml={2} variant='h4'>{userDetails?.basicDetails?.name}</Typography>
        </Box>

        <IconButton
          aria-label="edit"
          style={{ position: 'absolute', top: '30px', right: '70px', zIndex: 9999, background: "#DBEDFF" }}
        >
          <EditIcon sx={{ color: "#0671E0" }} />
        </IconButton>

        <IconButton
          aria-label="close"
          style={{ position: 'absolute', top: '30px', right: '20px', zIndex: 9999 }}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={4} mb={2}>
        <TextField
          disabled
          fullWidth
          label={"Email ID"}
          variant="outlined"
          name="contact"
          value={userDetails?.basicDetails?.primary_email}
        />
        <TextField
          disabled
          fullWidth
          label={"Personal email ID"}
          variant="outlined"
          name="contact"
          value={userDetails?.basicDetails.alternative_email}
        />
        <TextField
          disabled
          fullWidth
          label={"Phone Number"}
          variant="outlined"
          name="contact"
          value={userDetails?.basicDetails?.phone_number}
        />
        <TextField
          disabled
          fullWidth
          label={"Alternative Phone Number"}
          variant="outlined"
          name="contact"
          value={userDetails?.basicDetails?.alternative_phone_number
          }
        />
        <Box fullWidth width="100%" className="userInput__form">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                disabled
                sx={{ width: "100%" }}
                value={birthDate}
                onChange={(newValue) => {
                  console.log("newValue --- ", newValue)
                  setbirthDate(dayjs(newValue))
                }}
                label="Date of birth"
                maxDate={dayjs(eighteenYearsAgo)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <Box mt={3} display="flex" flexDirection={mobileDevice ? 'column' : 'row'} gap={mobileDevice ? '10px' : ''} justifyContent="space-between" alignItems="center" sx={{ backgroundColor: "#F5F7FA", borderRadius: "4px", padding: "10px" }}>
          <Typography fontFamily={"Inter, sans-serif"} fontWeight={600} sx={{ marginLeft: "10px" }} variant='h6'>{userDetails?.advanceDetails[`${userDetails?.advanceDetails?.user_type}`]?.government_document?.doc_type}</Typography>
          <Box display="flex" flexDirection='row' justifyContent="space-between">
            <Box><Button variant="outlined" color="error" sx={{ borderRadius: "2px", marginRight: "15px", border: "none" }} >
              Delete
            </Button></Box>
            <Box><Button variant="outlined" sx={{ borderRadius: "2px", backgroundColor: "white", color: "#0671E0", border: "none" }}>
              Update
            </Button></Box>

          </Box>
        </Box>
      </Box>
    </>
  )
}

export default UserDetails