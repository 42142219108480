import { useState, useEffect } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import './Register.css'
import Logo from "../../../assets/bhashininewlogo3.png";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardComponent from "./CardComponent/CardComponent";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardComponentData from "./CardComponent/CardComponentData";
import FormComponent from "./FormComponent/FormComponent";
export default function Register() {
  
  
  const [formData, setFormData] = useState({
    representative_type:"Organization",
      userRole:"customer",
      representative_name:"",
      organisation_type:"Central Government",
      firstname:"",
      lastname:"",
      gender:"",
      email_address: "",
      personal_email_Address: "",
      phone_number:"",
      alternative_phone_number:"",
      date_of_birth: null,
      otpValue:"",
      termsLink:"",
      date_of_joining:"",
      confirmation:false,
  });
  const isMobile = useMediaQuery('(max-width:600px)');


  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    
       responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };



  
 

  return (
    <>
    <ToastContainer />

    <Box className="OnboardingForm_register">
      {/* <Container> */}
        <Grid container style={{ width: isMobile ? '' : '100%',height:"100%" }}>
          <Grid item xs={12} md={6}>
            <Box className="OnboardingForm__imgSection">
              <Box className="OnboardingForm__imgSectionLogo">
                <img src={Logo} alt="logo" />
              </Box>
              <Box className="OnboardingForm__imgSectionTextContainer">
                <Box>

                <Typography variant="h5" className="OnboardingForm__imgSectionHeading" fontFamily={"Inter, sans-serif"} fontWeight={600}>
                Welcome to Bhashini Onboarding Portal 
                </Typography>
                <Typography variant="body1" className="OnboardingForm__imgSectionText">
                Your portal to access Parikshan Applications
                </Typography>
                </Box>
                {/* <div style={{display:"flex", justifyContent:"center"}}>
        <div style={{width:"400px"}}>

      <div className="slider-container">
      <Slider {...settings}>
       {CardComponentData.map((item, index) => (
        <div key={index}>
          <CardComponent
            image={item.image}
            title={item.title}
            description={item.description}
            cardNumber={index + 1}
            IconComponent={item.icon}
          />
        </div>
      ))}
      </Slider>
    </div>
        </div>

      </div> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormComponent
              formData={formData}
              setFormData={setFormData}
            />
          </Grid>
        </Grid>
      {/* </Container> */}
    </Box>
    </>
  );
}
