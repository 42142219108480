import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import successtick from '../../../../assets/successtick.svg'
import './FormComponent.css'
import arrowBack from '../../../../assets/arrow_back.svg'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import WarningIcon from '../../../../assets/warning.svg'
import fetchOrganisationNamesAPI from '../../../../redux/actions/api/UserManagement/fetchOrganisationNames';
import { useDispatch, useSelector } from 'react-redux';
import { APITransport } from '../../../../redux/actions';
const ContactInformation = ({ handleSubmit, isSubmitted, Email_OTP_Inputs, handleKeyDown, handleOTPChange, isButtonDisabled, buttonRef, formData, handleEmailSubmit, isSubmitted1, Alternate_Email_OTP_Inputs, isButtonDisabled1, handleChange1, handleChange, step, stepChangePrevHandler, handleEmailSubmitSuccess, emailOTPSuccess, alternateEmailOTPSuccess, errors,handleForm1ButtonClick,handleCheckboxChange,handleOrganisationType }) => {
const dispatch = useDispatch()
const orgDetails = useSelector(state => state.fetchOrganisationNames.data);
  const getOrganisationTypes = () => {
    const reqObj = new fetchOrganisationNamesAPI();
    dispatch(APITransport(reqObj));
}

useEffect(() => {
  getOrganisationTypes()
}, [])

  return (
    <div className="FormComponent__section">
     
      <form onSubmit={handleSubmit}>
        <div className="formContainer">
        {/* <div style={{marginBottom:"0px", marginTop:"3rem", display:"flex", alignItems:"center"}}><Link to='/user/login'><img src={arrowBack} style={{marginRight:"10px"}}/></Link>Back</div> */}
      {/* <div className="FormComponent__Heading" style={{marginBottom:"0px",fontFamily:"Inter, sans-serif", fontWeight:'600', marginTop:"3rem"}}>Register </div> */}
      {formData?.userRole === 'employee' ? "" : <Box>
       <Typography variant="h6" marginTop="1rem" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Organisation Type</Typography>
      <FormControl fullWidth variant="outlined">
        <Select
          labelId="role-select-label"
          id="role-select"
          value={formData?.organisation_type}
          onChange={handleOrganisationType}
          label="Organisation Type"
        >
          {orgDetails.map(data => 
          <MenuItem value={data?.name}>{data?.description}</MenuItem>

          )}
          
          {/* <MenuItem value="State Government">State Government</MenuItem>
          <MenuItem value="Public Sector Units (PSUs)">Public Sector Units (PSUs)</MenuItem>
          <MenuItem value="Banking & Financial Institutes (BFSI)">
            Banking & Financial Institutes (BFSI)
          </MenuItem>
          <MenuItem value="Private Sector Corporates">Private Sector Corporates</MenuItem>
          <MenuItem value="Academics & Research Institutions">
            Academics & Research Institutions
          </MenuItem> */}
        </Select>
      </FormControl>
    </Box>}
      <Box sx={{display:"flex", gap:"20px"}}>
      <div className='textFieldValidation'>
      <div style={{ position: 'relative' }}>
      <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>First Name</Typography>
      <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            name="firstname"
            value={formData.firstname || ""}
            onChange={handleChange}
            error={errors.firstname && formData.firstname === '' && Boolean(errors.firstname)}
            inputProps={{ maxLength: 250 }}
          />
            {/* {errors.firstname && formData.firstname === '' && <img src={WarningIcon} style={{ position: 'absolute', right: 20, top: '65%', transform: 'translateY(-50%)' }} />} */}
            </div>
           {errors.firstname && formData.firstname === '' && <span className="error">{errors.firstname}</span>}
        </div>
        <div className='textFieldValidation'>
        <div style={{ position: 'relative' }}>
      <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Last Name</Typography>
          <TextField
            id="outlined-basic"
            label=""
            variant="outlined"
            name="lastname"
            value={formData.lastname || ""}
            onChange={handleChange}
            error={errors.lastname && formData.lastname === '' && Boolean(errors.lastname)}
            inputProps={{ maxLength: 250 }}
          />
          {/* {errors.lastname && formData.lastname === '' && <img src={WarningIcon} style={{ position: 'absolute', right: 20, top: '65%', transform: 'translateY(-50%)' }} />} */}
          </div>
           {errors.lastname && formData.lastname === '' && <span className="error">{errors.lastname}</span>}
        </div>

      </Box>
          
           <div className='textFieldValidation'>
                <div style={{ position: 'relative' }}>
      <Typography variant="h6" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"5px"}}>Official Email Address</Typography>
                  <TextField
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    name="email_address"
                    value={formData.email_address || ""}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.email_address && formData.email_address === '' && Boolean(errors.email_address)}
                    disabled={emailOTPSuccess}
                    inputProps={{ maxLength: 250 }}
                  />
                  {/* {errors.email_address && formData.email_address === '' && <img src={WarningIcon} style={{ position: 'absolute', right: 20, top: '65%', transform: 'translateY(-50%)' }} />} */}
                </div>
                {errors.email_address && formData.email_address === '' && <span className="error">{errors.email_address}</span>}
              </div>
          {/* <div className="formContainer__emailSection" style={{border : emailOTPSuccess ? 'none' : '2px solid lightgray', borderStyle : emailOTPSuccess ? 'none' : 'dashed', padding : emailOTPSuccess ? '0px' : '25px 15px'}}>
            {isSubmitted ? (
              <div>
                <div className="formController_emailSectionText">
                  Enter the 4-digit code sent to you at: {formData.email_address}
                </div>
                <div className="formContainer__emailSectionOTPSection mt-4">
                  <div>
                    {Email_OTP_Inputs.map((el, index) => {
                      return <input
                        key={index}
                        ref={Email_OTP_Inputs[index]}
                        onKeyDown={(e) => handleKeyDown(e, index, Email_OTP_Inputs)}
                        onChange={(e) => handleOTPChange(e, index, Email_OTP_Inputs)}
                        type="text"
                        maxLength={1}
                        className="OTPInput__email"
                      />
                    })}
                  </div>
                  <div
                    className="formContainer__emailSectionDiv"
                    style={{ marginTop: "0px" }}
                  >
                    <button
                      className={`${ !Email_OTP_Inputs.every(inputRef => inputRef.current && inputRef.current.value)
                        ? "formContainer__emailSectionDivBtn"
                        : "formContainer__emailSectionDivBtnEnabled"
                        }`}
                      ref={buttonRef}
                      disabled={!Email_OTP_Inputs.every(inputRef => inputRef.current && inputRef.current.value)}
                      onClick={handleEmailSubmitSuccess}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className='textFieldValidation'>
                <div style={{ position: 'relative' }}>
                  <TextField
                    id="outlined-basic"
                    label="Email address*"
                    variant="outlined"
                    name="email_address"
                    value={formData.email_address || ""}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    error={errors.email_address && formData.email_address === '' && Boolean(errors.email_address)}
                    disabled={emailOTPSuccess}
                  />
                  {emailOTPSuccess && <img src={successtick} style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />}
                </div>
                {errors.email_address && formData.email_address === '' && <span className="error">{errors.email_address}</span>}
              </div>
            )}
            {!isSubmitted && (
              <div className="formContainer__emailSectionDiv" style={{display : emailOTPSuccess ? 'none' : 'flex'}}>
                <Button
                  className={`${(isButtonDisabled ) || formData.email_address === ''
                    ? "formContainer__emailSectionDivBtn"
                    : "formContainer__emailSectionDivBtnEnabled"
                    }`}
                  ref={buttonRef}
                  disabled={isButtonDisabled}
                  onClick={handleEmailSubmit}
                >
                  Send OTP
                </Button>
              </div>
            )}
          </div> */}
          {/* <div className="">
            {isSubmitted1 ? (
              <div>
                <div className="formController_emailSectionText" style={{fontFamily:"Inter, sans-serif"}}>
                  Enter the 4-digit code sent to you at: {formData?.alternative_email_Address}
                </div>
                <div className="formContainer__emailSectionOTPSection mt-4">
                  <div>
                    {Alternate_Email_OTP_Inputs.map((el, index) => {
                      return <input
                        key={index}
                        ref={Alternate_Email_OTP_Inputs[index]}
                        onKeyDown={(e) => handleKeyDown(e, index, Alternate_Email_OTP_Inputs)}
                        onChange={(e) => handleOTPChange(e, index, Alternate_Email_OTP_Inputs)}
                        type="text"
                        maxLength={1}
                        className="OTPInput__email"
                      />
                    })}
                  </div>
                 
                </div>
              </div>
            ) : (
              <div className='textFieldValidation'>
                <div style={{ position: 'relative' }}>
                  <TextField
                    id="outlined-basic"
                    label="Alternative Email address"
                    variant="outlined"
                    name="alternative_email_Address"
                    value={formData.alternative_email_Address || ""}
                    onChange={handleChange1}
                    style={{ width: "100%" }}
                    error={errors.alternative_email_Address && formData.alternative_email_Address === '' && Boolean(errors.alternative_email_Address)}
                  />
                  {alternateEmailOTPSuccess && <img src={successtick} style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }} />}
                </div>
                {errors.alternative_email_Address && formData.alternative_email_Address === '' && <span className="error">{errors.alternative_email_Address}</span>}
              </div>
            )}
            
          </div> */}
          {/* <div className='textFieldValidation'>
            <TextField
              id="outlined-basic"
              label="Phone Number*"
              variant="outlined"
              name="phone_number"
              value={formData.phone_number || ""}
              onChange={handleChange}
              error={errors.phone_number && formData.phone_number === '' && Boolean(errors.phone_number)}
            />
            {errors.phone_number && formData.phone_number === '' && <span className="error">{errors.phone_number}</span>}
          </div> */}
          {/* <div className='textFieldValidation'>
            <TextField
              id="outlined-basic"
              label="Alternative Phone Number"
              variant="outlined"
              name="alternative_phone_number"
              value={formData.alternative_phone_number || ""}
              onChange={handleChange}
              error={errors.alternative_phone_number && formData.alternative_phone_number === '' && Boolean(errors.alternative_phone_number)}
            />
          </div> */}

          {/* <DatePicker
            selected={formData.date_of_birth}
            onChange={(val) => {
              let data = {
                target: {
                  name: "date_of_birth",
                  value: val
                }
              }
              handleChange(data);
            }}      
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            className="customDatePicker"
            placeholderText="Date of birth*"
            showYearDropdown
            yearDropdownItemNumber={90}
            scrollableYearDropdown
          /> */}

          <div className="FormComponent__divBtndiv1">
            {/* <button
              type="button"
              className="FormComponent__divBtn me-5"
              style={{ background: "none", color: "#0671E0" }}
              onClick={stepChangePrevHandler}
            >
              Previous
            </button> */}
            <div>
             <FormControlLabel
          control={
            <Checkbox
              checked={formData?.confirmation}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label={
            <Typography variant="body2">
              I&apos;ve read and agree with your{' '}
              <a href="https://userdatav1.blob.core.windows.net/dashboardblob/Terms_and_Conditions_Bhashini.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'blue' }}>
                Terms of Services
              </a>
            </Typography>
          }
          // style={{ marginTop: '20px' }}
        />
          {errors.confirmation && formData?.confirmation === false && (
        <div style={{ color: 'red' }}>{errors.confirmation}</div>
      )}
      </div>
          {/* <input type="text" name="commonField3" onChange={handleChange} /> */}
        </div>
        <div className='FormComponent__divBtnBox'>
        {/* <button
              className="FormComponent__divBtn"
              style={{  marginRight:"2rem" }}
              
              onClick={stepChangePrevHandler}
            >
              Previous
            </button> */}
            <button className="FormComponent__divBtn" type="submit" onClick={handleForm1ButtonClick}>
            Next
            </button>
        </div>
          </div>
      </form>
      
    </div>
  )
}

export default ContactInformation