import C from "../../../constants";

const reducer = (state, action) => {
  switch (action.type) {
    case C.GET_USER_DETAILS:
      return {
        ...state,
        data: action.payload
      };
    default:
      return {
        ...state
      }
  }
};

export default reducer;
