
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class UpdateInviteManagerAPI extends API {
  constructor(first_name,last_name,official_email, timeout = 2000) {
    super("POST", timeout, false); 
    this.type = C.UPDATE_INVITE_MANAGER;
    this.first_name = first_name;
    this.last_name = last_name;
    this.official_email = official_email;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.InviteManager}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.customer = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    
    return {
        official_email: this.official_email,
        first_name:this.first_name,
        last_name:this.last_name  

    };
  }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.customer;
  }
}
