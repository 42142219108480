import React,{useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Header from '../components/common/Header';
import { sidebarMenuData } from '../config/sidebarMenuData';
import { useLocation, useNavigate } from 'react-router-dom';
import { setSnackBar } from "../redux/actions/Common";
import CustomizedSnackbars from "../components/common/SnackBar";
import { LayoutStyles } from "../styles/LayoutStyles";
import { useDispatch, useSelector } from 'react-redux';
import authenticateUser from '../utils/authenticateUser';
import { roles } from '../constants/constants';
import Logo from '../assets/Bhashini_Logo_Dark_2x.png';

const drawerWidth = 250;

export default function PrivateLayout(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = LayoutStyles();
  const snackbar = useSelector((state) => state.commonReducer.snackbar);
  const userDetails = useSelector(state => state.getUserDetails.data);
  const location =  useLocation()
  const { component, path } = props
  const findpath = location.pathname
  localStorage.setItem('findpath', findpath);
  const currentUserRole = localStorage.getItem('role');
  const currentPath = location.pathname;
  
  const activeMenu = sidebarMenuData.find(menu => menu.path === currentPath);

  useEffect(() => {
    if (!authenticateUser([currentUserRole])) {
      navigate('/user/login');  
    } else if (activeMenu && !activeMenu.allowedRoles.includes(currentUserRole)) {
      navigate('/unauthorized'); 
    }
  }, [navigate, currentUserRole, activeMenu]);

  const getActiveSidebarMenu = (menuPath) => {
    return menuPath === path
  }

  const renderSnackBar = React.useCallback(() => {
    return (
      <CustomizedSnackbars
        open={snackbar.open}
        handleClose={() =>
          dispatch(setSnackBar({ open: false, message: "", variant: "" }))
        }
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        variant={snackbar.variant}
        message={[snackbar.message]}
      />
    );

    //eslint-disable-next-line
  }, [snackbar]);

  return (
    <>
      {renderSnackBar()}
      {authenticateUser('User') && 
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
      {findpath === '/update-profile' ? null :  <Header />}
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            zIndex: 99999,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: "#0B1941" },
          }}
        >
          <Box sx={{ display:"flex",
            justifyContent:"space-between",
            flexDirection:"column", height:"100%"}}>
          <Box>
          <img
            width={100} src={Logo} alt="Logo" style={{marginLeft: '2rem', marginTop: '0.8rem'}} />
          {/* <Toolbar /> */}
          <Box sx={{ overflow: 'auto', paddingTop: 5 }}>
          {findpath === '/update-profile' ? '' : <List>
              {sidebarMenuData.map((el, index) => (
                el.allowedRoles.includes(localStorage.getItem('role')) && 
                <ListItem key={el.name} disablePadding
                  onClick={() => {
                    localStorage.setItem('activePath', el.path);
                    localStorage.removeItem('tabId');
                    navigate(el.path)
                  }}
                  sx={{
                    backgroundColor: getActiveSidebarMenu(el.path) ? "#DBEDFF" : "none",
                    color: getActiveSidebarMenu(el.path) ? "#000000" : "#ABBED1"
                  }}
                >
                  <ListItemButton>
                          <ListItemIcon style={{ minWidth: "40px" }}>
                            {el.icon({ sx: { color: getActiveSidebarMenu(el.path) ? "#000000" : "#ABBED1" } })}
                          </ListItemIcon>
                          <ListItemText primary={el.name} primaryTypographyProps={{ style: { fontSize: "17px" } }} />
                        </ListItemButton>
                      </ListItem>
                    ))}
            </List>}
          </Box>
          </Box>
          {findpath !== '/update-profile' ? null : <Typography variant='body1' color="#ffffff" padding="30px 10px">A few clicks away from accessing your Dashboard</Typography>}
          </Box>
        </Drawer>
        <Box sx={{ flexGrow: 1, p:findpath === '/profile' ? 0 : 5 }}>
        {findpath === '/update-profile' || findpath === '/view' ? '' :  <Toolbar />}
          {component}
        </Box>
      </Box>}
    </>
  );
}