import img1 from '../../../../assets/Carousel/CarouselIcon1.svg'
import img2 from '../../../../assets/Carousel/CarouselIcon2.svg'
import img3 from '../../../../assets/Carousel/CarouselIcon3.svg'
import img4 from '../../../../assets/Carousel/CarouselIcon4.svg'

const CardComponentData = [
    {
      "image": img1,
      "title": "Create Account",
      "description": "Register/log in to your account on the Onboarding Portal",
      "icon": "YourIcon1"
    },
    {
      "image": img2,
      "title": "Profile Completion",
      "description": "Complete your profile to access the Dashboard & Applications ",
      "icon": "YourIcon2"
    },
    {
      "image": img3,
      "title": "Request to Access ",
      "description": "Click on Request to Access to send approval.",
      "icon": "YourIcon3"
    },
    {
      "image": img4,
      "title": "Explore Applications",
      "description": "After approval, Access the different Bhashini Applications",
      "icon": "YourIcon4"
    }
  ]

export default CardComponentData