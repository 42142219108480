import React, { useState, useMemo, useRef,useEffect } from "react";
import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import tableTheme from "../../theme/tableTheme";
import { DashboardStyles } from "../../styles/dashboardStyles";
import { APITransport } from "../../redux/actions";
import FetchTranslationPluginRequestsAPI from "../../redux/actions/api/Dashboard/PluginDashboard/FetchTranslationPluginRequests";
import {useDispatch, useSelector} from "react-redux";
import{setRegisteredExpertFilter,setOnboardingFilter,setpluginRequestFilter,setSahyogiRequestFilter,setManagerUserManagementFilter,setCustomerUserManagementFilter} from "../../redux/actions/Common";

const CeoPlugin = () => {
  // Dropdown options
  
  const statusData = [
    { value: "", label: "All Requests" },
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  const navigate = useNavigate();
  const classes = DashboardStyles();
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const OnboardingFilter = useSelector((state) => state?.pluginRequestFilter?.filterData);
  // State for filters
  const [selectedHost, setSelectedHost] = useState(OnboardingFilter?.hostName?OnboardingFilter?.hostName:"");
  const [selectedAPIKey, setSelectedAPIKey] = useState(OnboardingFilter?.ApiKeys?OnboardingFilter?.ApiKeys:"");
  const [selectedStatus, setSelectedStatus] = useState(OnboardingFilter?.allRequest?OnboardingFilter?.allRequest:"");
  const [pendingactions,setPendingactions] =useState("")

  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");

  const fetchTranslationPluginRequests = useSelector(
    (state) => state?.fetchTranslationPluginRequests?.data
  );

  useEffect(() => {
    dispatch(setpluginRequestFilter({ApiKeys:selectedAPIKey,
      hostName: selectedHost,
      allRequest: selectedStatus}))

  }, [dispatch,selectedAPIKey,selectedHost,selectedStatus]);


  useEffect(() => {
    dispatch(setOnboardingFilter({}));
    dispatch(setSahyogiRequestFilter({}));
    dispatch(setManagerUserManagementFilter({}));
    dispatch(setCustomerUserManagementFilter({}));
    dispatch(setRegisteredExpertFilter({}));

  }, []);

  // Filtering data based on selected values
  const filteredData = useMemo(() => {
    if (!fetchTranslationPluginRequests?.requests) return [];

    return fetchTranslationPluginRequests.requests.filter(item => {
      const matchesHost = selectedHost ? item.host_url === selectedHost : true;
      const matchesAPIKey = selectedAPIKey ? item.api_key === selectedAPIKey : true;
      const matchesStatus = selectedStatus
      ? (role === "ceo"
        ? (selectedStatus === "Pending for my action"
          ? (item.status === 'Pending' && item.manager_approval.status === 'Approved')
          : (item.status === selectedStatus))
        : ( item.manager_approval.status === selectedStatus))
      : true;     
      return matchesHost && matchesAPIKey && matchesStatus ;
    });
  }, [selectedHost, selectedAPIKey, selectedStatus, fetchTranslationPluginRequests?.requests,role]);

  const FetchTranslationPluginRequest = () => {
    const apiObj = new FetchTranslationPluginRequestsAPI(email,role);
    dispatch(APITransport(apiObj));
  };

  useEffect(() => {
    FetchTranslationPluginRequest()
  }, []);

  const onViewRequestClick = (value) => {
    const reqData = fetchTranslationPluginRequests?.requests?.filter(
      (el) => el?._id === value?.rowData[0]
    )[0];
    navigate("/plugin-request-action", { state: { reqData, rowValue: value?.rowData } });
  };

  const getUniqueApiKeys = () => {
    const requests = fetchTranslationPluginRequests?.requests || [];
        const apiKeys = requests
      .map(item => item.api_key)
      .filter(key => key !== undefined && key !== null);
    
    const uniqueApiKeys = [...new Set(apiKeys)];
    
    const formattedKeys = uniqueApiKeys.map(key => ({
      value: key,
      label: key
    }));
    
    return [{ value: "", label: "Select API Key" }, ...formattedKeys];
  };
  

  const getUniqueHostName= () => {
    const requests = fetchTranslationPluginRequests?.requests || [];
        const apiKeys = requests
      .map(item => item.host_url)
      .filter(key => key !== undefined && key !== null);
    
    const uniqueApiKeys = [...new Set(apiKeys)];
    
    const formattedKeys = uniqueApiKeys.map(key => ({
      value: key,
      label: key
    }));
    
    return [{  value: "", label: "Select Hostname"  }, ...formattedKeys];
  };

  const handlePendingFilterChange = (e) => {
    setPendingactions(e.target.value);
  };
  const statusDatafiltred = [
    ...statusData,
    ...(role === "ceo" ? [{ value: "Pending for my action", label: "Pending for my action" }] : []),
  ];
  const renderToolBar = () => {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item >
        <Typography
            variant="h3"
            fontFamily={"Inter, sans-serif"}
            fontWeight={600}
            sx={{ fontSize: "18px", textAlign: "left" }}
          >
            Bhashini Translation Plugin Request
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          spacing={2}
          justifyContent="flex-start"
        >
          <Grid item display="flex" alignItems="center" gap={2} sx={{ mr: 2, mb: 3 }}>
          {/* <Select
              displayEmpty
              labelId="apiKey-select-label"
              id="apiKey-select"
              className={classes.searchBox}
              value={selectedAPIKey}
              onChange={(e) => setSelectedAPIKey(e.target.value)}
              style={{ minWidth: "200px", textAlign:"left" }}
            >
              {getUniqueApiKeys()?.map((el) => (
                <MenuItem key={el.value} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select> */}
            {/* <Select
              displayEmpty
              labelId="hostname-select-label"
              id="hostname-select"
              className={classes.searchBox}
              value={selectedHost}
              onChange={(e) => setSelectedHost(e.target.value)}
              style={{ minWidth: "200px", textAlign:"left" }}
            >
              {getUniqueHostName()?.map((el) => (
                <MenuItem key={el.value} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select> */}
            <Select
              displayEmpty
              labelId="status-select-label"
              id="status-select"
              className={classes.searchBox}
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              style={{ minWidth: "200px", textAlign:"left" }}
            >
              {statusDatafiltred?.map((el) => (
                <MenuItem key={el.value} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  // MUI DataTable options
  const options = {
    textLabels: {
      body: { noMatch: "No records" },
      toolbar: {
        search: "Search",
        viewColumns: "View Column",
      },
      pagination: { rowsPerPage: "Rows per page" },
      options: { sortDirection: "desc" },
    },
    selectToolbarPlacement: "none",
    displaySelectToolbar: false,
    fixedHeader: false,
    download: false,
    print: false,
    rowsPerPageOptions: [10, 25, 50, 100],
    filter: false,
    viewColumns: false,
    search: false,
    jumpToPage: false,
    pagination: true,
    customToolbar: renderToolBar,
    selectableRows: "none",
  }; 

  const data = filteredData && filteredData.length > 0 ?filteredData?.map((el, i) => {
    return [
        el._id,
        // el.api_key,
        el.app_name,
        el.host_url,
        el.requestor_name,
        el.requested_by,
      role === "ceo" && el.manager_approval.status,
      role === "ceo"? el.status:el.manager_approval.status,
       
    ]
})  : [];
  // MUI DataTable columns
  const columns = [
    { name: "_id", label: "" , options: {
      display: false,
    },},
    // { name: "api_key", label: "API Key" },
    { name: "app_name", label: "App Name" , options: {
      setCellHeaderProps: () => ({
        style: {
          width: "auto",
          whiteSpace: 'nowrap', 
          overflow: 'hidden',  
          textOverflow: 'ellipsis' 
        },
      }),
    },},
    { name: "host_url", label: "Host Name" , options: {
      setCellHeaderProps: () => ({
        style: {
          width: "auto",
          whiteSpace: 'nowrap', 
          overflow: 'hidden',  
          textOverflow: 'ellipsis' 
        },
      }),
    },},
    { name: "requestor_name", label: "Name" },
    { name: "requestor_by", label: "Official Email ID" , options: {
      setCellHeaderProps: () => ({
        style: {
          width: "auto",
          whiteSpace: 'nowrap', 
          overflow: 'hidden',  
          textOverflow: 'ellipsis' 
        },
      }),
    },},
   {
      name: "status",
      label: "Manager Approval",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        display:  role === "ceo"? true:false,
        customBodyRender: (value) => {
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    },
   
    {
      name: "status",
      label: "Request Status",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value) => {
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        align: "center",
        customBodyRender: (value, meta) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Link
                fontWeight={600}
                fontSize={"14px"}
                color={"#2947A3"}
                onClick={() => onViewRequestClick(meta)}
              >
                View More
              </Link>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Grid container direction="row" sx={{mt:"20px"}}>
        <Grid item md={12} xs={12} style={{ width: "100%" }}>
          <ThemeProvider theme={tableTheme}>
            <MUIDataTable
              ref={tableRef}
              data={data}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default CeoPlugin;

