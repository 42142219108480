
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchApplicationRequestStatusAPI extends API {
  constructor(status, timeout = 2000) {
    super("GET", timeout, false); 
    this.type = C.FETCH_APPLICATION_REQUEST;
    this.status = status;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.ApplicationRequestStatus}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.customer = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    
    return {};
  }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.customer;
  }
}
