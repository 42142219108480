import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import UpdateProfileDetails from "../../redux/actions/api/UserManagement/UpdateProfileDetails";
import { APITransport } from "../../redux/actions";
import { setSnackBar } from "../../redux/actions/Common";
import GetUserDetailsAPI from '../../redux/actions/api/UserManagement/GetUserDetails'

const MyProfile = () => {
    const [editMode, setEditMode] = useState(false);
    const userDetails = useSelector((state) => state.getUserDetails.data);
    const [profileDetails, setProfileDetails] = useState(null)
    const apiStatus = useSelector(state=>state.apiStatus)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!userDetails) {
            setProfileDetails(userDetails)
        }
    }, [userDetails])

    useEffect(() => {
        if (apiStatus.success) {
            setEditMode(false)
        }
        if (apiStatus.apiType === "USER_UPDATE_PROFILE")
            dispatch(setSnackBar({
                open: true,
                message: apiStatus.success ? 'Profile updated successfully' : 'Failed to update the profile',
                variant: apiStatus.success ? "success" : "error"
            }))
        if(apiStatus.apiType === "USER_UPDATE_PROFILE" && apiStatus.success){
            const apiObj = new GetUserDetailsAPI()
            dispatch(APITransport(apiObj))
        }
    }, [apiStatus])

    const handleSaveProfile = async () => {
        const apiObj = new UpdateProfileDetails({ ...profileDetails, phone_number: profileDetails?.phone, personal_email: profileDetails?.personal_email_id });
        dispatch(APITransport(apiObj))
    }

    const handleEditToggle = () => {
        setEditMode(true);
    };

    const handleChangeProfile = (prop, value) => {
        setProfileDetails((prev) => ({ ...prev, [prop]: value }))
    };

    console.log(profileDetails)

    return <Card sx={{ ml: '20px', mr: '28px', borderRadius: '10px', backgroundColor: '#ffffff', mt: '28px', height: '650px', position: 'relative' }} elevation={3}>
        <CardHeader style={{ width: "100%", height: "68px", background: "linear-gradient(0deg, rgba(178, 195.54, 249.33, 0.40) 0%, rgba(178, 195.54, 249.33, 0.40) 100%)" }} />
        <CardContent sx={{ pl: '36px', pr: '42px', mt: '44px' }}>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ textAlign: "start", mb: 2, display: "flex", gap: "20px", marginBottom: "30px", alignItems: "center" }}>
                    <Avatar
                        sx={{ width: 80, height: 80, mx: "auto", mb: 1 }}
                        alt="Profile Picture"
                        src=""
                    >
                        {profileDetails?.first_name?.substring(0, 2).toUpperCase()}
                    </Avatar>
                    <Box>
                        <Typography fontFamily={"Inter, sans-serif"} variant="h3" fontWeight={"600"}>{`${profileDetails?.first_name ?? ''} ${profileDetails?.last_name ?? ''}`}</Typography>
                        <Typography fontFamily={"Inter, sans-serif"} fontWeight={"600"} variant="body2" color="textSecondary">
                            {profileDetails?.email_id}
                        </Typography>
                        <Typography fontFamily={"Inter, sans-serif"} fontWeight={"600"} variant="body2" color="textSecondary">
                            {profileDetails?.role}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ textAlign: "center", mb: 2 }}>

                    {!editMode ? <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEditToggle}
                        startIcon={<EditIcon />}
                        style={{ borderRadius: "4px" }}
                    >
                        Edit
                    </Button> : <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveProfile}
                        style={{ borderRadius: "4px" }}
                    >
                        Save
                    </Button>}
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" style={{ fontFamily: "Inter, sans-serif", fontWeight: "500", marginBottom: "5px" }}>Phone Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Phone Number"
                        name="firstName"
                        value={profileDetails?.phone}
                        onChange={(e)=>handleChangeProfile('phone', e.target.value)}
                        disabled={!editMode}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" style={{ fontFamily: "Inter, sans-serif", fontWeight: "500", marginBottom: "5px" }}>Date of Birth</Typography>
                    <TextField
                        fullWidth
                        placeholder="Date of Birth"
                        name="dateOfBirth"
                        type={`${editMode ? 'date' : 'input'}`}
                        value={profileDetails?.dob}
                        onChange={(e)=>handleChangeProfile('dob', e.target.value)}
                        disabled={!editMode}
                        variant="outlined"
                        inputProps={{ maxLength: 250 }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" style={{ fontFamily: "Inter, sans-serif", fontWeight: "500", marginBottom: "5px" }}>Date of Joining</Typography>
                    <TextField
                        fullWidth
                        placeholder="Date of Joining"
                        name="dateOfJoining"
                        type={`${editMode ? 'date' : 'input'}`}
                        value={profileDetails?.date_of_joining}
                        onChange={(e)=>handleChangeProfile('date_of_joining', e.target.value)}
                        disabled={!editMode}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" style={{ fontFamily: "Inter, sans-serif", fontWeight: "500", marginBottom: "5px" }}>Personal Email ID</Typography>
                    <TextField
                        fullWidth
                        placeholder="Personal Email ID"
                        name="alternateEmail"
                        value={profileDetails?.personal_email_id}
                        onChange={(e)=>handleChangeProfile('personal_email_id', e.target.value)}
                        disabled={!editMode}
                        variant="outlined"
                        inputProps={{ maxLength: 250 }}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}

export default MyProfile;