import React, { useEffect, useRef, useState } from "react";
import { Grid, Link, Tab, Tabs, ThemeProvider, Typography } from "@mui/material";
import tableTheme from "../../../theme/tableTheme";
import MUIDataTable from "mui-datatables";
import { Button, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DashboardStyles } from "../../../styles/dashboardStyles";
import TabPanel from "../../../common/TabPanel";
import { useDispatch, useSelector } from 'react-redux';
import { APITransport, FetchOnboardUsersAPI, UpdateOnboardUserAPI } from "../../../redux/actions";
import { convertBase64ToPDF } from "../../../utils/utils";
import { setSnackBar } from "../../../redux/actions/Common";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const Dashboard = () => {
  const classes = DashboardStyles();
  const dispatch = useDispatch();
  const onboardUserList = useSelector((state)=>state.fetchOnboardUsers.userList);

  const count = useSelector((state) => state.fetchOnboardUsers.count);
  const [value, setValue] = useState(0);
  const [searchText, setSearchText] = useState(''); // State to store search text
  const [selectedTableFilter, setSelectedTableFilter] = React.useState('Request Received');

  const tableRef = useRef(null);

  const fetchOnboardUsers = (status) => {
    const apiObj = new FetchOnboardUsersAPI(status);
    dispatch(APITransport(apiObj));
  };

  useEffect(() => {
    const status = value === 0 ? 'customer-assigned' : 'ceo-assigned'
    fetchOnboardUsers(status)
  }, [value])

  const handleSearch = (event) => {
    setSearchText(event.target.value); // Update search text state
  };

  const onBulkButtonClick = async (bulkActionType) => {
    if (tableRef.current) {

      const selectedRows = tableRef.current.state.selectedRows.data.map(row => row.index);

      const selectedData = selectedRows.map((index) => ({ request_id: onboardUserList[index].request_id, status: bulkActionType }));
      // console.log("selectedData --- ", selectedData);

      await UpdateOnboardUserAPICall(selectedData);

    }
  }

  const renderToolBar = () => {
    const enableBulkButtons = tableRef.current?.state.selectedRows.data.length > 0;
    return (
      <>
        {/* <Button variant="contained" className={classes.bulkApproveBtn} disabled={!enableBulkButtons} onClick={() => onBulkButtonClick("approved")}>
          Bulk Approve
        </Button>

        <Button variant="contained" className={classes.bulkRejectBtn} disabled={!enableBulkButtons} onClick={() => onBulkButtonClick("rejected")}>
          Bulk Reject
        </Button> */}

<FormControl sx={{width: "20rem"}}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedTableFilter}
          // label="Filter"
          
          onChange={(event)=>setSelectedTableFilter(event.target.value)}
        >
          <MenuItem value={"Request Received"}>ALL</MenuItem>
          <MenuItem value={"Approved"}>Approved</MenuItem>
          <MenuItem value={"Rejected"}>Rejected</MenuItem>
        </Select>
      </FormControl>

        <TextField
          placeholder="Search"
          className={classes.searchBox}
          sx={{width: "60%"}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Typography variant="subtitle1" sx={{width: "100%", textAlign: "left", fontSize: 18, fontWeight: "600"}}>{selectedTableFilter}</Typography>
      </>
    );
  };

  const UpdateOnboardUserAPICall = async (data) => {
    const updateOnboardUserObj = new UpdateOnboardUserAPI(data);
    const resp = await fetch(updateOnboardUserObj.apiEndPoint(), {
      method: 'POST',
      headers: updateOnboardUserObj.getHeaders().headers,
      body: JSON.stringify(updateOnboardUserObj.getBody())
    })
    if (resp.ok) {
      const resp_data = await resp.json();

      const status = value === 0 ? 'customer-assigned' : 'ceo-assigned'
      fetchOnboardUsers(status)

      dispatch(setSnackBar({
        message: resp_data.message,
        open: true,
        variant: "success"
      }));
    }
  }

  const updateOnboardUser = async (requestId, status) => {
    await UpdateOnboardUserAPICall([{ request_id: requestId, status }])
  };


  const options = {
    textLabels: {
      body: { noMatch: "No records" },
      toolbar: {
        search: "Search",
        viewColumns: "View Column",
      },
      pagination: { rowsPerPage: "Rows per page" },
      options: { sortDirection: "desc" },
    },
    selectToolbarPlacement: "none",
    displaySelectToolbar: false,
    fixedHeader: false,
    filterType: "checkbox",
    download: false,
    print: false,
    rowsPerPageOptions: [10, 25, 50, 100],
    filter: false,
    viewColumns: false,
    search: false,
    jumpToPage: false,
    pagination: true,
    customToolbar: renderToolBar,
    // search: true, // Enable search
    searchText: searchText, // Set search text
    onSearchChange: (searchText) => setSearchText(searchText),
    selectableRows: "multiple", // Enable multiple row selection
  };

  const columns = [
    {
      name: "request_id",
      label: "Request Id",
      options: {
        display: false,
      }
    },
    {
      name: "reference_app_name",
      label: "Reference App",
    },
    {
      name: "representative_name",
      label: "Representative Name",
    },
    {
      name: "representative_type",
      label: "Representative Type",
    },
    {
      name: "organization_name",
      label: "Organization",
    },
    {
      name: "individual",
      label: "Individual",
      options: {
        display: false
      }
    },
    {
      name: "email_address",
      label: "Email Address",
    },
    {
      name: "alternate_email_address",
      label: "Alternate Email Address",
      options: {
        display: false,
      }
    },
    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        display: false,
      }
    },
    {
      name: "alternate_phone_number",
      label: "Alternate Phone Number",
      options: {
        display: false,
      }
    },
    {
      name: "govt_document_type",
      label: "Govt Document Type",
    },
    {
      name: "document_name",
      label: "Govt Document",
      options: {
        customBodyRender: (value) => {
          const { content, filename } = value;
          return <Link
            onClick={() => convertBase64ToPDF(content, filename)}>{filename}</Link>
        }
      }
    },
    {
      name: "purpose_of_usage",
      label: "Purpose of Usage",
      options: {
        display: false,
      }
    },
    {
      name: "source_of_info",
      label: "Source of Info",
      options: {
        display: false,
      }
    },
    {
      name: "onboard_manager",
      label: "Onboard Manager",
      options: {
        display: false,
      }
    },
    {
      name: "approval_status",
      label: "Approval Status",
      options: {
        display: false,
      }
    },
    // {
    //   name: "Email ID",
    //   label: "Email ID",
    // },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        align: "center",
        customBodyRender: (value, meta) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Button variant="text" className={classes.rejectBtn} onClick={() => updateOnboardUser(meta.rowData[0], "rejected")}>
                Reject
              </Button>

              <Button variant="outlined" className={classes.approveBtn} onClick={() => updateOnboardUser(meta.rowData[0], "approved")}>
                Approve
              </Button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Tabs value={value} onChange={(_event, newValue) => setValue(newValue)}>
        {/* <Tab label={`Request received (${count})`} className={classes.tabHeader} />
        <Tab label={"Assigned (0)"} className={classes.tabHeader} /> */}
        {/* <Tab label={"Request received"} className={classes.tabHeader} />
        <Tab label={"Assigned"} className={classes.tabHeader} /> */}
      </Tabs>

      <TabPanel
        value={value}
        index={0}
        style={{ textAlign: "center", maxWidth: "100%" }}
      >
        <Grid container direction="row">
          <Grid item md={12} xs={12} style={{ width: "100%" }}>
            <ThemeProvider theme={tableTheme}>
              <MUIDataTable
                ref={tableRef}
                data={onboardUserList}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel
        value={value}
        index={1}
        style={{ textAlign: "center", maxWidth: "100%" }}
      >
        <Grid container direction="row">
          <Grid item md={12} xs={12} style={{ width: "100%" }}>
            <ThemeProvider theme={tableTheme}>
              <MUIDataTable
                ref={tableRef}
                data={onboardUserList}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
};

export default Dashboard;
