import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./FormComponent.css";
const AdditionalInfo = ({
  handleSubmit,
  formData,
  errors,
  handleChange,
  handleForm3ButtonClick,
  stepChangePrevHandler,
  emailError
}) => {
  return (
    <div className="FormComponent__section">
      <form onSubmit={handleSubmit}>
        <div className="formContainer">
          <div
            className="FormComponent__Heading"
            style={{
              marginBottom: "0px",
              fontFamily: "Inter, sans-serif",
              fontWeight: "600",
              marginTop: "3rem",
            }}
          >
            Additional Information{" "}
          </div>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <div>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "600",
                  marginBottom: "5px",
                }}
              >
                Date of Birth
              </Typography>
              <ReactDatePicker
                selected={formData.date_of_birth}
                onChange={(val) => {
                  let data = {
                    target: {
                      name: "date_of_birth",
                      value: val,
                    },
                  };
                  handleChange(data);
                }}
                style={{ border: errors.date_of_birth === "Date of birth is required or invalid" ? "1px solid red" : "" }}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                className="customDatePicker"
                placeholderText="Date of birth*"
                showYearDropdown
                yearDropdownItemNumber={90}
                scrollableYearDropdown
              />
              {errors.date_of_birth && formData.date_of_birth === null  &&(
                <div className="error" >{errors.date_of_birth}</div>
              )}
            </div>
            <div>
              <Typography
                variant="h6"
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: "600",
                  marginBottom: "5px",
                }}
              >
                Phone Number
              </Typography>
              <TextField
                id="outlined-basic"
                label=""
                variant="outlined"
                name="phone_number"
                value={formData.phone_number || ""}
                onChange={handleChange}
                error={
                  errors.phone_number &&
                  formData.phone_number === "" && 
                  Boolean(errors.phone_number)
                }
                inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                // helperText={errors.phone_number}
              />
               {errors.phone_number && formData.phone_number === "" && (
                <div className="error">{errors.phone_number}</div>
              )}
             
            </div>
          </Box>
          <div>
            <Typography
              variant="h6"
              style={{
                fontFamily: "Inter, sans-serif",
                fontWeight: "600",
                marginBottom: "5px",
              }}
            >
              Personal Email Address
            </Typography>
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              name="personal_email_Address"
              value={formData.personal_email_Address || ""}
              onChange={handleChange}
              style={{ width: "100%" }}
              inputProps={{ maxLength: 250 }}
              error={errors.personal_email_Address && formData.personal_email_Address === '' && Boolean(errors.personal_email_Address)}
            />
            {errors.personal_email_Address && formData.personal_email_Address === "" && (
                <span className="error">{errors.personal_email_Address}</span>
              )}
              {emailError && <span className="error">{emailError}</span>}
          </div>
          <div className="FormComponent__divBtnBox">
          <button
              className="FormComponent__divBtn"
              style={{  marginRight:"2rem" }}
              
              onClick={stepChangePrevHandler}
            >
              Previous
            </button>
            <button
              className="FormComponent__divBtn"
              // style={{ width: "30%", marginTop:"2rem" }}
              type="submit"
              onClick={handleForm3ButtonClick}
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdditionalInfo;
