
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchTranslationPluginRequestsAPI extends API {
  constructor(email,role,timeout = 2000) {
    super("GET", timeout, false);
    this.type = C.FETCH_TRANSLATION_PLUGIN_REQUESTS;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.translationPluginRequests}?page=1&page_size=2000${role === "manager" ? `&request_to=${email}` : ''}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody(){
    return{
       
    }
  }

  getHeaders() {
    const authorization = localStorage.getItem("token");
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authorization
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report
  }
}
