import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Icon from '@mui/material/Icon';
import banner from '../../assets/dashboardbanner.png'
import icon1 from '../../assets/dashboardIcons/Icon1.svg';
import icon2 from '../../assets/dashboardIcons/Icon2.svg';
import icon3 from '../../assets/dashboardIcons/Icon3.svg';
import icon4 from '../../assets/dashboardIcons/Icon4.svg';
import { APITransport } from '../../redux/actions';
import FetchAppNumbersApi from '../../redux/actions/api/ReferenceApplications/fetchAppNumbers';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    height:"190px"
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: '3rem',
  },
  number: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  textBelow: {
    marginTop: theme.spacing(1),
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '80vh',
    gap:"20px",
    marginTop: theme.spacing(4),
   
  },
  firstBox: {
    backgroundImage: `url(${banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'start',
    textAlign: 'start',
    padding:"70px 40px",
  },
  secondBox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'start',
    flexDirection:"column",
    alignItems: 'start',
    padding: theme.spacing(4),
    backgroundColor: "white",
  },
}));

const UserDashboard = () => {
  const classes = useStyles();
  const email = localStorage.getItem('email')
  const dispatch = useDispatch()
 const appNumbers = useSelector(state => state.fetchAppNumbers.data)
 console.log(appNumbers,"ap");
  const cardData = [
    { icon: icon1, number: appNumbers?.total_applications, text: 'Total Application' },
    { icon: icon2, number: appNumbers?.approved_applications, text: 'Application Approved' },
    { icon: icon3, number: appNumbers?.pending_applications, text: 'Application Pending' },
    { icon: icon4, number: appNumbers?.rejected_applications, text: 'Application Rejected' },
  ];

  const FetchingAplicationNumbers = () => {
    const reqObj = new FetchAppNumbersApi(email);
    dispatch(APITransport(reqObj));
  };

  useEffect(() => {
    FetchingAplicationNumbers()
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className={classes.card}>
              <img className={classes.icon}  src={card.icon}/>
              <CardContent>
                <Typography className={classes.number}>{card.number}</Typography>
                <Typography variant="h6" fontFamily="Inter, sans-serif" fontWeight="600" className={classes.textBelow}>
                  {card.text}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box className={classes.mainContainer}>
        <Box className={classes.firstBox} style={{zIndex:"1"}}>
          <Typography variant="h4" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"25px"}} >AI-Powered Innovation</Typography>
          <Typography variant="h3" style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"25px"}}>BHASHINI: Parikshan Application</Typography>
          <Typography variant="body1"  style={{fontFamily:"Inter, sans-serif", fontWeight:"600",marginBottom:"40px"}}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </Typography>
          <Link to='/applications'>
          <Button variant="contained" color="primary" style={{borderRadius:"4px"}}>
          View Parikshan Applications
          </Button>
          </Link>
        </Box>
        <Box className={classes.secondBox}>
          <Typography variant="h5" style={{fontFamily:"Inter, sans-serif", fontWeight:"600"}}>Recent Activities</Typography>
          <Typography variant="body2">Some text at the top of the second box.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserDashboard;
