
import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class VerifyManagerRegistrationAPI extends API {
  constructor(email,timeout = 2000) {
    super("POST", timeout, false);
    this.type = C.VERIFY_MANAGER_REGISTRATION;
    this.email = email;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.verifyManagerRegistration}?email_id=${email}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return {
        // email_id: this.email
    };
  }

  getHeaders() {
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        "authorization": localStorage.getItem("token")
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report
  }
}
