// ForgetPassword

import { TextField, Grid, Button, Typography, IconButton, Box, useMediaQuery, InputAdornment } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoginStyles } from "../../../styles/LoginStyles";
import { useDispatch, useSelector } from "react-redux";
import { APITransport } from "../../../redux/actions";
import { setApiStatus } from "../../../redux/actions/Common";
import Logo from "../../../assets/bhashininewlogo3.png";
import ForgotPasswordAPI from "../../../redux/actions/api/UserManagement/ForgotPassword";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import '../Register/Register.css'
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const VerifyOtp = ({ open, handleClose }) => {
    const classes = LoginStyles();
    const dispatch = useDispatch();
    const location = useLocation()
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const apiStatus = useSelector((state) => state.apiStatus)
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const Base_url =
    process.env.REACT_APP_APIGW_BASE_URL ||
    "https://dashboard-staging-be.bhashini.co.in";

    const { emailValue } = location?.state;

    // const handleEmailSubmit = useCallback(async (e) => {
    //   e.preventDefault()
    // const endpoint = `${Base_url}/v1/send_otp`;
    //   try {
    //     const response = await axios.post(endpoint, {
    //       email_id: emailValue
    //     });
    //     console.log(response.data?.message)
    //     toast.success(response?.data?.message);
    //   } catch (error) {
    //     console.error('Error sending OTP:', error);
    //     // Handle error state here if needed
    //   }
    // }, [emailValue]);

    const submitForgetPasswordRequest = (e) => {
      e.preventDefault();
      // navigate("/user/verify-otp");

      const forgetPasswordAPIObj = new ForgotPasswordAPI(emailValue);
      dispatch(APITransport(forgetPasswordAPIObj));
  };

    useEffect(() => {
        if (apiStatus && apiStatus.apiType === "FORGOT_PASSWORD" && apiStatus.success) {
            // navigate("/user/login");
            dispatch(setApiStatus({
                progress: true,
                errors: false,
                message: "",
                unauthorized: false,
                apiType: "",
                data: [],
                success: true,
            }))
        }
    }, [apiStatus])

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleEmailSubmitSuccess = async (e) => {
      e.preventDefault()
      const endpoint = `${Base_url}/v1/verify_otp`;
      try {
        const response = await axios.post(endpoint, {
          email_id: emailValue,
          otp: otp,
        });
        console.log(response.data)
        // setEmailOTPSucess(true)
        // toast.success(response?.data?.message);
        // const forgetPasswordAPIObj = new ForgotPasswordAPI(emailValue);
        // dispatch(APITransport(forgetPasswordAPIObj));
        navigate(`/user/reset-password/${emailValue}`, {
          state: {
            check:true
          },
        }); 
        return response.data;
        // setResponse(response.data);
      } catch (error) {
        console.error('Error sending OTP:', error);
        // setSubmitted(true)
        // setEmailOTPSucess(false)
        toast.error(error?.response?.data?.detail)
        return null;
        // Handle error state here if needed
      }
      /// console.log(otpValues.join(''), "text")
      // alert("email sent successfully");
    };

    // const submitForgetPasswordRequest = (e) => {
    //     e.preventDefault();
    //     const forgetPasswordAPIObj = new ForgotPasswordAPI(email);
    //     dispatch(APITransport(forgetPasswordAPIObj));
    // };   

    return (
        <>
        <ToastContainer />
          <Box className="OnboardingForm_register">
        <Grid container style={{ width: isMobile ? '' : '100%',height:"100%" }}>
          <Grid item xs={12} md={6}>
            <Box className="OnboardingForm__imgSection">
              <Box className="OnboardingForm__imgSectionLogo">
                <img src={Logo} alt="logo" />
              </Box>
              <Box className="OnboardingForm__imgSectionTextContainer">
              <Box>

<Typography variant="h5" className="OnboardingForm__imgSectionHeading" fontFamily={"Inter, sans-serif"} fontWeight={600}>
Welcome to Bhashini Onboarding Portal
</Typography>
<Typography variant="body1" className="OnboardingForm__imgSectionText">
Your portal to access Parikshan Applications
</Typography>
</Box>
              
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
          <form onSubmit={handleEmailSubmitSuccess} style={{height:"102.2%"}}>
            <Grid container spacing={2} className={classes.forgotPasswordCard} style={{marginLeft:"0px"}}>
                <Box sx={{width:"100%", display:"flex", flexDirection:"column", gap:"10px"}}>

               
                <Grid item xs={12}>
                <Typography className={classes.subHeading} style={{marginBottom:"15px",marginTop:"10px"}}>Go back to<Link to="/user/login" style={{marginLeft:"7px",color:"#2947A3"}}>Log in</Link> </Typography>
                    <Typography className={classes.heading}>Verify Code</Typography>
                    <Typography className={classes.subHeading} style={{marginBottom:"15px",marginTop:"10px"}}>An authentication code has been sent to your email.</Typography>
                </Grid>

                <Grid item xs={12} style={{paddingTop:"0px", margin:"20px 0px"}} >
                    {/* <TextField
                        fullWidth
                        label="Enter Code"
                        className={classes.emailField}
                        name="email"
                        onChange={handleEmailChange}
                        value={email}
                    // InputLabelProps={{ shrink: true }}
                    /> */}
                     <Typography className={classes.subHeading} style={{color:"black"}}>Enter Code</Typography>
                     <TextField
            fullWidth
            // label="Enter Code"
            name="email"
            type={passwordVisibility ? "text" : "password"}
            onChange={handleOtpChange}
            value={otp}
            InputLabelProps={{shrink: true}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setPasswordVisibility(!passwordVisibility)}
                  >
                    {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
                     <Typography className={classes.subHeading} style={{marginBottom:"15px",marginTop:"10px"}}>Didn't receive code?<span style={{marginLeft:"7px",color:"red", cursor:"pointer"}} onClick={submitForgetPasswordRequest}>Resend</span> </Typography>
                </Grid>
                
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        className={classes.loginButton}
                        // onClick={submitForgetPasswordRequest}
                        type="submit"
                    >
                        Submit
                    </Button>
                </Grid>
                <Box display="flex" justifyContent="center" sx={{ width: "100%" }} mt={1} mb={1}>
                    {/* <Button variant="text" className={classes.forgotPasswordBtn} onClick={() => navigate("/user/login")}>
                        Sign In
                    </Button> */}
                     <Typography className={classes.registerLink}>Don’t have an account? <Link to='/user/register' style={{color:"#2947A3"}}>Create Account</Link></Typography>
                </Box>
                </Box>

            </Grid></form>
          </Grid>
        </Grid>
      {/* </Container> */}
    </Box>
        </>
    )
};

export default VerifyOtp;