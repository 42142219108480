
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchTranslationPluginRequestDetailsAPI extends API {
  constructor( Apikey,hostUrl ,timeout = 2000) {
    super("GET", timeout, false);
    this.type = C.FETCH_TRANSLATION_PLUGIN_REQUESTS_DETAILS;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.translationPluginRequestsdetails}?page=1&page_size=1000&api_key=${Apikey}&host_url=${hostUrl}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody(){
    return{
       
    }
  }

  getHeaders() {
    const authorization = localStorage.getItem("token");
    this.headers = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": authorization
      },
    };
    return this.headers;
  }

  getPayload() {
    return this.report
  }
}
