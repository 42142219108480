/**
 * Login API
 */
import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class FetchPluginUlcaKeysApi extends API {
  constructor(email, timeout = 2000) {
    super("POST", timeout, false);
    this.type = C.FETCH_PLUGIN_ULCA_KEYS;
    this.email = email;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.fetchPluginUlcaKeys}?email_id=${email}`;
  }

  processResponse(res) {
    super.processResponse(res);
    if (res) {
        this.report = res;
    }
}

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    return this.body
  }

  getHeaders() {
    this.headers = {
        headers: {
            "Content-Type": "application/json",
            "authorization": localStorage.getItem("token")
        },
    };
    return this.headers;
}

  getPayload() {
    return this.report
  }
}
