// RequestActionDialog

import React, {useEffect, useState} from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Paper,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import { styled } from "@mui/material/styles";
import {PluginRequestDetails} from "./PluginRequestDetails"
import {UserDetails} from "./UserDetails"
import { APITransport } from "../../redux/actions";
import FetchTranslationPluginRequestDetailsAPI from "../../redux/actions/api/Dashboard/PluginDashboard/FetchTranslationPluginRequestsDetails";

const BlueTab = styled(Tab)(({ theme }) => ({
    color: "#656565",
    fontFamily:"Inter, sans-serif",
    fontWeight: 'normal',
    "&.Mui-selected": {
      color: "#2563EB",
      fontWeight: 'normal'
    },
    "&:hover": {
      color: "#656565",
      fontWeight: 'normal'
    }
  }));

  const CustomTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
      backgroundColor: '#2563EB', 
      height: '3px',
    },
  }));
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{color:"#656565"}}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

export const PluginRequestAction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [rowData, setRowData] = React.useState();
  const requestData = location?.state?.reqData
  ;
  const requestRowData = location.state?.rowValue
  ;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const fetchTranslationPluginRequestDetails = useSelector(
    (state) => state?.fetchTranslationPluginRequestDetails?.data
  );

  const FetchTranslationPluginRequestDetails = () => {
    const apiObj = new FetchTranslationPluginRequestDetailsAPI(requestData?.api_key , requestData?.host_url);
    dispatch(APITransport(apiObj));
  };

  const role = localStorage.getItem("role");

  useEffect(() => {
    FetchTranslationPluginRequestDetails()
  }, []);

  useEffect(() => {
   if(role==="ceo"){
    setRowData(requestData?.ceo_approval)
   }else if(role==="manager"){
    setRowData(requestData?.manager_approval)
   }
  }, [role,requestData]);
  return (
    <Container maxWidth="lg">
      <Paper elevation={3}>
        <Button
          variant="text"
          color="inherit"
          onClick={() => navigate(-1, {replace: true})}
          sx={{mt: "16px",mb:2}}
        >
          <ArrowBackOutlinedIcon /> &nbsp; Bhashini Translation Plugin Request
        </Button>

        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <CustomTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{pl:3}}
        >
          <BlueTab label="Plugin Request Details" {...a11yProps(0)} />
          <BlueTab label="User Details" {...a11yProps(1)} />
        </CustomTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PluginRequestDetails requestData={requestData} filterdata={rowData} FetchTranslationPluginRequestDetails={FetchTranslationPluginRequestDetails} fetchTranslationPluginRequestDetails={fetchTranslationPluginRequestDetails} requestRowData={requestRowData}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UserDetails  fetchTranslationPluginRequestDetails={fetchTranslationPluginRequestDetails}/>
     </CustomTabPanel>
    </Box>
      </Paper>
    </Container>
  );
};
