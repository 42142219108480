import { makeStyles } from "@mui/styles";

export const LoginStyles = makeStyles({
  root: {
    height: "100vh",
    backgroundColor: "#F5F7FA",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  bhashiniLogoImg: {
    marginBottom: "50px",
  },

  loginCard: {
    background: "#fff",
    boxShadow: "0px 6px 12px 0px #ABBED14D",
    borderRadius: "8px",
    padding: "50px",
    maxWidth: "598px",
  },
  loginNewCard: {
    background: "#fff",
    boxShadow: "0px 6px 12px 0px #ABBED14D",
    borderRadius: "8px",
    padding: " 150px 110px",
    // maxWidth: "598px",
    width:"100%",
    height:"100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width:650px)": {
      padding: "20px",
    },
    // "@media (min-width:1850px)": {
    //   padding: " 250px 110px",
    // },
  },

  forgotPasswordCard: {
    background: "#fff",
    boxShadow: "0px 6px 12px 0px #ABBED14D",
    borderRadius: "8px",
    padding: " 150px 110px",
    // maxWidth: "598px",
    width:"100%",
    height:"100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap:"0px",
    "@media (max-width:650px)": {
      padding: "20px",
    },
    // "@media (min-width:1850px)": {
    //   padding: " 250px 110px",
    // },
  },

  heading: {
    fontSize: "36px",
    lineHeight: "44px",
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#000",
  },

  subHeading:{
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24.52px",
    color:"#8F8F8F",
    marginBottom:"20px"
 },

  emailField: {
    margin: "20px 0 15px 0px",
  },

  forgotPasswordBtn: {
    color: "#0671E0",
    fontSize: "16px",
    fontFamily: "Inter",
    padding: 0,
    fontWeight: 500,
    textDecoration: "underline",
    float: "inline-end",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },

  loginButton: {
    borderRadius: "4px",
    background: "#2947A3",
    width: "100%",
    height: "48px",
    fontSize: "16px",
  },

  registerLink:{
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24.52px",
    color:"#8F8F8F",
    marginTop:"20px"
 },
});
