import C from "../../../constants";

const initialState = {
    data: [],
}



const FetchManagerOverview = (state = initialState, action) => {
    console.log(action,"actionactionaction")
    switch (action.type) {
        case C.FETCH_MANAGERS_OVERVIEW:
           
            return {
                ...state,
                data : action.payload
            }
        default:
            return {
                ...state
            }
    }
};

export default FetchManagerOverview;