// referenceApplicationRequest


import API from "../api";
import ENDPOINTS from "../../../../config/apiEndpoint";
import C from "../../../constants";

export default class AddPluginUlcaDataApi extends API {
    constructor(email,name,app_name,user_id,apiKey,host_url, timeout = 2000) {
        super("POST", timeout, false);
        this.type = C.ADD_PLUGIN_DATA;
        this.email = email;
        this.name = name;
        this.app_name = app_name;
        this.user_id = user_id;
        this.apiKey = apiKey;
        this.host_url = host_url;
        this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.addPluginData}`;
    }

    processResponse(res) {
        super.processResponse(res);
        if (res) {
            this.report = res;
        }
    }

    apiEndPoint() {
        return this.endpoint;
    }

    getBody() {
        return {
            requested_by: this.email,
            requestor_name: this.name,
            app_name: this.app_name,
            user_id: this.user_id,
            api_key: this.apiKey,
            host_url: this.host_url,
          };
    }

    getHeaders() {
        this.headers = {
            headers: {
                "Content-Type": "application/json",
                "authorization": localStorage.getItem("token")
            },
        };
        return this.headers;
    }

    getPayload() {
        return this.report
    }
}
