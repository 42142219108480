import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
} from "react";
import {
    Box,
    Grid,
    Link,
    MenuItem,
    Select,
    ThemeProvider,
    IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import tableTheme from "../../theme/tableTheme";
import { APITransport } from "../../redux/actions";
import { DashboardStyles } from "../../styles/dashboardStyles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomizedSnackbars from "../../components/common/SnackBar";
import { setSnackBar } from "../../redux/actions/Common";
import ClearIcon from '@mui/icons-material/Clear';
import FetchCustomerRequestAPI from "../../redux/actions/api/Dashboard/FetchCustomerRequests";
import FetchcustomerCompleteDetailsAPI from "../../redux/actions/api/Dashboard/FetchCustomerRequests/customerCompleteDetails"
import fetchOrganisationNamesAPI from '../../redux/actions/api/UserManagement/fetchOrganisationNames';
import{setRegisteredExpertFilter,setOnboardingFilter,setpluginRequestFilter,setSahyogiRequestFilter,setManagerUserManagementFilter,setCustomerUserManagementFilter} from "../../redux/actions/Common";
const CustomerRequests = ({requestData}) => {
    // const statusData = [
    //     {
    //         value: "",
    //         label: "All Requests",
    //     },
    //     {
    //         value: "Pending",
    //         label: "Pending",
    //     },
    //     { value: "Approved", label: "Approved" },
    //     { value: "Rejected", label: "Rejected" },
    //     { value: "Registered", label: "Registered" },
    // ];


    const navigate = useNavigate();
    const classes = DashboardStyles();
    const [status, setStatus] = useState("");
    const [searchText, setSearchText] = useState(""); // State to store search text
    const dispatch = useDispatch();
    const [registration, setRegistration] = useState("");
    const [selectedDate, setSelectedDate] = useState(null); // add this state
    const tableRef = useRef(null);
    const orgDetails = useSelector(state => state.fetchOrganisationNames.data);
    const CustomerUserManagementFilter = useSelector((state) => state?.CustomerUserManagementFilter?.filterData);
    const [selectedorgDetails,setSelectedOrgDetails]= useState(requestData?requestData:CustomerUserManagementFilter?.orgType?CustomerUserManagementFilter?.orgType:"")

    const snackbar = useSelector((state) => state.commonReducer.snackbar);
    const onViewRequestClick = (rowData) => {
        navigate("/customer-request-action", { state: rowData.rowData });

    };

    useEffect(() => {
      dispatch(setCustomerUserManagementFilter({ orgType:selectedorgDetails,
       }))
  
    }, [dispatch,selectedorgDetails]);
  
    useEffect(() => {
        dispatch(setOnboardingFilter({}));
        dispatch(setpluginRequestFilter({}));
        dispatch(setSahyogiRequestFilter({}));
        dispatch(setManagerUserManagementFilter({}));
        dispatch(setRegisteredExpertFilter({}));
      }, []);

    const handleStatusFilterChange = (e) => {
        setStatus(e.target.value);
    };

    const handleRegistrationFilterChange = (e) => {
        setRegistration(e.target.value);
    };

     const getOrganisationTypes = () => {
    const reqObj = new fetchOrganisationNamesAPI();
    dispatch(APITransport(reqObj));
}

    useEffect(() => {
    getOrganisationTypes()
    }, [])

    const getorgDetails = () => {
        const requests = orgDetails || []; 
    
        // Format the data to include both 'value' and 'label'
        const formattedKeys = requests.map(item => ({
            value: item.name,  
            label: item.description || item.name 
        }));
    
        return [{ value: "", label: "Organisation Type" }, ...formattedKeys];
    };

    const renderToolBar = () => {
        return (
          <Grid container direction="column" spacing={2}>
            
            <Grid
              item
              container
              alignItems="center"
              spacing={2}
              justifyContent="flex-start"
            >
              <Grid item display="flex" alignItems="center" gap={2} sx={{ mr: 2, mb: 3 }}>
              <Select
                  displayEmpty
                  labelId="apiKey-select-label"
                  id="apiKey-select"
                  className={classes.searchBox}
                  value={selectedorgDetails}
                  onChange={(e) => setSelectedOrgDetails(e.target.value)}
                  style={{ minWidth: "200px", textAlign:"left" }}
                >
                  {getorgDetails()?.map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                      {el.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        );
      };

    const renderSnackBar = React.useCallback(() => {
        return (
            <CustomizedSnackbars
                open={snackbar.open}
                handleClose={() =>
                    dispatch(setSnackBar({ open: false, message: "", variant: "" }))
                }
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                variant={snackbar.variant}
                message={[snackbar.message]}
            />
        );

        //eslint-disable-next-line
    }, [snackbar]);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleClearDate = (event) => {
        event.stopPropagation();
        setSelectedDate(null);
    };

    const options = {
        textLabels: {
            body: { noMatch: "No records" },
            toolbar: {
                search: "Search",
                viewColumns: "View Column",
            },
            pagination: { rowsPerPage: "Rows per page" },
            options: { sortDirection: "desc" },
        },
        selectToolbarPlacement: "none",
        displaySelectToolbar: false,
        fixedHeader: false,
        download: false,
        print: false,
        rowsPerPageOptions: [10, 25, 50, 100],
        filter: false,
        viewColumns: false,
        search: false,
        jumpToPage: false,
        pagination: true,
        customToolbar: renderToolBar,
        searchText: searchText,
        onSearchChange: (searchText) => setSearchText(searchText),
        selectableRows: "none",
    };

    const columns = [
        {
            name: "_id",
            label: "Request Id",
            options: {
                display: false,
            },
        },
        {
            name: "first_name",
            label: "First Name",
            options: {
                setCellHeaderProps: () => ({
                  style: {
                    width: "auto",
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden',  
                    textOverflow: 'ellipsis' 
                  },
                }),
              },
        },
        {
            name: "last_name",
            label: "Last Name",
            options: {
                setCellHeaderProps: () => ({
                  style: {
                    width: "auto",
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden',  
                    textOverflow: 'ellipsis' 
                  },
                }),
              },
        },
        {
            name: "email_id",
            label: "Official Email Id",
            options: {
                setCellHeaderProps: () => ({
                  style: {
                    width: "auto",
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden',  
                    textOverflow: 'ellipsis' 
                  },
                }),
              },
            // options: {
            //     customBodyRender: (value) => {
            //         const date = new Date(value);
            //         const formattedDate = date.toLocaleDateString("en-GB");
            //         return <>{formattedDate}</>;
            //     },
            // },
        },
        {
            name: "org_type",
            label: "Organisation Type",
            options: {
                setCellHeaderProps: () => ({
                  style: {
                    width: "auto",
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden',  
                    textOverflow: 'ellipsis' 
                  },
                }),
              },
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                align: "center",
                customBodyRender: (value, meta) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <Link
                                fontWeight={600}
                                fontSize={"14px"}
                                color={"#2947A3"}
                                onClick={() => onViewRequestClick(meta)}
                            >
                                View More
                            </Link>
                        </div>
                    );
                },
            },
        },
    ];

    useEffect(()=>{
        const apiObj = new FetchCustomerRequestAPI("customer")
        dispatch(APITransport(apiObj))
    },[])

    const customerRequests = useSelector(
        (state) => state.fetchCustomerRequests?.data ?? []
    );

    const filteredData = useMemo(() => {
        if (!customerRequests) return [];
    
        return customerRequests?.filter(item => {
          const matchesOrgDetails = selectedorgDetails ? item.org_type === selectedorgDetails : true;
          
          return matchesOrgDetails;
        });
      }, [selectedorgDetails,customerRequests]);


    return (
        <div>
            {renderSnackBar()}
            <Grid container direction="row">
                <Grid item md={12} xs={12} style={{ width: "100%" }}>
                    <ThemeProvider theme={tableTheme}>
                        <MUIDataTable
                            ref={tableRef}
                            data={filteredData}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </div>
    );
};

export default CustomerRequests;
