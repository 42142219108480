import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton
} from "@mui/material";
import { styled } from "@mui/system";
import fetchReferenceApplicationStatusAPI from "../../redux/actions/api/ReferenceApplications/fetchReferenceApplicationStatus";
import { useDispatch, useSelector } from "react-redux";
import { APITransport } from "../../redux/actions";
import ReferenceApplicationRequestAPI from "../../redux/actions/api/ReferenceApplications/referenceApplicationRequest";
import app1 from "../../assets/application/chitraanuvaad.png";
import app2 from "../../assets/application/lekhaanuvaad.png";
import app3 from "../../assets/application/wtp.png";
import app4 from "../../assets/application/vaanianuvaad.png";
import { Link } from "react-router-dom";
import FetchReferenceApplicationsApi from "../../redux/actions/api/ReferenceApplications/fetchreferenceApplication";
import RevokeApplicationRequestAPI from "../../redux/actions/api/ReferenceApplications/RevokeApplication";
import { setSnackBar } from "../../redux/actions/Common";
import getApplicationDetailsApi from "../../redux/actions/api/ReferenceApplications/getApplicationDetails";
import CustomModal from "../../components/common/Modal";
import RequestAccessModal from "./RequestAccessModal";
import CloseIcon from '@mui/icons-material/Close';
import FetchCustomerRequestAPI from "../../redux/actions/api/Dashboard/FetchCustomerRequests";
import Upload from "../../assets/success.gif";


const StyledButton = styled(Button)(({ status }) => ({
  padding: '10px 20px',
  borderRadius: '5px',
  fontSize: '16px',
  color: 'white',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  // Conditional styles based on status
  ...(status === 'Approved' && {
    backgroundColor: '#DEFFDB',
    color:"#0C8501",
    borderRadius:"20px",
    '&:hover': {
      backgroundColor: '#DEFFDB',
    },
  }),
  ...(status === 'Pending' && {
    backgroundColor: '#FFF5E0',
    color:"#FFAC05",
    borderRadius:"20px",
    height:"auto",
    '&:hover': {
      backgroundColor: '#FFF5E0',
    },
  }),
  ...(status === 'Rejected' && {
    backgroundColor: '#FFF1F0',
    color:"#E02B1D",
    borderRadius:"20px",
    '&:hover': {
      backgroundColor: '#FFF1F0',
    },
  }),
  // Default style
  ...(status === undefined && {
    backgroundColor: 'gray',
    '&:hover': {
      backgroundColor: 'darkgray',
    },
  }),
}));

const ReferenceApplications = () => {
  const [selectedApps, setSelectedApps] = useState([]);
  const applicationList = useSelector(
    (state) => state?.referenceApplicationStatus?.data?.total_applications
  );
  const myapplicationList = useSelector(
    (state) => state.referenceApplicationStatus?.data?.my_applications
  );
  const fetchUserDetails = JSON.parse(localStorage.getItem('userDetails'));
  console.log(fetchUserDetails);
  const [clicked, setClicked] = useState({});
  const apiStatus = useSelector((state) => state.apiStatus);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const email = localStorage.getItem("email");
  const org_type = localStorage.getItem('org_type')
  const username = localStorage.getItem('username')
  const [appName, setAppName] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const [appDetails, setAppDetails] = useState({});
  const[assignManagerButton,setAssignManagerButton] = useState(false)
  const [openConfoirmModal, setOpenConfoirmModal] = useState(false);
  useEffect(()=>{
    const apiObj = new FetchCustomerRequestAPI("ceo")
    dispatch(APITransport(apiObj))
},[dispatch])

  const handleClickOpen = (app) => {
    setOpen(true);
    setAppName(app)
  };

    const handleButtonClick = (appdata) => {
      setAssignManagerButton(true)

      setOpenModal(true);
      const appdataCopy = { ...appdata }; 
    setAppDetails(appdataCopy);
      localStorage.setItem('appDetails', JSON.stringify(appdataCopy));
  };

  const handleRequestAccessButtonClick = (request_to) =>{
    const reqObj = new ReferenceApplicationRequestAPI(appDetails.name,email, org_type, username,request_to);
    dispatch(APITransport(reqObj));
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenConfoirmModal(false)
      setAssignManagerButton(false)
    }, 3000);

    return () => clearTimeout(timer);
  }, [openConfoirmModal]);

  const isInMyApplications = (appName) => {
    return myapplicationList.some((app) => app.application_name === appName);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getApplicationStatus = (appName) => {
    const app = myapplicationList.find((app) => app.application_name === appName);
    return app ? app.status : null;
  };
  let newsdata;
  const getAllApplicationStatus = (appName) => {
    const app = myapplicationList?.find((app) => app?.application_name === appName);
    console.log(app,"add");
    newsdata = app ? app?.status : null
    return app ? app?.status : null;
  };
  
  const getAllApplicationData = () => {
    const reqObj = new fetchReferenceApplicationStatusAPI();
    dispatch(APITransport(reqObj));
    setSelectedApps([]);
  };

  const FetchingReferenceAplication = () => {
    const reqObj = new FetchReferenceApplicationsApi(email);
    dispatch(APITransport(reqObj));
  };

  useEffect(() => {
    getAllApplicationData();
    FetchingReferenceAplication();
  }, []);

  const requestForReferenceAplication = () => {
    const reqObj = new ReferenceApplicationRequestAPI(selectedApps);
    dispatch(APITransport(reqObj));
  };

  const revokeReferenceAplication = () => {
    const reqObj = new RevokeApplicationRequestAPI(appName,email);
    dispatch(APITransport(reqObj));
  };

  const FetchSingleAplicationDetails = (appname) => {
    const reqObj = new getApplicationDetailsApi(appname,email);
    dispatch(APITransport(reqObj));
  };

  useEffect(() => {
    if (apiStatus.apiType === "REQUEST_FOR_REFERENCE_APPLICATION" || apiStatus.apiType === "REVOKE_REFERENCE_APPLICATION") {
      apiStatus?.success && setOpen(false);
      apiStatus?.success && FetchingReferenceAplication();
      apiStatus?.success && setOpenModal(false)
      apiStatus?.success && assignManagerButton && setOpenConfoirmModal(true)
      apiStatus?.success && dispatch(setSnackBar({
        open: true,
        message: apiStatus.apiType === "REQUEST_FOR_REFERENCE_APPLICATION" ?  'Request sent successfully' : 'Request revoked successfully',
        variant: "success"
      }));
    }
  }, [apiStatus,assignManagerButton]);

  const onCardClick = (app) => {
    if (app.status !== "Yet to submit") {
      return;
    }
    let allSelectedApps = [...selectedApps];
    if (allSelectedApps.includes(app.name)) {
      allSelectedApps = allSelectedApps?.filter((el) => el !== app.name);
    } else {
      allSelectedApps.push(app.name);
    }
    console.log("allSelectedApps --- ", allSelectedApps);
    setSelectedApps(allSelectedApps);
  };

 const handleConfoirmModalClose =()=>{
  setOpenConfoirmModal(false)
  setAssignManagerButton(false)
 }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {" "}
          <Typography
            sx={{ fontFamily: "Inter, sans-serif", fontWeight: "600" }}
            variant="h2"
            gutterBottom
          >
            Bhashini’s{" "}
            <span style={{ color: "#2947A3" }}>Parikshan Applications</span>
          </Typography>
          <Typography
            sx={{ fontFamily: "Inter, sans-serif", fontWeight: "400" }}
            variant="subtitle2"
            gutterBottom
          >
            Choose the applications that best fit your needs.
          </Typography>
        </Box>
        <Box>
          {" "}
          {selectedApps.length > 0 && (
            <Button
              variant="contained"
              sx={{
                borderRadius: "4px",
                background: "#2947A3  ",
                // width: "40%",
                // height: "48px",
                fontSize: "16px",
              }}
              // onClick={requestForReferenceAplication}
              onClick={handleClickOpen}
            >
              Send for approval
            </Button>
          )}
        </Box>
      </Box>

      <Grid container spacing={4}>
        {applicationList?.map((app, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={app.name}
            sx={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => onCardClick(app)}
          >
            <Card
              sx={{
                height: "100%",
                border: selectedApps.includes(app.name)
                  ? "2px solid #2947A3"
                  : "#FFFFFF",
              }}
            >
              <CardMedia
                style={{ height: "140px", margin: "20px" }}
                image={app.icon}
                title={app.name}
              />
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Box>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      fontFamily: "Inter, sans-serif",
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                    mb={1}
                  >
                    {app.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Inter, sans-serif",
                      fontWeight: "400",
                      textAlign: "start",
                    }}
                    mb={2}
                  >
                    {app.description}
                  </Typography>
                </Box>
                <Grid sx={{ flexDirection: "column", display: "flex", gap: 2 }}>
                  {/* <Button variant="text" color="primary" sx={{ fontFamily: "Inter, sans-serif" }} onClick={()=>console.log("will teach you in phase 2!")}>
                                        Learn more
                                    </Button> */}
                  {/* {app.status !== "Yet to submit" ? <StyledButton disableRipple>
                                        Sent for review
                                    </StyledButton> :  <div style={{height: "30px"}}></div>} */}
                  {/* {app.status !== "Yet to submit" 
                                    ? app.status === "CEO Approved"
                                        ? <StyledButtonSuccess disableRipple> Approved</StyledButtonSuccess>
                                        : <StyledButton disableRipple>Sent for Review</StyledButton>
                                    : <div style={{height: "30px"}}></div>} */}
                  {getAllApplicationStatus(app?.name)  ? (
                    <>
                    <StyledButton disableRipple status={newsdata}> {newsdata === 'Pending' ? 'Sent for review' : newsdata}</StyledButton>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                  {/* { console.log(getAllApplicationStatus(app?.name),"aa")} */}
                  {/* {app.status !== "Yet to submit" 
                                    ? app.status === "CEO Approved" ? 
                                        <StyledButtonSuccess disableRipple> Approved</StyledButtonSuccess>
                                        : app.status === "Rejected"
                                        ? <StyledButton disableRipple>Rejected</StyledButton> : <StyledButton disableRipple>Sent for Review</StyledButton>
                                    : <div style={{height: "30px"}}></div>} */}
                </Grid>
              </CardContent>
              <Box
                mb={3}
                display="flex"
                justifyContent="end"
                gap={2}
                alignItems="center"
                px={2}
              >
                {!isInMyApplications(app.name)  ? (
                  <Box>
                   
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: "4px" }}
                        onClick={() => handleButtonClick(app,true)}
                      >
                        Request Application
                      </Button>
                
                  </Box>
                ) : (
                  <>
                    <Box>
                      <Link to="/view">
                        <Button
                          variant={(getApplicationStatus(app.name) === 'Approved') || (getApplicationStatus(app.name) === 'Rejected') ? 'contained' :"outlined"}
                          color="primary"
                          style={{ borderRadius: "4px" }}
                        //   onClick={() => window.open(app.app_url, "_blank")}
                        onClick={() => FetchSingleAplicationDetails(app.name)}
                        >
                          View more
                        </Button>
                      </Link>
                    </Box>
                   {(getApplicationStatus(app.name) === 'Approved') || (getApplicationStatus(app.name) === 'Rejected') ? null : <Box>
                      {" "}
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: "4px" }}
                        onClick={() => handleClickOpen(app.name)}
                      >
                        Revoke Request
                      </Button>
                    </Box>}
                  </>
                )}
                  {/* <div key={app.name} className="card">
          <h3>{app.name}</h3>
          {!clicked[app.name] ? (
            // Initial state with one button
            <button onClick={() => handleButtonClick(app.name)}>
              Initial Button
            </button>
          ) : (
            // State after clicking: display two buttons
            <div>
              <button onClick={() => console.log('Button 1 clicked')}>
                Button 1
              </button>
              <button onClick={() => console.log('Button 2 clicked')}>
                Button 2
              </button>
            </div>
          )}
        </div> */}
              </Box>
            </Card>
          </Grid>
        ))}
        {/* {selectedApps.length > 0 && <Grid md={12} lg={12} sm={12} textAlign={"end"} mt={20}>
                    <Button 
                        variant='contained' 
                        sx={{borderRadius: "4px",
                        background: "#0671E0",
                        width: "20%",
                        height: "48px",
                        fontSize: "16px"}}
                        onClick={requestForReferenceAplication}
                    >Send for approval</Button>
                </Grid>}  */}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiPaper-root": { borderRadius: "16px", padding: "20px" } }}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h5"
            fontFamily={"Inter, sans-serif"}
            fontWeight={600}
            textAlign={"start"}
          >
            Do you want to revoke the application request?
          </Typography>
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "4px",
              background: "#ffffff  ",
              fontSize: "16px",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "4px",
              background: "#2947A3  ",
              fontSize: "16px",
            }}
            onClick={revokeReferenceAplication}
            autoFocus
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      {openModal && ( 
          <CustomModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            paddingdata="30px 0px 20px 20px"

          >
           <RequestAccessModal appDetails={appDetails} setOpenModal={setOpenModal} handleRequestAccessButtonClick={handleRequestAccessButtonClick}/>
          </CustomModal>
        )}


        {openConfoirmModal && (
          <CustomModal
            open={openConfoirmModal}
            handleClose={handleConfoirmModalClose}
            paddingdata="30px 0px 20px 20px"
          >
             <Grid container direction="column" sx={{ position: 'relative'}}>
      <Grid item>
        <IconButton
          sx={{
            position: 'absolute',
            top: -9,
            right: 5,
          }}
          onClick={handleConfoirmModalClose}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item >
      <Typography align="center">
                <img
                  src={Upload}
                  alt="Logo"
                  style={{
                    height:"130px"
                  }}
                />
              </Typography>
        <Typography sx={{ fontSize: '20px', fontWeight: '600',ml:2  }}>
          Your request has been successfully sent.
        </Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: '400', textAlign: 'center', mt: '2px',mr:3 }}>
          We've received your request and will get back to you shortly.
        </Typography>
      </Grid>
    </Grid>
          </CustomModal>
        )}
    </>
  );
};

export default ReferenceApplications;
