/**
 * Fetch Customer Requests API
 */
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";

export default class FetchcustomerCompleteDetailsAPI extends API {
    constructor(email,timeout = 2000) {
        super("POST", timeout, false);
        this.type = C.CUSTOMER_COMPLETE_DETAILS;
        this.email = email;
        this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.customerCompleteDetails}`;
    }

    processResponse(res) {
        super.processResponse(res);
        if (res) {
            this.report = res;
        }
    }

    apiEndPoint() {
        return this.endpoint;
    }

    getBody() { return {
        email_id: this.email,
        
       
      }; }

    getHeaders() {
        const authorization = localStorage.getItem("token");
        this.headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: authorization,
            },
        };
        return this.headers;
    }

    getPayload() {
        return this.report;
    }
}
