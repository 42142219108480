import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {DashboardStyles} from "../../styles/dashboardStyles";
import { CChart, CChartBar } from "@coreui/react-chartjs";
import { APITransport } from "../../redux/actions";
import{setRegisteredExpertFilter,setOnboardingFilter,setpluginRequestFilter,setSahyogiRequestFilter,setManagerUserManagementFilter,setCustomerUserManagementFilter} from "../../redux/actions/Common";
import fetchCeoDashboardRequestsAPI from "../../redux/actions/api/Dashboard/FetchCeodashbord/FetchCeoDashBoard";
import FetchCustomeroverviewAPI from "../../redux/actions/api/Dashboard/FetchCeodashbord/Fetchcustomer_overview";
import FetchEmployeeRegistrationAPI from "../../redux/actions/api/Dashboard/FetchCeodashbord/FetchEmployeeRegistration";
import FetchSahyogiRequestOverviewAPI from "../../redux/actions/api/Dashboard/FetchCeodashbord/FetchSahyogiRequestOverview";
import FetchEmployeeInviteManagerAPI from "../../redux/actions/api/Dashboard/FetchCeodashbord/FetchEmployeeInvitedManagers";
import FetchManagersOverViewAPI from "../../redux/actions/api/Dashboard/FetchCeodashbord/FetchManagersOverView";
import FetchApplicationRequestStatusAPI from "../../redux/actions/api/Dashboard/FetchCeodashbord/FetchApplicationRequestStatus";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Stack, Container, TextField } from "@mui/material";
import HorizontalBarChartComponent  from "./ManagersOverview";
import DoughnutChart from "./ApplicationRequestStatus"
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  DoughnutController,
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns";

// Register the necessary Chart.js components
ChartJS.register(
  Tooltip,
  CategoryScale,
  Legend,
  BarElement,
  LinearScale,
  DoughnutController,
  ArcElement,
  PointElement,
  LineElement,
  TimeScale,
  ChartDataLabels
);
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#FFF5CC",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FFB200" : "#308fe8",
  },
}));
const DirectBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#CCF8FE",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#02A0FC" : "#308fe8",
  },
}));
const colors = [
  ["#02A0FC", "#CCF8FE"],
  ["#FFB200", "#FFF5CC"],
  ["#E76F18", "#FFE5D3"],
  ["#5429FF", "#ebdef0"],
  ["#D429FF", "#f1d4f0"],
];

// Styled LinearProgress component with dynamic color
const BorderLinearProgressApplication = styled(LinearProgress)(
  ({ backgroundColor, barColor }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor || "#e0e0e0", // Default background color
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barColor || "#1a90ff", // Default bar color
    },
  })
);

const users = [
  { username: "John Doe" },
  { username: "Smith" },
  { username: "John Doe" },
];

const GridComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = DashboardStyles();

  const CeoDashboardRequests = useSelector(
    (state) => state?.fetchCeoDashboardRequests?.data
  );
  const CustomeroverviewRequests = useSelector(
    (state) => state?.fetchCustomeroverview?.data
  );

  const EmployeeRegistration = useSelector(
    (state) => state?.FetchEmployeeRegistration?.data
  );

  const SahyogiRequestOverview = useSelector(
    (state) => state?.FetchSahyogiRequestOverview?.data
  );

  const EmployeeInviteManager = useSelector(
    (state) => state?.FetchEmployeeInviteManager?.data
  );

  const ManageroverviewRequests = useSelector(
    (state) => state?.FetchManagerOverview?.data
  );

  const ApplicationRequestStatus = useSelector(
    (state) => state?.FetchApplicationRequestStatus?.data
  );

  const approvedAndRejected =
    EmployeeRegistration?.approved_requests +
    EmployeeRegistration?.rejected_requests;
  const EmployeeRegistrationpercentage = Math.round(
    (approvedAndRejected / EmployeeRegistration?.total_requests) * 100
  );

  const EmployeeInviteRegistrationpercentage = Math.round(
    (EmployeeInviteManager?.Registered_requests /
      EmployeeInviteManager?.total_requests) *
      100
  );



  const role = localStorage.getItem("role");
  const email = localStorage.getItem("email");

  const fetchOnboardManagers = () => {
    const apiObj = new fetchCeoDashboardRequestsAPI(role, email);
    dispatch(APITransport(apiObj));
  };

  const customerResult = () => {
    const apiObj1 = new FetchCustomeroverviewAPI();
    dispatch(APITransport(apiObj1));
  };

  const managerResult = () => {
    const apiObj1 = new FetchManagersOverViewAPI();
    dispatch(APITransport(apiObj1));
  };

  const applicationRequestStatusAPI = () => {
    const apiObj1 = new   FetchApplicationRequestStatusAPI();
    dispatch(APITransport(apiObj1));
  };


  const FetchEmployeeRegistration = () => {
    const apiObj = new FetchEmployeeRegistrationAPI();
    dispatch(APITransport(apiObj));
  };

  const FetchSahyogiRequestOverview = () => {
    const apiObj = new FetchSahyogiRequestOverviewAPI();
    dispatch(APITransport(apiObj));
  };

  const FetchEmployeeInviteManager = () => {
    const apiObj = new FetchEmployeeInviteManagerAPI();
    dispatch(APITransport(apiObj));
  };

  useEffect(() => {
    fetchOnboardManagers();
    customerResult();
    FetchEmployeeRegistration();
    FetchSahyogiRequestOverview();
    FetchEmployeeInviteManager();
    managerResult()
    applicationRequestStatusAPI()
  }, []);

   useEffect(() => {
    dispatch(setOnboardingFilter({}));
    dispatch(setpluginRequestFilter({}));
    dispatch(setSahyogiRequestFilter({}));
    dispatch(setManagerUserManagementFilter({}));
    dispatch(setCustomerUserManagementFilter({}));
    dispatch(setRegisteredExpertFilter({}));

  }, []);

  const labels = CustomeroverviewRequests?.org_type_counts?.map(
    (item) => item.org_type || "Unknown"
  );
  const chartData = CustomeroverviewRequests?.org_type_counts?.map(
    (item) => item.count
  );

  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: chartData,
        barPercentage: 0.6,
        categoryPercentage: 0.8,
        backgroundColor: "#42A5F5",
        hoverBackgroundColor: "#0941b8",
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "end",
        color: "#000",
        font: {
          weight: "bold",
        },
        formatter: (value) => value,
        padding: {
          top: 8,
        },
        clip: false,
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10, // Increase bottom padding if needed
        left: 10, // Increase left padding if needed
        right: 50, // Increase right padding if needed
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#000",
        },
      },
    },
    onClick: (e, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        const label = labels[index];
        navigate("/user-management", {
          state: { clickedLabel: label, userManagement: true },
        });
      }
    },
  };

  const transformData = (data) => {
    const monthToNumber = (month) => {
      const months = {
        january: "01",
        february: "02",
        march: "03",
        april: "04",
        may: "05",
        june: "06",
        july: "07",
        august: "08",
        september: "09",
        october: "10",
        november: "11",
        december: "12",
      };
      return months[month.toLowerCase()] || "01";
    };

    if (!data) return [];

    return Object.entries(data || {}).flatMap(([monthYear, entries]) => {
      const [month, year] = (monthYear || "").split(/(\d{4})/).filter(Boolean);
      const formattedMonth = monthToNumber(month);

      return (entries || []).map((entry) => {
        const [day] = (entry?.date || "").split(" ");
        return {
          x: `${year}-${formattedMonth}-${day?.padStart(2, "0")}`,
          y: entry?.count,
        };
      });
    });
  };

  const transformedData = transformData(SahyogiRequestOverview?.formList);

  const linechartData = {
    datasets: [
      {
        label: "Total Customers",
        data: transformedData,
        fill: false,
        backgroundColor: "#1E93FF",
        borderWidth: 1.5,
        borderColor: "#1E93FF",
        tension: 0.5,
        pointRadius: 3,
        pointHoverRadius: 1,
      },
    ],
  };

  const linechartOptions = {
    responsive: true,
    plugins: {
      annotation: {
        annotations: {
          line1: {
            type: "line",
            xMin: 5,
            xMax: 5,
            borderColor: "#1E93FF",
            borderWidth: 2,
            borderDash: [10, 5],
          },
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#EAF946",
        titleColor: "#000",
        bodyColor: "#000",
        callbacks: {
          label: function (context) {
            let label = `Total Customers: ${
              context.raw.y !== null ? context.raw.y : "N/A"
            }`;
            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        align: "start",
        padding: {
          top: 10,
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
          tooltipFormat: "dd/MMM/yy",
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "#000",
          autoSkip: false,
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        grid: {
          color: "#ddd",
          borderDash: [5, 5],
        },
        ticks: {
          color: "#000",
          stepSize: 2,
          callback: function (value) {
            return value % 4 === 0 ? value : "";
          },
        },
        suggestedMin: 0,
        suggestedMax: 16,
      },
    },
  };

 

  const handleProgressClick = (label, value, event) => {
    if (value === "Application Request Status") {
      event.stopPropagation();

      navigate("/requests", { state: { clickedLabel: label } });
    } else {
      navigate("/requests", { state: { label } });
    }
  };

  const handleCustumberOverviewClick = () => {
    navigate("/user-management", {
      state: { clickedLabel: "", userManagement: true },
    });
  };

  const handleEmployeeRegistrationClick = (label, event) => {
    event.stopPropagation();
    navigate("/user-management", { state: { label } });
  };

  const handleApplicationRequestStatusClick = () => {
    navigate("/requests");
  };

  const calculateProgress = (approved, total, rejected) =>
    total === 0 ? 0 : ((approved + rejected) / total) * 100;

  // Function to get colors for a given index
  const getColorPair = (index) => colors[index % colors.length];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2} >
          <Grid item xs={12}>
              <Box
                className={classes.applicationRequestBox}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box
                     className={classes.applicationRequestHeaderBox}
                      onClick={handleApplicationRequestStatusClick}
                    >
                      <Typography
                        variant="subtitle2"
                        className={classes.applicationRequestTypography}
                      >
                        Application Request Status
                      </Typography>
                    </Box>

                    <Box
                     className={classes.applicationRequestTotalRequestslabel}
                      onClick={handleApplicationRequestStatusClick}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontFamily: "Inter, sans-serif",
                          fontSize: "13px",
                          fontWeight: 400,
                        }}
                        onClick={handleCustumberOverviewClick}
                      >
                        Total Requests:{" "}
                        <span style={{ fontWeight: 600 }}>
                          {CeoDashboardRequests.total_requests}
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <DoughnutChart ApplicationRequestStatus={ApplicationRequestStatus}/>
                    
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {role === "ceo"?
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: "#ffffff", mt: 2}}>
                  <Grid container>
                    <Grid container  alignItems="center">
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          // className={classes.customerOverviewHeader}

                          sx={{
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 600,
                            fontSize: "14px",
                            padding: "8px",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            marginTop: "8px",
                            marginLeft: "8px",
                            width: "200px",
                          }}
                          // onClick={handleApplicationRequestStatusClick}
                        >
                          Managers Overview
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <HorizontalBarChartComponent ManageroverviewRequests={ManageroverviewRequests} />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>:[]}
          <Grid container spacing={2}>
            <Grid item xs={12} >
              <Box sx={{ backgroundColor: "white", marginTop: 2 , p:1}}>
                <Typography
                  variant="subtitle2"
                  className={classes.sahyogiRequestHeader}
                >
                  Sahyogi Request: Overview
                </Typography>

                <Line
                  data={linechartData}
                  options={linechartOptions}
                  height={100} 
                />
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: "#ffffff", mt: 3 ,ml:2}}>
                  <Grid container>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle2"
                          className={classes.customerOverviewHeader}

                          sx={{
                           
                          }}
                          onClick={handleCustumberOverviewClick}
                        >
                          Customer Overview
                        </Typography>
                      </Grid>
                      <Grid item xs={6} container justifyContent="flex-end">
                        <Typography
                          variant="subtitle2"
                          className={classes.totalCustomersHeader}
                          onClick={handleCustumberOverviewClick}
                        >
                          Total Customers:{" "}
                          <span style={{ fontWeight: 600 }}>
                            {CustomeroverviewRequests?.total_customers}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ width: "100%" }}>
                        <Bar data={data} options={options} height={100} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={4} sx={{ width: "100vh" }}>
        {role === "ceo" && (
              <Grid item xs={12}sx={{mb:2}}>
                <Box
                className={classes.employeeRegistrationheader}
                   onClick={(event) =>
                      handleEmployeeRegistrationClick(
                        "",
                        event
                      )
                    }
                >                
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      lg={10}
                      xl={10}
                      sx={{ padding: 2 }}
                    >
                      <Typography
                        variant="subtitle2"
                        className={classes.employeeRegistrationTypography}
                      >
                        Employee Registration
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={11}
                      lg={11}
                      xl={11}
                      sx={{ padding: "0px 0px 15px 20px" }}
                    >
                      <Box
                      className={classes.invitedHeader}
                         onClick={(event) =>
                            handleEmployeeRegistrationClick(
                              "Invited",
                              event
                            )
                          }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: 1,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.invitedTypography}
                          >
                            Invited Managers
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className={classes.invitedTypographyValue}
                          >
                            {EmployeeInviteRegistrationpercentage || 0}%
                          </Typography>
                        </Box>
                        <BorderLinearProgress
                          variant="determinate"
                          value={EmployeeInviteRegistrationpercentage || 0}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={11}
                      lg={11}
                      xl={11}
                      sx={{ padding: "0px 0px 40px 20px" }}
                    >
                       <Box
                        className={classes.invitedHeader}
                         onClick={(event) =>
                            handleEmployeeRegistrationClick(
                              "Direct",
                              event
                            )
                          }
                      >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.invitedTypography}
                        >
                          Direct Managers
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={classes.directTypographyValue}
                        >
                          {EmployeeRegistrationpercentage || 0}%
                        </Typography>
                      </Box>

                        <DirectBorderLinearProgress
                          variant="determinate"
                          value={EmployeeRegistrationpercentage || 0}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          <Box sx={{ backgroundColor: "#ffffff", padding: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "Inter, sans-serif",
                fontSize: "14px",
                fontWeight: 600,
                padding: 1,
              }}
            >
              Customer Requests by Application
            </Typography>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              {Object.entries(CeoDashboardRequests)?.map(
                (
                  [
                    key,
                    { total_requests, approved_requests, rejected_requests },
                  ],
                  index
                ) => {
                  if (key === "total_requests") return null;

                  const progress = calculateProgress(
                    approved_requests,
                    total_requests,
                    rejected_requests
                  );
                  const [barColor, backgroundColor] = getColorPair(index);

            return (
              <Box
                key={key}
                className={classes.customerRequestsSubtitleHeader}
                onClick={() => handleProgressClick(key)}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  padding="15px 0px 28px 0px"
                >
                  <Typography
                    variant="subtitle2"
                    className={classes.customerRequestsSubtitleName}
                  >
                    {key}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.customerRequestsSubtitleValue}
                    sx={{
                      color: barColor,
                    }}
                  >
                    {approved_requests+rejected_requests}/{total_requests}
                  </Typography>
                </Box>
                <BorderLinearProgressApplication
                  variant="determinate"
                  value={progress}
                  backgroundColor={backgroundColor}
                  barColor={barColor}
                />
              </Box>
            );
          }
        )}
      </Stack>
    </Box>
    
          {/* <Box sx={{ backgroundColor: "#ffffff", mt: 3, padding: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "Inter, sans-serif",
                fontWeight: "bold",
                fontSize: "16px",
                fontWeight: 600,
                padding: 1,
              }}
            >
              Employee Activity
            </Typography>
            {users.map((user, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                spacing={2}
                marginBottom={2}
                sx={{ marginTop: "10px" }}
              >
                <Avatar sx={{ bgcolor: "#02A0FC", width: 20, height: 20 }}>
                  {user.username.charAt(0)}
                </Avatar>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Inter, sans-serif",
                    fontWeight: "bold",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {user.username}
                </Typography>
              </Stack>
            ))}
          </Box> */}
        </Grid>
      </Grid>
    </>
  );
};

export default GridComponent;
