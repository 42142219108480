// ForgetPassword

import { TextField, Grid, Button, Typography, IconButton, Box, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LoginStyles } from "../../../styles/LoginStyles";
import { useDispatch, useSelector } from "react-redux";
import { APITransport } from "../../../redux/actions";
import { setApiStatus } from "../../../redux/actions/Common";
import Logo from "../../../assets/bhashininewlogo3.png";
import ForgotPasswordAPI from "../../../redux/actions/api/UserManagement/ForgotPassword";
import '../Register/Register.css'
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ForgetPassword = ({ open, handleClose }) => {
    const classes = LoginStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const apiStatus = useSelector((state) => state.apiStatus)
    const [email, setEmail] = useState("");

    const Base_url =
    process.env.REACT_APP_APIGW_BASE_URL ||
    "https://dashboard-staging-be.bhashini.co.in";

    useEffect(() => {
        if (apiStatus && apiStatus.apiType === "FORGOT_PASSWORD" && apiStatus.success) {
          navigate("/user/verify-otp", {
                  state: {
                    emailValue: email,
                  },
                });
            dispatch(setApiStatus({
                progress: true,
                errors: false,
                message: "",
                unauthorized: false,
                apiType: "",
                data: [],
                success: true,
            }))
        }
    }, [apiStatus])

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // const handleEmailSubmit = useCallback(async (e) => {
    //   e.preventDefault()
    // const endpoint = `${Base_url}/v1/send_otp`;
    //   try {
    //     const response = await axios.post(endpoint, {
    //       email_id: email
    //     });
    //     console.log(response.data?.message)
    //     toast.success(response?.data?.message);
    //     // alert(response?.data?.message);
    //     navigate("/user/verify-otp", {
    //       state: {
    //         emailValue: email,
    //       },
    //     });
    //     // setResponse(response.data);
    //   } catch (error) {
    //     console.error('Error sending OTP:', error);
    //     // Handle error state here if needed
    //   }
    // }, [email]);

    const submitForgetPasswordRequest = (e) => {
        e.preventDefault();
        // navigate("/user/verify-otp");
        const forgetPasswordAPIObj = new ForgotPasswordAPI(email);
        dispatch(APITransport(forgetPasswordAPIObj));
    };

    return (
        <>
        <ToastContainer />
          <Box className="OnboardingForm_register">
        <Grid container style={{ width: isMobile ? '' : '100%',height:"100%" }}>
          <Grid item xs={12} md={6}>
            <Box className="OnboardingForm__imgSection">
              <Box className="OnboardingForm__imgSectionLogo">
                <img src={Logo} alt="logo" />
              </Box>
              <Box className="OnboardingForm__imgSectionTextContainer">
                
              <Box>

                <Typography variant="h5" className="OnboardingForm__imgSectionHeading" fontFamily={"Inter, sans-serif"} fontWeight={600}>
                Welcome to Bhashini Onboarding Portal
                </Typography>
                <Typography variant="body1" className="OnboardingForm__imgSectionText">
                Your portal to access Parikshan Applications
                </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
          <form onSubmit={submitForgetPasswordRequest} style={{height:"102.2%"}}>
            <Grid container spacing={2} className={classes.forgotPasswordCard} style={{marginLeft:"0px"}}>
                <Box sx={{width:"100%", display:"flex", flexDirection:"column", gap:"10px"}}>

               
                <Grid item xs={12}>
                    <Typography className={classes.heading}>Forgot Password?</Typography>
                    <Typography className={classes.subHeading} style={{marginBottom:"15px",marginTop:"10px"}}>Go back to<Link to="/user/login" style={{marginLeft:"7px",color:"#2947A3"}}>Log in</Link> </Typography>
                </Grid>

                <Grid item xs={12} style={{paddingTop:"0px"}}>
                    <TextField
                        fullWidth
                        label="Enter Email Id"
                        className={classes.emailField}
                        name="email"
                        onChange={handleEmailChange}
                        value={email}
                    // InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        className={classes.loginButton}
                        // onClick={submitForgetPasswordRequest}
                        type="submit"
                    >
                        Submit
                    </Button>
                </Grid>
                <Box display="flex" justifyContent="center" sx={{ width: "100%" }} mt={1} mb={1}>
                    {/* <Button variant="text" className={classes.forgotPasswordBtn} onClick={() => navigate("/user/login")}>
                        Sign In
                    </Button> */}
                     <Typography className={classes.registerLink}>Don’t have an account? <Link to='/user/register' style={{color:"#2947A3"}}>Register</Link></Typography>
                </Box>
                </Box>

            </Grid></form>
          </Grid>
        </Grid>
      {/* </Container> */}
    </Box>
        </>
    )
};

export default ForgetPassword;