
import React, {
  useState,
  useEffect,
  useRef,
  useMemo
} from "react";
import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  ThemeProvider,
  IconButton,
  Typography,
  Avatar,
  Container,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import tableTheme from "../../theme/tableTheme";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
const RequestDetails = ({ customerCompleteDetail }) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const TranslationPluginRequest = customerCompleteDetail.plugin_requests
  const ParikshanApplicationRequest = customerCompleteDetail.ref_app_requests
  const Profile = customerCompleteDetail.profile


  const options = {
    textLabels: {
      body: { noMatch: "No records" },
      toolbar: {
        search: "Search",
        viewColumns: "View Column",
      },
      pagination: { rowsPerPage: "Rows per page" },
      options: { sortDirection: "desc" },
    },
    selectToolbarPlacement: "none",
    displaySelectToolbar: false,
    fixedHeader: false,
    download: false,
    print: false,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    filter: false,
    viewColumns: false,
    search: false,
    jumpToPage: false,
    pagination: true,
    // customToolbar: renderToolBar,
    searchText: searchText,
    onSearchChange: (searchText) => setSearchText(searchText),
    selectableRows: "none",
    rowsPerPage: 5
  };



  const rowData = useMemo(() => {
    return ParikshanApplicationRequest?.map((el, i) => ({
      ...el,
      id: el._id,
      applicationName: el?.application_name,
      requested_on: el?.requested_on,
      manager_name: el.manager_approval?.updater_name,
      manager_approval_updated_on: el.manager_approval?.updated_on,
      manager_approval_status: el.manager_approval?.status,
      ceo_approval_updated_on: el.ceo_approval?.updated_on,
      overallStatus: el?.status
    }));
  }, [ParikshanApplicationRequest]);

  const columns = [
    {
      name: "id",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "applicationName",
      label: "Application Name",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),

    },
  },
    {
      name: "requested_on",
      label: "Date of Request",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value, meta) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB");
          return <>{formattedDate}</>;
        },
      },
    },
    {
      name: "manager_name",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),

    },
      label: "Manager Name",
    },
    {
      name: "manager_approval_updated_on",
      label: "Manager Updated On",
      options: {
         setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value, meta) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB");
          return <>{formattedDate}</>;
        },
      },

    },
    {
      name: "manager_approval_status",
      label: "Manager Approval",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value) => {
          console.log(value, "valuevalue")
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    },
    {
      name: "ceo_approval_updated_on",
      label: "CEO Updated On",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value, meta) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB");
          return <>{value ? formattedDate:'-'}</>;
        },
      },

    },
    {
      name: "overallStatus",
      label: "Overall Status",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value) => {
          console.log(value, "valuevalue")
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    }
  ];

  const customerRequests = useMemo(() => {
    return TranslationPluginRequest?.map((el, i) => ({
      ...el,
      api_key: el.api_key,
      app_name: el?.app_name,
      date_of_request: el.requested_on,
      host_name: el.host_url,
      manager_name: el.manager_approval?.updater_name,
      manager_approval_updated_on: el.manager_approval?.updated_on,
      manager_approval: el.manager_approval?.status,
      ceo_approval_updated_on: el.ceo_approval?.updated_on,
      overallStatus: el?.status,
    }));
  }, [TranslationPluginRequest]);

  const Bhashinicolumns = [
    {
      name: "api_key",
      label: "API Key",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "app_name",
      label: "App Name",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "host_name",
      label: "Host Name",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "date_of_request",
      label: "Date of Request",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value, meta) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB");
          return <>{formattedDate}</>;
        },
      },

    },
    {
      name: "manager_name",
      label: "Manager Name",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
      },
    },
    {
      name: "manager_approval_updated_on",
      label: "Manager Updated On",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value, meta) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB");
          return <>{formattedDate}</>;
        },
      },

    },
    {
      name: "manager_approval",
      label: "Manager Approval",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value) => {
          console.log(value, "valuevalue")
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    },
    {
      name: "ceo_approval_updated_on",
      label: "CEO Updated On",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value, meta) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("en-GB");
          return <>{value ? formattedDate:'-'}</>;
        },
      },

    },
    {
      name: "overallStatus",
      label: "Overall Status",
      options: {
        setCellHeaderProps: () => ({
          style: {
            width: "auto",
            whiteSpace: 'nowrap', 
            overflow: 'hidden',  
            textOverflow: 'ellipsis' 
          },
        }),
        customBodyRender: (value) => {
          console.log(value, "valuevalue")
          return (
            <>
              {value === "Approved" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#EDFFEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CheckCircleIcon
                    style={{ width: "16px", height: "16px", color: "#165E3D" }}
                  />
                  <Typography
                    color={"#165E3D"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Approved
                  </Typography>
                </Box>
              ) : value === "Pending" ? (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFFCEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <RemoveCircleIcon
                    style={{ width: "16px", height: "16px", color: "#B89231" }}
                  />
                  <Typography
                    color={"#B89231"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Pending
                  </Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  gap="6px"
                  width="fit-content"
                  bgcolor={"#FFEAEA"}
                  borderRadius="100px"
                  padding={"4px 6px"}
                >
                  <CancelIcon
                    style={{ width: "16px", height: "16px", color: "#B83131" }}
                  />
                  <Typography
                    color={"#B83131"}
                    fontSize="12px"
                    fontWeight={500}
                  >
                    Rejected
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    }
  ];



  return (
    <div>
      <Container maxWidth="lg">

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Avatar
                        style={{
                          height: "57px",
                          width: "57px",
                          backgroundColor: "#A8A8A8",
                          color: "#595656",
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography fontWeight={700} fontSize={"24px"}>
                        {Profile?.first_name} {Profile?.last_name}
                      </Typography>
                      <Typography fontSize={"16px"} gutterBottom color="#3A3A3A">
                        {Profile?.email_id}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>



        <Grid container direction="row">
          <Typography sx={{ backgroundColor: "#F5F7FA", p: 2, fontWeight: 600 }}>Parikshan Application Requests</Typography>
          <Grid item md={12} xs={12} style={{ width: "100%" }}>

            <ThemeProvider theme={tableTheme}>
              <MUIDataTable
                ref={tableRef}
                data={rowData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </Grid>
          <Typography sx={{ backgroundColor: "#F5F7FA", p: 2, fontWeight: 600 }}>Translation Plugin Requests</Typography>

          <Grid item md={12} xs={12} style={{ width: "100%" }} sx={{ mt: 2 }}>

            <ThemeProvider theme={tableTheme}>
              <MUIDataTable
                ref={tableRef}
                data={customerRequests}
                columns={Bhashinicolumns}
                options={options}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default RequestDetails;
