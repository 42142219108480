import React ,{useEffect,useState}from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { DashboardStyles } from "../../styles/dashboardStyles";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const HorizontalBarChartComponent = ({ ManageroverviewRequests }) => {
  const navigate = useNavigate();
  const classes = DashboardStyles();
  const [containerHeight, setContainerHeight] = useState(300);

  const labels = ManageroverviewRequests.map((item) => item.manager_name);
  const emails = ManageroverviewRequests.map((item) => item.manager_email);

  useEffect(() => {
    const handleResize = () => {
      setContainerHeight(window.innerWidth < 600 ? 200 : 300); 
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const approvedRequests = ManageroverviewRequests.map(
    (item) => item.approved_requests
  );
  const pendingRequests = ManageroverviewRequests.map(
    (item) => item.pending_requests
  );
  const rejectedRequests = ManageroverviewRequests.map(
    (item) => item.rejected_requests
  );

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Approved",
        data: approvedRequests,
        backgroundColor: "#05B966",
        borderColor: "#05B966",
        borderWidth: 1,
        barPercentage: 0.6,
        categoryPercentage: 1.0,
        barThickness: 18,
      },
      {
        label: "Pending",
        data: pendingRequests,
        backgroundColor: "#F0B727",
        borderColor: "#F0B727",
        borderWidth: 1,
        barPercentage: 0.6,
        categoryPercentage: 1.0,
        barThickness: 18,
      },
      {
        label: "Rejected",
        data: rejectedRequests,
        backgroundColor: "#E94949",
        borderColor: "#E94949",
        borderWidth: 1,
        barPercentage: 0.6,
        categoryPercentage: 1.0,
        barThickness: 18,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "end",
        color: "#000",
        font: {
          weight: "bold",
        },
        formatter: (value) => value,
        padding: {
          top: 8,
        },
        clip: false,
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 40,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(0, 0, 0, 0.87)",
          font: {
            size: 13,
          },
        },
      },
    },
  };

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12}>
      <Box
          sx={{
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "relative",
              height: containerHeight,
            }}
          >
            <Bar data={data} options={options} height={containerHeight} />
          </Box>
        </Box>
      </Grid>

      <Grid container item xs={12} spacing={1} sx={{ ml: 1 }}>
        <Grid item>
          <Box className={classes.managerBox} sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "#05B966",
                marginRight: 1,
              }}
            />
            <Typography variant="subtitle2" className={classes.managerTypography}>
              Approved
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box className={classes.managerBox} sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "#F0B727",
                marginRight: 1,
              }}
            />
            <Typography variant="subtitle2" className={classes.managerTypography}>
              Pending
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box className={classes.managerBox} sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "#E94949",
                marginRight: 1,
              }}
            />
            <Typography variant="subtitle2" className={classes.managerTypography}>
              Rejected
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>

  );
};

export default HorizontalBarChartComponent;
