import "./FormComponent.css";
import { useCallback, useEffect, useRef, useState } from "react";
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContactInformation from "./ContactInformation";
import axios from "axios";
import { toast } from "react-toastify";
import EmailVerification from "./EmailVerification";
import AdditionalInfo from "./AdditionalInfo";
// import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Stepper, Step, StepLabel, Box, Typography,styled, useMediaQuery,StepConnector, stepConnectorClasses } from '@mui/material';
import Check from '@mui/icons-material/Check';
import UserRole from "./UserRole";
import { format } from "date-fns";
import VerifyManagerRegistrationAPI from "../../../../redux/actions/api/UserManagement/VerifyManagerRegistration";
import { APITransport } from "../../../../redux/actions";
import { useDispatch,useSelector } from "react-redux";

const steps = [ 'Create Account', 'Add Personal Info', 'Verification'];

// Custom styles for the step icons
const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  display: 'flex',
  height: 22,
  alignItems: 'center',
  color: ownerState.completed || ownerState.active ? theme.palette.primary.main : theme.palette.grey[400],
  '& .QontoStepIcon-completedIcon': {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
    backgroundColor: ownerState.completed ? "#0A65CC" : 'transparent',
    borderRadius: '50%',
    width: 30,
    height: 30,
    color:"white",
    padding:"5px",
  },
  '& .QontoStepIcon-circleNumber': {
    color: ownerState.active ? theme.palette.primary.main : theme.palette.grey[500],
    border: ownerState.active ? "3px solid #0A65CC" : '2px solid lightGray',
    borderRadius: '50%',
    width: 30,
    height: 30,
    display: 'flex',
    color:"#0A65CC",
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
  },
}));

const QontoStepIcon = (props) => {
  const { active, completed, icon, label, currentStep, index, isMobile } = props;
  const ownerState = { completed, active };

  return (
    <StepIconRoot ownerState={ownerState} >
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <>
          <div className="QontoStepIcon-circleNumber">{icon}</div>
        </>
      )}
    </StepIconRoot>
  );
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StepperComponent = ({ currentStep }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Stepper alternativeLabel activeStep={currentStep - 1} connector={<QontoConnector />}>
      {steps.map((label, index) => (
        <Step key={label} >
          <StepLabel StepIconComponent={(props) => <QontoStepIcon {...props} icon={index + 1} label={label} currentStep={currentStep} index={index} isMobile={isMobile} />}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="body2" sx={{ fontWeight: currentStep - 1 === index ? 'bold' : 'normal', fontSize: isMobile ? '12px' : '16px', marginBottom: 1 }}>
                {label}
              </Typography>
              {/* <Box
                sx={{
                  width: '100%',
                  height: 4,
                  backgroundColor: currentStep > index ? 'primary.main' : 'grey.300',
                  mt: 0.5,
                }}
              /> */}
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
const FormComponent = ({
 
  formData,
  setFormData,
 

}) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isButtonDisabled1, setButtonDisabled1] = useState(true);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSubmitted1, setSubmitted1] = useState(false);
  const [otpValues, setOtpValues] = useState(null);
  const [emailOTPSuccess, setEmailOTPSucess] = useState(false)
  const [alternateEmailOTPSuccess, setAlternateEmailOTPSucess] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const [errors, setErrors] = useState({});
  const [time, setTime] = useState(180); // 15 minutes in seconds (15 * 60)
  const [timerRunning, setTimerRunning] = useState(false);
  const [verifyManager, setVerifyManager] = useState(false);
  const Email_OTP_Inputs = useRef()
  const [selectedRole, setSelectedRole] = useState('customer');
  const [emailError, setEmailError] = useState("");
  const Alternate_Email_OTP_Inputs = [useRef(), useRef(), useRef(), useRef()];
  const apiStatus = useSelector((state) => state.apiStatus)

  const buttonRef = useRef(null);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const Base_url =
  process.env.REACT_APP_APIGW_BASE_URL ||
  "https://dashboard-staging-be.bhashini.co.in";

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemaining = seconds % 60;
    // Use padStart to ensure two digits for seconds
    const formattedSeconds = String(secondsRemaining).padStart(2, '0');
    return `${minutes}:${formattedSeconds}`;
  };


  const startTimer = () => {
    if (!timerRunning) {
      setTimerRunning(true);
      setTime(180);
    }
  };

  useEffect(() => {
    let timerInterval;

    if (timerRunning && time > 0) {
      timerInterval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time <= 0) {
      clearInterval(timerInterval);
      setTimerRunning(false);
      // alert("time has been expired");
      // Timer has reached 0, you can trigger some action here
    }

    return () => clearInterval(timerInterval);
  }, [time, timerRunning]);

  useEffect(() => {
    if (apiStatus && apiStatus.apiType === "VERIFY_MANAGER_REGISTRATION") {
          if(apiStatus.errors===true){
              setVerifyManager(false)
           } else {
                  setVerifyManager(true)
                  handleEmailSubmit()
                  setStep(step + 1);
                }
    }
}, [apiStatus])

  const verifyManagerRegistration =()=>{
        const apiObj = new VerifyManagerRegistrationAPI(formData?.email_address);
        dispatch(APITransport(apiObj));

  }


  const handleEmailSubmitSuccess = async () => {
    setSubmitted(false)
    const endpoint = `${Base_url}/v1/verify_otp`;
    try {
      const response = await axios.post(endpoint, {
        email_id: formData?.email_address,
        otp: formData?.otpValue,
      });
      console.log(response.data)
      setEmailOTPSucess(true)
      toast.success(response?.data?.message);
      return response.data;
      // setResponse(response.data);
    } catch (error) {
      console.error('Error sending OTP:', error);
      setSubmitted(true)
      setEmailOTPSucess(false)
      toast.error(error?.response?.data?.detail)
      return null;
      // Handle error state here if needed
    }
    // console.log(otpValues.join(''), "text")
    // alert("email sent successfully");
  };

  const validateForm0 = () => {
    const errors = {};
    if (formData.userRole === '') {
      errors.otpValue = 'User Role is required';
    }
    return errors;
  };
    
  const validateForm1 = () => {
    const errors = {};
    if (!formData.firstname.trim() || formData.firstname === '') {
      errors.firstname = 'First Name is required';
    }
  
    if (!formData.lastname.trim() || formData.lastname === '') {
      errors.lastname = 'Last Name  is required';
    }

    if (!formData.email_address.trim() || formData.email_address === '') {
      errors.email_address = 'Email Address is required';
    }

    if (formData.confirmation === false) {
      errors.confirmation = 'Please confirm the above information';
    }

  
    return errors;
  };

  const validateForm2 = () => {
    const errors = {};
    if (formData.otpValue === '') {
      errors.otpValue = 'OTP is required';
    }
    return errors;
  };

  const validateForm3 = () => {
    const errors = {};
    if (!formData.date_of_birth || isNaN(new Date(formData.date_of_birth).getTime())) {
      errors.date_of_birth = 'Date of birth is required or invalid';
    }
  
    if (!formData.phone_number.trim() || formData.phone_number === '') {
      errors.phone_number = 'Phone Number  is required';
    }

    if (!formData.personal_email_Address.trim() || formData.personal_email_Address === '') {
      errors.personal_email_Address = 'Email Address is required';
    }


    return errors;
  };

  const handleForm0ButtonClick = () => {
    const validationErrors = validateForm0();
    // if (selectedRole) {
    //   console.log("Selected Role:", selectedRole);
    //   setStep((prev) => prev + 1)
    //   // Proceed to the next step in your form logic
    // }
    if (Object.keys(validationErrors).length === 0) {
      setErrors({})
      console.log('Form submitted:', validationErrors);
      // startTimer()
    } else {
      setErrors(validationErrors);
    }
  };
  const handleForm1ButtonClick = () => {
    const validationErrors = validateForm1();
    if ((Object.keys(validationErrors).length === 0) && (verifyManager===true)
    ) {
      setErrors({})
      console.log('Form submitted:', validationErrors);
      // startTimer()
    } else {
      setErrors(validationErrors);
    }
  }

  const handleForm2ButtonClick = async() => {
    const validationErrors = validateForm2();
    if (Object.keys(validationErrors).length === 0) {
      setErrors({})
      // setEmailOTPSucess(true)
      try {
        const data = await handleEmailSubmitSuccess(); // Await the async function
        if (data) {
          console.log(data, "data");
          setEmailOTPSucess(true)
          setTimeout(() => {
            if (data.message === 'OTP verified successfully') {
              // setStep(step + 1);
              // Call signup API
      const myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("authorization", localStorage.getItem('token'))

      const urlencoded = new URLSearchParams();
      urlencoded.append("name", formData.firstname + '' + formData.lastname);
      // urlencoded.append("role", formData.userRole);
      urlencoded.append("first_name", formData.firstname);
      urlencoded.append("last_name", formData.lastname);
      urlencoded.append("firstname", formData.firstname);
      urlencoded.append("lastname", formData.lastname);
      urlencoded.append("official_email", formData.email_address);
      urlencoded.append("personal_email", formData.personal_email_Address);
      urlencoded.append("dob", new Date(formData.date_of_birth).toLocaleDateString('en-GB'));
      urlencoded.append("phone_number", formData.phone_number);
      urlencoded.append("tnc_url", formData.termsLink);
      urlencoded.append("gender", formData.gender);
      urlencoded.append("organization_type", formData.organisation_type);
      urlencoded.append("role", formData.userRole);
      urlencoded.append("date_of_joining", formData.date_of_joining);
      // urlencoded.append("alternative_phone_number", formData.alternative_phone_number);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow"
      };
      const endpoint = formData.userRole === 'customer' ? `${Base_url}/v1/signup` : `${Base_url}/v1/manager-signup`;
      fetch(endpoint, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if(result.code === 422 || result.code === 400){
            toast.error(result.message)
          }else{

            setFormSuccess(true)
            setStep(step + 1)
            toast.success(result.message)
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong!")
        });   
            } else {
              setStep(2)
              console.error('OTP verification failed.');
            }
          }, 3000); // Adding a slight delay to ensure state update
        } else {
          setEmailOTPSucess(false)
          console.error('No data returned from email submission.');
        }
      } catch (error) {
        console.error('Error during email submission:', error);
        setEmailOTPSucess(false)
      }
      console.log('Form submitted:', validationErrors);
    } else {
      setErrors(validationErrors);
    }
  }

  const handleForm3ButtonClick = () => {
    const validationErrors = validateForm3();
    if (Object.keys(validationErrors).length === 0) {
      setErrors({})
      startTimer()
      console.log('Form submitted:', validationErrors);
    } else {
      setErrors(validationErrors);
    }
  }

  // Function to signup user from first page with basic information only
  const handleBasicInfoSubmitClick = async (event) => {
    event.preventDefault();

    console.log("submit clicked...");

    const validationFunctions = [validateForm1,validateForm3, validateForm2];
    const validationErrors = validationFunctions[step - 1]();
    if (Object.keys(validationErrors).length === 0) {
    // if (!formData.representative_name) {
    //   toast.error("Name cannot be empty.");
    //   setButtonDisabled(false)
    // } else if (emailOTPSuccess === false) {
    //   toast.error("Please provide a valid email and verify it with OTP.");
    //   setButtonDisabled(false)
    // } else if (!formData.phone_number || formData.phone_number.length < 10) {
    //   toast.error("Please provide valid phone number.")
    //   setButtonDisabled(false)
    // } else if (!formData.date_of_birth) {
    //   toast.error("Please provide a valid date of birth.")
    //   setButtonDisabled(false)
    // } else {

    if(step === 1 ){
      setStep(step + 1)
      handleEmailSubmit()

    // } else if (step === 2) {
    //   // if (isButtonDisabled) {
    //   //   toast.error('Please Enter Valid Email');
    //   //   // setButtonDisabled(false);
    //   //   return;
    //   // }
      
    //   if(formData.userRole==="customer"){
    //     setStep(step + 1);
    //     handleEmailSubmit()
    //   }else{
    //     verifyManagerRegistration()
    //   }
    } 
    else if(step === 2){
      const today = new Date();
      const birthDate = new Date(formData.date_of_birth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
  
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
  
      if (age < 18) {
        toast.error("You must be at least 18 years old.");
        setButtonDisabled(false);
        return;
      }

    const officialEmail = formData?.email_address?.trim().toLowerCase();
    const personalEmail = formData?.personal_email_Address?.trim().toLowerCase();
    
    const areEmailsSame = officialEmail && personalEmail && (officialEmail === personalEmail);

    if (areEmailsSame || emailError !== "") {
      setEmailError('Official Email Id and Personal Email Id cannot be the same');
      return
    }

    if (!emailRegex.test(personalEmail)) {
      toast.error('Email format is incorrect');
      return
    }   

      setStep((prev) => prev + 1)
    }else{
  
      if (step === 3 && Object.keys(validationErrors).length === 0 ) {
      
      
    }
}
}   
  
  }

  // Function to handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setButtonDisabled(!emailRegex.test(value));
    // setButtonDisabled1(!emailRegex.test(value));
  };

  const handleOrganisationType = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData, // Preserve other state properties if needed
      organisation_type: event.target.value,
    }));
  };

  const handleChange1 = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setButtonDisabled1(!emailRegex.test(value));
    // setButtonDisabled1(!emailRegex.test(value));
  };

  const stepChangePrevHandler = () => {
    setStep(step - 1);
  };

  const handleEmailSubmit = useCallback(async () => {
    setSubmitted(true);
  const endpoint = `${Base_url}/v1/send_otp`;
    try {
      const response = await axios.post(endpoint, {
        email_id: formData?.email_address
      });
      console.log(response.data)
      toast.success(response?.data?.message);
      startTimer()
      // setResponse(response.data);
    } catch (error) {
      console.error('Error sending OTP:', error);
      // Handle error state here if needed
    }
  }, [formData?.email_address]);

  // const handleAlternateEmailSubmit = useCallback(async () => {
  //   setSubmitted1(true);
  //   try {
  //     const response = await axios.post('https://dashboard-staging-be.bhashini.co.in/send_otp', {
  //       email: formData?.alternative_email_Address
  //     });
  //     console.log(response.data)

  //     toast.success(response?.data?.message);
  //     // setResponse(response.data);
  //   } catch (error) {
  //     console.error('Error sending OTP:', error);
  //     // Handle error state here if needed
  //   }
  // }, [formData?.alternative_email_Address]);
  const termsLink = 'https://userdatav1.blob.core.windows.net/dashboardblob/Terms_and_Conditions_Bhashini.pdf'

  const handleCheckboxChange = (event) => {
    // setFormData({
    //   ...formData,
    //   // confirmation: !formData.confirmation,
    //   confirmation: event.target.checked,
    //   link: termsLink,
    // })
    setFormData((prevData) => ({
      ...prevData,
      confirmation: event.target.checked,
      termsLink: termsLink,
    }));
  };



  // const handleAlternativeEmailSubmitSuccess = async () => {
  //   setSubmitted1(false)
  //   setOtpValues(null)
  //   try {
  //     const response = await axios.post('https://dashboard-staging-be.bhashini.co.in/verify_otp', {
  //       email: formData?.alternative_email_Address,
  //       otp: otpValues.join(''),
  //     });
  //     console.log(response.data)
  //     setAlternateEmailOTPSucess(true)
  //     toast.success(response?.data?.message);
  //     // setResponse(response.data);
  //   } catch (error) {
  //     console.error('Error sending OTP:', error);
  //     toast.error("Invalid OTP")
  //     // Handle error state here if needed
  //   }
  //   console.log(otpValues.join(''), "text")
  //   // alert("email sent successfully");
  // };

  const focusNextInput = (index, OTP_Ref_Arr) => {
    if (index < OTP_Ref_Arr.length - 1) {
      OTP_Ref_Arr[index + 1].current.focus();
    }
  };

  const focusPrevInput = (index, OTP_Ref_Arr) => {
    if (index > 0) {
      OTP_Ref_Arr[index - 1].current.focus();
    }
  };

  const handleKeyDown = (e, index, OTP_Ref_Arr) => {
    if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
      focusNextInput(index, OTP_Ref_Arr);
    } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      focusPrevInput(index, OTP_Ref_Arr);
    } else if (e.key === 'Backspace' && e.target.value === '') {
      focusPrevInput(index, OTP_Ref_Arr);
    }

    console.log(e.target.value, "key")
  };

  const handleOTPChange = (e, index, OTP_Ref_Arr) => {
    const newValue = e.target.value;
    // if (e.target.value.length === 1) {
    //   focusNextInput(index, OTP_Ref_Arr);
    // }

    // const otpValuesFromRefs = OTP_Ref_Arr.map(ref => ref.current.value);
    console.log("OTP values from refs:", newValue);
    setOtpValues(newValue)

  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    setFormData((prevFormData) => ({
      ...prevFormData, // Preserve other state properties if needed
      userRole: role,
    }));
  };


  useEffect(() => {
    // Set the current date as the placeholder
    const currentDate = new Date();
    // setFormData(format(currentDate, 'MM/dd/yyyy'));
    setFormData((prevFormData) => ({
      ...prevFormData, // Preserve other state properties if needed
      date_of_birth: format(currentDate, 'MM/dd/yyyy'),
    }));
  }, []);

  useEffect(() => {
    const { personal_email_Address, email_address } = formData;
    if (personal_email_Address && email_address &&  (personal_email_Address.toLowerCase().startsWith(email_address.toLowerCase()) ||
    email_address.toLowerCase().startsWith(personal_email_Address.toLowerCase()))) {
     setEmailError("Official Email Id and Personal Email Id cannot be the same");
    } else {
      setEmailError("");
    }
  }, [formData]);
 

  const renderFormFields = () => {

    return (
      <>
      {/* {step === 1 && <UserRole handleSubmit={handleBasicInfoSubmitClick} handleRoleSelect={handleRoleSelect} handleForm0ButtonClick={handleForm0ButtonClick} selectedRole={selectedRole}/>} */}

       {step === 1 && <ContactInformation handleSubmit={handleBasicInfoSubmitClick} isSubmitted={isSubmitted} Email_OTP_Inputs={Email_OTP_Inputs} handleKeyDown={handleKeyDown} handleOTPChange={handleOTPChange} isButtonDisabled={isButtonDisabled} buttonRef={buttonRef} formData={formData} handleEmailSubmit={handleEmailSubmit} isSubmitted1={isSubmitted1} Alternate_Email_OTP_Inputs={Alternate_Email_OTP_Inputs} isButtonDisabled1={isButtonDisabled1} handleChange1={handleChange1}  handleChange={handleChange} step={step} stepChangePrevHandler={stepChangePrevHandler} handleEmailSubmitSuccess={handleEmailSubmitSuccess}  emailOTPSuccess={emailOTPSuccess} alternateEmailOTPSuccess={alternateEmailOTPSuccess}  errors={errors} handleForm1ButtonClick={handleForm1ButtonClick} handleCheckboxChange={handleCheckboxChange} handleOrganisationType={handleOrganisationType} />}

       {step === 2 && <AdditionalInfo handleSubmit={handleBasicInfoSubmitClick} formData={formData} handleChange={handleChange} errors={errors} handleForm3ButtonClick={handleForm3ButtonClick} stepChangePrevHandler={stepChangePrevHandler} emailError={emailError}/>}

       {step === 3 && <EmailVerification handleEmailSubmit={handleEmailSubmit} formData={formData} handleOTPChange={handleOTPChange} Email_OTP_Inputs={Email_OTP_Inputs} handleForm2ButtonClick={handleForm2ButtonClick} errors={errors} handleChange={handleChange} handleSubmit={handleBasicInfoSubmitClick} formatTime={formatTime} time={time} stepChangePrevHandler={stepChangePrevHandler}/>}


        


      </>
    )
  };
  return (
    formSuccess ?
      <div style={{ textAlign: "-webkit-center",display: "flex",flexDirection: "column",justifyContent: "center",alignTtems: "center",
        margin: "auto",height: "100%", padding:"10px 50px" }}>
        {/* <CheckCircleOutlineIcon sx={{ fontSize: 100, background: "#4CAF50", borderRadius: "50px", color: "#FFFFFF" }} /> */}
        <Typography variant="h3" sx={{ marginY: "3%" }} fontFamily={"Inter, sans-serif"} fontWeight={600} gutterBottom>
        {selectedRole ==="User"? " Your registration is successful !":"Your registration request has been successfully submitted!"}
        </Typography>
        <Typography variant="body1" width={"100%"} textAlign="center" fontFamily={"Inter, sans-serif"} fontWeight={500}>
{     selectedRole ==="customer" ? "You will receive your account credentials at your official email" :"Your registration request has been sent for approval. You'll receive your account credentials at your official email after the authentication and verification process"
}        </Typography>
  <br />
        <Typography variant="body1" width={"100%"} textAlign="center" fontFamily={"Inter, sans-serif"} fontWeight={500}>
        Redirect to &nbsp;<Link to='/user/login'> Log in </Link> </Typography>
      </div> : <div className="FormComponent__container">
       
       <StepperComponent currentStep={step} />

        {renderFormFields()}
        <Box display="flex" justifyContent="center" marginTop="12%">
        <Typography variant="h5" sx={{ marginTop: "3%" }} fontFamily={"Inter, sans-serif"} fontWeight={600} gutterBottom>
        Already have an account?<Link to='/user/login'> Log in </Link>
        </Typography>
        </Box>
      </div>

  );
};

export default FormComponent;
