// LogoutAPI.js
import API from "../../api";
import ENDPOINTS from "../../../../../config/apiEndpoint";
import C from "../../../../constants";
import axios from 'axios';


export default class viewPdfAPI extends API {
  constructor(value, timeout = 2000) {
    super("GET", timeout, false); 
    this.type = C.VIEW_PDF;
    this.endpoint = `${super.apiEndPointAuto()}${ENDPOINTS.viewPDF}?filename=${value}`;
  }
  async downloadDocument() {
    try {
      const response = await axios.get(this.endpoint, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': localStorage.getItem('token')
        }
      });
  
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
  
      // Convert the response to a Blob
      const blob = response.data;
  
      // Ensure the blob is a PDF
      if (blob.type !== 'application/pdf') {
        throw new Error('The file is not a PDF. Please download the file to view it.');
      }
  
      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);
  
      // Open the PDF in a new tab
      window.open(url, '_blank');
  
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert(error.message || "An error occurred while processing the document.");
    }
  }
  
  
  processResponse(res) {
    super.processResponse(res);
    console.log(res,"res");
    if (res) {
        this.report = res;
    }
  }

  apiEndPoint() {
    return this.endpoint;
  }

  getBody() {
    
    return {};
  }  

  getHeaders() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('token')
      },
    };
  }

  getPayload() {
    return this.report;
  }
}
